'use strict';
var util = require('./util');

/**
 * Global Carousel Object
 *
 * - Registered carousels in data.sliders will try to init once per page load.
 *
 * - To init a specific carousel (after an ajax call for example) use the load() method.
 * (e.g., carousel.load('#homepage-slides'))
 *
 * - Additional slick operations are expected to be handled outside of this object
 * (e.g., .slick('unslick'))
 *
 * @object      data
 * @function    init
 * @function    load
 * @function    loadAll
 * @function    getSlider
 */
var carousel = {
    data: {
        // Default slider configurations
        // - overwritable if redefined in a slider's options object (data.sliders.options)
        defaultOptions: {
            arrows: false,
            centerMode: true,
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            speed: 300,
            responsive: [
                {
                    breakpoint: util.getViewports('large'),
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },
        sliders: [{
            // Home Page Content Asset Carousel (ID: home-cycle)
            element: '#homepage-slides',
            options: {
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        }, {
            // Header Banner Content Asset Carousel (content slot: header-banner)
            element: '#header-banner-carousel',
            options: {
                centerMode: false,
                slidesToScroll: 1,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 20000,
                dots: false
            }
        }, {
            // Product Recommendations Carousel
            element: '#carousel-recommendations',
            options: {
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: util.getViewports('large'),
                        settings: {
                            slidesToShow: 2
                        }
                    }
                ]
            }
        }, {
            // Blog landing Carousel
            element: '#carousel-blog-landing',
            options: {
                arrows: true,
                autoplay: false,
                centerMode: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                cssEase: 'linear',
                variableWidth: true,
                variableHeight: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            centerMode: false
                        }
                    }
                ]
            }
        }, {
            // Blog Outfit Carousel 1
            element: '#carousel-blog-outfit1',
            options: {
                arrows: true,
                autoplay: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                cssEase: 'linear',
                variableWidth: true,
                variableHeight: true
            }
        }, {
            // Blog Outfit Carousel 2
            element: '#carousel-blog-outfit2',
            options: {
                arrows: true,
                autoplay: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                cssEase: 'linear',
                variableWidth: true,
                variableHeight: true
            }
        }, {
            // Blog Outfit Carousel 3
            element: '#carousel-blog-outfit3',
            options: {
                arrows: true,
                autoplay: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                cssEase: 'linear',
                variableWidth: true,
                variableHeight: true
            }
        }, {
            // PDP slides
            element: '#pdp-main-slides',
            options: {
                centerMode: false,
                adaptiveHeight: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        }]
    },

    init: function () {
        // Load all necessary carousels for the current page
        this.loadAll();

        return;
    },

    /**
     * Load all sliders in data.sliders that
     * exist on the current page
     *
     * @var     obj     options
     * @return  VOID
     */
    loadAll: function () {
        // Loops through all registered carousels
        for (var i = 0; i < this.data.sliders.length; i++) {
            // If the carousel selector exists initialize slick
            if ($(this.data.sliders[i].element).length > 0) {
                // Get the slider configurations
                var options = util.extendObject(this.data.defaultOptions, this.data.sliders[i].options);

                // Initialize Slick
                $(this.data.sliders[i].element).slick(options);

                // Match slide heights
                if (!('syncHeight' in this.data.sliders[i]) || this.data.sliders[i].syncHeight && window.innerWidth >= 768) {
                    this.syncHeight(this.data.sliders[i].element);
                }
            }
        }
        this.denimLandingSliders();
        this.loadPdp();
        this.loadPdpZoom();
        this.fitFinderCarousel();
        return;
    },

    loadPdp: function () {
        // Load PDP carousel
        // Re-init Slick after it's been destroyed using Window resize handler
        $('.product-images-carousel.first-carousel').on('destroy', function (e, slick) {
            var carousel = $(this);
            var timeout;
            $(window).on('resize.pdp-carousel', function () {
                // Debounce the resize event
                clearTimeout(timeout);
                timeout = setTimeout(function () {

                    // Ignore if not in carousel viewport width
                    if (slick.activeBreakpoint < window.innerWidth) {
                        return;
                    }

                    // Re-instantiate Slick
                    carousel.slick(slick.options);

                    // Window resize unsubscribe
                    $(window).off('resize.pdp-carousel');

                }, 100);

            });
        }).slick({
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            arrows: true,
            asNavFor: '#pdp-image-carousel-thumbnail',
            responsive: [
                {
                    breakpoint: (util.isMobileSize),
                    settings: 'unslick'
                }
            ]
        });
        $('#pdp-image-carousel-thumbnail').on('destroy', function (e, slick) {
            var carousel = $(this);
            var timeout;
            $(window).on('resize.pdp-carousel', function () {
                // Debounce the resize event
                clearTimeout(timeout);
                timeout = setTimeout(function () {

                    // Ignore if not in carousel viewport width
                    if (slick.activeBreakpoint < window.innerWidth) {
                        return;
                    }

                    // Re-instantiate Slick
                    carousel.slick(slick.options);

                    // Window resize unsubscribe
                    $(window).off('resize.pdp-carousel');

                }, 100);

            });
        }).slick({
            dots: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            mobileFirst: true,
            arrows: false,
            focusOnSelect: true,
            asNavFor: '#pdp-image-carousel',
            responsive: [
                {
                    breakpoint: (util.isMobileSize),
                    settings: 'unslick'
                }
            ]
        });
    },
    loadPdpZoom: function () {
        // Load PDP carousel
        // Re-init Slick after it's been destroyed using Window resize handler
        $('.zoom-images-carousel').on('destroy', function (e, slick) {
            var carousel = $(this);
            var timeout;
            $(window).on('resize.pdp-carousel', function () {
                // Debounce the resize event
                clearTimeout(timeout);
                timeout = setTimeout(function () {

                    // Ignore if not in carousel viewport width
                    if (slick.activeBreakpoint < window.innerWidth) {
                        return
                    }

                    // Re-instantiate Slick
                    carousel.slick(slick.options);

                    // Window resize unsubscribe
                    $(window).off('resize.pdp-carousel');

                }, 100);

            });
        }).not('.slick-initialized').slick({
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false
        });
        
    },
    loadQuickView: function ($container) {
        setTimeout(() => {
            $container.find('.product-image-container').on('destroy', function (e, slick) {
                var carousel = $(this);
                var timeout;
                $(window).on('resize.pdp-carousel', function () {
                    // Debounce the resize event
                    clearTimeout(timeout);
                    timeout = setTimeout(function () { // Ignore if not in carousel viewport width
                        if (slick.activeBreakpoint < window.innerWidth) {
                            return
                        } // Re-instantiate Slick
                        carousel.slick(slick.options); // Window resize unsubscribe
                        $(window).off('resize.pdp-carousel'); 
                    }, 100); 
                });
            }).not('.slick-initialized').slick({
                slidesToShow: 1,
                slidesToScroll: 1, 
                dots: true,
                arrows:true,
                appendDots: $container.find('.carousel-dots'),
                prevArrow: $container.find('.carousel-arrow-prev.slick-prev'),
                nextArrow: $container.find('.carousel-arrow-next.slick-next'),
            }).on('beforeChange', function() {
                $(this).addClass('is-sliding');
            }).on('afterChange', function(){
                $(this).removeClass('is-sliding');
            })
        }, 0);
    },
    loadShopTheLook: function ($container, index) {
        index = index ?? 0;
        setTimeout(() => {
            $container.find('.stl-slick-container').on('destroy', function (e, slick) {
                var carousel = $(this);
                var timeout;
                $(window).on('resize.stl-carousel', function () {
                    clearTimeout(timeout)
                    timeout = setTimeout(function () { // Ignore if not in carousel viewport width
                        if (slick.activeBreakpoint < window.innerWidth) {
                            return
                        } // Re-instantiate Slick
                        carousel.slick(slick.options); // Window resize unsubscribe
                        $(window).off('resize.pdp-carousel'); 
                    }, 100); 
                });
            }).not('.slick-initialized').slick({
                vertical:true,
                verticalSwiping:true,
                slidesToShow:3,
                initialSlide:index,
                slidesToScroll:1, 
                infinite:false,
                arrows:true,
                prevArrow: $container.find('.stl-carousel-arrow-prev.slick-prev'),
                nextArrow: $container.find('.stl-carousel-arrow-next.slick-next'),
                responsive:[
                    {
                        breakpoint: 1025,
                        settings: {
                            vertical:false,
                            verticalSwiping:false,
                            slidesToShow:5,
                        }
                    },
                    {
                        breakpoint: 460,
                        settings: {
                            vertical:false,
                            verticalSwiping:false,
                            slidesToShow:4,
                            draggable:true,
                        }
                    }
                ]
            }).on('beforeChange', function() {
                $(this).addClass('is-sliding');
            }).on('afterChange', function(){
                $(this).removeClass('is-sliding');
            })
        }, 0);
    },
    /**
     * Loads a specific carousel with either
     * saved options or parameter options.
     *
     * @param   obj     e
     * @param   obj     config (not required)
     * @var obj slider
     * @var obj configurations
     * @var obj selector
     * @return  VOID
     */
    load: function (e, config) {
        // If the carousel element exists on the page
        if ($(e).length > 0) {

            var slider = this.getSlider(e), // Check if the carousel is registered in carousel.data.sliders
                configurations = null,
                selector = null;

            // Carousel is registered
            if (slider != false || slider != undefined) {
                configurations = util.extendObject(this.data.defaultOptions, slider.options);
                selector = slider.element
            } else {
                // Carousel is not registered
                configurations = util.extendObject(this.data.defaultOptions, config);
                selector = e;
            }

            // Initialize Slick
            $(selector).slick(configurations);

            // Match slide heights
            this.syncHeight(selector);
        }

        return;
    },

    /**
     * Tries to find and return data for a saved
     * carousel using the given element selector
     *
     * @param   string  slider
     * @return  MIXED
     */
    getSlider: function (slider) {
        // Loops through all registered carousels
        for (var i = 0; i < this.data.sliders.length; i++) {
            // If the carousel selector matches the given selector
            if (this.data.sliders[i].element == slider) {
                return this.data.sliders[i];
            }
        }

        // No carousel was found
        return false;
    },

    /**
     * Sets all slide heights to match the tallest
     * using the slide track as reference
     *
     * @param   string  container
     * @var     obj     track
     * @var     obj     slide
     * @var     obj     slidePadding
     * @var     int     paddingOffset
     * @return  VOID
     */
    syncHeight: function (container) {
        if ($(container).length === 0) return;
        var track = $(container).find('.slick-track'), // Carousel Slide Track
            slide = $(container).find('.slick-slide'), // Carousel Slides
            slidePadding = slide.css(['paddingTop', 'paddingBottom']), // Carousel slide top and bottom padding
            paddingOffset = parseInt(slidePadding.paddingTop, 10) + parseInt(slidePadding.paddingBottom, 10); // Add carousel slide paddings

        // Match each slide's height to the track. Compensated for individual slide paddings
        slide.css({
            height: track.innerHeight() - paddingOffset
        });
    },

    /**
     * calculating position of dots
     */
    updateDotsPosition: function(slider) {
        var imgHeight = slider.find('.slick-active img').height(),
            slickDots = slider.find('.slick-dots'),
            slickDotsHeight = slickDots.height();

        slickDots.css({'top': (imgHeight - slickDotsHeight - 20) + 'px'}).addClass('noTransform'); // 20px - bottom margin
    },

    denimLandingSliders: function() {
        // Denim Landing Freeform Carousel
        var freeFormCarousel = $('#freeform-carousel');

        freeFormCarousel.on('init afterChange', function(){
            var slider = $(this);
            carousel.updateDotsPosition(slider);
        });

        freeFormCarousel.slick({
            arrows: true,
            autoplay: false,
            centerMode: false,
            infinite: true,
            dots: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        dots: false
                    }
                }
            ]
        });

        // Category landing Carousel
        var categoryLanding = $('#cat-landing-carousel');

        categoryLanding.on('init afterChange', function(){
            var slider = $(this);
            carousel.updateDotsPosition(slider);
        });

        categoryLanding.slick({
            autoplay: false,
            centerMode: true,
            centerPadding: '24%',
            infinite: true,
            adaptiveHeight: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        centerMode: true,
                        centerPadding: '20%'
                    }
                },{
                    breakpoint: 1023,
                    settings: {
                        centerMode: true,
                        centerPadding: '18%'
                    }
                }, {
                    breakpoint: 769,
                    settings: {
                        centerMode: false,
                        centerPadding: 0,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        adaptiveHeight: true,
                        dots: false
                    }
                }
            ]
        });
    },
    fitFinderCarousel: function() {

        // Fit Finder Carousel
        var fitFinderSelector = '.fitfinder-results-carousel .carousel-inner';
        if ($(fitFinderSelector).find('li').length === 0) {
            return false;
        }

        // On slick carousel init, reveal results in the background (below the dialog modal)
        $(fitFinderSelector).on('init', function () {
            if (util.mediaBreakpointUp('md')) {
                $('.fit-finder-results.fit-finder-page').addClass('active');
            }
        });

        var $status = $('.counter-inner');

        $(fitFinderSelector).on('init reInit afterChange', function (event, slick, currentSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            if (!slick.$dots){
                return;
            }

            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + ' OF ' + (slick.$dots[0].children.length));
        });

        $(fitFinderSelector).slick({
            autoplay: false,
            arrows: true,
            centerMode: false,
            dots: true,
            infinite: false,
            slide: '.grid-tile',
            slidesToShow: 4,
            slidesToScroll: 1,
            speed: 300,
            variableHeight: false,
            responsive: [
                {
                    breakpoint: util.getViewports('desktop'),
                    settings: {
                        arrows: true,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        variableWidth: true
                    }
                },
                {
                    breakpoint: util.getViewports('large'),
                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true
                    }
                }
            ]
        });
    }
};

module.exports = function () {
    carousel.init();
};
module.exports.loadPdp = carousel.loadPdp;
module.exports.loadPdpZoom = carousel.loadPdpZoom;
module.exports.loadQuickView = carousel.loadQuickView;
module.exports.loadShopTheLook = carousel.loadShopTheLook;
