/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var util = require('org/util');
var event = require('./event');
var pages = {
    product: require('./pages/product'),
    cart: require('./pages/cart'),
    account: require('./pages/account'),
    confirmation: require('./pages/confirmation'),
    checkout: require('./pages/checkout'),
    search: require('./pages/search'),
    bazaarvoice: require('./pages/bazaarvoice')
}

exports.init = function () {
    try {
        var ns = window.pageContext.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        //global events
        var common = require('./pages/common');
        common.init();
        
        // Calls after all ajax requests to check if the server sends any data for DTM
        $(document).ajaxComplete(function () {
            if (!window.s) {
                return true;
            }
            var omniture = util.readCookie('omniture');
            var needPush = false;
            var i;
            
            util.eraseCookie('omniture');
            omniture = JSON.parse(omniture);
            
            if (!omniture) {
                return;
            }
            
            if (typeof omniture === 'string') {
                omniture = JSON.parse(omniture);
            }
            
            if (omniture.events) {
                event.removeEvents();
                for (i in omniture.events) {
                    var eventData = {name: i, value: omniture.events[i]};
                    event.pushEvent(eventData.name, eventData.value, true);                    
                }
                
                needPush = true;
            }
            
            if (omniture.variables) {
                for (i in omniture.variables) {
                    var eVar = {name: i, value: omniture.variables[i]};
                    event.pushEVar(eVar.name, eVar.value, true);                    
                }
                
                needPush = true;
            }
            
            if (omniture.properties) {
                for (i in omniture.properties) {
                    var prop = {name: i, value: omniture.properties[i]};
                    event.pushProp(prop.name, prop.value, true);                    
                }
                
                needPush = true;
            }
            
            if (omniture.products) {
                event.removeProducts();
                for (i in omniture.products) {
                    var product = omniture.products[i];
                    event.pushProduct(
                            product.category,
                            product.productName,
                            product.quantity,
                            product.price,
                            product.events,
                            product.variables,
                            true
                        );                    
                }
                
                needPush = true;
            }
            
            if (needPush) {
                event.push();
            }
        });
        
        $(window).load(function () {
            if (!window.omnitureEventsPushed) {
                event.push();
            }
        })
        
        $(document).on('click', '#BVButtonSubmitID button', function () {
            
            // event165
            // Submit Product Review
            // Counter event to trigger whenever a visitor successfully submits a product review.
            event.pushEvent('event165');
        });

        // Ingest CJ Affiliate URL Parameter values AID/PID/SID
        var cjAID = util.getUrlParameter(window.location.search, 'AID'),
            cjSID = util.getUrlParameter(window.location.search, 'SID'),
            cjPID = util.getUrlParameter(window.location.search, 'PID');
        
        var ifEventPushed = false;

        if (cjAID !== null && cjAID.length !== 0) {
            event.pushEVar('eVar117',cjAID, true);
            ifEventPushed = true;
        }

        if (cjSID !== null && cjSID.length !== 0) {
            event.pushEVar('eVar118',cjSID, true);
            ifEventPushed = true;
        }

        if (cjPID !== null && cjPID.length !== 0) {
            event.pushEVar('eVar119',cjPID, true);
            ifEventPushed = true;
        }
        if (ifEventPushed) {
            event.push();
        }
    } catch (err) {
        window.console.log('omniture error: ' + err);
    }
}
