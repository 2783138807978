/* global DocumentTouch */

'use strict';

var quickview = require('./quickview'),
    util = require('./util'),
    _ = require('lodash');

function quickviewShow($this, $link) {
    var $tile = $this.closest('tr[data-a]');
    var position = 0;
    var pid = 0;
    $('tr[data-a]').each(function (i) {
        if ($this.data('a') == $tile.data('a')) {
            position = i;
            pid = $tile.data('pid');
            return false;
        }
    });
    var url = $link.attr('data-quick-view-url');
    url = util.appendParamToURL(url, 'format', 'ajax');
    var quickViewCarrousellTitle = '';
    if($this.parents(".product-listing").length > 0){
        quickViewCarrousellTitle = $this.parents(".product-listing").children("h3").text().toLowerCase().trim();
    }
    quickview.show({
        url: url,
        progress: $link,
        source: 'quickview',
        position: position,
        carrousellTitle: quickViewCarrousellTitle
    });
    // do not use smartResize as we want this to trigger on height changes too
    $(window).on('resize', _.debounce(() => {
        $('#QuickViewDialog').parent('.ui-dialog').position({
            my: 'center',
            at: 'center',
            collision: 'fit',
            of: window
        });
        if ($this.dialog.isOpen === true) {
            $this.dialog('close');
        }
    }, 100));
}
/**
 * @function
 * @description Sets up the quick view button when called with the appropriate values
 * @param {jQuery} $link - Link that is used to define the quick view button
 * @param {boolean} focus - Mark this as true when the focus event is used
 */
function initQuickView($link) {
    var $qvButton = $('#quickviewbutton');
    var $oosVariant = $link.parent().parent().find('.item-details .notavailable.other-oos');
    var $message = $oosVariant.length == 0 ? Resources.QUICK_VIEW : Resources.VIEW_AVAILABLE_OPTIONS;

    if ($qvButton.length) {
        $qvButton.remove();
    }

    if ($('[data-component="hybrid"]').length) {
        return false;
    }

    $qvButton = $('<div id="quickviewbutton"><a href="javascript:void(0)" class="quickview button secondary">' + $message + '</a></div>');

    $qvButton.addClass('redesign');
    if($oosVariant.length == 0){
        $qvButton.addClass('oos-qs-button');
    }

    $qvButton.attr({
        'href': $link.attr('data-quick-view-url'),
        'title': $link.attr('title')
    });

    $qvButton.on('click', function (e) {
        e.preventDefault();
        quickviewShow($(this), $link);
    });

    $qvButton.insertAfter($link);
}

function initEditFavorites($link) {
    var $editLink = $link.find(".dashboard-actions .option-update.edit-favorites");
    var $thumb = $link.find(".thumb-link");
    
    $editLink.on('click', function (e) {
        e.preventDefault();
        quickviewShow($link, $thumb);
    });

    $editLink.attr({
        'href': $thumb.attr('data-quick-view-url'),
        'title': $thumb.attr('title')
    });
}

function initAvailableOptionsMobile($link) {
    var $oosVariant = $link.find('.item-details .notavailable.other-oos');
    
    if($oosVariant.length > 0){
        var $availableLink = $link.find(".quick-view-mobile.redesign-mobile");
        var $thumb = $link.find(".thumb-link");

        $availableLink.addClass('show');
    
        $availableLink.on('click', function (e) {
            e.preventDefault();
            quickviewShow($link, $thumb);
        });

        $availableLink.attr({
            'href': $thumb.attr('href'),
            'title': $thumb.attr('title')
        });
    }
}

/**
 * @function
 * @description Sets up the product tile so that when the mouse cursor enters the tile the quick view button appears
 */
var initQuickViewButtons = function () {
    $(document).off('mouseenter.product-image').on('mouseenter.product-image', '.tiles-container .product-image', function (e) {
        e.stopPropagation();
        var $link = $(this).find('.thumb-link');
        initQuickView($link); 
    });
    var $tiles = $('.tiles-container tr.product-tile');
    $tiles.each(function (){
        initEditFavorites($(this));
        if(window.screen.width < 768){
            initAvailableOptionsMobile($(this))
        }
    });
}

exports.init = function () {
    var $tiles = $('.tiles-container .product-image');
    if ($tiles.length === 0) { return; }
    initQuickViewButtons();
};