'use strict';

var dialog = require('../../dialog');

module.exports = {
    init: function () {
        $('body').on('click', '.loqate-address-button', function (e) {
            e.preventDefault();
            if ($(this).is(':disabled') || $(this).hasClass('disabled')) {
                return false;
            }
            var $this = $(this);
            var $form = $this.closest('.loqate-address-form').length ?
                            $this.closest('.loqate-address-form') : $('.loqate-address-form:visible');

            if (window.pca && $(window.pca.container).find(".pcaautocomplete.pcatext").is(":visible")) {
                $("body").trigger("click");
            }
            if ($form.length > 0 && !$form.find('.shipping-fields').is(':visible')) {
                $form.find('.shipping-fields').addClass('shipping-fields-validate');
                if (!$form.valid()) {
                    $form.find('.shipping-fields').removeClass('shipping-fields-validate d-none');
                    return false;
                }
            }
            if ($form.length > 0 && $form.data('showshippingform')) {
                
                var $savedAddress = $form.find("select[name$='singleshipping_addressList']"),
                    $saveAddressObj = $savedAddress.find('option:selected').val() !== '' ? $savedAddress.find('option:selected').data('address') : {};

                if ($this.hasClass('validated') ||
                        ($savedAddress.length && $savedAddress.val() !== '' && $saveAddressObj.addressValidated)) {
                    $($this.data('target')).trigger('click');
                    return false;
                }

                $('.loqate-address-form, .loqate-address-button').removeClass('validation-inprogress');
                $this.addClass('validation-inprogress');
                $form.addClass('validation-inprogress');

                $form.find('.address-error-msg').remove();
                $form.find('.address-error').removeAttr('loqate-val').removeClass('address-error');
                $('.loqate-error-container').empty();

                $.ajax({
                    type: 'GET',
                    dataType: 'json',
                    url: $form.data('loqate-url'),
                    data: {
                        "address1": $('input[name$="_address1"]', $form).val(),
                        "address2": $('input[name$="_address2"]', $form).val(),
                        "city": $('input[name$="_city"]', $form).val(),
                        "postalCode": $('input[name$="_postal"]', $form).val(),
                        "state": $('select[name$="_state"]', $form).val(),
                        "country": $('select[name$="_country"]', $form).val()
                    },
                    success: function(data) {
                        $('.loqate-error-container').html('');
                        if (data.resultType === 'reject') {
                            $('.loqate-error-container').html('<div class="address-error-msg">'+ data.errorMsg +'</div>');
                            $form.find('input[name$="_address1"]').attr('loqate-val', $form.find('input[name$="_address1"]').val()).addClass('address-error').closest('.field-wrapper').append('<div class="address-error-msg">Please enter a valid address.</div>');
                            $form.find('input[name$="_city"]').attr('loqate-val', $form.find('input[name$="_city"]').val()).addClass('address-error').closest('.field-wrapper').append('<div class="address-error-msg">Please enter a valid city.</div>');
                            $form.find('select[name$="_state"]').attr('loqate-val', $form.find('select[name$="_state"]').val()).parents('.select-style').addClass('address-error').closest('.field-wrapper').append('<div class="address-error-msg">Please select a state.</div>');
                            $form.find('input[name$="_postal"]').attr('loqate-val', $form.find('input[name$="_postal"]').val()).addClass('address-error').closest('.field-wrapper').append('<div class="address-error-msg">Please enter a valid zip code.</div>');
                            $form.find('.form-modifier:visible .form-modifier__update').trigger('click');
                        } else if (data.resultType === 'review') {
                            var $template = $('.loqate-address-template').clone();
                            var $suggestedAddress = '';
                            var $enteredAddress = '';
                            $('.loqate-address-radio', $template).each(function () {
                                var $id = $(this).attr('id');
                                $(this).attr('id', $id + 1);
                                $(this).siblings('label').attr('for', $id + 1);
                            });

                            if (data.enteredAddress) {
                                $('.loqate-suggested-address', $template).attr('data-address', JSON.stringify(data.enteredAddress));
                                var { Address1, Locality, AdministrativeArea, PostalCode } = data.enteredAddress;
                                var spanEnteredAddress1 = $('<span>').addClass('line-one').text(Address1);
                                var spanEnteredAddress2 = $('<span>').addClass('line-two').text(Locality+', '+AdministrativeArea+' '+ PostalCode);
                                $('.loqate-entered-address', $template).append(spanEnteredAddress1, spanEnteredAddress2);
                            }

                            if (data.suggestedAddress) {
                                $('.loqate-suggested-address', $template).attr('data-address', JSON.stringify(data.suggestedAddress));
                                var { address1, state, city, postal} = data.suggestedAddress;
                                // var postalCode = address2 == undefined ? '' : address2.match(/\d{5}/)[0];
                                var spanSuggestedAddress1 = $('<span>').addClass('line-one').text(address1);
                                var spanSuggestedAddress2 = $('<span>').addClass('line-two').text(city+', '+state+' '+postal);
                                $('.loqate-suggested-address', $template).append(spanSuggestedAddress1, spanSuggestedAddress2);
                            }
                            // Reload the dialog with the returned html.
                            dialog.open({
                                html: $template.html(),
                                autoOpen: true
                            });
                        } else {
                            if ($savedAddress.length && $savedAddress.val() !== '' && $saveAddressObj.ID) {
                                $form.append('<input type="hidden" value="'+ $saveAddressObj.ID +'" name="addressVerifiedUUID" />');
                            } else {
                                $form.append('<input type="hidden" value="true" name="addressVerifiedUUID" />');
                            }
                            $($this.data('target')).trigger('click');
                        }
                    },
                    finally: function() {
                        enableContinueButton();
                    }
                });
            } else {
                $($this.data('target')).trigger('click');
            }
            return false;
        });

        $('body').on('click', '.loqate-address-apply', function (e) {
            var $form = $('.loqate-address-form.validation-inprogress'),
                $button = $('.loqate-address-button.validation-inprogress'),
                $parent = $(this).closest('.loqate-address-verfication'),
                $type = $parent.find('input[name="loqate-address-radio"]:checked').val(),
                $address = $parent.find('.loqate-suggested-address').attr('data-address'),
                $savedAddress = $form.find("select[name$='singleshipping_addressList']"),
                $saveAddressObj =  $savedAddress.find('option:selected').val() !== '' ? $savedAddress.find('option:selected').data('address') : {};
            
            if (!$type) {
                e.preventDefault();
                return false;
            }
            $button.prop('disabled', true).addClass('validated');
            if ($type === 'suggested') {
                $address = JSON.parse($address);
                $.each($address, function (i, v) {
                    $('[name$="'+ i +'"]', $form).val($.trim(v));
                });
                if ($savedAddress.length && $savedAddress.val() !== '' && $saveAddressObj.ID) {
                    $form.append('<input type="hidden" value="'+ $saveAddressObj.ID +'" name="addressVerifiedUUID" />');
                } else {
                    $form.append('<input type="hidden" value="true" name="addressVerifiedUUID" />');
                }
            } else {
                $form.append('<input type="hidden" value="false" name="addressVerifiedUUID" />');
            }
            dialog.close();
            $($button.data('target')).trigger('click');
        });

        $('body').on('click', '.loqate-address-cancel', function () {
            dialog.close();
        });

        $(document).off('click.select-address').on('click.select-address', '#is-entered-address1, #is-suggested-address1' ,function () {
            var $self = $(this);
            $self.closest('.loqate-actions').toggleClass('selected')
                 .siblings('.loqate-actions').removeClass('selected');
        });

        $('body').on('blur change', '.loqate-address-form .shipping-fields input, #edit-address-form.loqate-address-form input', function () {
            $('.loqate-address-button').removeClass('validated');
            $('.loqate-address-form, .loqate-address-button').removeClass('validation-inprogress');
            if ($(this).val() != $(this).attr('loqate-val')) {
                $(this).removeAttr('loqate-val').removeClass('address-error');
                $(this).siblings('.address-error-msg').remove();
            }
            var selectedAddr = $("select[name$='singleshipping_addressList']").length ? $("select[name$='singleshipping_addressList']") : '';
            if (selectedAddr != '') {
                var optionSelected = selectedAddr.find('option:selected');
                if (optionSelected.length > 0) {
                    var dataAddress = optionSelected.data('address');
                    if (dataAddress) {
                        selectedAddr.find('option:selected').data('address').addressValidated = false;
                    }
                }
            }
            enableContinueButton();
        }).on('change click', '.loqate-address-form .shipping-fields select, #edit-address-form.loqate-address-form select', function () {
            $('.loqate-address-button').removeClass('validated');
            $('.loqate-address-form, .loqate-address-button').removeClass('validation-inprogress');
            if ($(this).val() != $(this).attr('loqate-val')) {
                $(this).closest('.select-style').removeClass('address-error');
                $(this).closest('.field-wrapper').find('.address-error-msg').remove();
                $(this).removeAttr('loqate-val');
            }
        });

        $('body').on('click', '.pcalist .pcaitem:not(.pcaexpandable)', function() {
            $('.loqate-address-form .address-error-msg').remove();
            $('.loqate-address-form .address-error').removeAttr('loqate-val').removeClass('address-error');
         });
    }
}

function enableContinueButton() {
    var $form = $('.loqate-address-form');
    var $continueBtn = $('.loqate-address-button');
    var $addressInputs = $form.find('input.input-text');
    var $addressSelect = $form.find('select.input-select');
    var isValid = false;

    $addressInputs.each(function() {
        if($(this).hasClass('error')) isValid = true;
    })
    if($addressSelect.hasClass('error')) isValid = true;

    $continueBtn.prop('disabled', isValid);
}
