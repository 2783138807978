/**
* Description of the module and the logic it provides
*
* @module cartridge/js/stickyfooter
*/

'use strict';

var ajax = require('./ajax');

var stickyfooter = {
    init: function () {
        var sessionShowStickyFooter = sessionStorage.getItem('showStickyFooterPromo');
        var sms2enabled = SitePreferences.SMS2_ENABLED;
        var optInIsRestricted = sessionStorage.getItem('optInIsRestricted');
        if (!optInIsRestricted && sessionShowStickyFooter && sessionShowStickyFooter === 'true' && (['checkout'].indexOf(window.pageContext.ns) === -1) && sms2enabled) {
            ajax.load({
                url: window.Urls.stickyfooter,
                callback: function (response) {
                    $('body').append(response);
                }
            });
        } else {
            return;
        }

        $('body').on('click', '.js-sticky-promo-footer-close', function() {
            $('.js-sticky-promo-footer').addClass('d-none');
            sessionStorage.setItem('showStickyFooterPromo', false);
        });
    }
};

module.exports = stickyfooter;
