'use strict';

var determineISPosition = function() {
    var position = -1;
    if (dataLayer) {
        for (var k = dataLayer.length; k--; k === 0) {
            if (dataLayer[k].v2) {
                position = k;
                break;
            }
        }
    }
    return position;
}

var dataLayer = 'dataLayer' in window ? window.dataLayer : null,
    interactionStudioEnabled = false,
    isPosition = -1,
    isExists,
    fullDataLayerPresent;

var checkForIsPosition = function() {
    interactionStudioEnabled = $('#interactionStudioGate').length > 0;
    isPosition = determineISPosition();
    isExists = isPosition !== -1;
    fullDataLayerPresent = interactionStudioEnabled && dataLayer && isExists;
}

checkForIsPosition();

var initDataLayer = function() {
    return {
        v2 : {
        }
    };
}

var constructEmptyProductField = function() {
    return {
        id : '',
        imageUrl : '',
        imageOnFigUrl : '',
        defaultCategory: '',
        extendedSizes: null,
        styleClassic: null,
        name : '',
        sku : '',
        size : '',
        sizeName : '',
        styleColor : '',
        variant: '',
        clickedStyle: '',
        clickedName: '',
        clickedColor: '',
        clickedSize: '',
        swatchImage: '',
        color : '',
        colorName : '',
        extendedSizing : '',
        extendedSizingName : '',
        categories : '',
        price : '',
        priceLocal : '',
        priceSale : '',
        priceSaleLocal : '',
        priceMin : '',
        priceMinLocal : '',
        priceMax : '',
        priceMaxLocal : ''
    };
}

var initProductLayer = function(product, eventName) {
    // GA v2 tagging needs to remain available during GA360 transition to prevent compatibility issues with existing 3rd party tags in GTM
    checkForIsPosition();
    if (fullDataLayerPresent) {
        let currentDataLayer = initDataLayer();
        if (dataLayer[isPosition].hasOwnProperty('v2')) {
            currentDataLayer = JSON.parse(JSON.stringify(dataLayer[isPosition]));
        }
        if (eventName !== undefined && eventName !== null && eventName !== '') {
            currentDataLayer.v2.event = eventName;
        } else if (currentDataLayer.v2.hasOwnProperty('event')) {
            delete currentDataLayer.v2.event;
        }

        var eventProductObj = {};
        if (currentDataLayer.v2.hasOwnProperty('product')) {
            eventProductObj = currentDataLayer.v2.product;
        } else {
            // then try to keep the data integrity - by filling blank all the product fields
            // don't like the idea - if the list expanded - would need to update this list as well
            eventProductObj = {
                id : '',
                imageUrl : '',
                imageOnFigUrl : '',
                defaultCategory: '',
                extendedSizes: null,
                styleClassic: null,
                name : '',
                sku : '',
                size : '',
                sizeName : '',
                styleColor : '',
                variant: '',
                clickedStyle: '',
                clickedName: '',
                clickedColor: '',
                clickedSize: '',
                swatchImage: '',
                color : '',
                colorName : '',
                extendedSizing : '',
                extendedSizingName : '',
                categories : '',
                price : '',
                priceLocal : '',
                priceSale : '',
                priceSaleLocal : '',
                priceMin : '',
                priceMinLocal : '',
                priceMax : '',
                priceMaxLocal : ''
            };
        }
        // let's update all the values that is possible to update
        // if something should be pushed there - let's do it
        Object.keys(product).forEach(function (prop) {
            eventProductObj[prop] = product[prop];
        });
        if (eventProductObj.hasOwnProperty('color') && eventProductObj.hasOwnProperty('size') && product.id && eventProductObj.color !== '' && eventProductObj.size !== '') {
            eventProductObj.variant = product.id;
        }
        currentDataLayer.v2.product = eventProductObj;

        dataLayer.push(currentDataLayer);
    }
}

var updateProductsArrayLayer = function(productsArray) {
    var newProductsArray = [];
    for(var i = 0; i < productsArray.length; i++) {
        const product = productsArray[i];
        const eventProductObj = constructEmptyProductField();
        Object.keys(product).forEach(function (prop) {
            eventProductObj[prop] = product[prop];
        });
        if (eventProductObj.hasOwnProperty('color') && eventProductObj.hasOwnProperty('size') && product.id && eventProductObj.color !== '' && eventProductObj.size !== '') {
            eventProductObj.variant = eventProductObj.id;
        }
        newProductsArray.push(eventProductObj);
    }
    return newProductsArray;
}

exports.triggerQuickShop = function(product) {
    checkForIsPosition();
    if (fullDataLayerPresent && product) {
        dataLayer.push({
            'quickShop': product.id
        });
        initProductLayer(product, 'quickShop');
    }
}

exports.triggerVariationSelection = function(pid, mpid, swatch, swatchValue, swatchDisplayValue, styleColor, swatchImage, price, priceLocal) {
    checkForIsPosition();
    if (fullDataLayerPresent && pid) {
        var product = {
            id: mpid,
            clickedStyle: mpid,
            clickedName: '',
            clickedColor: '',
            clickedSize: '',
            swatchImage: swatchImage,
            sku: pid,
            price: price,
            priceLocal: priceLocal
        };

        var selectedSize = swatch === 'size';
        var selectedColor = swatch === 'color';

        if (swatchValue !== undefined && swatchValue !== null) {
            product[swatch] = swatchValue;
            if (selectedSize) {
                product.clickedSize = swatchValue;
            }
            if (selectedColor) {
                product.clickedColor = swatchValue;
            }
        }
        var swatchAttrNameKey = swatch + 'Name';
        if (swatchDisplayValue !== undefined && swatchDisplayValue !== null) {
            product[swatchAttrNameKey] = swatchDisplayValue;
            if (selectedSize || selectedColor) {
                product.clickedName = swatchDisplayValue;
            }
        }
        if (styleColor !== undefined && styleColor !== null && styleColor !== '') {
            product.styleColor = styleColor;
        }
        initProductLayer(product, 'PDPInteraction');
    }
}

exports.triggerEmailSignup = function(email) {
    checkForIsPosition();
    if (fullDataLayerPresent && email) {
        dataLayer.push({
            'userid': email
        });
        let currentDataLayer = initDataLayer();
        if (dataLayer[isPosition].hasOwnProperty('v2')) {
            currentDataLayer = JSON.parse(JSON.stringify(dataLayer[isPosition]));
        }
        currentDataLayer.v2.event = 'emailSignup';

        if (currentDataLayer.v2.hasOwnProperty('user')) {
            currentDataLayer.v2.user.email = email;
        } else {
            currentDataLayer.v2.user = {
                'email': email
            };
        }

        dataLayer.push(currentDataLayer);
    }
}

exports.triggerATC = function(prod, count, salesprice, priceLocal, sku, variant, isUpdate, postLoad, styleColor) {
    // GA v2 tagging needs to remain available during GA360 transition to prevent compatibility issues with existing 3rd party tags in GTM
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var addToCart = {
            product: {
                id: prod,
                qty: count,
                price: salesprice,
                priceLocal: priceLocal ? priceLocal : '',
                sku: sku ? sku : '',
                styleColor: styleColor ? styleColor : '',
                variant: variant ? variant : ''
            }
        }

        var eventName = 'addToCart';

        var useCookie = false;

        if (isUpdate !== undefined && isUpdate !== null && isUpdate === true) {
            eventName = 'updateLineItem';
            if (postLoad !== undefined && postLoad !== null && postLoad === true) {
                useCookie = true;
            }
        }
        var eventObj = {};
        eventObj[eventName] = addToCart;
        if (useCookie) {
            document.cookie = 'isUpdateLineItemProduct=' + JSON.stringify(addToCart.product) + '; path=/; expires=Tue, 19 Jan 2100 01:01:01 GMT';
        } else {
            dataLayer.push(eventObj);

            initProductLayer(addToCart.product, eventName);
        }
    }
}

exports.removeCartItem = function(prod, count, salesprice, postLoad, priceLocal, sku, variant, styleColor) {
    // GA v2 tagging needs to remain available during GA360 transition to prevent compatibility issues with existing 3rd party tags in GTM
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var product = {
            pid : (prod !== undefined && prod !== null) ? prod : '',
            qty : (count !== undefined && count !== null) ? count : '1',
            price : (salesprice !== undefined && salesprice !== null) ? salesprice : '',
            priceLocal: (priceLocal !== undefined && priceLocal !== null) ? priceLocal : '',
            sku: (sku !== undefined && sku !== null) ? sku : '',
            styleColor: (styleColor !== undefined && styleColor !== null) ? styleColor : '',
            variant: (variant !== undefined && variant !== null) ? variant : ''
        }

        if (postLoad) {
            document.cookie = 'isRemoveFromCart=' + JSON.stringify(product) + '; path=/; expires=Tue, 19 Jan 2100 01:01:01 GMT';
        } else {
            //minicart should not be post-load

            dataLayer.push({
                'removeFromCart': {
                    'product': {
                        'id': product.pid,
                        'price': product.price,
                        'qty': product.quantity,
                        'priceLocal': priceLocal ? priceLocal : '',
                        'sku': sku ? sku : '',
                        'styleColor': styleColor ? styleColor : '',
                        'variant': variant ? variant : ''
                    }
                }
            });

            initProductLayer(product, 'removeFromCart');
        }
    }
}

//Keeping this helper function in this file to avoid compiling issues with popup/Helper
var getCookie = function(cookie) {
    var name = cookie + '=',
        cookies = decodeURIComponent(document.cookie),
        cookieArr = cookies.split('; '),
        cookieVal = '';

    cookieArr.forEach(function(val) {
        if (val.indexOf(name) === 0) cookieVal = val.substring(name.length);
    });

    var finalCookie = cookieVal.length > 0 ? cookieVal : null;
    return finalCookie;
}

var getPath = function(currentDataLayer) {
    var pageObject = {};
    if (currentDataLayer.hasOwnProperty('v2') && currentDataLayer.v2.hasOwnProperty('page')) {
        pageObject = JSON.parse(JSON.stringify(currentDataLayer.v2.page));
    }

    pageObject.path = window.location.pathname.toString();

    return pageObject;
}

exports.pushCartData = function(products) {
    checkForIsPosition();
    if (products && (fullDataLayerPresent || (isExists && dataLayer && dataLayer[isPosition] && dataLayer[isPosition].hasOwnProperty('v2')))) {
        if (!dataLayer[isPosition].v2.hasOwnProperty('event')) {
            dataLayer[isPosition].v2.products = products;
            dataLayer[isPosition].v2.event = 'viewCart';
        } else {
            var cartPageISPosition = -1;
            for (var k = 0; k < dataLayer.length; k++) {
                if (dataLayer[k].v2 && dataLayer[k].v2.hasOwnProperty('page') && dataLayer[k].v2.page.hasOwnProperty('pageType') && dataLayer[k].v2.page.pageType === 'cart' && !dataLayer[k].v2.hasOwnProperty('event')) {
                    cartPageISPosition = k;
                    break;
                }
            }
            if (cartPageISPosition >-1) {
                dataLayer[cartPageISPosition].v2.products = products;
                dataLayer[cartPageISPosition].v2.event = 'viewCart';
            }
        }
    }
}

exports.postLoadEvents = function() {
    checkForIsPosition();
    if (fullDataLayerPresent || (isExists && dataLayer && dataLayer[isPosition] && dataLayer[isPosition].hasOwnProperty('v2'))) {
        let currentDataLayer = initDataLayer();
        var product,
            pushNewObject = true;
        if (dataLayer[isPosition].hasOwnProperty('v2')) {
            currentDataLayer = JSON.parse(JSON.stringify(dataLayer[isPosition]));
        }
        currentDataLayer.v2.page = getPath(currentDataLayer);
        var currentPath = currentDataLayer.v2.page.path;
        if (isExists
            && dataLayer
            && dataLayer[isPosition]
            && dataLayer[isPosition].hasOwnProperty('v2')
            && dataLayer[isPosition].v2.page
            && dataLayer[isPosition].v2.page.path
            && (dataLayer[isPosition].v2.page.path !== currentPath || dataLayer[isPosition].v2.page.path === '/')) {
            pushNewObject = false;
        }

        if (currentDataLayer.v2 != null && currentDataLayer.v2.event == 'completeTransaction') {
            pushNewObject = false;
        }

        if (pushNewObject) {
            dataLayer.push(currentDataLayer);
        } else {
            dataLayer[isPosition].v2.page.path = currentPath;
        }

        var removedFromCart = getCookie('isRemoveFromCart');
        if (removedFromCart !== undefined && removedFromCart !== null) {
            var removedProduct = JSON.parse(removedFromCart);
            product = {
                id: removedProduct.pid,
                price: removedProduct.price,
                qty: removedProduct.qty,
                priceLocal: removedProduct.priceLocal ? removedProduct.priceLocal : '',
                sku: removedProduct.sku ? removedProduct.sku : '',
                styleColor: removedProduct.styleColor ? removedProduct.styleColor : '',
                variant: removedProduct.variant ? removedProduct.variant : ''
            };
            initProductLayer(product, 'removeFromCart');

            dataLayer.push({
                'removeFromCart': {
                    'product': {
                        'id': removedProduct.pid,
                        'price': removedProduct.price,
                        'quantity': removedProduct.qty
                    }
                }
            });
            document.cookie = 'isRemoveFromCart=; Max-Age=0; path=/;';
        }

        var updatedFromCart = getCookie('isUpdateLineItemProduct');
        if (updatedFromCart !== undefined && updatedFromCart !== null) {
            var updatedProduct = JSON.parse(updatedFromCart);
            initProductLayer(updatedProduct, 'updateLineItem');

            dataLayer.push({
                'updateLineItem': {
                    'product': updatedProduct
                }
            });
            document.cookie = 'isUpdateLineItemProduct=; Max-Age=0; path=/;';
        }

        var savedForLater = getCookie('isSaveForLater');
        if (savedForLater !== undefined && savedForLater !== null) {
            var savedProduct = JSON.parse(savedForLater);
            product = {
                id: savedProduct.id,
                price: savedProduct.price,
                qty: savedProduct.qty,
                priceLocal: savedProduct.priceLocal ? savedProduct.priceLocal : '',
                sku: savedProduct.sku ? savedProduct.sku : '',
                styleColor: savedProduct.styleColor ? savedProduct.styleColor : '',
                variant: savedProduct.variant ? savedProduct.variant : ''
            };
            initProductLayer(product, 'saveForLater');
            initProductLayer(product, 'removeFromCart');
            dataLayer.push({
                'saveForLater': {
                    'product': {
                        'id': savedProduct.id,
                        'price': savedProduct.price,
                        'quantity': savedProduct.qty
                    }
                },
                'removeFromCart': {
                    'product': {
                        'id': savedProduct.id,
                        'price': savedProduct.price,
                        'quantity': savedProduct.qty
                    }
                }
            });
            document.cookie = 'isSaveForLater=; Max-Age=0; path=/;';
        }

        var movedToBag = getCookie('isMoveToBag');
        if (movedToBag !== undefined && movedToBag !== null) {
            var movedProduct = JSON.parse(movedToBag);
            product = {
                id: movedProduct.id,
                price: movedProduct.price,
                qty: movedProduct.qty,
                priceLocal: movedProduct.priceLocal ? movedProduct.priceLocal : '',
                styleColor: movedProduct.styleColor ? movedProduct.styleColor : '',
                sku: movedProduct.sku ? movedProduct.sku : '',
                variant: movedProduct.variant ? movedProduct.variant : ''
            };
            initProductLayer(product, 'moveToBag');
            initProductLayer(product, 'addToCart');

            dataLayer.push({
                'moveToBag': {
                    'product': {
                        'id': movedProduct.id,
                        'price': movedProduct.price,
                        'quantity': movedProduct.qty
                    }
                },
                'addToCart': {
                    'product': {
                        'id': movedProduct.id,
                        'price': movedProduct.price,
                        'quantity': movedProduct.qty
                    }
                }
            });

            document.cookie = 'isMoveToBag=; Max-Age=0; path=/;';
        }

        var isMoveFavoriteToWishlist = getCookie('isMoveFavoriteToWishlist');
        if (isMoveFavoriteToWishlist !== undefined && isMoveFavoriteToWishlist !== null) {
            var productToWishlist = JSON.parse(isMoveFavoriteToWishlist);
            product = {
                'id': productToWishlist.id,
                'styleColor': productToWishlist.styleColor,
                'qty': productToWishlist.qty,
                'price': productToWishlist.price,
                'priceLocal': productToWishlist.priceLocal
            }
            initProductLayer(product, 'addToFavorites');

            dataLayer.push({
                'addToFavorites': productToWishlist.id
            });

            document.cookie = 'isMoveFavoriteToWishlist=; Max-Age=0; path=/;';
        }

        var isRemovedFromFavorites = getCookie('isRemoveFromFavorites');
        if (isRemovedFromFavorites !== undefined && isRemovedFromFavorites !== null) {
            var removedItem = JSON.parse(isRemovedFromFavorites);
            product = {
                'id': removedItem.id,
                'price': removedItem.price,
                'priceLocal': removedItem.priceLocal
            }
            initProductLayer(product, 'removeFromFavorites');

            dataLayer.push({
                'removeFromFavorites': removedItem.id
            });

            document.cookie = 'isRemoveFromFavorites=; Max-Age=0; path=/;';
        }

        var isTriggerPostVariationSelection = getCookie('isTriggerPostVariationSelection');
        if (isTriggerPostVariationSelection !== undefined && isTriggerPostVariationSelection !== null) {
            var selectedVariant = JSON.parse(isTriggerPostVariationSelection);
            document.cookie = 'isTriggerPostVariationSelection=; Max-Age=0; path=/;';
            exports.triggerVariationSelection(selectedVariant.variant, selectedVariant.master, selectedVariant.clickedObject, selectedVariant.swatchValue, selectedVariant.swatchDisplayValue, selectedVariant.styleColor, selectedVariant.swatchImage);
        }
    }
}

exports.addToFavorites = function(prod, price, priceLocal, styleColor, qty) {
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var addToFavorites = {
            id: prod,
            styleColor: (styleColor !== undefined && styleColor !== null) ? styleColor : '',
            qty: (qty !== undefined && qty !== null) ? qty : '1',
            price: price,
            priceLocal: priceLocal
        }

        dataLayer.push({
            'addToFavorites': addToFavorites.id
        });
        initProductLayer(addToFavorites, 'addToFavorites');
    }
}

exports.removeFromFavorites = function(prod, price, priceLocal, styleColor) {
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var removeFromFavorites = {
            id: prod,
            styleColor: (styleColor !== undefined && styleColor !== null) ? styleColor : '',
            price: price,
            priceLocal: priceLocal
        }

        dataLayer.push({
            'removeFromFavorites': removeFromFavorites.id
        });
        initProductLayer(removeFromFavorites, 'removeFromFavorites');
    }
}

exports.saveForLater = function(prod, count, salesprice, priceLocal, sku, variant, styleColor) {
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var saveForLater = {
            id : (prod !== undefined && prod !== null) ? prod : '',
            qty : (count !== undefined && count !== null) ? count : '1',
            price : (salesprice !== undefined && salesprice !== null) ? salesprice : '',
            priceLocal: (priceLocal !== undefined && priceLocal !== null) ? priceLocal : '',
            sku: (sku !== undefined && sku !== null) ? sku : '',
            styleColor: (styleColor !== undefined && styleColor !== null) ? styleColor : '',
            variant: (variant !== undefined && variant !== null) ? variant : ''
        }
        document.cookie = 'isSaveForLater=' + JSON.stringify(saveForLater) + '; path=/; expires=Tue, 19 Jan 2100 01:01:01 GMT';
    }
}

exports.moveToBag = function (prod, count, salesprice, priceLocal, sku, variant, styleColor) {
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var moveToBag = {
            id : (prod !== undefined && prod !== null) ? prod : '',
            qty : (count !== undefined && count !== null) ? count : '1',
            price : (salesprice !== undefined && salesprice !== null) ? salesprice : '',
            priceLocal: (priceLocal !== undefined && priceLocal !== null) ? priceLocal : '',
            styleColor: (styleColor !== undefined && styleColor !== null) ? styleColor : '',
            sku: (sku !== undefined && sku !== null) ? sku : '',
            variant: (variant !== undefined && variant !== null) ? variant : ''
        }
        document.cookie = 'isMoveToBag=' + JSON.stringify(moveToBag) + '; path=/; expires=Tue, 19 Jan 2100 01:01:01 GMT';
    }
}


exports.moveFavoriteToWishlist = function (prod, price, priceLocal, styleColor, qty) {
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var addToFavorites = {
            id: prod,
            styleColor: styleColor,
            qty: (qty !== undefined && qty !== null) ? qty : '1',
            price: price,
            priceLocal: priceLocal
        }
        document.cookie = 'isMoveFavoriteToWishlist=' + JSON.stringify(addToFavorites) + '; path=/; expires=Tue, 19 Jan 2100 01:01:01 GMT';
    }
}

exports.removeFromWishlist = function (prod, price, priceLocal) {
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var removeFromWishlist = {
            id: prod,
            price: price,
            priceLocal: priceLocal
        }
        document.cookie = 'isRemoveFromFavorites=' + JSON.stringify(removeFromWishlist) + '; path=/; expires=Tue, 19 Jan 2100 01:01:01 GMT';
    }
}

exports.triggerPostVariationSelection = function (pid, mpid, swatch, swatchValue, swatchDisplayValue, styleColor) {
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var triggerPostVariationSelection = {
            master: mpid,
            styleColor: styleColor,
            variant: pid,
            clickedObject: swatch,
            swatchValue: swatchValue,
            swatchDisplayValue: swatchDisplayValue
        }
        document.cookie = 'isTriggerPostVariationSelection=' + JSON.stringify(triggerPostVariationSelection) + '; path=/; expires=Tue, 19 Jan 2100 01:01:01 GMT';
    }
}


exports.updatePageType = function(map) {
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var updatedPageType = '',
            isMapping = JSON.parse(map);

        if (isMapping !== undefined && isMapping !== null) {
            var url = window.location.href.toString();

            for (var entry in isMapping) {
                if (url.indexOf(entry.toString()) > -1) {
                    updatedPageType = isMapping[entry];
                    break;
                }
            }
        }

        if (updatedPageType !== undefined && updatedPageType !== null && updatedPageType.length > 0) {
            if (dataLayer[isPosition].hasOwnProperty('v2')) {
                if (!dataLayer[isPosition].v2.hasOwnProperty('page')) {
                    dataLayer[isPosition].v2.page =  {};
                }
                dataLayer[isPosition].v2.page.pageType = updatedPageType;
            } else {
                var currentDataLayer = initDataLayer();
                if (currentDataLayer.hasOwnProperty('v2') && !currentDataLayer.v2.hasOwnProperty('page')) {
                    currentDataLayer.v2.page =  {};
                }
                currentDataLayer.v2.page.pageType = updatedPageType;
                dataLayer.push(currentDataLayer);
            }
        }
    }
}

exports.sendOrderConfirmation = function(confirmationDetails) {
    // GA v2 tagging needs to remain available during GA360 transition to prevent compatibility issues with existing 3rd party tags in GTM
    checkForIsPosition();
    //this should never resolve as false on order-confirmation, but let's check just in case (so we don't break the data layer)
    let currentDataLayer = initDataLayer();
    if (fullDataLayerPresent && dataLayer[isPosition].hasOwnProperty('v2')) {
        currentDataLayer = JSON.parse(JSON.stringify(dataLayer[isPosition]));
    }
    if (currentDataLayer.hasOwnProperty('v2') && confirmationDetails) {
        currentDataLayer.v2.event = 'completeTransaction';
        if (confirmationDetails.page) {
            currentDataLayer.v2.page = JSON.parse(JSON.stringify(confirmationDetails.page));
            currentDataLayer.v2.page.path = getPath({}).path;
        }
        if (confirmationDetails.products) {
            var products = updateProductsArrayLayer(confirmationDetails.products);
            currentDataLayer.v2.products = JSON.parse(JSON.stringify(products));
        }
        dataLayer.push(currentDataLayer);
    }
}

exports.getLoadMoreProductIds = function(responseData) {
    checkForIsPosition();
    if (fullDataLayerPresent) {
        var newProductDivs = $(responseData).find('.product-tile');
        var fieldName;
        let currentDataLayer = initDataLayer();
        if (dataLayer[isPosition].hasOwnProperty('v2')) {
            currentDataLayer = JSON.parse(JSON.stringify(dataLayer[isPosition]));
        }
        if (currentDataLayer.v2.hasOwnProperty('page')) {
            if (currentDataLayer.v2.page.hasOwnProperty('categoryProductIds') && currentDataLayer.v2.page.categoryProductIds.length) {
                fieldName = 'categoryProductIds';
            }
            if (currentDataLayer.v2.page.hasOwnProperty('searchProductIds') && currentDataLayer.v2.page.searchProductIds.length) {
                fieldName = 'searchProductIds';
            }
        } else {
            currentDataLayer.v2.page = {};
        }
        if (fieldName) {
            var newProductsArray = [];
            for (var i = 0; i < newProductDivs.length; i++) {
                var productId = $(newProductDivs[i]).data('itemid');
                newProductsArray.push(productId);
            }
            currentDataLayer.v2.page[fieldName] = newProductsArray;
            dataLayer.push(currentDataLayer);
        }
    }
}
