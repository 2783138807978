/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var event = require('../event');

var account = {
    init: function (){
        if (window.location.href.indexOf('registration=true') != -1) {
            
            // event5
            // Registrations
            // Counter event to fire the event whenever an account is created on the site.
            event.pushEvent('event5');
        } else {
            if ($('.account-welcome-header').size() > 0) {
                
                // event10
                // Successful Login
                // Counter event to fire whenever a visit successfully logs in to their account. Can occurr on any page type.
                event.pushEvent('event10');
            }
        }
        
        if ($('.login-account').size() > 0) {
            
            // event17
            // Sign in Page
            // Counter event to fire whenever a visitor views a sign in page on the site. A sign in page is any page that prompts the customer to sign in.
            event.pushEvent('event17');
        }
    }
}

exports.init = account.init;
