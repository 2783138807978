/**
* Description of the module and the logic it provides
*
* @module cartridge/js/registration
*/
'use strict';

var util = require('../util'),
    dialog = require('../dialog'),
    bluecore = require('../bluecore'); //eslint-disable-line

var registration = {
    init: function () {
        $(document).on('change', 'select[name$="customer_bMonth"]', registration.updateDays);
        if (window.pageContext.ns === 'cart') {
            bluecore.checkForAutoRemoveItems();
        }

        $(document).on('change', 'select[name$="_country"]', function (e) {
            e.preventDefault();
            var $this = $(this);
            if ($('.international-customer').length === 0) {
                var countryCode = $this.find(':selected').val();
                if (countryCode.toLowerCase() === 'us') {
                    sessionStorage.setItem('optInIsRestricted', false);
                    sessionStorage.removeItem('optInIsRestricted');
                    $('.smsoptin-phonenum').show();
                    $('.smsoptin-checkbox').show();
                } else {
                    sessionStorage.setItem('optInIsRestricted', true);
                    $('.smsoptin-phonenum').hide();
                    $('.smsoptin-checkbox').hide();
                }
            }
        });
    },

    updateDays: function () {
        var bDay =  $('select[name$="_customer_bDay"]');
        bDay.find('option').remove();
        var month = $(this).val(),
            daysCount = new Date((new Date()).getFullYear(), month, 0).getDate();

        var leadingZero = function (i) {
            return i.length == 1?'0'+i:i;
        }
        for (var i=1; i<=daysCount; i++) {
            var val = leadingZero(i);
            bDay.append($('<option></option>').attr('value', val).text(val));
        }
    },
    
    popup: function (e) {
        e.preventDefault();
        var url = window.Urls.registration;
        var sheerTarget = '';
        // redirect to the correct Sheer ID form if the customer has landed here after attempting to verify their status
        if ($('#sheerid-login').length && $('#sheerid-login').val()) {
            if ($('#sheerid-login').val().toLowerCase() === 'student') {
                sheerTarget = 'sheerTarget=student';
            } else {
                sheerTarget = 'sheerTarget=teacher';
            }
        }
        if (util.isMobile()) {
            if (window.pageContext.ns == 'cart') {
                window.location.href = url + '?targetLocation=COShipping-Start';
            } else {
                if ($('#bvLocation').size() > 0) {
                    window.location.href = $('#bvLocation').val(); 
                } else if (sheerTarget.length > 0) {
                    window.location.href = url + '?' + sheerTarget;
                } else {
                    window.location.href = url;
                }
            }
            
        } else {
            $(document).off('submit', '#RegistrationForm', registration.formHandler);
            $(document).on('submit', '#RegistrationForm', registration.formHandler);
            url = url + '?format=ajax';
            if (sheerTarget.length > 0) {
                url = url + '&' + sheerTarget;
            }
            dialog.open({
                url: url,
                options: {
                    width: 'auto',
                    height: 'auto'
                }
            });
        }
    },

    formHandler: function (e) {
        e.preventDefault()
        var form = $(this);
        if (form.valid()) {
            var url = form.attr('action').indexOf('?') > -1 ? form.attr('action') + '&format=ajax' : form.attr('action') + '?format=ajax';
            $.post(url, form.serialize(), function (data) {
                if (data && data.indexOf('registration-success') != -1) {
                    // if the customer is registering after attempting a Save For Later cart action or clicking "Sign Up" in the SFL section
                    if ((data && data.indexOf('saveForLaterReturn') != -1) || $('.save-for-later-container').length > 0) {
                        window.location.href = window.Urls.sflLoginReturn;
                        return;
                    }
                    if (window.pageContext.ns == 'cart') {
                        window.location.href = window.Urls.shippingPage;
                    } else {
                        if ($('#bvLocation').size() > 0) {
                            window.location.href = $('#bvLocation').val(); 
                        } else {
                            window.location.href = window.Urls.accountShow + '?registration=true';
                            if (data && data.indexOf('sheerIdStudent') != -1) {
                                window.location.href = window.Urls.accountShow + '?registration=true&sheerTarget=student';
                            } else if (data && data.indexOf('sheerIdTeacher') != -1) {
                                window.location.href = window.Urls.accountShow + '?registration=true&sheerTarget=teacher';
                            }
                        }
                    }
                } else {
                    $('.registration-form-container').first().replaceWith(data);
                }
            })
        }
    }
}

module.exports = registration;
