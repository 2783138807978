'use strict';

var initEventHandler = function () {
    $(document).on('click keypress', '.show-pass', function (e) {
        var inputPass = $(this).siblings('input');
        $(this).toggleClass('hide-pass');
        if($(this).hasClass('hide-pass')){
            $(this).attr('aria-label', 'Hide password');
        }else{
            $(this).attr('aria-label', 'Show password');
        }
        inputPass.attr('type', inputPass.attr('type') === 'password' ? 'text' : 'password');
    });
};
module.exports = function () {
    initEventHandler();
};
