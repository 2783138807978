/**
* Description of the module and the logic it provides
*
* @module cartridge/js/loyaltymodal
*/

'use strict';
var dialog = require('./dialog'),
    page = require('./page'),
    util = require('./util'),
    optinmodal = require('./optinmodal'),
    stickyFooter = require('./stickyfooter'),
    registrationModel = require('./registrationmodal');

var loyaltymodal = {
    init: function () {
        var isGated = $('body').hasClass('gated');
        var loginmodal = require('./loginmodal');
        var sitePrefTurnedOff = SitePreferences.LOYALTY_POPUP === false;
        var loginModal = util.getUrlParameter(window.location.search, 'loginShow');
        var isRegistrationModelOpened = false;
        if(loginModal == 'true') {
            if (window.User.anonymous) {
                isRegistrationModelOpened = true;
                registrationModel.loadRegistration();
            } else {
                loginmodal.loginModal('javascript:void(0)', false);
            }
        }
        if (sitePrefTurnedOff) {
            Cookies.set('dismissRegister', true, {expires: 30});
        }
        var dismissRegister = Cookies.get('dismissRegister');
        if (isGated || ('pageContext' in window && 'ns' in window.pageContext)) {
            if (window.pageContext.ns === 'orderconfirmation') {
                Cookies.set('confirmation', true, {expires: 30});
            } else if ((['cart','checkout'].indexOf(window.pageContext.ns) === -1) && !isRegistrationModelOpened) {
                var utmSource = util.getUrlParameter(window.location.search, 'utm_source');

                if (utmSource.length && utmSource == 'talkable' && window.User.anonymous) {
                    var talkableUuid = util.getUrlParameter(window.location.search, 'tkbl_cvuuid');
                    var registrationData = {
                        isTalkableSignUp : true
                    };

                    if (talkableUuid.length) {
                        registrationData.talkableUuid = talkableUuid;
                    }
                    registrationModel.loadRegistration(null, registrationData);
                } else if (isGated || (window.User.anonymous && (typeof dismissRegister == 'undefined' || dismissRegister == 'false'))) {

                    var urlSource = util.getUrlParameter(window.location.search, 'source'),
                        noPopUp = util.getUrlParameter(window.location.search, 'noPopUp');

                    var showOnCurrentVisit = sessionStorage.getItem('showOptinSecondPageLoad');
                    //show loyalty sign up modal for the second page load for the new visitors
                    if (showOnCurrentVisit && (isGated || (!(urlSource.length && urlSource == 'googlePLA') && !(noPopUp.length && noPopUp == 'true')))) {
                        /* Initiate loyalty/login modal for first-time visitors or gated pages */
                        $('.create-account').addClass('on-session');
                        // sessionStorage.removeItem('showOptinSecondPageLoad');
                        // fix for MWW-1770
                        // registrationModel.loadRegistration();
                    } else {
                        // sessionStorage.setItem('showOptinSecondPageLoad', true);
                    }
                } else if (dismissRegister === 'true') {
                    optinmodal.init();
                }
            } else if ((window.pageContext.ns === 'cart')) {
                stickyFooter.init();
            }
        }
    },
    emailLightboxFunction: function(url, cookiesEnabled) {
        var isAnon = window.User.anonymous;
        var timeout = util.isMobileSize ? 2000 : 0;
        var optinClass = isAnon ? 'optin-dialog anon' :'optin-dialog non';

        if (!cookiesEnabled) {
            if (isAnon) {
                Cookies.set('dismissRegister', false, {expires: 30});
            }
            Cookies.set('dismissEmailSignUp', false, {expires: 30});
        }

        setTimeout(function() {
            dialog.open({
                url: url,
                options: {
                    width: 500,
                    dialogClass: optinClass,
                    open: function () {
                        $(document).trigger('loyalty:' + ($(this).find('#loyalty-form').size() > 0 ? 'register' : 'optin'));
                    },
                    close: function () {
                        $(this).dialog('close');
                        $('#wrapper').attr('aria-hidden', 'false');
                        if ($(this).find('#loyalty-form').size() > 0) {
                            Cookies.set('dismissRegister', true, {expires: 30});
                        } else if ($(this).find('#optin-form').size() > 0) {
                            Cookies.set('dismissEmailSignUp', true, {expires: 30});
                        }
                        var $form = $('.RegistrationForm-modal');
                        if ($form.find('input[name$="_password"]').legnth === 0) {
                            page.refresh();
                        }
                    }
                }
            });
        }, timeout);
        $(document).on('submit', '#LoyaltyRegistrationForm', loyaltymodal.loyaltyForm);
        $(document).on('submit', '#OptinRegistrationForm', loyaltymodal.optinForm);
    },
    loyaltyForm: function (e) {
        e.preventDefault();
        var form = $(this);
        if (form.valid()) {
            $.post(form.attr('action'), form.serialize(), function (data) {
                if (data && data.indexOf('registered-success') != -1) {
                    window.location.href = window.Urls.accountShow;
                } else {
                    $('#loyalty-form').replaceWith(data);
                }
            });
        }
    },
    optinForm: function (e) {
        e.preventDefault();
        var form = $(this);
        if (form.valid()) {
            $.post(form.attr('action'), form.serialize(), function (data) {
                $('#optin-form').replaceWith(data);
            });
        }
    },
    initEmailModalListener: function () {
        var url = window.Urls.loyaltymodal;

        $('.home-lightbox-trigger').on('click', function() {
            loyaltymodal.emailLightboxFunction(url, false);
        });
    }
};

$('body').on('click','.ui-widget-overlay', function() {
    if ($('body').hasClass('gated')) return;
    $('.ui-dialog').filter(function () {
        return $(this).css('display') === 'block';
    }).find('.ui-dialog-content').dialog('close');
});

//center dialog on window resize
util.smartResize(function() {
    if ($(window).width() > 767) {
        $('.ui-dialog.registration, .ui-dialog.login-in').find('#dialog-container').dialog('option', 'position', {my: 'center center', at: 'center center', of: window});
    }
});

module.exports = loyaltymodal;
