'use strict';

var dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    page = require('../../page'),
    util = require('../../util'),
    Promise = require('promise'),
    _ = require('lodash'),
    quickview,
    bluecore = require('../../bluecore');

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]');

    // update ISPU availability in form
    if ($('.isavail').length && $('.isavail').data('status')) {
        var status = $('.isavail').data('status');
        var isAvailStatus = status.indexOf('STS') > -1 ? 'false' : 'true';
        $form.find('#availableInStore').val(isAvailStatus);
        if (status == 'SDP') {
            $('#pickupDay').val('today');
        } else if (status == 'SDP_tomorrow') {
            $('#pickupDay').val('tomorrow');
        } else {
            $('#pickupDay').val('');
        }
    }

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }

    if ($form.find('input#homeDelivery').val() == 'false') {
        // check for store at capacity or ISPU status N/A
        if ($('input#isStoreAtCapacity').val() == 'true' || $('input#isProductUnavailable').val() == 'true') {
            // display error message
            $('.ispuCapacityMessage').removeClass('hidden');
            $('.instore-delivery').addClass('unavailable');
            // do not add product to the cart
            return Promise.resolve(function() {})
            .then(function (response) {
                return response;
            });
        }
    }

    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize()
    })).then(function (response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            // if editing save for later item, skip bluecore push and return response
            if ($form.find('[name="source"]').length > 0 && $form.find('[name="source"]').val() === 'saveforlater') {
                return response;
            }
            // bluecore
            // if item is being edited, include a removeItem push for the original variant
            if ($form.find('[name="originalPid"]').length > 0 && $form.find('[name="originalPid"]').val()) {
                bluecore.pushRemoveItem($form.find('[name="originalPid"]').val(), $('[name="masterPid"]').val());
            }
            // push the item being added
            bluecore.pushAddItem($form.find('[name="pid"]').val(), $('[name="masterPid"]').val());
            bluecore.pushBasketGenderObject();

            var isEvents = require('../../interaction-studio-events');
            var $btnAddtoCart = $form.find('.option-add-to-cart button');
            var isUpdate = $('#cartAction').val() === 'update';
            var postLoad = false;
            var pid = $('#pid').length === 0 ? $btnAddtoCart.data('productid') : $('#pid').val();
            if (isUpdate === true && pid === '') {
                pid = $form.find('#pid').val();
            }
            var price = typeof $('.product-price .price-sales').data('price') === 'undefined' ? $btnAddtoCart.data('price') : $('.product-price .price-sales').data('price');
            var variantData = $form.find('input[name=variantData]').val() || '';
            if (variantData !== null && variantData !== undefined && variantData !== '') {
                try {
                    variantData = JSON.parse(variantData);
                } catch (error) {
                    variantData = {};
                }
            } else {
                variantData = {};
            }
            var sku ='';
            var styleColor = '';
            var priceLocal = '';
            var variant = '';
            if (variantData && variantData.sku) {
                sku = variantData.sku;
            }
            if (variantData && variantData.priceLocal) {
                priceLocal = variantData.priceLocal;
            }
            if (variantData && variantData.variant) {
                variant = variantData.variant;
            }
            if (variantData && variantData.styleColor) {
                styleColor = variantData.styleColor;
            }
            if (isUpdate) {
                postLoad = true;
            }
            isEvents.triggerATC(pid, 1, price, priceLocal, sku, variant, isUpdate, postLoad, styleColor);
            if (SitePreferences.STYLITICS_FORCE_V3 && typeof StyliticsClassicWidget !== 'undefined') {
                window.styliticsWidget.trackAddItemToCart(pid);
            }

            return response;
        }
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    //check that it is a giftcard because it has its own handler
    var isGiftCard = $('#GiftCertificateForm .giftcard');
    if (isGiftCard.length) {
        return;
    }
    e.preventDefault();
    var $btn = $(this);
    var $form = $btn.closest('form');

    if ($btn.data('needquickview')) {
        var qty, $qty = $form.find('input[name="Quantity"]');
        if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
            qty = 1;
        } else {
            qty = $qty.val();
        }
        var url = $btn.data('pdpurl');
        url = util.appendParamToURL(url, 'Quantity', qty);
        var $itemid = $form.find('input[name="itemid"]');
        if ($itemid.length > 0 && $itemid.val().length > 0) {
            url = util.appendParamToURL(url, 'wliid', $itemid.val());
        }
        quickview = quickview || require('../../quickview');
        quickview.show({
            url: url,
            source: 'quickview'
        });
        return;
    }

    addItemToCart($form).then(function (response) {
        var $uuid = $form.find('input[name="uuid"]');
        var isMultiSell = $('.shop-the-look-carousel').length > 0;
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.redirect(Urls.cartShow);
        } else {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (response.indexOf('limit-error') != -1) {
                $('#pdpMain').find('.qty-limit-error').html(response).show();
            } else {
                if (!$(this).hasClass('sub-product-item') && !isMultiSell) {
                    dialog.close();
                }
                if (window.pageContext.ns === 'cart') {
                    window.location.reload();
                } else {
                    minicart.show(response, true, isMultiSell);
                }
            }
        }
    }.bind(this));
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    Promise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1], true);
        });
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function ($scope) {
    $scope = $scope || $('body');
    $scope.find('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    $scope.find('.product-detail').on('click', '.add-to-cart', addToCart);
    $scope.find('.option-add-to-cart').on('click', '.add-to-cart', addToCart);
    $scope.find('#add-all-to-cart').on('click', addAllToCart);
    $scope.find('.js-capture-disabled-click').on('click', function() {
        if($scope.find('.js-select-a-size-message.sku-size').length > 0){
            $scope.find('.js-select-a-size-message').css({'color':'#c00','font-weight':'500'});
            $scope.find('.button-fancy-large').css({'background-color':'#ccc'});
            if($(".pdp-main .add-to-cart-favorites-wrapper .add-to-favorites #add-to-favorites-txt").length > 0){
                $scope.find('.js-select-a-size-message').parent().addClass('long-message');
            }
        }
    });
};
