/* eslint-disable import/prefer-default-export,object-shorthand,func-names,camelcase,radix */
var tls = require('./tls')

/**
  Usage: trigger tagAbCampaigns on every page load
  Then, from A/B campaigns trigger

  window.abDataLayer = window.abDataLayer || []
  window.abDataLayer.push({
    event: "experiment_impression",
    experiment_id,
    variant_id,
  })
*/

function pushEvent(experiment_id, variant_id) {
    // construct GA4 event
    const event = {
        event: 'experiment_impression_bundle',
        experiment_id,
        variant_id,
    }

    // push GA4 event
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(event)
}

const initAbCampaignTagging = function () {
    const arrayData = window.abDataLayer || []
    const delayCookieName = 'mw_abcd'
    const cookieName = 'mw_abc'
    const counterCookieName = 'mw_abcc'
    const experimentSeparator = ','
    const variantSeparator = ':'
    const eventSeparator = ','
    const isDelayedTrigger = tls.getCookie(delayCookieName) === '1'

    // proxy to set a cookie whenever we get an abDataLayer push
    // so that we can track across different pages
    // and to bundle and trigger only once (hopefully)
    window.abDataLayer = new Proxy([], {
        get: function (target, property) {
            return target[property]
        },

        set: function (target, property, value) {
            if (!isNaN(parseInt(property))) {
                const cookieVal = (tls.getCookie(cookieName) || '').split(experimentSeparator).filter(v => !!v)
                const experimentString = value.experiment_id + variantSeparator + value.variant_id

                if (!cookieVal.includes(experimentString)) {
                    if (!isDelayedTrigger) {
                        // if not already triggered and flagged to trigger immediatelly, then push the event
                        pushEvent(value.experiment_id, value.variant_id)
                    }

                    cookieVal.push(experimentString)

                    tls.setCookie(cookieName, cookieVal.join(','))
                }
            }

            target[property] = value
            return true
        },
    })

    // run the initial values through the proxy
    arrayData.forEach(function (elem) {
        window.abDataLayer.push(elem)
    })

    // method to retrieve the list of ab campaigns for the session and trigger the bundle event
    const triggerDataLayerEvent = function () {
        if (!isDelayedTrigger) {
            // if the tags were already triggered immediately
            // there's no need to trigger them again
            return
        }

        // convert the cookie value into GA4 event
        const cookieValue = tls.getCookie(cookieName) || ''
        const lastpushLength =
            parseInt(tls.getCookie(counterCookieName)) || 0

        if (cookieValue.length === lastpushLength) {
            // cookie has not changed since last push,
            // no need to re-push
            return
        }

        const experiments = cookieValue.split(experimentSeparator)
        const experiment_ids = []
        const variant_ids = []


        experiments.forEach(function (experiment) {
            const [experiment_id, variant_id] = experiment.split(variantSeparator)
            experiment_ids.push(experiment_id)
            variant_ids.push(variant_id)
        })

        pushEvent(experiment_ids.join(eventSeparator), variant_ids.join(eventSeparator))

        // save the length of the last cookie triggered in the bundle
        // to use it to check for changes and not trigger events that haven't changed
        // for instance, page A contains campaign, user navigates to page B with no new campaigns
        tls.setCookie(counterCookieName, cookieValue.length)
    }

    let timeout
    const restartTimeout = function () {
        clearTimeout(timeout)
        timeout = setTimeout(triggerDataLayerEvent, 10000)
    }

    // start time to trigger the bundle event
    restartTimeout()

    window.onbeforeunload = function () {
        // user navigated away, attempt to trigger it now
        // in case timeout didn't trigger
        triggerDataLayerEvent()
        clearTimeout(timeout)
    }

    window.addEventListener('locationchange', function () {
        // location soft-change, restart timer to catch
        // new ab campaigns that might trigger
        // e.g. checkout is SPA
        restartTimeout()
    })
}

const pushABEvent = function (experiment_id, variant_id) {
    // construct AB event
    const event = {
        event: 'experiment_impression',
        experiment_id,
        variant_id,
    }

    // push AB event
    window.abDataLayer = window.abDataLayer || [];
    window.abDataLayer.push(event);
}

exports.initAbCampaignTagging = initAbCampaignTagging;
exports.pushABEvent = pushABEvent;
