'use strict';

var _ = require('lodash'),
    dialog = require('./dialog'),
    util = require('./util'),
    templates = require('./templates');

var Store = {
    setUserZip: function (zip) {
        User.zip = zip;
        $.ajax({
            type: 'POST',
            url: Urls.setZipCode,
            data: {
                zipCode: zip
            }
        });
    },
    invalidZipMessage: function () {
        $('#preferred-store-panel').parent().find('.no-results').remove()
        $(`<div class="no-results">${Resources.INVALID_ZIP}</div>`).insertAfter($('.zip-retry-form-row'));
    },
    /**
     * @description test whether zipcode is valid for either US or Canada
     * @return {Boolean} true if the zipcode is valid for either country, false if it's invalid for both
     **/
    validateZipCode: function (zipCode) {
        var regexes = {
            canada: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
            usa: /^\d{5}(-\d{4})?$/
        };
        var valid = false;
        if (!zipCode) {
            Store.invalidZipMessage();
            return valid;
        }
        _.each(regexes, function (re) {
            var regexp = new RegExp(re);
            valid = regexp.test(zipCode);
        });
        if (!valid) {
            Store.invalidZipMessage();
        }
        return valid;
    },

    /**
     * @param {string} [errorMessage=Resources.TRY_LATER] - Error message
     **/
    showServiceError: function (errorMessage) {
        var $container = $('.store-list-container');
        var message = errorMessage || Resources.TRY_LATER;
        var messageHtml = `<div class="no-results">${message}</div>`;
        $container.removeClass('.loading-template');
        $container.empty().append(messageHtml);
    },

    initStoreTiles: function () {
        var $asapPickups = $('.ispu-select-store').find('.available-today');
        if ($asapPickups.length) {
            $asapPickups.last().addClass('last-available-today');
        } else {
            $('.ispu-select-store').find('.store-list-container').append(`<p class="error-none-available-today">${Resources.NOT_AVAILABLE_TODAY}</p>`)
        }
        $('.ispu-select-store').on('click', '.store-address .down-arrow', function () {
            var $expandableArea = $(this).parent().next('.store-address-info');
            $(this).toggleClass('expanded');
            if ($(this).hasClass('expanded')) {
                $expandableArea.show(200);
            } else {
                $expandableArea.hide(200);
            }
        });
        $('.ispu-select-store').on('change', '.zip-sort-option', function () {
            if ($(this).val() === 'pickup_asap') {
                $('.store-list-container').addClass('pickup-asap');
            } else {
                $('.store-list-container').removeClass('pickup-asap');
            }
        });
    },

    /**
     * @description open the dialog to select store
     * @param {Array} options.stores
     * @param {String} options.selectedStoreId
     * @param {String} options.selectedStoreText
     * @param {Function} options.continueCallback
     * @param {Function} options.selectStoreCallback
     **/
    selectStoreDialog: function (options) {
        var stores = options.stores;
        var selectedStoreId = options.selectedStoreId;
        var selectedStoreText = options.selectedStoreText;
        var noStoresFoundMessage = options.noStoresFoundMessage;
        var chooseStoreText = ($('.js-mystore-select[data-clicked="mystore"]').length > 0) ||
            ($('.js-mystore-select[data-clicked="drawer"]').length > 0) ? Resources.SET_MY_STORE : '';
        var selectStoreModalDisclaimer = $('.js-mystore-select[data-clicked="plp"]').length
            && $('.js-mystore-select[data-clicked="plp"]').data('disclaimer') ? $('.js-mystore-select[data-clicked="plp"]').data('disclaimer') : '';
        var that = this;
        var titleIfExisting = options.title ? options.title : dialog && dialog.isActive() ? dialog.$container.find('#dialog-title').text() : undefined;

        if (stores && stores.length) {
            var storeList = templates.storeListTemplate(stores, selectedStoreId, selectedStoreText, chooseStoreText, titleIfExisting, selectStoreModalDisclaimer);
            dialog.open({
                html: storeList,
                options: {
                    modal: true,
                    width: '100%', // CSS to handle max width
                    dialogClass: 'ispu-select-store',
                    selectStoreCallback: that.updatePrefferedStore,
                    open: function () {
                        that.initStoreTiles();
                        $('.select-store-button').off('click').on('click', function (e) {
                            e.preventDefault();
                            var $target = $(this);
                            var storeId = $target.data('storeId');
                            // if the store is already selected, don't select again
                            if (storeId && selectedStoreId && storeId.toString() === selectedStoreId.toString() && window.location.href.indexOf('filterstoreid=true') > -1) {
                                dialog.close();
                                return;
                            }
                            $('.store-list .store-tile.selected').removeClass('selected').find('.select-store-button').text(Resources.SELECT_VIEW_STORE);
                            $target.text(selectedStoreText).closest('.store-tile').addClass('selected');
                            if (options.selectStoreCallback) {
                                options.selectStoreCallback(storeId);
                            }
                            if (options.continueCallback) {
                                options.continueCallback();
                            }
                            dialog.close();
                            if (pageContext.ns === 'search') {
                                var plpUrl;
                                if (window.location.href.indexOf('filterstoreid=false') > -1) {
                                    plpUrl = window.location.href.replace('filterstoreid=false', 'filterstoreid=true');
                                } else {
                                    plpUrl = window.location.href + (window.location.href.indexOf('?') > -1 ? '&' : '?') + "filterstoreid=true"
                                }
                                window.location.href = plpUrl;
                            }
                        }); 
                        $('.search-by-user-zip').off('click.search-by-zip').on('click.search-by-zip', function (e) {
                            // there's another binding (for favorite store)
                            // that would also trigger unless we prevent it
                            e.stopPropagation();
                            var zipCode = $('#user-zip').val();
                            if (that.validateZipCode(zipCode)) {
                                User.previousStores = stores;
                                that.setUserZip(zipCode);
                                if (pageContext.ns === 'cart' && !User.storeId) {
                                    $(document).trigger('trigger:getStoresInCart', {
                                        zipCode: zipCode
                                    });
                                } else {
                                    // TODO: this function is used in other modules
                                    // It worth to be converted to util or helper
                                    that.showStoresByZipCode(zipCode);
                                }
                            }
                        });
                        $('.search-new-zip button').on('click', function() {
                            var zipCode = $('#user-zip').val();
                            if (that.validateZipCode(zipCode)) {
                                that.setUserZip(zipCode);
                                if (options.reSearchCallback) {
                                    dialog.close();
                                    options.reSearchCallback(zipCode);
                                }
                            }
                        });
                        $('#user-zip').on('keypress', function (e) {
                            if (e.which === 13) {
                                // trigger the search button
                                var zipCode = $('#user-zip').val();
                                if (that.validateZipCode(zipCode)) {
                                    that.setUserZip(zipCode);
                                    if (options.reSearchCallback) {
                                        dialog.close();
                                        options.reSearchCallback(zipCode);
                                    }
                                }
                            }
                        });
                    }
                }
            });
        } else {
            that.showServiceError(noStoresFoundMessage);
        }
    },

    showStoresByZipCode: function (zipCode) {
        User.zip = zipCode;
        var that = this;
        var params = {
            zipCode: zipCode
        };
        var dialogOptions = {
            stores: [],
            selectedStoreId: User.storeId,
            selectedStoreText: Resources.PREFERRED_STORE,
            noStoresFoundMessage: Resources.TRY_ANOTHER_ZIP,
            selectStoreCallback: function () {
                var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');
                that.setPreferredStore(newStoreId);
            }
        }
        $.ajax({
            url: util.appendParamsToUrl(Urls.myStoreLookUpByZipCode, params),
            dataType: 'json',
            success: function (response) {
                dialogOptions.stores = response.stores;
                that.selectStoreDialog(dialogOptions);
                //if store is unavailable disable its select-store-button
                $('.store-tile.unavailable').find('.select-store-button').attr('disabled', 'disabled');
            },
            error: function () {
                return that.showServiceError();
            }
        });
    },

    setPreferredStore: function (storeId, type) {
        User.storeId = storeId;
        var that = this;
        var storeFrom = $('.js-mystore-select.active-store-select').data('clicked') || '';
        if (typeof type !== 'undefined' && type === 'geolocation') {
            storeFrom = type;
        }
        $.ajax({
            url: Urls.myStoreSetPrefferedStore,
            type: 'POST',
            data: {storeId: storeId, storeInSessionOnly: true, storeFrom: storeFrom},
            success: function (response) {
                that.updatePrefferedStore(response)
                $('#plp-preferred-store-id').val(storeId);
            }
        });
    },

    updatePrefferedStore: function (response) {
        var storeData = response.store && Object.keys(response.store).length > 0 ? response.store : false;
        var plpStoreData = response.plpstore && Object.keys(response.plpstore).length > 0 ? response.plpstore : false;
        var tagmanager = require('googletags/tagmanager');
        $('#filterstoreid').prop('disabled', false);
        const plpWasClicked = $('.js-mystore-select[data-clicked="plp"]').length > 0;
        const drawerWasClicked = $('.js-mystore-select[data-clicked="drawer"]').length > 0;
        const myStoreWasClicked = $('.js-mystore-select[data-clicked="mystore"]').length > 0;
        var selectStoreClicked = $('.js-mystore-select[data-no-store="true"][data-clicked]').length > 0;
        // let's update all the places with necessary templates accordingly
        if (storeData) {
            var $profileStore = $('.mystore.user-link-description');
            if ($profileStore.find('.mystore-address').length === 0) {
                $profileStore.prepend('<div class="mystore-address"><a class="mystore-address-address js-account-drawer-store" href="' + $profileStore.data('href') + '" title="' + $profileStore.data('title') + '"></a></div>')
            }
            $('.js-mystore-select').data('storeId', storeData.storeId);
            $('.js-account-drawer-store').empty().append(templates.accountDrawerStoreTemplate(storeData));
            $('.js-mystore-wrapper').empty().append(templates.prefferedStoreTemplate(storeData));
        }
        if (plpStoreData) {
            $('.js-plp-filter-store-id').empty().append(templates.filterPLPByStoreTemplate(plpStoreData));
        }
        if (selectStoreClicked) {
            if ($('.js-mystore-select[data-clicked]').text().includes('Change')) {
                selectStoreClicked = false;
            }
        }
        if ($('.js-mystore-select[data-no-store="true"]').siblings('.mystore-address').length) {
            $('.js-mystore-select[data-no-store="true"]').text(Resources.MYSTORE_SELECT_LINK);
        }
        this.initStoreMap();
        if (plpWasClicked) {
            storeData.location = 'PLP';
            tagmanager.changeStore(storeData, selectStoreClicked);
        }
        if (drawerWasClicked) {
            storeData.location = 'Account Drawer';
            tagmanager.changeStore(storeData, selectStoreClicked);
        }
        if (myStoreWasClicked) {
            storeData.location = 'My Store';
            tagmanager.changeStore(storeData, selectStoreClicked);
        }
    },

    findStoreByZipCodeDialog: function(title) {
        var title = $(this).data('title');
        var loadingOptions = {
            dialogClass: 'ispu-select-store'
        };
        dialog.open({
            html: templates.findStoreTemplate(title),
            options: loadingOptions
        });
    },

    showStoresByGeolocation: function(lat, long) {
        var params = {
            lat: lat,
            long: long
        };
        var that = this;

        $.ajax({
            url: util.appendParamsToUrl(Urls.myStoreLoadByLatLong, params),
            dataType: 'json',
            success: function (response) {
                that.selectStoreDialog({
                    stores: response.stores,
                    selectedStoreId: User.storeId,
                    selectedStoreText: Resources.PREFERRED_STORE,
                    selectStoreCallback: function () {
                        var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');
                        that.setPreferredStore(newStoreId);
                    }
                });
                //if store is unavailable disable its select-store-button
                $('.store-tile.unavailable').find('.select-store-button').attr('disabled', 'disabled');
            },
            error: function () {
                return that.showServiceError();
            }
        });
    },

    getSetStoreByGeolocation: function(lat, long) {
        var params = {
            lat: lat,
            long: long
        };
        var that = this;
        $.ajax({
            url: util.appendParamsToUrl(Urls.myStoreLoadByLatLong, params),
            dataType: 'json',
            success: function (response) {
                if (response.stores && response.stores.length > 0) {
                    if (response.stores[0].storeId) {
                        that.setPreferredStore(response.stores[0].storeId, "geolocation");
                    }
                }
            }
        });
    },

    getLocationAndSetPreferredStore: function () {
        var geolocationObj = null;
        var that = this;

        $.getJSON(Urls.getGeolocation, function (data) {
            if (data && 'available' in data && data.available === true) {
                geolocationObj = data;
            } else {
                navigator.geolocation.getCurrentPosition(function (pos) {
                    geolocationObj = {};
                    var coords = pos.coords;
                    if (coords.latitude && coords.longitude) {
                        geolocationObj.available = true;
                        geolocationObj.location = {
                            lat: coords.latitude,
                            long: coords.longitude
                        }
                        that.getSetStoreByGeolocation(geolocationObj.location.lat, geolocationObj.location.long);
                    }
                });
            }
        }).done(function () {
            if (geolocationObj && geolocationObj.available) {
                that.getSetStoreByGeolocation(geolocationObj.location.lat, geolocationObj.location.long);
            }
        });
    },

    initMyStore: function(geoLocate) {
        var preferedStoreId = $('#plp-preferred-store-id').val();
        var that = this;
        if ('pageContext' in window && 'ns' in window.pageContext && window.pageContext.ns !== 'storefront') {
            if (!preferedStoreId) {
                if (geoLocate === true) {
                    that.getLocationAndSetPreferredStore();
                }
            } else {
                that.setPreferredStore(preferedStoreId);
            }
        }
        
        $(document).on('click', '.js-mystore-select', function() {
            var $target = $(this);
            var title = $(this).data('dialog-title');
            if ($target.hasClass('disabled')) {
                return;
            }
            var loadingOptions = {
                dialogClass: 'ispu-select-store',
            };
            // to keep track of what store link clicked
            $('.js-mystore-select').removeAttr('data-clicked').removeClass('active-store-select');
            $target.addClass('active-store-select');
            // clear all and set current
            if ($target.parents('.plp-nav-filters').length > 0) {
                $target.attr('data-clicked', 'plp');
            } else if ($target.parents('.mystore-wrapper').length > 0) {
                $target.attr('data-clicked', 'mystore');
            } else if ($target.parents('.user-panel.right-drawer').length > 0 || $(this).prev().is('button')) {
                $target.attr('data-clicked', 'drawer');
            }

            if ($target.data('storeId')) {
                User.storeId = $target.data('storeId');
            }

            dialog.open({
                html: templates.findStoreTemplate(title),
                options: loadingOptions
            });
            var geolocationObj = null;
            $.getJSON(Urls.getGeolocation, function (data) {
                if (data && 'available' in data && data.available === true) {
                    geolocationObj = data;
                } else {
                    navigator.geolocation.getCurrentPosition(function (pos) {
                        geolocationObj = {};
                        var coords = pos.coords;
                        if (coords.latitude && coords.longitude) {
                            geolocationObj.available = true;
                            geolocationObj.location = {
                                lat: coords.latitude,
                                long: coords.longitude
                            }
                        }
                        var zipCode = User.zip;
                        if (that.validateZipCode(zipCode)) {
                            $('#user-zip').val(zipCode);
                            that.showStoresByZipCode(zipCode);
                            that.setUserZip(zipCode);
                        } else if (geolocationObj && geolocationObj.available) {
                            that.showStoresByGeolocation(geolocationObj.location.lat, geolocationObj.location.long);
                        } else {
                            dialog.close();
                            that.findStoreByZipCodeDialog();
                        }
                        //scroll to top if mobile to prevent scrolling issue
                        if (util.isMobile()) {
                            document.body.scrollTop = document.documentElement.scrollTop = 0;
                        }
                    });
                }
            }).done(function() {
                if (geolocationObj && geolocationObj.available) {
                    var zipCode = User.zip || geolocationObj && geolocationObj.available && geolocationObj.location.zip;
                    if (that.validateZipCode(zipCode)) {
                        $('#user-zip').val(zipCode);
                        that.showStoresByZipCode(zipCode);
                        that.setUserZip(zipCode);
                    } else if (geolocationObj && geolocationObj.available) {
                        that.showStoresByGeolocation(geolocationObj.location.lat, geolocationObj.location.long);
                    } else {
                        dialog.close();
                        that.findStoreByZipCodeDialog(title);
                    }
                    //scroll to top if mobile to prevent scrolling issue
                    if (util.isMobile()) {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                    }
                }
            });
        });

        $(document).on('click', '.search-by-user-zip', function() {
            var zipCode = $('#user-zip').val();
            if (that.validateZipCode(zipCode)) {
                that.setUserZip(zipCode);
                if (pageContext.ns === 'cart' && !User.storeId) {
                    $(document).trigger('trigger:getStoresInCart', {
                        zipCode: zipCode
                    });
                } else {
                    // User.previousStores = stores;
                    // TODO: this function is used in other modules
                    // It worth to be converted to util or helper

                    that.showStoresByZipCode(zipCode);
                }
            }
        });
    },

    addPin: function(storeDataObj, map) {
        if (map && storeDataObj) {
            var marker = new google.maps.Marker({
                position: {lat: storeDataObj.lat, lng: storeDataObj.lng},
                map: map,
            });
        }
    },

    getStoreObj: function() {
        var $myStoreMapContainer = $('.js-mystore-map');
        var storeObj = {};

        if ($myStoreMapContainer.length) {
            var storeLat = $myStoreMapContainer.data('store-lat');
            var storeLng = $myStoreMapContainer.data('store-lng');

            if (storeLat && storeLng) {
                storeObj.lat = storeLat;
                storeObj.lng = storeLng;
            }
        }

        return storeObj;
    },

    initStoreMap: function() {
        if (!$('.js-mystore-map').length) {
            return;
        }

        var that = this;
        var storeDataObj = that.getStoreObj();

        if (window.mapsLoaded && Object.keys(storeDataObj).length) {

            var map = new google.maps.Map(document.getElementById('js-mystore-map'), {
                center: {lat: storeDataObj.lat, lng: storeDataObj.lng},
                zoom: 15,
            });
            that.addPin(storeDataObj, map);
        }
    },
};

module.exports = Store;