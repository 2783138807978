'use strict';

var productTile = require('./product-tile');

/**
 * @private
 * @function
 * @description Listens for new DOM nodes loaded asynchronously within product recs zone and fires events necessary for quickshop. Only enabled on cart.
 */
function initRecs() {
    var targets = $('.cart-recommendations *[id^="cq_recomm_slot-"]');
    if (!targets.length) return;
    var config = {attributes: false, childList: true, characterData: false}
    targets.each(function() {
        var observer = new MutationObserver(function() {
            productTile.initializeEvents();
            observer.disconnect();
        });
        observer.observe($(this)[0], config);
    });

    productTile.initializeQuickviewEvents($('.cart-recommendations'));
}

exports.init = function() {
    initRecs();
}
