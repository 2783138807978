/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var event = require('../event');

var cart = {
    init: function (){
        //Cart Remove
        $('button[name$="deleteProduct"]').on('click', function() {
            var $cartRow = $(this).closest('tr'),
                upc = $cartRow.find('.cart-pid').val();
            cart.cartRemove(upc);
        });
        $('button[name$="checkoutCart"]').on('click', cart.checkoutStart);
        $('[name$="_quantity"]').on('change', function () {
            // eVar96
            // Update total number of items in cart
            event.pushEVar('eVar96', cart.getTotalItems(), true);
            // event39
            // Cart Edit
            // Counter event to fire in the shopping cart whenver a user edits anything in their cart.
            event.pushEvent('event39');
        });
        
        $(document).on('click', '#add-coupon', function () {
            var couponCode = $('#dwfrm_cart_couponCode').val();
            
            // eVar112
            // Promo Code Value Entered
            // Passes the value a visitor used when hitting the apply button on the promo code field. 
            event.pushEVar('eVar112', couponCode);
            
            // event182
            // Promo Code Apply
            // Counter event to trigger every time a value is entered into the promo code field and a visitor clicks apply. This will always trigger with the Promo Code Value Entered eVar.
            event.pushEvent('event182');
        });
        
        // check for SFL action continuation after login/sign up
        if ($('#sflAction').val() && $('#sflPid').val() != 'null') {
            var $sflProduct = $('.sfl-item[data-pid="' +$('#sflPid').val()+ '"]');
            if ($sflProduct) {
                if ($('#sflAction').val() === 'add') {
                    cart.addToSaveForLater($sflProduct.data('masterproductid'), 0);
                }
            }
        }
        // event279
        // SFL Views
        // Occurs each time a save for later list is viewed
        event.pushEvent('event279', null, true);
        
        // scView
        // Cart Views
        // Occurs each time a shopping cart is viewed.
        if ($('#dwfrm_login').size() > 0) {
            event.pushEvent('scCheckout', null, true);
            event.pushEvent('event18');
        } else {
            event.pushEvent('scView', null, false, {});
        }
        
        // Save For Later events
        // save for later add
        $('a.save-for-later').on('click', function(e) {
            e.preventDefault();
            cart.addToSaveForLater($(this).data('masterproductid'), Number($(this).closest('.cart-row').find('[name$="_quantity"]').val()));
            window.location.assign(e.target.href);
        });
        
        // save for later product - move to bag
        $('.sfl-item a.add-to-cart').on('click', function() {
            cart.sflMoveToBag($(this).data('masterproductid'));
        });
        
        // save for later - remove product
        $('.sfl-user-actions a.remove').on('click', function() {
            cart.sflRemove($(this).data('masterproductid'));
        });
    },

    cartRemove: function (upc) {
        // eVar96
        // Update total number of items in cart
        event.pushEVar('eVar96', cart.getTotalItems(), true);
        
        // scRemove
        // Cart Removes
        // Occurs when a user removes an item from their cart.
        event.pushEvent('scRemove', null, true, {'eVar38': upc});
        
        // event39
        // Cart Edit
        // Counter event to fire in the shopping cart whenver a user edits anything in their cart.
        event.pushEvent('event39', null, true);
        event.push()
    },
    
    checkoutStart: function () {
        
        // scCheckout
        // Checkouts
        // Occurs each time a user initiates checkout.
        event.pushEvent('scCheckout');
    },
    
    addToSaveForLater: function (masterID, qty) {
        // eVar96
        // Update total number of items in cart
        event.pushEVar('eVar96', (cart.getTotalItems() - qty), true);
        if ($('.sfl-no-products').length > 0 || (qty === 0 && $('.sfl-item').length === 1)) {
            // event280
            // Save For Later list open
            // Triggered when a customer adds their first product to Save For Later
            event.pushEvent('event280', null, true);
        }
        // event275
        // Product added to Save For Later
        // This event only passes the product ID, but we need to include empty strings for the blank fields to preserve formatting
        var product = {category: '', product: masterID, quantity: '', price: '', events: '', variables: ''};
        event.pushEvent('event275', null, true, {}, product);
    },
    
    sflMoveToBag: function (masterID) {
        // eVar96
        // Update total number of items in cart
        event.pushEVar('eVar96', (cart.getTotalItems() + 1), true);
        // event277
        // Product moved to the cart from a customer's Save For Later list
        // This event only passes the product ID, but we need to include empty strings for the blank fields to preserve formatting
        var product = {category: '', product: masterID, quantity: '', price: '', events: '', variables: ''};
        event.pushEvent('event277', null, true, {}, product);
    },
    
    sflRemove: function (masterID) {
        // Product removed from a customer's Save For Later list
        // This event only passes the product ID, but we need to include empty strings for the blank fields to preserve formatting
        var product = {category: '', product: masterID, quantity: '', price: '', events: '', variables: ''};
        event.pushEvent('event276', null, true, {}, product);
    },
    
    getTotalItems: function () {
        var totalItems = 0;
        $('[name$="_quantity"]').each(function () {
            
            totalItems += Number($(this).val());
        });
        return totalItems;
    }
}

exports.init = cart.init;
