'use strict';

/**
 * @description set position of delivery options tabs
 */
function setTabsPosition($scope) {
    const $deliberyOptionsContainer = $scope.find('.js-pdp-delivery-types');
    const deliveryTypeSelector = '.js-pdp-delivery-type';
    const $deliveryType = $(deliveryTypeSelector);
    
    if (!$deliberyOptionsContainer.length) {
        return;
    }

    const $activeOption = $(`${deliveryTypeSelector} input[name="delivery_option"]:checked`);

    if ($activeOption.length) {
        $deliveryType.removeClass('active');
        $activeOption.closest(deliveryTypeSelector).addClass('active');
    }

    const $selectedOption = $deliberyOptionsContainer.find(`${deliveryTypeSelector}.active`);

    if ($selectedOption.length) {
        const sectionHeight = $selectedOption.find('.js-delivery-option-content').length ? $selectedOption.find('.js-delivery-option-content').height() : 100;
        $deliberyOptionsContainer[0].style.setProperty('--optionHeight', `${sectionHeight}px`);
    }
   
}

/**
 * @description init events for delivery type options
 */
function initEvents($scope) {
    const $deliberyOptionsContainer = $scope.find('.js-pdp-delivery-types');
    
    if (!$deliberyOptionsContainer.length) {
        return;
    }

    $('.js-delivery-option-header').on('click', (e) => {
        const $currentTarget = $(e.currentTarget);
        const $deliveryOptionInput = $currentTarget.find('input[name="delivery_option"]');

        if ($deliveryOptionInput.length) {
            $deliveryOptionInput.prop('checked', 'checked');
            setTabsPosition($scope);
        }
    });
   
}

module.exports.init = ($scope) => {
    $scope = $scope || $('body');
    setTabsPosition($scope);
    initEvents($scope);
}
