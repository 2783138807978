'use strict';
var util = require('./util');

const dataLayer = 'dataLayer' in window ? window.dataLayer : null;

exports.pushPDPInteraction = function(sku, productID) {
    // GA v2 tagging needs to remain available during GA360 transition to prevent compatibility issues with existing 3rd party tags in GTM
    if (dataLayer) {
        dataLayer.push({
            'event': 'PDPInteraction',
            'skuId' : sku,
            'productID' : productID
        });
    }
}

exports.pushPDPQuickShopView = function(productID) {
    // GA v2 tagging needs to remain available during GA360 transition to prevent compatibility issues with existing 3rd party tags in GTM
    if (dataLayer) {
        dataLayer.push({
            'event': 'PDPQuickShopView',
            'productID' : productID
        });
    }
}

exports.pushOnDialogOpen = function() {
    if (dataLayer && User.microSite != '') {
        dataLayer.push({
            'micrositegender' : User.microSite
        });
    }
}

exports.pushAddItem = function(sku, productID) {
    // GA v2 tagging needs to remain available during GA360 transition to prevent compatibility issues with existing 3rd party tags in GTM
    if (dataLayer) {
        dataLayer.push({
            'event': 'addItem',
            'skuId' : sku,
            'productID' : productID
        });
    }
}

exports.pushBasketGenderObject = function(forceRefresh) {
    if (dataLayer) {
        var url = Urls.getBasketGenderObject;
        if (forceRefresh) {
            url = util.appendParamToURL(url, 'forceRefresh', 'true');
        }
        $.ajax({
            url: url,
            success: function (response) {
                if (response.basketGenderObject && Object.keys(response.basketGenderObject).length > 0) {
                    dataLayer.push({
                        'shoppingcartgender' : response.basketGenderObject.shoppingcartgender,
                        'shoppingcartitemsmens' : response.basketGenderObject.shoppingcartitemsmens,
                        'shoppingcartitemswomens' : response.basketGenderObject.shoppingcartitemswomens,
                        'shoppingcartitemsunisex' : response.basketGenderObject.shoppingcartitemsunisex
                    });
                }
            }
        });
    }
}

exports.pushRemoveItem = function(sku, productID) {
    if (dataLayer) {
        // GA v2 tagging needs to remain available during GA360 transition to prevent compatibility issues with existing 3rd party tags in GTM
        dataLayer.push({
            'event': 'removeItem',
            'skuId' : sku,
            'productID' : productID
        });
    }
}

exports.checkForAutoRemoveItems = function() {
    if (dataLayer) {
        var removedPids = $('#autoRemovedPids').length > 0 && $('#autoRemovedPids').val().length > 0 ? JSON.parse($('#autoRemovedPids').val()) : null;
        if (removedPids && Array.isArray(removedPids)) {
            for (var i=0; i < removedPids.length; i++) {
                exports.pushRemoveItem(removedPids[i].pid, removedPids[i].masterpid);
            }
        }
        $('#autoRemovedPids').val('');
    }
}

exports.pushTransactionalEmail = function() {
    if (dataLayer) {
        dataLayer.push({
            'transactionalEmail': $('#transactionalEmail').val()
        });
    }
}
