'use strict';

/*
    This module sets up a lightweight alternative to the jQuery UI Dialog.  It aims to have the same UX the same as the jquery dialog,
    but the fuctionality under the hood is scaled way back. Noteably, the dialog content needs to already exist in the DOM when the
    lightbox is launched. See example markup below.

    Sample Markup:

    <a href="javascript:void(0)" data-lightbox-target="MYLIGHTBOX">click to open</a>
    <div id="MYLIGHTBOX" class="lightbox-modal">
        <div class="lightbox-dialog>
            <h4 class="lightbox-dialog-title"> MY TITLE </h4>
            <div class="lightbox-dialog-body"> MY CONTENT </div>
        </div>
    </div>
*/

var lightbox = {
    /**
     * Initialize the Lightbox
     */
    init: function () {
        // show on click of data-lightbox-target element
        $(document).on('click', '[data-lightbox-target]', function (e) {
            e.preventDefault();
            var id = $(this).data('lightbox-target');
            var $modal = $('#' + id);
            lightbox.open($modal);
        });
        // hide on click of modal backdrop
        $(document).on('click', function (e) {
            if ($(e.target).is('.lightbox-modal')) {
                var $modal = $(e.target);
                lightbox.close($modal);
            }
        });
        // hide on click of close button
        $(document).on('click', '.lightbox-close-btn', function () {
            var $modal = $(this).closest('.lightbox-modal');
            lightbox.close($modal);
        });
    },
    open: function ($modal) {
        if ($modal.attr('data-open') !== 'true') {
            $(document).trigger('freezeBackground');
            $modal.show(0).attr('data-open', true);
            var $dialog = $modal.find('.lightbox-dialog');
            if ($dialog.find('.lightbox-close-btn').length === 0) {
                $dialog.prepend('<button class="lightbox-close-btn" type="button"></button>');
            }
        }
    },
    close: function ($modal) {
        if ($modal.data('open') === true) {
            $(document).trigger('unfreezeBackground');
            $modal.hide(0).attr('data-open',false);
        }
    }
};

module.exports = lightbox;
