'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    accordion = require('./accordion'),
    bluecore = require('../../bluecore'),
    matchingPiece = require('./matchingPiece'),
    deliveryOptions = require('./deliveryOptions'),
    carousel = require('../../carousel'),
    variantType = '';

/**
 * @description Move split swatch pricing to be above swatch
 **/
var splitSwatchPricing = function () {
    var splitSwatchPrice = $('.split-swatch-pricing');
    if ($(splitSwatchPrice).length) {
        $(splitSwatchPrice).show();
        $(splitSwatchPrice).each(function () {
            var prev = $(this).prev();
            $(this).insertBefore(prev);
        })
    }
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 * @param {function} callback - function to call with response
 * @param {Ojbject} $scope - function to call with response
 **/
var updateContent = function (href, callback, $scope) {
    $scope = $scope || $('body');

    var $pdpForm = $scope.find('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($scope.find('#pdpMain'));

    $(document).trigger('load-variant:before');

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $scope.find('#product-content'),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages($scope);
            tooltip.init({
                position: {
                    my: 'right-25px center',
                    at: 'left-25px center'
                }
            });
            productStoreInventory.initGetItByTooltip();
            productStoreInventory.updateLocalGetItByCutOffTime();

            var rowIdexToOpen = 0
            if ($('#addtocartlocation').val() && $('#addtocartlocation').val().length > 0 && $('#addtocartlocation').val() == 'quickview') {
                rowIdexToOpen = 10;
            }
            if (callback) {
                callback();
            }
            accordion.init('.product-accordion', null, null, rowIdexToOpen);
            $(document).trigger('load-variant:after');
            var masterPid = $scope.find('#masterPid').val();
            if (window.$BV && window.configData && window.bvSubmissionContainerUrl && $('.js-bv-reviews').length && masterPid && masterPid !== window.configData.productId) {
                var bvGlobalData = {};
                var bvParams = {
                    pid: masterPid
                };
                var bvSubmissionUrl = util.removeParamFromURL(window.bvSubmissionContainerUrl, 'pid');
                bvGlobalData.submissionContainerUrl = util.appendParamsToUrl(bvSubmissionUrl, bvParams)
                window.$BV.configure("global", bvGlobalData);
                window.configData.productId = masterPid;
                window.$BV.ui('rr', 'show_reviews', window.configData);
            }
            $('.product-usd').removeClass('hide-USD');
            var $sizeChartLink = $('.size-chart-link');
            if ($sizeChartLink.length > 0) {
                $('#personalize').addClass('withSizeChart');
            }
            var $inStoreOption = $('.pt_product-details .instore-delivery');
            var isQuickView = $('#QuickViewDialog').length;
            if ($inStoreOption.length && $inStoreOption.is(':visible')) {
                //add withoutAddress class to ispu error message and hide change store in cases where size is unselected
                var addressText = $('.selected-store-address').text();
                if (addressText == '') {
                    $('.in-store-pickup-error').addClass('withoutAddress');
                    $('.change-store').addClass('withoutAddress');
                }
                if (href.indexOf('isMaster=true') > -1) {
                    $('#isMasterProduct').val('true');
                }
                var notEligible = $('.notAvailForIspuMessage').length,
                    isAddToBagDisabled = $('#add-to-cart').is(':disabled'),
                    isMaster = $('#isMasterProduct').val() == 'true',
                    isISPU = href.indexOf('ispu') > -1;
                if ($('.in-store-pickup-error.hidden').length < 1
                    && isISPU && !notEligible && !isQuickView && !isAddToBagDisabled && !isMaster) {
                    $('.instore-delivery .delivery-option:checked').trigger('change');
                }
                if (notEligible.length > 0) {
                    $('.instore-delivery').addClass('notEligible');
                }
            }
            if ($('.unselectable.selected').length > 0) {
                $('.availability-message').addClass('hidden');
            }
            var colorMergeEnabled = $scope.find('.product-main-content') ? $scope.find('.product-main-content').data('enabled-color-merge') : false;
            if (colorMergeEnabled) {
                if ($('.pt_product-details').length > 0) {
                    window.history.pushState('', document.title, $scope.find('.masterUrl').html());
                    util.setVirtualPage();
                    $('.product-reviews-lockup__item-number').html('Item ' +$scope.find('#masterPid').val());
                }
                if (isQuickView) {
                    $('a.view-full-details, div.view-full-details a').attr('href', $scope.find('.masterUrl').html());
                    $('.product-reviews-lockup-qv__item-number').html('Item ' +$scope.find('#masterPid').val());
                }
            }
            if ($scope.find('input[name=variantData]').length > 0) {
                var variantData = $scope.find('input[name=variantData]').val() || '';
                if (variantData !== null && variantData !== undefined && variantData !== '') {
                    try {
                        variantData = JSON.parse(variantData);
                    } catch (error) {
                        variantData = {};
                    }
                } else {
                    variantData = {};
                }
                if (variantData && variantData.hasOwnProperty('sku')) {
                    variantData.sku = $('#pid').val();
                }
                if (variantData && variantData.hasOwnProperty('variant')) {
                    var size = $scope.find('.swatches.size').find('.selected').data('value');
                    var color = $scope.find('.swatches.color').find('.selected').data('value');
                    if (size || color) {
                        variantData.variant = $scope.find('#masterPid').val() + color + size;
                    }
                }
                $scope.find('input[name=variantData]').val(JSON.stringify(variantData));
            }
            matchingPiece.init();
            pushPDPInteraction();
            isPDPInteraction();
            deliveryOptions.init($scope);
            var isRedesignEnabled = $('#QVRedesignEnabled').data('redesign');
            if (isQuickView && isRedesignEnabled) {
                carousel.loadQuickView($('#QuickViewDialog'));
            }
            $('.eswGetPrice').each(function () {
                var el = $(this);
                var url = el.data('url');

                $.ajax({
                    url : url,
                    success : function (response) {
                        el.replaceWith(response);
                    }
                });
            });
            carousel.loadQuickView($('#QuickViewDialog'));            
            var $quickviewDialog = $('#QuickViewDialog');
            if($quickviewDialog.hasClass('shop-the-look-dialog')){
                var addToBagBtn = $quickviewDialog.find('.pdpForm');
                var dialogAccordion = $quickviewDialog.find('.dialog-details');
                var mainCarousel = $quickviewDialog.find('.product-image-carousel-container');
                var mainCarouselPrev = $quickviewDialog.find('.carousel-arrow-prev');
                var mainCarouselNext = $quickviewDialog.find('.carousel-arrow-next');
                if(util.isDesktopSize()) {
                    addToBagBtn.insertBefore(dialogAccordion);
                    mainCarouselPrev.appendTo(mainCarousel);
                    mainCarouselNext.appendTo(mainCarousel);
                }
            }
            //handle fav items
            var favItems = sessionStorage.getItem('favItems');
            if (favItems && favItems !== null && favItems !== '') {
                var favItemList = favItems.split(',')
                for (var k = 0; k <favItemList.length; k++) {
                    if ($('.add-to-favorites[data-pid='+favItemList[k]+']').length) {
                        $('.add-to-favorites[data-pid='+favItemList[k]+']').addClass('added');
                    }
                }
            }
        }
    });
};

/**
 * @description push PDPInteraction event for Bluecore (if a variant is selected)
 **/
var pushPDPInteraction = function () {
    if ($('input#isMasterProduct').val() === 'false') {
        bluecore.pushPDPInteraction($('input#pid').val(), $('input#masterPid').val());
    }
}

function isPDPInteraction() {
    if (pageContext.ns === 'product' && $('#QuickViewDialog').is(':visible')) {
        return false;
    }
    var isEvents = require('../../interaction-studio-events');
    var pid = $('#pid').val();
    var masterPid = $('#masterPid').val();
    var parentUL = variantType && variantType.parents('ul');
    var itemLI = variantType;
    var swatchType = '';
    var swatchValue = '';
    var swatchDisplayValue = '';
    var swatchImage = '';
    var styleColor = '';

    if (variantType.length > 0) {
        if (parentUL.hasClass('size')) {
            swatchType = 'size';
            swatchValue = itemLI.data('value');
            swatchDisplayValue = itemLI.find('a.swatchanchor').attr('title');
        }

        if (parentUL.hasClass('color')) {
            swatchType = 'color';
            swatchValue = itemLI.data('value');
            swatchDisplayValue = itemLI.find('a.swatchanchor').attr('title');
            swatchImage = itemLI.find('a.swatchanchor').find('img').attr('src');
        }

        if (parentUL.hasClass('extended-sizing-tiles')) {
            swatchType = 'extendedSizing';
            pid = itemLI.find('a').data('fit-pid');
            swatchValue = itemLI.find('span.extended-sizing-name').html();
            swatchDisplayValue = swatchValue;
        }

        if (swatchType === 'color') {
            styleColor = masterPid + '_' + swatchValue;
        }

        var itemNumber;

        try {
            // itemNumber = $('.product-variations').find('.swatches.color .selected a img').attr('src').split('/')[6].split('_');
            // itemNumber.splice(2, 1);
            // itemNumber = itemNumber.join('_');
            var colorCode = $('.swatches.color li.selected').length ? $('.swatches.color li.selected').data('value') : $('.product-variations').find('.swatches.color .selected a img').attr('src').split('/')[4].split('_');
                itemNumber = $('.pdp-main').data('pid')+'_'+colorCode;
        } catch (e) {
            // error handling
        }

        if (SitePreferences.STYLITICS_FORCE_V3 && typeof StyliticsClassicWidget !== 'undefined' && window.styliticsWidget) {
            // eslint-disable-next-line camelcase
            window.styliticsWidget.refresh({api: {item_number: itemNumber || styleColor || masterPid}});
        }

        try {
          var interactionStudioObj = $('.interaction-studio-favorites.visually-hidden').data('is-info')

          isEvents.triggerVariationSelection(pid, masterPid, swatchType, swatchValue, swatchDisplayValue, styleColor, swatchImage, interactionStudioObj.price, interactionStudioObj.priceLocal);
        } catch(e) {
          // nothing, but tagging should not break stuff
        }
    }
}

function initTruefitContextClasses () {
    // add class to truefit and monogram link if size chart is present
    var $sizeChartLink = $('.size-chart-link');
    if ($sizeChartLink.length > 0) {
        $('.tfc-fitrec-product, #personalize').addClass('withSizeChart');
    }
    // Setup a new observer to determine whether the truefit widget gets populated, if so, add a class to help CSS determine layout
    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function(mutation) {
            if ($(mutation.target).find('.tfc-cfg-widget').length > 0) {
                $('.tfc-fitrec-product').addClass('widget-populated');
            }
        })
    });
    if ($('.tfc-fitrec-product').length > 0) {
        observer.observe($('.tfc-fitrec-product')[0], {
            childList: true
        });
    }
}

module.exports = function ($scope) {
    $scope = $scope || $('body');
    var $pdpMain = $scope.find('#pdpMain');
    // hover on swatch - should update main image with swatch image
    $pdpMain.on('mouseenter mouseleave', '.swatchanchor', function () {
        var largeImg = $(this).data('lgimg'),
            $imgZoom = $pdpMain.find('.main-image'),
            $mainImage = $pdpMain.find('.primary-image');

        if (!largeImg) { return; }
        // store the old data from main image for mouseleave handler
        $(this).data('lgimg', {
            hires: $imgZoom.attr('href'),
            url: $mainImage.attr('src'),
            alt: $mainImage.attr('alt'),
            title: $mainImage.attr('title')
        });
        // set the main image
        image.setMainImage(largeImg);
    });

    initTruefitContextClasses();

    $(document).on("load-variant:after", function () {
        //run code after swatch changes
        initTruefitContextClasses();
    });


    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).closest('li').hasClass('selected')) {
            return;
        }
        if ($(this).closest('li').hasClass('unselectable')) {
            this.href += '&isMaster=true';
        }
        if ($(this).closest('.product-variations').data('ismatchwith') === true) {
            this.href += '&matchItWith=true';
        }
        variantType = $(this).parents('li');
        var variantValue = variantType.data('value');

        updateContent(this.href, function () {
            if (variantValue) {
                var $selectedSwatch = $('.swatches li[data-value="' + variantValue + '"]');
                if ($selectedSwatch.length) {
                    var $swatchElem = $selectedSwatch.find('.swatchanchor');
                    if ($swatchElem.length) {
                        $swatchElem.focus();
                    }
                }
            }
        }, $scope);

        if (SitePreferences.STYLITICS_ENABLE) {
            var onItemClick = function() { // eslint-disable-line no-unused-vars
                util.createCookie('recommendation_source', 'stylitics');
            }
            var itemNumber;

            try {
                var $itemImg = $(this).closest('li').find('a img');

                if ($('.swatches.color li.selected').length || $itemImg.length) {
                    // itemNumber = $itemImg.attr('src').split('/')[6].split('_');
                    // itemNumber.splice(2, 1);
                    // itemNumber = itemNumber.join('_');
                    var colorCode = $('.swatches.color li.selected').length ? $('.swatches.color li.selected').data('value') : ($itemImg ? $itemImg.attr('src').split('/')[4].split('_') : '');
                    itemNumber = $('.pdp-main').data('pid')+'_'+colorCode;
                }
            } catch (e) {
                // error handling
            }

            if (itemNumber){
                if (typeof StyliticsWidget !== 'undefined') {
                    window.styliticsWidget.refresh(itemNumber, function(outfits){
                        console.log(outfits);// eslint-disable-line
                        if (outfits.length > 0 && $('#stylitics_head').length == 0) {
                            $('#stylitics').prepend('<h3 id="stylitics_head" style="text-align:center">' + Resources.STYLITICS_HEAD + '</h3>');
                        }
                        if (outfits.length < 1) {
                            $('#stylitics_head').remove();
                        }
                    });
                }
            }
        }
    });

    // click on extended sizing - this should redirect to product with different fit
    $pdpMain.on('click', '.extended-sizing-tile:not(.unselectable):not(.selected) a', function (e) { // eslint-disable-line no-unused-vars
        e.preventDefault();
        variantType = $(this).parents('li');
        pushPDPInteraction();
        isPDPInteraction();
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function () {
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val(), null, $scope);
    });

    splitSwatchPricing();
    pushPDPInteraction();
    isPDPInteraction();
};
