/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var event = require('../event');

var product = {
    init: function () {
        $('.product-image-zoom').on('click', function () {
            
            // event19
            // View Zoom Alternate Image
            // Occurs when a user clicks on the zoom button on the PDP. Tagging issues from 7/31/14-9/12/14 and 10/31/14-11/13/14.
            event.pushEvent('event19');
        });

        $(document).on('click', '.shop-the-look-product-tile', function () {
            
            // event169
            // Shop the Look Click
            // Counter event to trigger when a Shop the Look is available on the product page and a visitor a product on the shop the look section.
            event.pushEvent('event169');
        });
        
        if ($('.product-listing .grid-tile').length > 0) {
            
            // event32
            // Merchandising Instance
            // Counter event that triggers each time a page is viewed and at least one product is displayed. This can be a product display that takes a person directly to the PDP or it can be a product that opens to a quickview/quicklook.
            event.pushEvent('event32');
        }
    }
};

exports.init = product.init;
