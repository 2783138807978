'use strict';

var accordion = require('./accordion');
var util = require('../../util');

/**
 * @description Change text on show more button for Matching Pieces Products
 **/
var showShowMoreBtn = function() {
    var $btn = $('.js-matching-piece-show-more-btn');
    $btn.text($btn.data('showtext'));
}

/**
 * @description Change text on show more button for Matching Pieces Products
 **/
var hideShowMoreBtn = function() {
    var $btn = $('.js-matching-piece-show-more-btn');
    $btn.text($btn.data('hidetext'));
}


/**
 * @function
 * @description Initializes matching piece functionality
 */
exports.init = function () {
    var $matchingPieceItems = $('.js-matching-piece-items');

    if ($matchingPieceItems.length) {
        var slidesToShowVal = $matchingPieceItems.data('productsetcount');
        $matchingPieceItems.on('init', function(event, slick){
            slick.resize();
        })
        $matchingPieceItems.slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: slidesToShowVal,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: util.getViewports('large'),
                    settings: 'unslick'
                }
            ]
        });
    }

    var $matchingPieceAccordion = $('.js-matching-piece-accordion');

    if ($matchingPieceAccordion.length) {
        accordion.init('.js-matching-piece-accordion', hideShowMoreBtn, showShowMoreBtn, null, true);
    }
};
