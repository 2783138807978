/*eslint camelcase: [0, {properties: "never"}]*/
/**
* Description of the module and the logic it provides
*
* @module cartridge/js/omniture/event
*/

'use strict';

var omniture = {
    
    /**
     * pushEvent
     * 
     * @param {String} name - if no name, pass an empty string.
     * @param {String} value - if no value, pass an empty string.
     * @param {Boolean} notPush - whether to send data to Adobe DTM
     * @param {Object} productData - products and custom attributes to be set on s.products string
     * @param {String} product - the product associated with a Save For Later cart action
     */
    pushEvent: function (name, value, notPush, productData, product) {
        this.checkLibraryLoaded();
        var eventData = value || value === 0  ? name + '=' + value : name;
        var omnitureProducts = window.s.products ? window.s.products : '';
        
        window.s.events = window.s.events ? window.s.events + ',' + eventData : eventData;
        
        /* Product can optionally be passed as a parameter
         * If product is passed, the event will only include that product in the s.products string
         * Useful for events that occur in the cart, since there could be more than one product in window.s.products
         * SFL events should only include the master product ID for the product on which the action occurs
         */
        if (product) {
            var attributes = [];
            for (var attr in product) {
                attributes.push(product[attr]);
            }
            omnitureProducts = attributes.join(';');
        } else {
            if (omnitureProducts) {
                var products = omnitureProducts.split(',');
                for (var i=0; i < products.length; i++) {
                    // syntax fix - add semicolon for events if events is empty
                    if (products[i].indexOf('event') == -1) {
                        var dataParts = products[i].split(';');
                        dataParts.splice(4, 0, '');
                        products[i] = dataParts.join(';');
                    }
                }
                omnitureProducts = products.join(',');
            }
        }

        if (productData && omnitureProducts) {
            var dataString = '';
            var count = 0;
            for (var key in productData) {
                // check if eVar values need to be replaced rather than appended 
                if (omnitureProducts.indexOf(key) != -1) {
                    omnitureProducts = this.updateProductsString(omnitureProducts, productData, key);
                } else {
                    // add leading semicolon if necessary
                    var separator = omnitureProducts[omnitureProducts.length-1] == ';' ? '' : ';';
                    dataString += count ? '|' : separator;
                    dataString += key;
                    dataString += '=';
                    dataString += productData[key];
                    count++;
                }
            }
            window.s.products = omnitureProducts + dataString;
        }

        if (!notPush) {
            this.push();
        }
    },
    
    /**
     * pushEVar
     * 
     * @param {String} name - if no name, pass an empty string.
     * @param {String} value - if no value, pass an empty string.
     * @param {Boolean} notPush - whether to send data to Adobe DTM
     */
    pushEVar: function (name, value, notPush) {
        this.checkLibraryLoaded();
        window.s[name] = value;

        if (!notPush) {
            this.push();
        }
    },
    
    /**
     * pushProp
     * 
     * @param {String} name - if no name, pass an empty string.
     * @param {String} value - if no value, pass an empty string.
     * @param {Boolean} notPush - whether to send data to Adobe DTM
     */
    pushProp: function (name, value, notPush) {
        this.checkLibraryLoaded();
        window.s[name] = value;

        if (!notPush) {
            this.push();
        }
    },
    
    /**
     * pushProduct
     * 
     * @param {String} category - if no category, pass an empty string.
     * @param {String} productName - if no productName, pass an empty string.
     * @param {String} quantity - if no quantity, pass an empty string.
     * @param {String} price - equals product price multiplied by quantity. If no price, pass an empty string.
     * @param {Object} events - {eventName: eventValue}. Pass an empty string as eventValue for Counter events.
     * @param {Object} variables - {eVarName: eVarValue}
     * @param {Boolean} notPush - whether to send data to Adobe DTM.
     */
    pushProduct: function (category, productName, quantity, price, events, variables, notPush) {
        this.checkLibraryLoaded();
        var i;
        category = category || '';
        productName = productName || '';
        quantity = quantity || '';
        price = price || '';
        events = events || {};
        variables = variables || {};
        
        var productData = [category, productName, quantity, price];

        // Add product level events
        var productEvents = [];
        if (events) {
            for (i in events) {
                productEvents.push(i + (events[i] !== '' ? '=' + events[i] : ''));
            }
            productData.push(productEvents.join('|'));
        } else {
            productData.push('');
        }
        
        // Add product level variables
        var productVariables = [];
        if (variables) {
            for (i in variables) {
                productVariables.push(i + '=' + variables[i]);
            }
            productData.push(productVariables.join('|'));
        }
        
        var product = productData.join(';');

        if (notPush) {
            window.s.products = window.s.products ? window.s.products + ',' + product : product;
        } else {
            window.s.products = product;
            this.push();
        }
    },
    
    /**
     * removeEvents
     */
    removeEvents: function () {
        window.s.events = '';
    },
    
    /**
     * removeProducts
     */
    removeProducts: function () {
        window.s.products = '';
    },
    
    /**
     * Send data to DTM
     */
    push: function () {
        $(document).trigger('omniture');
        omniture.removeEvents();
        window.omnitureEventsPushed = true;
    },
    /**
     * updateProductsString - used when eVars need to be replaced instead of appended
     */
    updateProductsString: function (omnitureProducts, productData, key) {
        var currentProductData = omnitureProducts.split(';');
        var eVars = currentProductData[5].split('|');
        for (var j=0; j < eVars.length; j++) {
            // if eVar matches the key, update it
            if (eVars[j].indexOf(key) != -1) {
                eVars[j] = '';
                eVars[j] += key;
                eVars[j] += '=';
                eVars[j] += productData[key];
            }
        }
        currentProductData[5] = eVars.join('|');
        omnitureProducts = currentProductData.join(';');
        return omnitureProducts;
    },

    checkLibraryLoaded: function () {
        if (!window.s) {
            window.s = {
                t: function() { },
                tl: function() { }
            };
        }
    }
}

// Expose omniture helper methods
window.Omniture = omniture;

module.exports = omniture;
