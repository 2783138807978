'use strict';

/**
 * @function
 * @description handle toggle the PDP product description display on desktop and mobile
 */
var $btn = $('.pdp-product-accordion-btn');
var $btnText = $('.pdp-product-accordion-btn > p');
var $btnCarat = $('.pdp-product-accordion-btn > span');
var $content = $('.pdp-product-accordion-hide-details');
$btn.on('click', function() {
    $btnCarat.toggleClass('expanded-carat');
    $content.toggleClass('expanded');
    $btnText.toggleClass('expanded');
    if($content.hasClass('expanded')) {
        $content.attr('aria-expanded', 'true')
    } else {
        $content.attr('aria-expanded', 'false'); 
    }
});

/**
 * @function
 * @description handle toggle the SEO text in PLP bottom page
 */
var seoReadMoreSelector = '.readMoreButton';
var seoContentSelector = '.category-page-seo-content-block';
$(document).off('click.seo-read-more').on('click.seo-read-more', seoReadMoreSelector, function () {
    var $seoContent = $(seoContentSelector);
    var $button = $(seoReadMoreSelector);
    $seoContent.toggleClass('expanded');
    $button.toggleClass('expanded');

    if ($seoContent.hasClass('expanded')) {
        $button.html($button.data('expanded'));
    } else {
        $button.html($button.data('collapsed'));
    }
});

function resizeSeoContent() {
    var $seoContent = $(seoContentSelector);
    var $button = $(seoReadMoreSelector);

    if($seoContent.find('div, p').length === 0) {
        $seoContent.addClass('visually-hidden');
    }

    if($seoContent.length > 0) {
        // 85 vs .height() to prevent miscalculation when already expanded
        if ($seoContent[0].scrollHeight > 85) {
            $button.addClass('visible');
        } else {
            $button.removeClass('visible');
        }
    }
}
$(resizeSeoContent);
$(window).off('resize.seo-content').on('resize.seo-content', resizeSeoContent);

/**
 * @function
 * @description handle toggle the PDP Tooltip modal
 */

function openTooltipModal() {
    $(this).closest('.tooltip-container').find('.tooltip-modal').addClass('tooltip-visible');
    $(this).closest('.tooltip-container').find('.overlay-tooltip').addClass('opened');
    $(document).trigger('freezeBackground');
}

function closeTooltipModal() {
    $(this).closest('.tooltip-container').find('.tooltip-modal').removeClass('tooltip-visible');
    $(this).closest('.tooltip-container').find('.overlay-tooltip').removeClass('opened');
    $(document).trigger('unfreezeBackground');
}

$(document).off('click.icon-tooltip keypress', '.icon-label.stretch > svg, .icon-label.rise > svg').on('click.icon-tooltip keypress', '.icon-label.stretch > svg, .icon-label.rise > svg', openTooltipModal);
$(document).off('click.icon-tooltip-open keypress', '.tooltip-container > svg').on('click.icon-tooltip-open keypress', '.tooltip-container > svg', openTooltipModal);
$(document).off('click.close-tooltip keypress', '.close-tooltip').on('click.close-tooltip keypress', '.close-tooltip', closeTooltipModal);
$(document).off('click.close-tooltip-overlay keypress', '.overlay-tooltip').on('click.close-tooltip-overlay keypress', '.overlay-tooltip', closeTooltipModal);

/**
 * @function
 * @description Initializes the accordion
 */
exports.init = function (parentSelector, onAreaShow, onAreaHide, rowIdexToOpen, doNotOpen) {
    // if rowIdexToOpen != underfined
    // for PDP rowIdexToOpen = 0
    // for quickview modal = 10 to prevent open first item on window open
    if ($(parentSelector).length > 0) {
        if (!onAreaShow) {
            onAreaShow = function () {};
        }
        if (!onAreaHide) {
            onAreaHide = function () {};
        }
        if (!rowIdexToOpen) {
            rowIdexToOpen = 0;
        }
        var productAccordion = new A11yAccordion({ // eslint-disable-line
            parentSelector: parentSelector,
            showSearch: false,
            speed: 0,
            onAreaShow: onAreaShow,
            onAreaHide: onAreaHide
        });
        if (!doNotOpen) {
            productAccordion.uncollapseRow(rowIdexToOpen);
        }
        productAccordion.props.speed = 300;
    }
};
