'use strict';

var util = require('./util');

var page = {
    title: '',
    type: '',
    params: util.getQueryStringParams(window.location.search.substr(1)),
    redirect: function (newURL) {
        if (newURL !== window.location.href) {
            setTimeout(function () {
                window.location.href = newURL;
            }, 0);
        } else {
            this.refresh();
        }
    },
    refresh: function () {
        setTimeout(function () {
            location.reload(true);
        }, 500);
    }
};

module.exports = page;
