'use strict';

module.exports = {
    init: function () {
        var countryChanged = false;
        var needEmptyCart = false;
        var needReload = false;
        
        $(document).on('change', '#ipar_ddlCountry', function () {
            countryChanged = true;
        });
        $(document).on('click', '#ipar_wmatcontinuebutton', function () {
            needEmptyCart = countryChanged;
        });
        $(window).on('beforeunload', function(e) {
            if (!needEmptyCart || needReload) {
                return;
            }
         
            $.ajax({
                url: Urls.emptyShoppingCart
            }).done(function () {
                needReload = true;
                location.reload();
            });
         
            e.preventDefault();
        });
    }
};
