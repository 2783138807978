'use strict';

var event = require('omniture/omniture/event');
var personalizationDetails, recipeId, currentColor, cachedRecipeObj;
var tooltip = require('org/tooltip');

/**
 * Initialize events
 */
function initializeEvents() {

    window.configureParams = window.configureParams || {
        callbacks: {}
    };

    $(document).on('quickview-show:after', function () {
        initPersonalizeBtn();

        if (window.configureParams.recipeId) {
            initRecipe(window.configureParams.recipeId);
        }
    });

    initPersonalizeBtn();

    if (window.configureParams.recipeId) {
        initRecipe(window.configureParams.recipeId);
    }

    $(document).on('click', '#personalize', function (e) {
        e.preventDefault();

        var $selectedColor = $('.swatches.color').find('li.selected');
        var $noSelection = $('.no-selection').length > 0;

        if ($selectedColor.length && !$noSelection) {
            var color = $selectedColor.data('value');

            if (typeof window.configureParams.callbacks !== 'object') {
                window.configureParams.callbacks = {personalizationComplete: personalizationComplete};
            } else {
                window.configureParams.callbacks.personalizationComplete = personalizationComplete;
            }
            window.fluidConfigure.load(color, function (err) {
                if (err) {
                    return;
                }
                onModalOpen();
            });
        } else {
            $('.personalize-error').show();
        }

    });

    $(document).on('load-variant:before', function () {
        var $personalizationDetails = $('.personalization-details');

        if ($personalizationDetails.length) {
            personalizationDetails = $personalizationDetails.remove();
        }

        var $selectedColor = $('.swatches.color').find('li.selected');

        if ($selectedColor.length) {
            saveCurrentColor($selectedColor.data('value'));
        }

    });

    $(document).on('click', '.personalization-details-actions-delete', function (e) {
        updateIspuAvailability(false);
        updateAfterpayMessage(false);
        // Do nothing for line items
        if ($(this).closest('.personalization-details-li').length > 0) {
            return;
        }

        e.preventDefault();
        clearRecipe();
        $('.get-it-by-all-info').removeClass('get-it-by-monogramming-enabled');
        $('.personalization-details').addClass('hidden');
        $('.get-it-by-delivery-label>a').addClass('set-zip-code');
    });

    $(document).on('click', '.remove-monogramming', function(e) {
        e.preventDefault();
        var recipeId = $(this).closest('.cart-row').data('recipeid');
        $('.personalization-details-cart-li[data-recipeid="' + recipeId + '"]').find('.personalization-details-actions-delete').trigger('click');
        window.location.reload();
    });

    $(document).on('click', '.personalization-details-actions-edit', function (e) {
        e.preventDefault();
        window.configureParams.recipeId = undefined;
        $('#personalize').trigger('click');
    });

    $(document).on('load-variant:after', function () {
        initPersonalizeBtn();
        var $personalize = $('.personalization-details');
        // Restore personalization information from the variable after a variant loads
        if ($personalize.length && personalizationDetails) {
            $personalize.replaceWith(personalizationDetails);

            // Use a variable to determine recipeId after variant HTML is replaced.
            if (recipeId) {
                togglePersonalizeBtn(false);

                saveRecipeId(recipeId);

                initProductOption(true);

                var $selectedColor = $('.swatches.color').find('li.selected');

                if ($selectedColor.length) {
                    var variantColorID = $selectedColor.data('value'); // the ID of the variant color which the user just selected, ex: "BR6755"
                    var variantColorName = $selectedColor.find('a')?.attr('title')?.split(':')[1]?.trim(); // the display name of the color selected, ex: "SADDLE BROWN"
                    var savedVariantColor = cachedRecipeObj.configuration.render;
                    
                    // Find color mismatch
                    // This method is not 100% reliable, but most saved variant color names in Fluid tend to be
                    // a string which at least matches (case-insensitive) the catalog display color variant name.
                    // Example values in Fluid to try to match are: "BK5229TRUE BLACK", or "Warm Hickory"
                    if (variantColorName && savedVariantColor.toLowerCase().indexOf(variantColorName.toLowerCase()) === -1) {
                        if (typeof window.configureParams.callbacks !== 'object') {
                            window.configureParams.callbacks = {personalizationComplete: personalizationComplete};
                        } else {
                            window.configureParams.callbacks.personalizationComplete = personalizationComplete;
                        }
                        window.fluidConfigure.load(variantColorID, function (err) {
                            if (err) {
                                return;
                            }

                            // Disable UPDATE button if this is a cart line item update pdp, and turn on info tooltip.
                            // User must confirm their recipe (which has probably been cleared when they switched variants)
                            if ($('#cartAction').val() === 'update') {
                                turnOnConfirmTooltip();
                            }
                        });

                        updateAfterpayMessage(true);
                    } else {
                        // the user-selected variant color matches the saved recipe variant color, so enable the UPDATE button and turn off the tooltip.
                        turnOffConfirmTooltip();
                    }
                }
                initImage();
            }
        }
    });

    initLineItems();

    $(document).on('minicart:show', function () {
        initMiniLineItems();
    });

    $(document).on('checkout-summary:update', function () {
        initMiniLineItems();
    });
}

function personalizationComplete() {
    initRecipe();
    turnOffConfirmTooltip();

    // event154
    // Product Personlization Save
    // Counter event to trigger whenevr a user interacts with the product personalization and saves their choice. This event will always pass with the Personalization Options eVar.
    event.pushEvent('event154');
}

function turnOnConfirmTooltip () {
    $('.add-to-cart').attr('disabled', 'disabled');
    $('.add-to-cart-button-wrapper').addClass('disabled-for-personalization');
    if (tooltip.exists('.add-to-cart-button-wrapper.disabled-for-personalization')) {
        $('.add-to-cart-button-wrapper.disabled-for-personalization').tooltip('enable');
    } else {
        const confirmationMsg = Resources.FLUIDCONFIGURE_PLEASECONFIRM;
        tooltip.create($(document).find('.add-to-cart-button-wrapper.disabled-for-personalization'), {content: confirmationMsg});
    }
}

function turnOffConfirmTooltip () {
    if (tooltip.exists('.add-to-cart-button-wrapper.disabled-for-personalization')) {
        $('.add-to-cart-button-wrapper.disabled-for-personalization').tooltip('disable');
        $('.add-to-cart').removeAttr('disabled');
        $('.add-to-cart-button-wrapper').removeClass('disabled-for-personalization');
    }
}

/**
 * Initialize isPersonalized product option
 *
 * @param {String} personalized
 */
function initProductOption(personalized) {
    var $option = $('[data-optionid="isPersonalized"]');
    if ($option.length) {
       //change made to make option values case insensitive
        if (personalized) {
            $('[data-optionid="isPersonalized"] > option').each(function() {
                if ($(this).val().toLowerCase() == 'yes') {
                    $option.val($(this).val());
                }
            });
        } else {
            $('[data-optionid="isPersonalized"] > option').each(function() {
                if ($(this).val().toLowerCase() == 'no') {
                    $option.val($(this).val());
                }
            });
        }
        var price = $('option:selected', $option).data('price');
        $('.personalization-details-options', '.personalization-details').data('price', price);
        $('.personalization-details').removeClass('hidden');
        $('.get-it-by-all-info').addClass('get-it-by-monogramming-enabled');
    }
}

/**
 * Initialize wishlist line items
 */
function initLineItems () {
    var lineItems = $('.personalization-details-li');
    if (lineItems.length > 0) {
        lineItems.each(function () {
            var $li = $(this);
            initRecipe($li.data('recipeid'), $li);
        });
    }
}

/**
 * Initialize cart line items
 */
function initCartLineItems () {
    var lineItems = $('.personalization-details-cart-li');
    if (lineItems.length > 0) {
        lineItems.each(function () {
            var $li = $(this);
            initRecipe($li.data('recipeid'), $li);
        });
    }
}

/**
 * Initialize order review line items
 */
function initOrderReviewLineItems () {
    var lineItems = $('.personalization-details-order-review-li');
    if (lineItems.length > 0) {
        lineItems.each(function () {
            var $li = $(this);
            initRecipe($li.data('recipeid'), $li);
        });
    }
}

/**
 * Initialize order line items (history and confirmation pages)
 */
function initOrderDetailsLineItems () {
    var lineItems = $('.personalization-details-order-details-li');
    if (lineItems.length > 0) {
        lineItems.each(function () {
            var $li = $(this);
            initRecipe($li.data('recipeid'), $li);
        });
    }
}

/**
 * Initialize mini-cart and mini-summary line items
 */
function initMiniLineItems () {
    var lineItems = $('.personalization-details-mini-li');
    if (lineItems.length > 0) {
        lineItems.each(function () {
            var $li = $(this);
            initRecipe($li.data('recipeid'), $li);
        });
    }
}

/**
 * Save current color selected
 * @param {String} color
 */
function saveCurrentColor(color) {
    currentColor = color;
}

/**
 * Get saved color
 * @returns {String}
 */
function getCurrentColor () {
    return currentColor;
}

/**
 * Save recipe object
 *
 * @param recipe {Object} - Recipe object
 */
function saveRecipe(recipe) {
    cachedRecipeObj = recipe;
}

/**
 * Save recipe ID
 *
 * @param recipeId {String} - Recipe ID
 */
function saveRecipeId(id) {
    recipeId = id;
    $('#recipeId').val(id);
    $('.add-to-favorites').data('recipeId', id);
}

/**
 * Remove recipe ID
 */
function removeRecipeId() {
    $('#recipeId').val('');
    $('.add-to-favorites').data('recipeId', '');
    window.configureParams.recipeId = undefined;
}

/**
 * Initialize recipe
 *
 * @param {String} recipeId
 * @param {String} lineItemContainer
 */
function initRecipe(recipeId, lineItemContainer) {
    if (recipeId) {
        var url = window.configureParams.recipeEndpoint.replace('recipeId', recipeId);
        $.ajax({
            url: url,
            dataType: 'JSON',
            method: 'get'
        }).done(function (recipe) {
            if (!lineItemContainer) {
                saveRecipe(recipe);
                saveRecipeId(recipeId);
                initProductOption(true);
            }

            fillInRecipe(recipe, lineItemContainer);
        });

        return;
    }
    window.fluidConfigure.getRecipe(function (err, recipe) {
        if (err) {
            return;
        }

        if (!lineItemContainer) {
            saveRecipe(recipe.recipe);
            saveRecipeId(recipe.recipe.id);
            initProductOption(true);
        }

        fillInRecipe(recipe.recipe);
    });
}

/**
 * Fill in a recipe block
 *
 * @param {Object} recipe
 * @param {String} lineItemContainer
 */
function fillInRecipe(recipe, lineItemContainer) {
    var font = recipe.configuration.choose_font,
        color = recipe.configuration.font_color,
        text = recipe.custom.text;

    populateRecipeForm(lineItemContainer, font, color, text);

    // PDP and QV
    if (!lineItemContainer) {
        saveRecipeId(recipe.id);
        togglePersonalizeBtn(false);
        initImage();

        var personalizationOptions = '';
        personalizationOptions = font + '|' + color + '|' + text;

        if (personalizationOptions.length) {
            updateIspuAvailability(true);
            updateAfterpayMessage(true);
        } else {
            updateIspuAvailability(false);
            updateAfterpayMessage(false);
        }

        // eVar109
        // Personalization Options
        // Passes the personalization options chosen by a customer when interacting with the personalization functionality. This will pass the personalization font option, color value, and the character input.
        event.pushEvent('eVar109', personalizationOptions);

    } else {
        // Line item view
        $(lineItemContainer).show();
    }
}

/**
 * Populate recipe fields in a specific container
 *
 * @param {String} lineItemContainer
 * @param {String} font
 * @param {String} color
 * @param {String} message
 */
function populateRecipeForm(lineItemContainer, font, color, message) {
    if (!lineItemContainer) {
        lineItemContainer = '.personalization-details';
        var price = $('.personalization-details-options', lineItemContainer).data('price');
        price = price || '';
        $('.personalization-details-price', lineItemContainer).html(price);
    }

    font = font || '';
    color = color || '';
    message = message || '';
    $('.personalization-details-font', lineItemContainer).html(font);
    $('.personalization-details-color', lineItemContainer).html(color);
    $('.personalization-details-message', lineItemContainer).html(message);
}

/**
 * Clear recipe data
 */
function clearRecipe() {
    removeRecipeId();
    initProductOption(false);
    togglePersonalizeBtn(true);
    populateRecipeForm();
    initImage(true);
}

/**
 * Toggle Personalize button and details block visibility
 *
 * @param {Bool} show
 */
function togglePersonalizeBtn(show) {
    var $btn = $('#personalize');
    var $btnHelp = $('#personalize-help');
    var $details = $('.personalization-details');
    if (show) {
        $btn.show();
        $btnHelp.show();
        $details.hide();
    } else {
        $btn.hide();
        $btnHelp.hide();
        $details.show();
    }
}

/**
 * Remove the 'aria-describedby' attribute if size selected
 */
function bindPersonalizeBtnWithErrMsg() {
    var $btn = $('#personalize');

    if (!$btn.length) {
        return;
    }

    var $selectedColor = $('.swatches.color').find('li.selected');
    var $noSelection = $('.no-selection').length > 0;

    if ($selectedColor.length && !$noSelection) {
        $btn.removeAttr('aria-describedby');
    }
}

/**
 * Initialize Personalize button state (if disabled or not)
 */
function initPersonalizeBtn() {
    var $btn = $('#personalize');

    if (!$btn.length) {
        return;
    }

    var recipeId = $('#recipeId').val();

    bindPersonalizeBtnWithErrMsg();
    togglePersonalizeBtn(!recipeId);

}

/**
 * Initialize recipe image and zoom events
 * @param {Bool} remove
 * @returns
 */
function initImage(remove) {
    var $img = $('.personalization-details').find('.product-image-wrapper').find('img');

    // Undo initialization
    if (remove) {
        $('.fluid-image').off('click');
        $img = $('.personalization-details').find('.product-image-wrapper').find('img');
        $img.attr('src', undefined).data('hires', undefined);

        return;
    }

    if ($img.length) {
        var $modal = $('.product-image-modal');
        var $modalClose = $('.product-image-modal-close');
        var thumbnailSrc = window.configureParams.thumbnailEndpoint.replace('recipe-id', recipeId);
        var hiresSrc = window.configureParams.hiresEndpoint.replace('recipe-id', recipeId);

        $img.attr('src', thumbnailSrc).data('hires', hiresSrc);

        $('.fluid-image').on('click', '.product-image-zoom', function() {
            var hiResImage = $('<img/>').attr('src', $img.data('hires')).attr('alt', $img.attr('alt'));
            $modal.find('img').remove().end().append(hiResImage).trigger('open');
        });

        $modal.on('open', onModalOpen)
        .on('close', onModalClose);

        $modalClose.on('click', function () {
            $modal.trigger('close');
        });
    }
}

function onModalOpen () {
    // $('body').addClass('product-image-modal-visible');
    $(document).on('keyup.modal-close', function (e) {
        if (e.which === 27) {
            $modal.trigger('close');
        }
    });
}

function onModalClose () {
    // $('body').removeClass('product-image-modal-visible');
    $(document).off('keyup.modal-close');
}

/**
 * Updates ISPU pickup availability message based on monogramming option value
 * @param {Bool} isMonogrammed
 */
function updateIspuAvailability(isMonogrammed) {
    var $statusMsg = $('.store-info-wrap').find('.isavail');
    if ($statusMsg.length) {
        var productStoreInventory = require('org/storeinventory/product');
        productStoreInventory.updateAvailabilityMessaging(isMonogrammed);
    }
}

/**
 * Updates Afterpay message based on monogramming option value
 * @param {Bool} isMonogrammed
 */
function updateAfterpayMessage(isMonogrammed) {
    var $afterpayBlock = $('.product-afterpay-message');
    if ($afterpayBlock.length > 0) {
        if (isMonogrammed) {
            if ($afterpayBlock.find('.monogrammed').length === 0) {
                var message = '<span class="monogrammed"></span>';
                $afterpayBlock.find('.afterpay-link').prepend(message);
                $afterpayBlock.find('.monogrammed').text(Resources.AFTERPAY_MONOGRAMMED);
            }
            $afterpayBlock.find('.message').hide();
            $afterpayBlock.find('.monogrammed').show();
        } else  {
            $afterpayBlock.find('.message').show();
            $afterpayBlock.find('.monogrammed').hide();
        }
    }
}

module.exports = {
    init: function() {
        initializeEvents();
    },
    initMiniLineItems: initMiniLineItems,
    initLineItems: initLineItems,
    initCartLineItems: initCartLineItems,
    initOrderReviewLineItems: initOrderReviewLineItems,
    initOrderDetailsLineItems: initOrderDetailsLineItems
};
