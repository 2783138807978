'use strict';

var dialog = require('./dialog'),
    product = require('./pages/product'),
    progress = require('./progress'),
    util = require('./util'),
    fluidconfigure = require('fluidconfigure/fluidconfigure'),
    bluecore = require('./bluecore'),
    carousel = require('./carousel'),
    _ = require('lodash');


var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

var quickview = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
        }
        this.productLinks = $('#search-result-items .thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('href');
        });
        $('#QuickViewDialog').on('click', '.quickview-close', function () {
            $('#QuickViewDialog').dialog('close');
        });

        //Navigate to correct PDP with updated variants
        $('#QuickViewDialog').on('click', '.quickview-header > a', function (e) {
            e.preventDefault();
            var url = $('.qv-full-details-link').html();
            window.location.href = url;
        });

        fluidconfigure.init();
    },

    setup: function (qvUrl) {
        var $btnNext = $('.quickview-next'),
            $btnPrev = $('.quickview-prev');

        product.initializeEvents($('#QuickViewDialog'));

        var isRedesignEnabled = $('#QVRedesignEnabled').data('redesign');
        if (isRedesignEnabled) {
            carousel.loadQuickView($('#QuickViewDialog'));
        }

        this.productLinkIndex = _(this.productLinks).findIndex(function (url) {
            return removeParam(url) === removeParam(qvUrl);
        });

        // hide the buttons on the compare page or when there are no other products
        if (this.productLinks.length <= 1 || $('.compareremovecell').length > 0) {
            $btnNext.hide();
            $btnPrev.hide();
            return;
        }

        if (this.productLinkIndex === this.productLinks.length - 1) {
            $btnNext.attr('disabled', 'disabled');
        }
        if (this.productLinkIndex === 0) {
            $btnPrev.attr('disabled', 'disabled');
        }

        $btnNext.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(1);
        }.bind(this));
        $btnPrev.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(-1);
        }.bind(this));
        $('div.product-usd').removeClass('hide-USD');
    },

    /**
     * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
     */
    navigateQuickview: function (step) {
        // default step to 0
        this.productLinkIndex += (step ? step : 0);

        // event152
        // Array Results Number Clicked
        // Numeric event to trigger on the next page (PDP, Quick View, Content) with a numeric event identifying the location the object was clicked on from the array. For example, from left to right, if I clicked on the 8th product in the array, the value passed would be "8".
        window.Omniture.pushEvent('event152', this.productLinkIndex);

        var url = makeUrl(this.productLinks[this.productLinkIndex], 'quickview');
        dialog.replace({
            url: url,
            callback: this.setup.bind(this, url)
        });
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {jQuery} options.progress - element to overlay with progress indicator while quickview is loading
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var url;
        if (!this.exists()) {
            this.init();
        }
        url = makeUrl(options.url, options.source, options.productlistid);
        if (options.progress) progress.show(options.progress);

        dialog.open({
            target: this.$container,
            url: url,
            options: {
                width: 'auto',
                title: Resources.QUICK_VIEW_POPUP,
                beforeClose: function () {
                    if (pageContext.ns === 'product') {
                        $('#QuickViewDialog #BVCustomerRatings').remove();
                        if (window.$BV && window.configData) {
                            $('<div id="BVCustomerRatings"></div>').insertAfter('.pt_product-details .BVCustomerRatings-placeholder');
                            window.configData.productId = $('.pt_product-details #masterPid').val();
                            window.$BV.ui('rr', 'show_reviews', window.configData);
                        }
                    }
                },
                open: function () {
                    if ($('.hide-USD').length) {
                        $('.hide-USD').removeClass('hide-USD');
                    }
                    if ($('.pt_cart').length > 0) {
                        $('#QuickViewDialog').parent().addClass('cart-edit-details');
                    }
                    this.setup(url);
                    $(document).trigger('quickview-show:after');
                    if (typeof options.callback === 'function') { options.callback(); }

                    // The following is there to allow to show others modals above the quickview
                    $(document).on('focusin', '#iparcelbox', function(e){e.stopPropagation();});
                    $(document).on('focusin', '.fc-dialog-wrapper-container', function(e){e.stopPropagation();});

                    //remove pink box availability message
                    if ($('.unselectable.selected').length > 0) {
                        $('.availability-message').addClass('hidden');
                    }
                    //make sure if oos item has other available sizes the color swatch with available sizes is not unselectable
                    if ($('.swatches.color .unselectable.selected').length > 0 && $('.swatches.size .selectable').length > 0) {
                        $('.swatches.color .unselectable.selected').removeClass('unselectable');
                    }

                    if ($('.swatches.size .unselectable').length > 0) {
                        $('.swatches.size .unselectable').attr("aria-disabled",true);
                        $('.swatches.size .unselectable').attr("aria-label","Unavailable size");
                    }

                    if ($('.swatches.size .selectable').length > 0) {
                        $('.swatches.size .selectable').attr("aria-label","Available size");
                    }

                    var $fullDetailsLink = $('.additional-inseam-container .view-full-details');
                    if ($fullDetailsLink.size() > 0) {
                        options.url= util.removeParamFromURL(options.url,'format');
                        $fullDetailsLink.attr('href', options.url);
                    }

                    var gtmData = this.$container.find('[data-gtmdata]');
                    if (gtmData.length > 0) {
                        gtmData = gtmData.data('gtmdata');
                        $(document).trigger('productView', {
                            data: gtmData,
                            container: this.$container,
                            carrousellTitle: options.carrousellTitle
                        });
                    }

                    
                    //event PDPQuickShopView
                    var pid =  this.$container.find('input#masterPid');
                    if (pid.length > 0) {
                        bluecore.pushPDPQuickShopView(pid.val());

                        try {
                          if ($('.interaction-studio-favorites').length > 0 && $('.interaction-studio-favorites').attr('data-is-info')) {
                              var productInformation = JSON.parse($('.interaction-studio-favorites').attr('data-is-info'));
                              var isEvents = require('./interaction-studio-events');
                              isEvents.triggerQuickShop(productInformation);
                          }
                        } catch(e) {
                          // nothing, but tagging should not break stuff
                        }
                    }
                    if($('.shop-the-look-container').length == 0) {
                        $('#QuickViewDialog').removeClass('shop-the-look-dialog');
                    }
                    if (options.progress) progress.hide();
                    
                    // event71
                    // Quick Shop View
                    // Triggers whenever a product is viewed and not on a PDP.
                    //window.Omniture.pushEvent('event71');

                    // event152
                    // Array Results Number Clicked
                    // Numeric event to trigger on the next page (PDP, Quick View, Content) with a numeric event identifying the location the object was clicked on from the array. For example, from left to right, if I clicked on the 8th product in the array, the value passed would be "8".
                    window.Omniture.pushEvent('event152', options.position || 0);

                    //fav items
                    var favItems = sessionStorage.getItem('favItems');
                    if (favItems && favItems !== null && favItems !== '') {
                        var favItemList = favItems.split(',')
                        for (var k = 0; k <favItemList.length; k++) {
                            if ($('.add-to-favorites[data-pid='+favItemList[k]+']').length) {
                                $('.add-to-favorites[data-pid='+favItemList[k]+']').addClass('added');
                            }
                        }
                    }

                    if (pageContext.ns === 'product') {
                        $('.pt_product-details #BVCustomerRatings').remove();
                        window.configData.productId = $('#QuickViewDialog #masterPid').val();
                        window.$BV.ui('rr', 'show_reviews', window.configData);
                    }
                    $('.eswGetPrice').each(function () {
                        var el = $(this);
                        var url = el.data('url');
                        $.ajax({
                            url : url,
                            success : function (response) {
                                el.replaceWith(response);
                            }
                        });
                    });
                }.bind(this)
            }
        });
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = quickview;
