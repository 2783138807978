'use strict';

module.exports = function () {
    var observer = new MutationObserver(function(mutationRecords) {
        if (mutationRecords.length > 0) {
            var mutationRecord = mutationRecords[0];
            if ($(mutationRecord.target).find('.mobile-category-sub-nav-title').length) {
                $('.header-builder-nav').addClass('header-builder-nav-active');
            } else {
                $('.header-builder-nav').removeClass('header-builder-nav-active');
            }
        }
    });
    // observe everything except attributes
    if (document.querySelector('.header-builder-nav')) {
        observer.observe(document.querySelector('.header-builder-nav'), {
            childList: true, // observe direct children
            subtree: true, // lower descendants too
            characterDataOldValue: true, // pass old data to callback
        });
    }
};
