/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var event = require('../event'),
    util = require('org/util');

var common = {
    init: function () {
        
        $(document).on('click', '#add-to-cart', function () {
            // eVar75
            // Add to Bag ispu
            // Whenever a product is added to the bag, this passes the pick up in store message where the item was added to the bag.
            var availableInStore = $('#availableInStore').val(),
                shipDestination;
            
            if (availableInStore == 'false') {
                shipDestination = 'ship to store';
            } else if (availableInStore == 'true') {
                shipDestination = 'pick up in store';
            } else {
                shipDestination = 'ship to home';
            }
            // scAdd
            // Cart Adds
            // Occurs when a user adds an item into their shopping bag regardless if they are on the PDP or Quicklook.
            event.pushEvent('scAdd', null, false, {'eVar75': shipDestination, 'eVar38': $('#upc').val()});
        });
        $(document).on('click', '.add-to-cart-wish-list', function () {
            if (!$(this).parent().hasClass('sfl-item-details')) {
                event.pushEvent('scAdd', null, false, {'eVar38': $(this).data('upc')});
            }
        });
        
        $(document).on('click', '.breadcrumb-element', function () {
            
            // event75
            // Breadcrumb Click
            // Counter event to trigger any time a user clicks on a site breadcrumb to navigate to another page on the site.
            event.pushEvent('event75');
        });
        
        $(window).on('beforeunload', function () {
            
            // event185
            // Reload
            // Counter event to trigger when a visitor refreshes their page.
            event.pushEvent('event185');
        });

        common.globalNavigationTracking();
        
        try {
            var shippingCountry = 'US'; 
            if (util.getCookie('esw.location') != null)
                shippingCountry = util.getCookie('esw.location');	
            
            if (shippingCountry) {
                
                // eVar33
                // Ship-to Country
                // Two Letter Country (i.e. US)
                event.pushEVar('eVar33', shippingCountry, true);
                
                // prop33
                // Ship-to Country
                // Two Letter Country (i.e. US)
                event.pushProp('prop33', shippingCountry, true);
            }
        } catch (e) {
            // eVar33
            // Ship-to Country
            // Two Letter Country (i.e. US)
            event.pushEVar('eVar33', 'US', true);
            
            // prop33
            // Ship-to Country
            // Two Letter Country (i.e. US)
            event.pushProp('prop33', 'US', true);
        }
    },
    
    /**
     * Event 138( + eVar103, Prop52)
     */
    globalNavigationTracking: function () {
        var trackClicked = function (value) {
            value = btoa(value.trim());
            util.eraseCookie('omniture_globalnav');
            util.createCookie('omniture_globalnav', value);
            event.pushEvent('event138', atob(value));
        };
        
        //Global Slider Navigation
        $('#header-banner-carousel li a').on('click', function () {
            trackClicked($(this).text());
        });

        //Global Header Navigation
        $('.primary-logo').on('click', function () {
            trackClicked('logo');
        });
        $('.header-search').on('click', function () {
            trackClicked('magnifying-glass');
        });
        $('.user-account').on('click', function () {
            trackClicked('user-icon');
        });
        $('#navigation .stores').on('click', function () {
            trackClicked('favorites-header');
        });
        $('.mini-cart-total').on('click', function () {
            trackClicked('bag-desktop');
        });

        //Global Category Top Navigation
        $('.menu-category .top-level-link').on('click', function () {
            var itemText = $(this).text();
            trackClicked(itemText);
        });

        //Global Category Hover Navigation
        $('.menu-category .nav-item a').on('click', function (e) {
            var item = $(this);
            var resaleLink = item.hasClass('resale-show-modal');
            if (!resaleLink) {
                e.stopPropagation();
                var parentText = item.parents('.nav-item').find('.top-level-link').first().text().trim();
                var itemText = item.text().trim();
                trackClicked(parentText+':'+itemText);
            }
        });

        //Blog Category Top Navigation
        $('.blog-navigation .level-1-link').on('click', function () {
            var itemText = $(this).text();
            trackClicked(itemText);
        });

        //Blog Category Hover Navigation
        $('.blog-navigation .level-2 .sub-folders-two li a').on('click', function () {
            var item = $(this);
            var parentText = item.parents('.toggle-blog').find('.level-1-link ').first().text().trim();
            var itemText = item.text().trim();
            trackClicked(parentText+':'+itemText);
        });

        //Footer Navigation
        $('.footer-container a').on('click', function () {
            var itemText = $(this).text();
            trackClicked(itemText);
        });
        
        $(document).on('click', '#personalize', function () {
            event.pushProp('prop11','Madewell:Modals:Monogramming:Monogramming');
        });

        $(document).on('loyalty:register', function () {
            event.pushProp('prop11','Madewell:Modals:Loyalty Sign-Up Pop-Up:Loyalty Sign-Up Pop-Up');
        });

        $(document).on('loyalty:optin', function () {
            event.pushProp('prop11','Madewell:Modals:Email Opt-In Pop Up:Email Opt-In Pop Up');
        });
    }
}

exports.init = common.init;
