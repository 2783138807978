/* global DocumentTouch */

'use strict';

var quickview = require('./quickview'),
    util = require('./util'),
    _ = require('lodash');

var isPLPEnabled = $('.displayColumns').data('columncount');
var enablerefresh = $('.displayColumns').data('enablerefresh');

function quickviewShow($this, $link) {
    var $tile = $this.closest('li[data-a]');
    var position = 0;
    var pid = 0;
    $('.search-result-items li[data-a]').each(function (i) {
        if ($this.data('a') == $tile.data('a')) {
            position = i;
            pid = $tile.data('pid');
            return false;
        }
    });
    var url = $link.data('quick-view-url');
    url = util.appendParamToURL(url, 'format', 'ajax');
    var quickViewCarrousellTitle = '';
    if($this.parents(".product-listing").length > 0){
        quickViewCarrousellTitle = $this.parents(".product-listing").children("h3").text().toLowerCase().trim();
    }
    quickview.show({
        url: url,
        progress: $link,
        source: 'quickview',
        position: position,
        carrousellTitle: quickViewCarrousellTitle
    });
    // do not use smartResize as we want this to trigger on height changes too
    $(window).on('resize', _.debounce(() => {
        $('#QuickViewDialog').parent('.ui-dialog').position({
            my: 'center',
            at: 'center',
            collision: 'fit',
            of: window
        });
        if ($this.dialog.isOpen === true) {
            $this.dialog('close');
        }
    }, 100));
}
/**
 * @function
 * @description Sets up the quick view button when called with the appropriate values
 * @param {jQuery} $link - Link that is used to define the quick view button
 * @param {boolean} focus - Mark this as true when the focus event is used
 */
function initQuickView($link, focus) {
    var $qvButton = $('#quickviewbutton');

    if ($qvButton.length) {
        $qvButton.remove();
    }

    if ($('[data-component="hybrid"]').length) {
        return false;
    }

    $qvButton = $('<div id="quickviewbutton"><a href="javascript:void(0)" class="quickview button secondary">' + Resources.QUICK_VIEW + '</a></div>');

    if (isPLPEnabled) {
        $qvButton.addClass('redesign');
    }

    $qvButton.on('click', function (e) {
        e.preventDefault();
        quickviewShow($(this), $link);
    });

    $qvButton.attr({
        'href': $link.data('quick-view-url'),
        'title': $link.attr('title')
    });

    $qvButton.insertAfter($link);

    $('.product-tile-details').show();
    if (focus && !isPLPEnabled) {
        $('.swatch-container').removeAttr('style');
        $qvButton.next('.swatch-container').css({
            'display' : 'block',
            'position': 'absolute',
            'z-index': '1',
            'width' : '100%'
        });
        $qvButton.find('a').css({
            'display': 'block',
            'margin-top': '10px'
        });
        $link.parent().next('.product-tile-details').hide();
    }
}

/**
 * @function
 * @description Sets up the swatche tiles for the product
 * @param {jQuery} $link - Link that is used to define the swatches tiles
 * @param {jQuery} $target - where the swatch tiles will get appended to
 */
function initSwatch($link, $target) {
    var url = document.location.origin + $link.attr('data-swatch') + '&format=ajax';
    $.ajax({
        url: url,
        success: function (response) {
            var container = $target;
            if(enablerefresh) {
                container = $($target).siblings(".product-tile-details");
                container.find('.swatch-container').remove();
            }
            if($($target).is(':hover')) {
                $(container).append(response);
                initSwatchEvents(container);
            }
        }
    });
}


var loadSelectedSwatchDetails = function ($tile) {
    var $selectedSwatch = $tile.find('.swatch.selected');
    $tile.find('.product-tile-details .product-promo').not(".product-swatches .product-promo").empty();
    if ($selectedSwatch.siblings('.tile-swatch-promo').length) {
        $tile.find('.product-tile-details .product-promo').not(".product-swatches .product-promo").html($selectedSwatch.siblings('.tile-swatch-promo').find(".product-promo").html());
    }
    $tile.find('.product-swatches .selected-wash').html($selectedSwatch.children('img').data('washName'));
    if (!$selectedSwatch.find('.product-pricing').length) {
        $.get($selectedSwatch.find('img').data('priceUrl'), function(data) {
            if ($(data).filter('.product-pricing').length) {
                $selectedSwatch.append($(data).filter('.product-pricing').clone());
                $tile.find('.product-tile-details .product-pricing').not(".product-swatches .product-pricing").html($(data).filter('.product-pricing').html());
            }
        });
    } else {
        $tile.find('.product-tile-details .product-pricing').not(".product-swatches .product-pricing").html($selectedSwatch.find('.product-pricing').html());
    }
}

var initSwatchEvents = function ($target) {
    var $ele = $target && $($target).closest('.product-tile').find('.swatch-list').length ? $($target).closest('.product-tile').find('.swatch-list') : $('.swatch-list');
    $ele.find('.swatch').on('mouseleave', function () {
                // Restore current thumb image
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $thumb.data('current');

        loadSelectedSwatchDetails($tile);

        if (typeof data !== 'undefined' && $thumb.attr('src') !== data.src && $thumb.attr('srcset') !== data.srcset) {
            $thumb.attr({
                ['data-src']: data.src,
                ['data-srcset']: data.srcset,
                alt: data.alt,
                title: data.title
            });
        }
    });
    $ele.find('.swatch').on('click', function (e) {
        e.preventDefault();
        var $link = $(this).find('.thumb-link');
        initQuickView($link);

        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.product-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));
        $tile.removeClass("ajax-init");
        // handle fav icon
        // update preselected id for all swatches
        if ($(this).parent()) {
            $('.add-to-favorites').removeClass('added');
            $tile.find('.add-to-favorites').attr('data-pid', $(this).data('swatchpid'));
            var favItems = sessionStorage.getItem('favItems');
            if (favItems && favItems !== null && favItems !== '') {
                var favItemList = favItems.split(',')
                for (var k = 0; k <favItemList.length; k++) {
                    if ($('.add-to-favorites[data-pid='+favItemList[k]+']').length) {
                        $('.add-to-favorites[data-pid='+favItemList[k]+']').addClass('added');
                    }
                }
            }
        }

        var data = $(this).children('img').filter(':first').data('thumb');
        // select slick slide image if exists to avoid mobile preview image update
        var $thumb = $tile.find('.product-image .thumb-link .js-product-tile-carousel .slick-current img').length ? 
                    $tile.find('.product-image .thumb-link .js-product-tile-carousel .slick-current img').eq(0) :
                    $tile.find('.product-image .thumb-link img').eq(0); 
        var $altThumb = $tile.find('.product-image .thumb-link .js-product-tile-carousel .slick-current img').length ? 
                        $tile.find('.product-image .thumb-link .js-product-tile-carousel .slick-current img').eq(1) :
                        $tile.find('.product-image .thumb-link img').eq(1); 
        var altData = $(this).children('img').filter(':first').data('alt');
        var currentAttrs = {
            ['data-src']: data.src,
            ['data-srcset']:data.srcset,
            alt: data.alt,
            title: data.title
        };
        var altAttrs = {
            ['data-src']: altData.src,
            ['data-srcset']:altData.srcset
        };
        $thumb.attr(currentAttrs);
        $altThumb.attr(altAttrs);
        $thumb.data('current', {
            alt: data.alt,
            title: data.title,
            src: data.src,
            srcset: data.srcset
        });
        //$altThumb.attr('src', data.altsrc);
        $tile.find('.add-to-favorites').data('colorid', data.colorid);

        $altThumb.bind('error', function() {
            if (!$(this).hasClass('img-error')) {
                $(this).addClass('img-error');
                $(this).attr('src', window.Urls.staticPath + 'images/noimagelarge.png');
            }
        });
        $(this).blur();
    }).on('mouseenter', function () {
        if ($(this).hasClass('selected')) {
            return;
        }
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $(this).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current'),
            $this = $(this);

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                alt: $thumb[0].alt,
                title: $thumb[0].title,
                src: $thumb[0].src,
                srcset: $thumb[0].srcset
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        if (data) {
            $thumb.attr({
                alt: data.alt,
                title: data.title,
                ['data-src']: data.src,
                ['data-srcset']: data.srcset
            });
        }

        //$thumb.addClass('lazyload').removeClass('lazyloaded');
        $tile.find('.alternate-image').removeClass('visible');
        $tile.find('.primary-image').addClass('visible');

        if (!$this.find('.product-pricing').length) {
            $.get($this.find('img').data('priceUrl'), function(data) {
                if ($(data).filter('.product-pricing').length) {
                    $this.append($(data).filter('.product-pricing').clone());
                    $tile.find('.product-tile-details .product-pricing').not(".product-swatches .product-pricing").html($(data).filter('.product-pricing').html());
                }
            });
        } else {
            $tile.find('.product-tile-details .product-pricing').not(".product-swatches .product-pricing").html($(this).find('.product-pricing').html());
        }
        $tile.find('.product-tile-details .product-promo').not(".product-swatches .product-promo").empty();
        if ($(this).siblings('.tile-swatch-promo').length) {
            $tile.find('.product-tile-details .product-promo').not(".product-swatches .product-promo").html($(this).siblings('.tile-swatch-promo').find('.product-promo').html());
        }
        $tile.find('.product-swatches .selected-wash').html($(this).children('img').data('washName'));
    }).on('mouseleave', function () {
        var $tile = $(this).closest('.product-tile');
        loadSelectedSwatchDetails($tile);
    });

    if(enablerefresh) {
        if(!util.isMobileSize()){
            // swatch desktop show more swatches
            $(document).off('click.show-all-swatches', '.product-swatches-all-refresh').on('click.show-all-swatches', '.product-swatches-all-refresh', function() {
                $(this).hide();
                $(this).siblings('.swatch-list').addClass('all-swatches-visible');
            })
        } else {
            // swatch mobile show arrow
            $(document).off('resize.swatches_carat').on('resize.swatches_carat', showSwatchesArrows);
            $(document).off('scroll.swatches_mobile', '.swatch-list').on('scroll.swatches_mobile', '.swatch-list', showSwatchesArrows);
            $(showSwatchesArrows);
            // swatch mobile scroll
            $(document).off('click.swatch_carat_scroll', '.product-swatches-next').on('click.swatch_carat_scroll', '.product-swatches-next', swatchScrollBy);
        }
    }
}

/**
 * @function
 * @description Sets up the product tile so that when the mouse cursor enters the tile the quick view button appears
 */
var initQuickViewButtons = function ($container) {
    $($container || document).off('mouseenter.product-image').on('mouseenter.product-image', '.tiles-container .product-image', function (e) {
        e.stopPropagation();
        var $link = $(this).find('.thumb-link');
        initQuickView($link);
    })
}

/**
 * @function
 * @description Sets up the product tile so that when the tile is focused with the keyboard the quick view button appears
 */
var initQuickViewButtonsFocus = function ($container) {
    $($container || document).off('focus.thumb-link').on('focus', '.tiles-container .product-image .thumb-link', function (e) {
        e.stopPropagation();
        var $link = $(this);
        initQuickView($link, true);
    });
}

/**
 * @function
 * @description Gets the wishlist status of a product
 */
var wishlistStatus = function ($tile, pid) {
    var url = util.appendParamsToUrl(Urls.wishlistStatus, {pid: pid}),
        $favoritesBtn = $tile.find('.add-to-favorites');
    if ($favoritesBtn.hasClass('logged')) {
        $.ajax({
            dataType: 'json',
            url: url,
            data: {
                format: 'ajax'
            }
        }).done(function (data) {
            if (data.inWishlist) {
                $favoritesBtn.addClass('added');
            }
        });
        return true;
    }
    return false;
};

/**
 * @function
 * @description Show carat when is necessary
 */
var showSwatchesArrows = function() {
    $('.swatch-list').each(function() {
        var $swatchList = $(this);
        var $swatchContainer = $swatchList.closest('.swatch-container');  
        function checkScroll() {
            if ($swatchList[0].scrollWidth > $swatchContainer.width() || $swatchList[0].scrollWidth > $swatchList.width()) {
                $swatchList.siblings('.product-swatches-next').addClass('visible');
            } else {
                $swatchList.siblings('.product-swatches-next').removeClass('visible');
            }
            var isEnd = $swatchList.scrollLeft() + $swatchList.width() >= $swatchList[0].scrollWidth;
            if (isEnd) {
                $swatchList.siblings('.product-swatches-next').removeClass('visible');
            }
        }
        $swatchList.off('scroll.checking-position').on('scroll.checking-position', checkScroll);
        $(checkScroll);
    });
}

/**
 * @function
 * @description Swatches carousel functionality 
 */
var swatchScrollBy = function() {
    var $scroller = $(this).siblings('.swatch-list');
    var currentScroll = $scroller.scrollLeft();
    var scrollAmount = $scroller.width();
    var newScroll = currentScroll + scrollAmount;
    $scroller.animate({scrollLeft: newScroll}, 500, 'easeInOutCubic');
}

/**
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {

    // If touch events are available, disable the QuickViewButton initialization
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        $('.product-tile').addClass('has-touch');
        var productTileSlider = $('.js-product-tile-carousel').not('.slick-initialized');
        $(productTileSlider).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            arrows: false,
            dots: false
        });

    } else {
        // If touch events are not available, make the product images hoverable
        $('.tiles-container .product-image').addClass('hoverable');

    }

    initSwatchEvents();

    $(document).on('click', '.show-swatches', function () {
        var item = $(this);

        item.parent().find('.product-swatches').removeClass('hidden');
        item.hide();
    });

    var swatchdisplayonhover = $('#primary').data('swatchdisplayonhover');
    if ($('[data-component="hybrid"]').length && !swatchdisplayonhover) {
        swatchdisplayonhover = $('[data-component="hybrid"]').data('swatchdisplayonhover');
    }
    if(swatchdisplayonhover) {
        var productImageSelector = '.tiles-container .product-image';
        $(document).off('keyup.product_image', productImageSelector).on('keyup.product_image', productImageSelector, function(e) {
            var code = (e.keyCode ? e.keyCode : e.which);
            var $self = $(this);
            //detect tab through
            if (code == 9) {
                if ($self.find('.alternate-image').length > 0) {
                    $self.find('.primary-image').toggleClass('visible');
                    $self.find('.alternate-image').toggleClass('visible');
                }
            }
        }).off('mouseenter.product_image', productImageSelector).on('mouseenter.product_image', productImageSelector, function () {
            var $tile = $(this).closest('.product-tile'),
                pid = $(this).parent().data('itemid'),
                $link = $(this).find('.thumb-link');
            wishlistStatus($tile, pid);
            if (!$(this).find('.swatch-container').length && !$(this).siblings('.product-tile-details').find('.swatch-container').length && !('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
                initSwatch($link, this);
            } else {
                $(this).siblings('.product-tile-details').find('.swatch-container').show();
            }
        }).off('mouseleave.product_image', productImageSelector).on('mouseleave.product_image', productImageSelector, function() {
            $(this).siblings('.product-tile-details').find('.swatch-container').hide();
        });
    }

    if (!enablerefresh) {
        $('.product-tile').on('mouseleave', function () {
            $(this).find('.add-to-favorites.added').removeClass('added');
        });
    }
    if ($('.product-usd').length) {
        $('.product-usd').removeClass('hide-USD');
    }

    //Handle broken images on grid
    //If we overhaul imaging shot types we will want to remove these
    $('.thumb-link img').bind('error', function() {
        if (!$(this).hasClass('img-error')) {
            $(this).addClass('img-error');
            $(this).attr('src', window.Urls.staticPath + 'images/noimagelarge.png');
        }
    });

    /**
     * @listener
     * @description Listens to the mouseenter and mouseleave events on the product tile thumb-link to show/hide the primary and alternate images
     */
    $(document).off('mouseenter touchstart', '.product-image').on('mouseenter touchstart', '.product-image', function () {
        // Hide the primary image and show the alternate image if one exists
        if (!util.isMobileSize()) {
            var $altImage = $(this).find('.alternate-image[src]');
            if ($altImage.length) {
                $(this).find('.primary-image').removeClass('visible');
                $altImage.addClass('visible');
            }
        }
    });
    $(document).off('mouseleave touchend', '.product-image').on('mouseleave touchend', '.product-image', function () {
    // Hide the alternate image and show the primary image if one exists
        if (!util.isMobileSize()) {
            var $altImage = $(this).find('.alternate-image[src]');
            if ($altImage.length) {
                $altImage.removeClass('visible');
                $(this).find('.primary-image').addClass('visible')
            }
        }
    });
}

function initializeQuickviewEvents($container) {
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        initQuickViewButtons = function () { }
        initQuickViewButtonsFocus = function () { }
    }

    initQuickViewButtons($container);
    initQuickViewButtonsFocus($container);
}

exports.init = function () {
    var $tiles = $('.tiles-container .product-image');
    if ($tiles.length === 0) { return; }
    initializeEvents();
    initializeQuickviewEvents();
};

exports.initializeEvents = initializeEvents;
exports.initializeQuickviewEvents = initializeQuickviewEvents;
exports.initSwatchEvents = initSwatchEvents;
