'use strict';

const lightbox = require('./lightbox');
var minicart = require('./minicart'),
    dialog = require('./dialog'),
    progress = require('./progress'),
    giftcard = require('./giftcard'),
    util = require('./util');

/**
 * get recaptcha widget_id number
*/
var getRecaptchaId = function(id) {
    var getNumber = id.match(/\d+/);
    var number = isNaN(parseInt(getNumber, 10)) ? 0 : parseInt(getNumber, 10);
    return number;
}

var setAddToCartHandler = function (e) {
    e.preventDefault();
    var form = $(this);
    var isEvents = require('./interaction-studio-events');
    form.find('span.giftcart-error').remove();

    if (form.valid() && form.find('#amount').val()) {
        if (!($('.g-recaptcha-response').length && $('.g-recaptcha-response').val() =='')) {
            //if captcha is added but empty we should not trigger request
            var options = {
                url: util.ajaxUrl(form.attr('action')),
                method: 'POST',
                cache: false,
                data: form.serialize()
            };
            $.ajax(options).done(function (response) {
                if ($(response).filter('span.giftcart-error').length) {
                    $($(response).filter('span.giftcart-error').clone()).insertAfter('.giftcard');
                    return false;
                }
                
                minicart.show(response);
                form.find('input,textarea').not(':hidden').not('[readonly]').val('');
                var pid = $('.gcform__option-button-grid--card-type').find('.gcform__option-button--active').data('productid') || '';
                var price = $('.gcform__option-button-grid--amounts').find('.gcform__option-button--active').data('value') || '';
                isEvents.triggerATC(pid, 1, price);
                if (SitePreferences.STYLITICS_FORCE_V3 && typeof StyliticsClassicWidget !== 'undefined') {
                    window.styliticsWidget.trackAddItemToCart(pid);
                }
                try {
                    window.grecaptcha.reset();
                    $('.g-recaptcha-response').val('');
                } catch (e) {
                    return true;
                }
            }).fail(function (xhr, textStatus) {
                // failed
                if (textStatus === 'parsererror') {
                    window.alert(Resources.BAD_RESPONSE);
                } else {
                    window.alert(Resources.SERVER_CONNECTION_ERROR);
                }
            });
        } else {
            if ($('.g-recaptcha-response').val() == '') {
                alert('Please verify that you are not a robot.');
                return false;
            }
            return true;
        }
    } else {
        return false;
    }
};

var stripBadChars = {
    init: function () {
        $('.validate-bad-chars').on('change', function () {
            $(this).val(stripBadChars.strip($(this).val()));
        });
    },
    strip: function (str) {
        var invalidCharacters = RegExp(window.SitePreferences.GIFT_MESSAGE_INVALID_CHARACTERS, 'gi');
        return str.replace(invalidCharacters, '');
    }
}

var checkFormValidity = function() {
    var form = $('#GiftCertificateForm'),
        addToBag = $('#AddToBasketButton');

    if (form.valid() && form.find('#amount').val()) {
        addToBag.attr('disabled', '').prop('disabled', false);
    } else {
        addToBag.attr('disabled', 'disabled').prop('disabled', true);
    }
}

var datePicker = {
    init: function () {
        $('input[name$="_sendDate"]').datepicker({
            minDate: 0,
            onClose: function() {
                $(this).focus();
            }
        }).on('keyup', function(e) {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code == 9) {
                $(this).datepicker('show');
                $('.ui-datepicker-today a').focus();
            }
        });
    }
}

var init = function () {
    if ($('.js-gcform').length === 0) {
        return;
    }

    //Choose Classic or E Gift Card
    $('.js-card-type').on('keydown click', function(e) {
        if (e.keyCode === 13 || e.type === 'click') {
            var item = $(this),
                cardDesignInput = $('input[name="cardDesign"]'),
                classicGiftElements = $('.classic-gift'),
                eGiftElements = $('.e-gift')

            $('.hidden-elements').toggle((item.attr('id') == 'electronic-card'));
            $('.js-card-type').not(this).removeClass('gcform__option-button--active').attr('aria-pressed',false);
            $(this).addClass('gcform__option-button--active').attr('aria-pressed',true);
            $('input[name="pid"]').val(item.data('productid'));
            $('.js-card-type-value').text(item.data('name'));


            if (item.attr('id') == 'electronic-card') {
                $('.gcform').addClass('egift-active').removeClass('classic-active');
                classicGiftElements.hide();
                eGiftElements.show();
                cardDesignInput.val(JSON.stringify($('.js-card-design-button.gcform__image-button--active').data('value')));
            } else {
                $('.gcform').addClass('classic-active').removeClass('egift-active');
                eGiftElements.hide();
                classicGiftElements.show();
                cardDesignInput.val('');
            }
        }
    });

    $(document).on('keydown click', '.js-amount', function (e) {
        if (e.keyCode === 13 || e.type === 'click') {
            var item = $(this);
            if (item.hasClass('selected')) {
                item.removeClass('selected')
                $('.js-gc-recommendations-modal').removeClass('d-none');
                $('.js-amounts').find('.gcform__option-button--active').removeClass('gcform__option-button--active').attr('aria-pressed', false);
                $('.js-amount-value').addClass('d-none').text('');
            } else {
                $('.js-gc-recommendations-modal').addClass('d-none');
                $('.js-amounts').find('.gcform__option-button--active').removeClass('gcform__option-button--active').attr('aria-pressed', false);
                $('.js-amounts').find('.selected').removeClass('selected')
                $(this).addClass('selected')
                item.addClass('gcform__option-button--active').attr('aria-pressed', true);
                $('input[name="amount"]').val(item.data('value')).trigger('change');
                $('.js-amount-value').removeClass('d-none').text(item.text());
            }
        }
    });

    $(document).on('keydown click', '.js-amount-modal', function (e) {
        if (e.keyCode === 13 || e.type === 'click') {
            var cardValue = $(this).data('value');
            var $valueBtn = $('.js-amount[data-value="'+ cardValue + '"]');

            if ($valueBtn.length) {
                $valueBtn.trigger('click');
            }

            lightbox.close($(this).closest('.lightbox-modal'));
        }
    });

    $('.js-card-design-button').on('keydown click', function (e) {
        if (e.keyCode === 13 || e.type === 'click') {
            var item = $(this);
            item.closest('.js-card-design-buttons').find('.gcform__image-button--active').removeClass('gcform__image-button--active').attr('aria-pressed',false);
            $('.js-main-image').attr('data-src',item.data('src'))
            item.addClass('gcform__image-button--active').attr('aria-pressed',true);
            $('input[name="cardDesign"]').val(JSON.stringify(item.data('value')));
            $('.js-card-design-value').text(item.data('name'));
        }
    });

    datePicker.init();

    $('#AddToBasketButton').attr('disabled', 'disabled').prop('disabled', true);
    $('#GiftCertificateForm').find('.required').on('change', function () {
        checkFormValidity();
    })
    // if customer is registered, make from email readonly
    if ($('input[name$="_fromEmail"]').val()) {
        $('input[name$="_fromEmail"]').prop('readonly', true);
    }
    stripBadChars.init();
    $('#GiftCertificateForm').on('submit', setAddToCartHandler);

    // if form is prepopulated on page load, check form validity to enable add to bag if valid
    var isPrepopulated = $('#GiftCertificateForm').find('input:not([type="hidden"],textarea:not([type="hidden"])').filter(function() {
        return this.value.length !== 0;
    })

    if (isPrepopulated.length) {
        checkFormValidity();
    }

};

var initEvents = function () {
    $(document).on('click', '.js-open-giftcard-modal', function () {
        dialog.open({
            url: Urls.giftCardModal,
            options: {
                classes: {
                    'ui-dialog': 'giftcard-modal js-giftcard-modal',
                    'ui-dialog-content': 'giftcard-modal-content'
                },
                width: 375,
                open: function() {
                    window.recaptchaOnload();
                }
            },
            callback: function() {
                module.exports.init();
            }
        });
    });

    $(document).on('submit','.js-giftcard-modal #GiftCertificateForm', function() {
        dialog.close();
    });

    // show check balance modal
    $(document).on('click', '.js-check-balance-modal', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        dialog.open({
            url: url,
            options: {
                dialogClass: 'check-balance-dialog gc-modal',
                title: Resources.CIFTCARD_CHECK_BALANCE_MODAL_TITLE,
                open: function() {
                    if (typeof window.recaptchaOnload === 'function') {
                        window.recaptchaOnload();
                    }
                }
            }
        });
    });

    // show terms and conditions modal
    $(document).on('click', '.js-gc-terms-conditions-modal', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        dialog.open({
            url: url,
            options: {
                dialogClass: 'terms-conditions-dialog gc-modal',
                title: Resources.GIFTCARD_TERMS_COND_MODAL_TITLE
            }
        });
    });

    // check gift card balance
    $(document).on('click', '#CheckBalanceButton', function (e) {
        e.preventDefault();
        var giftFormContainer = $(this).parents('.check-balance-input-container');
        var $giftCertCode = giftFormContainer.find('input[name$="_giftCertID"]');
        var $giftCertPin = giftFormContainer.find('input[name$="_giftCertPIN"]');
        var recaptchaNumber = 0;
        var $balance = giftFormContainer.siblings('.balance'),
            $balanceModal = $(this).parents('.gift-certificate-purchase-modal').find('.giftcard-balance'),
            $balancedialog = $(this).parents('.check-balance-dialog'),
            error = giftFormContainer.siblings('.form-row.error');
        if ($balancedialog.length) {
            error = $(this).parents('.gift-certificate-purchase-modal').find('.giftcard-error');
            progress.show($balancedialog);
        } else {
            progress.show(giftFormContainer);
        }

        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING);
            $giftCertCode.attr('aria-invalid', true);
            progress.hide();
            return;
        }

        if ($giftCertPin.length === 0 || $giftCertPin.val().length === 0) {
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_PIN_MISSING);
            $giftCertPin.attr('aria-invalid', true);
            progress.hide();
            return;
        }

        error.html('');

        if ($balanceModal.length) {
            if (giftFormContainer.find('.g-recaptcha-response').length && giftFormContainer.find('.g-recaptcha-response').val() =='') {
                //if captcha is added but empty we should not trigger request
                error.html(Resources.GIFT_CERT_RECAPTCHA_MISSING);
                $balanceModal.html('').removeClass('show');
                $giftCertCode.attr('aria-invalid', true);
                progress.hide();
                return;
            }
            recaptchaNumber = getRecaptchaId(giftFormContainer.find('.g-recaptcha .g-recaptcha-response').attr('id'));
        }

        giftcard.checkBalance($giftCertCode.val(), $giftCertPin.val(), function (data) {
            if (!data || !data.giftCertificate || !data.message) {
                error.html(Resources.GIFT_CERT_INVALID);
                $balance.html('').addClass('error').removeClass('success');
                $giftCertCode.attr('aria-invalid', true);
                progress.hide();
                if ($balanceModal.length) {
                    $balanceModal.html('').removeClass('show');
                    window.grecaptcha.reset(recaptchaNumber);
                }
                return;
            }
            progress.hide();
            $giftCertCode.attr('aria-invalid', false);
            $giftCertPin.attr('aria-invalid', false);
            $balance.html(data.message).removeClass('error').addClass('success');
            if ($balanceModal.length) {
                var giftCardBalanceRespond = '<div class="check-balance-giftcardnumber">'
                                                + data.giftCardBalance.giftCardNumberTitle
                                                    + '<span class="font-heavy">'
                                                        + data.giftCardBalance.giftCardNumber
                                                    + '</span>'
                                            + '</div>'
                                            + '<div class="check-balance-giftcardnumber">'
                                                + data.giftCardBalance.giftCardBalanceTitle
                                                    + '<span class="font-heavy">'
                                                        + data.giftCardBalance.giftCardBalance
                                                    + '</span>'
                                            + '</div>';
                $balanceModal.addClass('show').html(giftCardBalanceRespond);
                window.grecaptcha.reset(recaptchaNumber);
            }
        });
    });
};

module.exports = {
    init: init,
    initEvents: initEvents
};
