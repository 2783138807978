'use strict';

var ajax = require('../../ajax'),
    dialog = require('../../dialog'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    fluidconfigure = require('fluidconfigure/fluidconfigure'),
    util = require('../../util'),
    googleplacesapi = require('../../googleplacesapi'),
    bluecore = require('../../bluecore'),
    SingleWidgetManager = require('hubbox/single-widget-manager.js');

var hubboxShippingformMapping = [
    {
        field: '_shippingAddress_addressFields_address1',
        elements: ['AddressLine1'],
        hubboxField: 'street1'
    },
    {
        field: '_shippingAddress_addressFields_address2',
        elements: ['AddressLine2'],
        hubboxField: 'street2'
    },
    {
        field: '_shippingAddress_addressFields_city',
        elements: ['City name'],
        hubboxField: 'city'
    },
    {
        field: '_shippingAddress_addressFields_postal',
        elements: ['postal'],
        hubboxField: 'postcode'
    }
];
var fixedData = {
    phone: '8665441937'
}


var shippingMethods;
/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    var $messageFields = $('input[name$="_shippingAddress_giftMessage1"], input[name$="_shippingAddress_giftMessage2"], input[name$="_shippingAddress_giftMessage3"], input[name$="_shippingAddress_giftMessage4"]');
    // show gift message box, if shipment is gift

    $('.gift-message-text .input-text').attr('aria-describedby', 'gift-instructions');
    $('.gift-message-text .char-count').attr('id', 'gift-instructions');
    $('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');
    $('.separate-gift-boxing').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');

    if ($('.gift-message-text').hasClass('hidden')) {
        $('.gift-message-text').attr('aria-hidden', 'true');
    } else {
        $('.gift-message-text').removeAttr('aria-hidden');
    }
    $messageFields.change(function() {
        $(this).val(stripInvalidCharacters($(this).val()));
    })
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');
    var fakeCBtn = $summary.find('#fakeContinueBtn');
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    var summaryUrl = util.appendParamToURL(Urls.summaryRefreshURL, 'checkoutstep', '2');
    $summary.load(summaryUrl, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
        $summary.find('.summary-bottom .form-row-button').empty().prepend(fakeCBtn.text(Resources.CONTINUE_PAYMENT));
        var shippingForm = $('form.checkout-shipping:visible').eq(0);
        if (shippingForm.length) {
            $('<div class="shipping-form-validate-overlay"></div>').insertAfter($summary.find('#fakeContinueBtn'));
        }
        fluidconfigure.initMiniLineItems();
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {
            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

            // TODO the for loop below isn't doing anything?
            // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
            //     var len = data.shippingPriceAdjustments.length;
            //     for (var i=0; i < len; i++) {
            //         var spa = data.shippingPriceAdjustments[i];
            //     }
            // }
        }
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    var $shippingMethodList = $('#shipping-method-list'),
        $selectedShipmentOption = $("input[name$='_shippingAddress_deliveryType']:checked");
    if (!$shippingMethodList || $shippingMethodList.length === 0) {
        disableContinueButton();
        return;
    }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);

    if ($selectedShipmentOption.length) {
        url = util.appendParamToURL(url, 'shipmentType', $selectedShipmentOption.val());
    }

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }

            if (shippingMethods && shippingMethods.toString() === data.toString()) {
                // No need to update the UI.  The list has not changed.

                // we need to update shipping methods even if list is not changed to update delivery estimation date
                //return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            $shippingMethodList.load(smlUrl, function () {
                $shippingMethodList.fadeIn('fast');
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {
                    selectShippingMethod($(this).val());
                });

                // update the summary
                updateSummary();
                progress.hide();
                tooltip.init();
                //if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                    $shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
                }
                disableContinueButton();
            });

        }
    });
    disableContinueButton();
}

/**
 * used in multiple places so moved to separate function
 */
function disableContinueButton () {
    /**
     * disable submit button in case if we have shipping error message (hazmat items) or at least one required field is empty
     */
    if ($('.no-shipping-methods').size() > 0
            || $('input[name$="_addressFields_address1"]').val() === ''
            || $('input[name$="_addressFields_city"]').val() === ''
            || $('input[name$="_addressFields_postal"]').val() === '') {
        $('button[name$="shippingAddress_save"]').attr('disabled', 'disabled');
    } else {
        $('button[name$="shippingAddress_save"]').removeAttr('disabled');
    }
}

function toggleEmailGenderOptions () {
    if ($('.account-newsletter input').is(':checked')) {
        $('.optin-input').show();
    } else {
        $('.optin-input').hide();
    }
}

function stripInvalidCharacters (str) {
    if (window.SitePreferences.GIFT_MESSAGE_INVALID_CHARACTERS) {
        var invalidChars = RegExp(window.SitePreferences.GIFT_MESSAGE_INVALID_CHARACTERS, 'gi');
        return str.replace(invalidChars, '');
    }
    return str;
}

function updateShippingDetailsJson(address) {
    var url = util.appendParamToURL(Urls.updateAddressDetailsJson, 'address', JSON.stringify(address));
    ajax.load({
        url: url
    });
}

function copyHubboxAddressToShippingForm(hubboxAddress, telephone) {
    hubboxShippingformMapping.forEach(function(mapping) {
        $('input[name$="' + mapping.field + '"]').val(hubboxAddress[mapping.hubboxField]);
    });
    if (telephone == '') {
        telephone = fixedData.phone;
    }
    $('input[name$=_shippingAddress_addressFields_phone]').val(telephone);
    $('.states option[value="' + hubboxAddress.county + '"]').prop('selected', true);
    if ($('.input-text.ID').val() == '') {
        $('.input-text.ID').val(hubboxAddress.street1);
    }
}

exports.init = function () {
    window.checkoutShippingPage = true;
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);
    $('.address').on('change',
        'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_zip"]',
        updateShippingMethodList
    );
    giftMessageBox();
    updateShippingMethodList();
    $('select[name$="_state"]').on('change', function () {
        if ($(this).val()==='') {
            $(this).parent().addClass('select-style-error');
        } else {
            $(this).parent().removeClass('select-style-error');
        }
    })

    if (window.User && window.User.authenticated &&
            $('.form-modifier.form-row').length > 0 &&
            $('.shipping-fields').hasClass('d-none') &&
            $('[id$=addressList] option:selected').val() !== '') {
        $('[id$=addressList]').trigger('change');
    }

    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector:'[id$="singleshipping_shippingAddress"]',
        fakeContinue:'#fakeContinueBtn'
    });
    // initialize Google autocomplete if Maps is loaded
    googleplacesapi.init();

    $('#secondary').on('click','#fakeContinueBtn', function(e) {
        e.preventDefault();
        var $form = $(this).data('target') ? $($(this).data('target')).closest('form') : null;
        if ($form && !$form.find('.shipping-fields').is(':visible')) {
            $form.find('.shipping-fields').addClass('shipping-fields-validate');
            if (!$form.valid()) {
                $form.find('.shipping-fields').removeClass('shipping-fields-validate d-none');
                return false;
            }
        }
        if (!$(this).hasClass('loqate-address-button') && !$(this).hasClass('disabled')) {
            $('button[name$="_shippingAddress_save"]').trigger('click');
        }
    }).on('click', '.shipping-form-validate-overlay', function () {
        var valid = true;
        var shippingForm = $('form.checkout-shipping:visible').eq(0);
        shippingForm.find('.required').each(function () {
            if ($(this).val() === '') {
                valid = false;
                $(this).trigger('blur');
            }
        });
        var $error = shippingForm.find('.error:visible').eq(0);
        if ($(this).siblings('#fakeContinueBtn').hasClass('disabled') && shippingForm.length && !valid && $error.length) {
            $('html, body').animate({scrollTop: $error.offset().top - $('.top-banner').height() - 20}, 500);
        }
    });
    
    var phoneField = $('.shipping-fields .phone');
    var statesField = $('.shipping-fields .states, .shipping-fields .states .input-select');
    var addressIdField = $('.shipping-fields .ID');
    addressIdField.attr('placeholder', Resources.ADDRESSID_PLACEHOLDER);
    function isEmpty(el){
        return !$.trim(el.html())
    }
    if ($('#guest-create-account').length > 0) {
        addressIdField.addClass('d-none').removeClass('required').removeAttr('aria-required');
        $('.address').find('[name$="addressFields_ID"]').val('addr');
    }
    function deliveryTypeHandler(event) {
        if (event.target.value === 'homedelivery') {
            var form = $('.address');
            var selected = $('select[name$="_addressList"]').children(':selected').first();
            $('.form-modifier').removeClass('d-none');
            if ($('.form-modifier').length > 0) {
                addressIdField.removeClass('d-none').addClass('required').attr('aria-required', true);
                $('.shipping-fields').addClass('d-none');
            } else if ($('#guest-create-account').length > 0) {
                addressIdField.addClass('d-none').removeClass('required').removeAttr('aria-required');
                form.find('[name$="addressFields_ID"]').val('addr');
            }
            var selectedAddress = $(selected).data('address');
            if (selectedAddress) {
                form.find('[name$="addressFields_ID"]').val(selectedAddress.ID);
                util.fillAddressFields(selectedAddress, form);
            } else {
                util.clearAddressFields(form);
                form.find('.error + span').hide();
                form.find('.error').removeClass('error');
                form.find('.select-style').removeClass('select-style-error');
            }
            if ($('[id$=addressList] option:first').text() == Resources.SELECT_NEW_TEXT) {
                $('.form-modifier').addClass('d-none');
                $('.shipping-fields').removeClass('d-none');
            }
            updateShippingMethodList();
            $('.hubbox-search, .hubbox-selected, .hubbox-who-is-picking-up, .shipping-type-hubbox-description').addClass('d-none');
            $('.use-address-for-billing, .select-address, .add-to-address-book, .use-address-as-default').removeClass('d-none');
            $('.add-to-address-book .input-checkbox').prop('checked', true);
            $('.shipping-fields').removeClass('hubbox-delivery-type');
            phoneField.addClass('required').attr('aria-required', true);
            statesField.addClass('required').attr('aria-required', true);
            $('.shipping-fields .firstName, .shipping-fields .lastName').removeAttr('placeholder');
            $('input[name$=_shippingAddress_useAsBillingAddress]').prop('checked', true);
            $('#shipping-method-list .shipping-methods-home').removeClass('d-none');
            $('#shipping-method-list .shipping-methods-ups').addClass('d-none');
            $('.gift-message-trigger').removeClass('d-none');
            $('.gift-message-trigger [value="true"]').prop('checked', false);
            $('.gift-message-trigger [value="false"]').prop('checked', true);
            giftMessageBox();
        }
        if (event.target.value === 'pickup') {
            $('.hubbox-selected, .hubbox-who-is-picking-up, .shipping-type-hubbox-description').removeClass('d-none');
            if (isEmpty($('#hubbox-selected'))) {
                $('.hubbox-search').removeClass('d-none');
            }
            $('.add-to-address-book .input-checkbox').prop('checked', false);
            $('.use-address-for-billing, .select-address, .add-to-address-book, .use-address-as-default').addClass('d-none');
            $('.shipping-fields').addClass('hubbox-delivery-type');
            phoneField.removeClass('required').removeAttr('aria-invalid required aria-required');
            statesField.removeClass('required').removeAttr('aria-invalid required aria-required');
            addressIdField.addClass('d-none').removeClass('required').removeAttr('aria-invalid required aria-required');
            $('.shipping-fields').removeClass('d-none');
            $('.form-modifier').addClass('d-none');
            $('.shipping-fields .firstName').attr('placeholder', Resources.HUBBOX_FIRST_NAME_PLACEHOLDER);
            $('.shipping-fields .lastName').attr('placeholder', Resources.HUBBOX_LAST_NAME_PLACEHOLDER);
            $('input[name$=_shippingAddress_useAsBillingAddress]').prop('checked', false);
            $('#shipping-method-list .shipping-methods-ups').removeClass('d-none');
            $('#shipping-method-list .shipping-methods-home').addClass('d-none');
            $('.gift-message-trigger').addClass('d-none');
            $('.gift-message-trigger [value="true"]').prop('checked', false);
            $('.gift-message-trigger [value="false"]').prop('checked', true);
            if ($('.hubbox-selection-box').length > 0) {
                var hubboxData = $('.hubbox-selection-box').data('hubbox');
                copyHubboxAddressToShippingForm(hubboxData.address, hubboxData.telephone);
                updateShippingMethodList();
            }
            giftMessageBox();
        }
    }

    function collectPointConfirmedHandler(collectPointSelectedJSON) {
        var pickupDataMessage = JSON.stringify(collectPointSelectedJSON.message);
        $.ajax({
            type: 'POST',
            dataType: 'html',
            url: Urls.hubboxUpdateAddress,
            data: {param: pickupDataMessage},
            success: function(response) {
                $('.hubbox-selected').empty().html(response);
                copyHubboxAddressToShippingForm(collectPointSelectedJSON.message.address, collectPointSelectedJSON.message.telephone);
                updateShippingMethodList();
                $('.ui-dialog-content').dialog().dialog('close');
                $('.hubbox-widget').empty().addClass('d-none');
                $('.hubbox-search').addClass('d-none');
            }
        });
    }


    document.querySelectorAll('input[name$="_shippingAddress_deliveryType"]').forEach((input) => {
        input.addEventListener('change', deliveryTypeHandler);
    });
    if (isEmpty($('#hubbox-selected'))) {
        $('input[value="homedelivery"]').attr('checked', true);
    } else {
        $('input[value="pickup"]').trigger('click');
        $('.hubbox-search').addClass('d-none');
        if ($('.hubbox-selection-box').length > 0) {
            var hubboxData = $('.hubbox-selection-box').data('hubbox');
            copyHubboxAddressToShippingForm(hubboxData.address, hubboxData.telephone);
            updateShippingMethodList();
        }
    }
    $(document).on('click', '.hubbox-search-again', function () {
        $('.hubbox-search button[type=submit]').trigger('click');
    });

    $(document).on('click', '#createGuestAccount', function () {
        var url = Urls.createGuestAccount;
        var email = $('.email > .field-wrapper > input').val();
        var password = $('#guest-create-account > .guest-create-account-container > .password > input').val();
        var firstName = $('.shipping-fields > .form-row > .field-wrapper > .firstName').val();
        var lastName = $('.shipping-fields > .form-row > .field-wrapper > .lastName').val();
        var companyName = $('#dwfrm_singleshipping_shippingAddress_addressFields_companyName').val();
        var address1 = $('#dwfrm_singleshipping_shippingAddress_addressFields_address1').val();
        var address2 = $('#dwfrm_singleshipping_shippingAddress_addressFields_address2').val();
        var city = $('#dwfrm_singleshipping_shippingAddress_addressFields_city').val();
        var state = $('#dwfrm_singleshipping_shippingAddress_addressFields_states_state').val();
        var zipcode = $('#dwfrm_singleshipping_shippingAddress_addressFields_postal').val();
        var phone = $('#dwfrm_singleshipping_shippingAddress_addressFields_phone').val();
        var country = $('#dwfrm_singleshipping_shippingAddress_addressFields_country').val();
        var addtoemaillist = $('#dwfrm_singleshipping_shippingAddress_addtoemaillist').is(':checked');
        var mailgender = $('[name$="singleshipping_shippingAddress_maillistgender"]:checked').val();

        var numUpper = password.replace(/[A-Z]/g, '').length;
        var numLower = password.replace(/[a-z]/g, '').length;
        var numInt = password.replace(/[^0-9]/g,'').length;
        if (email === '') {
            var $emailInput = $('.email > .field-wrapper > input');
            $emailInput.trigger('blur');
            $emailInput.siblings('span.error').html(Resources.EMAILUS_INVALID_ACCOUNT_CREATE);
            $(window).scrollTop(0);
            return false;
        }
        if(password.length >= 8 && numUpper > 0 && numLower > 0 && numInt > 0) {
            $.ajax({
                type: 'POST',
                url: util.ajaxUrl(url),
                data: { password: password,
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                        companyName: companyName,
                        address1: address1,
                        address2: address2,
                        city: city,
                        state: state,
                        zipcode: zipcode,
                        phone: phone,
                        country: country,
                        addtoemaillist: addtoemaillist,
                        mailgender: mailgender
                    },
                success: function (response) {
                    if(response.indexOf("guest-account-exists") > -1){
                        $('#guest-create-account').replaceWith(response);
                    } else {
                        $('#guest-create-account').replaceWith(response);
                        window.location.reload();
                    }
                }
            });
        } else {
            $('.guest-create-account-password-criteria-error').replaceWith('<div class="guest-create-account-password-criteria-error">Password must be at least 8 characters, including one uppercase letter, one lowercase letter and one number</div>');
        }
    });

    $(document).on('keyup change', '#guest-create-account > .guest-create-account-container > input', function () {
        var password = $('#guest-create-account > .guest-create-account-container > input').val();
        var minPasswordLength = 8;
        if(password.length > 0  && password.length < minPasswordLength) {
            $('.guest-create-account-length-error').replaceWith('<div class="guest-create-account-length-error">Password must be at least 8 characters</div>');
            $('.guest-create-account-input > .field-wrapper > input').addClass('error');
        } else {
            $('.guest-create-account-length-error').replaceWith('<div class="guest-create-account-length-error"> </div>');
            $('.guest-create-account-input > .field-wrapper > input').removeClass('error');
        }
    });

    $(document).on('dialogclose', '.pick-up-point', function() {
        $(document).trigger('unfreezeBackground');
        setTimeout(function () {
            $('html, body').removeAttr('style');
        }, 500);

    });

    $(document).on('click', '.hubbox-search button[type=submit]', function (e) {
        e.preventDefault();
        $(document).trigger('freezeBackground');
        if ($('.hubbox-widget').length === 0) {
            $('<div id="hubbox-widget" class="hubbox-widget"></div>').insertAfter('.hubbox-search');
        }
        $('.hubbox-widget').removeClass('d-none');

        if ($('#hubbox-widget').is(':empty')) {
            dialog.open({
                html: $('.hubbox-widget'),
                options: {
                    dialogClass: 'pick-up-point'
                }
            });
            var singleWidgetManager = new SingleWidgetManager({
                container: document.getElementById('hubbox-widget'),
                iframeUrl: $('#hubboxWidgetUrl').val(),
                iframeParams: {
                    configId: SitePreferences.HUBBOX_WIDGET_CONFIG_ID,
                    searchQuery: $('input[name$="_shippingAddress_pickupaddress"]').val()
                }
            });
            singleWidgetManager.events.subscribe(singleWidgetManager.topics.subscribe.COLLECT_POINT_CONFIRMED, collectPointConfirmedHandler);
        }
    });

    $(document).on('click', '.hubbox-search .hubbox-search-my-location-btn', function (e) {
        e.preventDefault();
        $(document).trigger('freezeBackground');
        if ($('.hubbox-widget').length === 0) {
            $('<div id="hubbox-widget" class="hubbox-widget"></div>').insertAfter('.hubbox-search');
        }
        $('.hubbox-widget').removeClass('d-none');

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                const coords = position.coords;
                if (coords.latitude && coords.longitude) {
                    if ($('#hubbox-widget').is(':empty')) {
                        dialog.open({
                            html: $('.hubbox-widget'),
                            options: {
                                dialogClass: 'pick-up-point'
                            }
                        });
                        var singleWidgetManager = new SingleWidgetManager({
                            container: document.getElementById('hubbox-widget'),
                            iframeUrl: $('#hubboxWidgetUrl').val(),
                            iframeParams: {
                                configId: SitePreferences.HUBBOX_WIDGET_CONFIG_ID,
                                userLatLng: [position.coords.latitude, position.coords.longitude]
                            }
                        });
                        singleWidgetManager.events.subscribe(singleWidgetManager.topics.subscribe.COLLECT_POINT_CONFIRMED, collectPointConfirmedHandler);
                    }
                }
            })
        }
    });

    $(document).on('change', '.gift-box-quantity-select', function (e) {
        var $this = $(this);
        e.preventDefault();

        var url = Urls.addGiftBox;
        var action = 'add';

        var formData = {
            Quantity: $this.val(),
            pid: $this.parents('li').data('giftbox-pid'),
            isMasterProduct: false
        };

        if ($this.parents('li').attr('data-uuid')) {
            if ($this.val() == '0') {
                url = Urls.removeGiftBox;
                formData = {
                    uuid: $this.parents('li').attr('data-uuid'),
                    pid: $this.parents('li').data('giftbox-pid')
                };
                action = 'remove';
            } else {
                url = Urls.updateQuantityGiftBox;
                formData = {
                    uuid: $this.parents('li').attr('data-uuid'),
                    Quantity: $this.val()
                };
                action = 'update';
            }
        }

        $.ajax({
            type: 'POST',
            url: util.ajaxUrl(url),
            data: formData,
            success: function (response) {
                $('.separate-gift-boxing .error').remove();
                if (typeof response === 'object' && response.success) {
                    updateSummary();
                    if (action === 'remove') {
                        $this.parents('li').attr('data-uuid', null);
                    } else if (action === 'add') {
                        $this.parents('li').attr('data-uuid', response.uuid);
                    }
                } else {
                    var errorMessage = response && response.message ? response.message : Resources.GIFTBOX_GENERAL_ERROR;
                    $('.separate-gift-boxing').append('<p class="error">' + errorMessage + '</p>');
                }
            }
        });
    });

    $('.account-newsletter input').change(function () {
        toggleEmailGenderOptions();
    })

    $(document).on('click', '.form-modifier__update, .form-modifier__add', function (e) {
        e.preventDefault();
        let actionItem = $(this).data('id');
        let $checkoutForm = $('.checkout-shipping');
        $('.shipping-fields, .add-to-address-book').removeClass('d-none');
        if (actionItem === 'update') {
            $('input[name$="usedNewAddress"]').prop('checked', false).attr('checked', false).val(false);
        } else {
            $('.form-modifier').addClass('d-none');
            $checkoutForm.find('[name$="addressFields_ID"]').val('').trigger('change');
            util.clearAddressFields($checkoutForm);
            $checkoutForm.find('[id$=addressList] option:selected').removeAttr('selected');
            $('input[name$="addToAddressBook"]').prop('checked', true).attr('checked', true);
            $('[id$=addressList] option:first').text(Resources.SELECT_NEW_TEXT);
            $('input[name$="usedNewAddress"]').prop('checked', true).attr('checked', true).val(true);
        }
    });

    $('select[name$="_addressList"]').on('change', function () {
        $('.form-modifier').hasClass('d-none') ? $('.form-modifier').removeClass('d-none') : '';
        if($('[id$=addressList] option:selected').val() !== '') {
            $('.shipping-fields, .add-to-address-book').addClass('d-none');
        }
    })

    //change address1 autocomplete "off" to "address-line1"
    $(function () {
        var $address1Selector = $('input[name$="_addressFields_address1"]');
        if ($address1Selector.length > 0) {
            util.address1Observer($address1Selector);
        }        
    })

    disableContinueButton();
    toggleEmailGenderOptions();
    formPrepare.validateForm();
    bluecore.checkForAutoRemoveItems();

};

exports.updateShippingMethodList = updateShippingMethodList;
exports.updateShippingDetailsJson = updateShippingDetailsJson;
