'use strict';

String.prototype.format = function() {
    var s = this, i = arguments.length;

    while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
    }
    return s;
};
function formatCounter (counter) {
    var hour = Math.floor(counter /3600),
        minute = Math.floor((counter - hour*3600)/60),
        seconds = counter - (hour*3600 + minute*60);
    return setLeadingZero(seconds);
}
function setLeadingZero(value) {
    value = ''+value;
    return value.length > 1 ? value : '0' + value;
}
module.exports = function(options) {

    var settings = {
        interval : 0,
        timeoutTrigger : SitePreferences.SESSION_TIMEOUT_TRIGGER,
        countdown : SitePreferences.SESSION_TIMEOUT_RESPONSE - SitePreferences.SESSION_TIMEOUT_TRIGGER,
        cookie: SitePreferences.SESSION_TIMEOUT_COOKIE,
        hello: Resources.SESSION_TIMEOUT_HELLO,
        message: Resources.SESSION_TIMEOUT_EXPIRE,
        dialogWidth : 375,
        dialogHeight : 184,
        continueBtn: Resources.SESSION_TIMEOUT_BUTTON,
        continueCallback: function() {},
        sessionExpiredCallback: function() {},
        closeDialog: function() {}
    }

    $.extend(settings, options);

    var TimeoutDialog = {
        init : function() {
            this.setupDialogTimer();
            $(document).on('keypress click touchstart touchmove', function(){
                if (!$('.timeout-dialog').length) {
                    window.clearTimeout(settings.interval);
                    TimeoutDialog.setupDialogTimer();
                }
            });
        },

        setupDialogTimer : function() {
            var self = this;
            settings.interval = window.setTimeout(function() {
                if (!$('.timeout-dialog').length) {
                    self.setupDialog();
                }
            }, settings.timeoutTrigger * 1000);
        },

        setupDialog : function() {
            var self = this;
            self.destroyDialog();

            $('<div id="timeout-dialog">'
                    + '<h3>' + settings.hello + '</h3>'
                    + '<p id="timeout-message">' + settings.message.format('<span id="timeout-countdown">' + formatCounter(settings.countdown) + '</span>')
                    + '</p></div>').appendTo(
            'body').dialog({
                modal : true,
                width : settings.dialogWidth,
                height: settings.dialogHeight,
                zIndex : 10000,
                closeOnEscape : false,
                draggable : false,
                resizable : false,
                dialogClass : 'timeout-dialog',
                buttons : {
                    'continue' : {
                        text : settings.continueBtn,
                        id : 'timeout-continue',
                        click : this.continueHandler
                    }
                }
            });
            Cookies.set(settings.cookie, true);
            self.startCountdown();
            self.closeDialog();
        },

        continueHandler : function() {
            window.clearInterval(TimeoutDialog.countdown);
            TimeoutDialog.destroyDialog();
            settings.continueCallback();
        },

        destroyDialog : function() {
            var popup = $('#timeout-dialog');
            if (popup.length) {
                popup.dialog('close');
                popup.remove();
            }
        },

        startCountdown : function() {
            var self = this, counter = settings.countdown;

            TimeoutDialog.countdown = window.setInterval(function() {
                counter -= 1;
                $('#timeout-countdown').html(formatCounter(counter));
                if (counter <= 0) {
                    window.clearInterval(self.countdown);
                    Cookies.remove(settings.cookie);
                    settings.sessionExpiredCallback();
                }
            }, 1000);
        },
        
        closeDialog : function() {
            var close = $('.timeout-dialog .ui-dialog-titlebar-close, .ui-widget-overlay');

            $(close).on('click', function(){
                Cookies.remove(settings.cookie);
                settings.sessionExpiredCallback();
            });
        }
    };

    TimeoutDialog.init();
};
