'use strict';

var util = require('../../util');
function setPageHeight () {
    if (!$('[data-component="hybrid"]').length || (pageContext.ns === 'product' && $('#QuickViewDialog').is(':visible')) || $('.pdp-wrapper').hasClass('full-view')) {
        return false;
    }

    var $selector = $('.product-details-content'),
        $target = $('.pdp-wrapper'),
        $h = $selector.height() + ($selector.offset().top - $target.offset().top),
        $img_selector = $('.product-images-desktop .product-image-wrapper').eq(0),
        $img_h = $img_selector.height() + ($img_selector.offset().top - $target.offset().top)
    
    if ($('.product-images-desktop').is(':visible') && $img_h > $h) {
        $h = $img_h;
    }
    setTimeout(function(){
        $('.pdp-wrapper').css({
            overflow: 'hidden',
            height: $h
        });
        $(".js-desktop-images:visible").height($h);
    }, 1000);
}

var hybrid = {
    init: function () {
        if (!$('[data-component="hybrid"]').length || pageContext.ns !== 'product') {
            $('.pdp-wrapper').addClass('full-view');
            $('.is_recently_viewed').addClass('show');
            return false;
        }

        setPageHeight();
        $(window).load(setPageHeight).resize(setPageHeight);
        $('body').trigger('init:lazyload');
        $('body').on('trigger:hybridpdpheight', setPageHeight);
        $(window).on('scroll', function () {
            var $hybrid = $('[data-component="hybrid"]:not(.loaded)');
            $hybrid.find('.scroll-loader').removeClass('hide');
            if ($hybrid.length && util.elementInViewport($hybrid.get(0), 250) && !$hybrid.hasClass('loading')) {
                $hybrid.addClass('loading');
                $.get($hybrid.data('url'), function (data) {
                    $hybrid.find('.scroll-loader').remove();
                    $hybrid.append($(data).find('#new-search-result-content').clone());
                    $(document).trigger('after:hybridTilesLoad');
                    $hybrid.find('[data-component="hybrid-title"]').removeClass('hide');
                    $hybrid.addClass('loaded').removeClass('loading');
                    $('body').trigger('init:producttile');
                });
            }
        });

        $(document).on('click', '[data-component="hybrid-more"]', function (e) {
            e.preventDefault();
            $(this).hide();
            $('.pdp-wrapper').addClass("full-view");
            $('.pdp-wrapper').removeAttr('style');
            $(".js-desktop-images").removeAttr('style');
            $('.is_recently_viewed').addClass('show');
            if ($(".matching-piece-items").hasClass('slick-slider')) {
                $(".matching-piece-items").slick('refresh');
            }
        });

        $(document).on('load-variant:after', function () {
            if (!$('.pdp-wrapper').hasClass("full-view")) {
                $(".js-desktop-images:visible").height($('.pdp-wrapper').height());
            } else {
                $(".js-desktop-images").removeAttr('style');
            }
        });

        $(document).on('after:hybridTilesLoad', function() {
            var $hybrid = $('[data-component="hybrid"]'),
                $size = $hybrid.data('size');
            if ($hybrid.length &&
                $size &&
                $hybrid.find('.grid-tile').length > 0) {
                var $tiles = $hybrid.find('.grid-tile:not(.visible)');
                $tiles = $tiles.splice(0, $size);
                if ($tiles.length > 0) {
                    $.each($tiles, function (i, ele) {
                        $(ele).addClass('visible');
                    });
                }
            } else {
                $hybrid.find('.grid-tile').addClass('visible');
            }

            if ($hybrid.find('.grid-tile:not(.visible)').length) {
                $hybrid.find('.loadMoreButton').addClass('load-cached-tiles');
            } else {
                $hybrid.find('.loadMoreButton').removeClass('load-cached-tiles');
            }
        });

        $(document).on('click', '[data-component="hybrid"] .loadMoreButton', function () {
            if ($(this).hasClass('load-cached-tiles')) {
                $(document).trigger('after:hybridTilesLoad');
            }
        });

        $(document).on('click', '.product-details-content #BVRRRatingSummaryLinkReadID a', function (e) {
            if ($('[data-component="hybrid-more"]').is(':visible')) {
                $('[data-component="hybrid-more"]').trigger('click');
                $('html,body').animate({scrollTop: $('#BVReviewsContainer').offset().top - 100}, 500);
            }
        });
    }
}

module.exports = hybrid;