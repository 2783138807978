/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var event = require('../event');

var checkout = {
    init: function (){
        if ($('.checkout-shipping').size() > 0) {
            
            // event6
            // Shipping Info Page
            // Counter event to fire whenever a visit reaches the shipping page. Adobe Analytics settings only recored this event once per visit.
            event.pushEvent('event6', null, true);
            
            // event20
            // Gift Options Page
            // Counter event to fire whenever a visit reaches the Checkout Gift Options page.
            event.pushEvent('event20', null, true);
            
            var $useAsBillingAddress = $('#dwfrm_singleshipping_shippingAddress_useAsBillingAddress');
            if ($useAsBillingAddress.prop('checked')) {
                
                // event38
                // Same Billing Address
                // Counter event to fire during checkout whena  user selects that their billing address is the same as their shipping address entered previously.
                event.pushEvent('event38', null, true);
                
            } else {
                $useAsBillingAddress.on('change', function () {
                    if ($(this).prop('checked')) {
                        
                        // event38
                        // Same Billing Address
                        // Counter event to fire during checkout whena  user selects that their billing address is the same as their shipping address entered previously.
                        event.pushEvent('event38');
                    }
                });
            }
        } else if ($('.checkout-billing').size() > 0) {
            
            // event7
            // Billing Page
            // Counter event to fire whenever a visit reaches the billing page. Adobe Analytics settings only recored this event once per visit.
            event.pushEvent('event7', null, true);
            var $sameAsShipping = $('#same-as-shipping');
            if ($sameAsShipping.prop('checked')) {
                
                // event38
                // Same Billing Address
                // Counter event to fire during checkout when a  user selects that their billing address is the same as their shipping address entered previously.
                event.pushEvent('event38', null, true);
                
            } else {
                $sameAsShipping.on('change', function () {
                    if ($(this).prop('checked')) {
                        
                        // event38
                        // Same Billing Address
                        // Counter event to fire during checkout when a  user selects that their billing address is the same as their shipping address entered previously.
                        event.pushEvent('event38');
                    }
                });
            }
        } else if ($('.order-review').size() > 0) {
            
            // event9
            // Order Review/Summary
            // Counter event to fire whenever a visit reaches the order review/summary page.
            event.pushEvent('event9', null, true);
        }
        
        // event13
        // Standard Checkout
        // Counter event to fire when a user visits the standard checkout page or the PayPal express checkout page after logging into to PayPal.
        event.pushEvent('event13', null, true);
        
        // event18
        // Guest Shipping Page
        // Counter event to fire whenever a visit reaches the Checkout Add Shipping Page.
        event.pushEvent('event18', null, true);
        
        event.pushEvent('scCheckout', null, true);
        
        event.push();
        
        $(document).on('click', '#add-coupon', function () {
            var couponCode = $('#dwfrm_billing_couponCode').val();
            
            // eVar112
            // Promo Code Value Entered
            // Passes the value a visitor used when hitting the apply button on the promo code field. 
            event.pushEVar('eVar112', couponCode);
            
            // event182
            // Promo Code Apply
            // Counter event to trigger every time a value is entered into the promo code field and a visitor clicks apply. This will always trigger with the Promo Code Value Entered eVar.
            event.pushEvent('event182');
        });
    }
}

exports.init = checkout.init;
