'use strict';

var util = require('../../util');
var shipping = require('./shipping');
var formPrepare = require('./formPrepare');
/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
    var $form = $('.address'),
        isShippingForm = $form.hasClass('checkout-shipping');
    // select address from list
    $('select[name$="_addressList"]', $form).on('change', function () {
        var selected = $(this).children(':selected').first();
        $('[id$=addressList] option:first').text(Resources.SELECT_STORE_TEXT);
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $form);
        $form.find('[name$="addressFields_ID"]').val(selectedAddress.ID);
        if (isShippingForm) {
            shipping.updateShippingMethodList();
            shipping.updateShippingDetailsJson(selectedAddress);
        }

        // re-validate the form
        $form.validate().form();
        formPrepare.validateForm();
        $('input[name$="addToAddressBook"]').prop('checked', false).attr('checked', false);
        if ($('.form-modifier').hasClass('d-none')) {
            $('.form-modifier').removeClass('d-none');
        }
        if($('[id$=addressList] option:selected').val() !== '') {
            $('.shipping-fields, .add-to-address-book').addClass('d-none');
        }
        $('input[name$="usedNewAddress"]').prop('checked', false).attr('checked', false).val(false);
    });

    $('.address-company-toggle', $form).on('click', function () {
        $(this).toggleClass('active');
    });
};
