'use strict';
var dialog = require('./dialog'),
    util = require('./util');

// TODO: delete old size chart JS (from this line down to "New Size Chart Styles below...") once all size charts have been updated to the new style

var sizechart = function ($scope) {
    $scope = $scope || $('body');
    $scope.find('.size-chart-header').unbind('click').on('click', function () {
        $(this).children('span').toggleClass('rot180-svg');
        $(this).next('.size-chart-wrapper').slideToggle('fast');
    });
}

var unitcontrol = function ($scope) {
    $scope.find('.alternate-toggle').on('click', function () {
        if ($(this).hasClass('active')) {
            return;
        } else {
            $(this).prev('.main-toggle').removeClass('active');
            $(this).toggleClass('active');
            $(this).parent().next('table').find('.inches').hide();
            $(this).parent().next('table').find('.cm').show();
        }
    })
    $scope.find('.main-toggle').on('click', function () {
        if ($(this).hasClass('active')) {
            return;
        } else {
            $(this).next('.alternate-toggle').removeClass('active');
            $(this).toggleClass('active');
            $(this).parent().next('table').find('.cm').hide();
            $(this).parent().next('table').find('.inches').show();
        }
    })
}

// New Size Chart Styles below this line

var initstickysecondcolumn = function ($scope) {
    $scope.find('.size-chart-table .sticky-col:nth-of-type(2)').each(function () {
        var columnOneWidth = $(this).prev('.sticky-col').outerWidth();
        $(this).css('left', columnOneWidth);
    });
    util.smartResize(function () {
        initstickysecondcolumn($scope);
    });
};

var unitswitcher = function ($scope) {
    $scope.find('.conversion-toggle').on('click', function () {
        if ($(this).hasClass('active')) {
            return;
        }
        var $parent = $(this).parents('.size-chart-section');
        var $sibling = $(this).siblings('.conversion-toggle');
        var $tables = $parent.find('.size-chart-table');
        var units = $(this).data('units');
        $(this).addClass('active');
        $sibling.removeClass('active');
        $tables.attr('data-units', units);
        initstickysecondcolumn($scope);
    });
};

var tableswitcher = function ($scope) {
    $scope.find('.table-toggle').on('click', function () {
        if ($(this).hasClass('active')) {
            return;
        }
        $(this).siblings('.table-toggle').removeClass('active');
        $(this).addClass('active');
        var tablename = $(this).data('table');
        $(this).parents('.size-chart-section').attr('data-table', tablename);
        initstickysecondcolumn($scope);
    });
};

var redesignmodal = function () {
    var reviewComponent = $('#BVCustomerRatings');
    //mutation Observer configuration
    var config = {childList: true, subtree: true};

    function fillSizeChartTitle () {
        if ($('.size-chart-modal .product-reviews-lockup .product-name').length == 0 && $('.size-chart-modal .product-reviews-lockup span').length == 0) {
            if (reviewComponent.length) {
                reviewComponent.clone().appendTo('.size-chart-modal .product-right-rail .product-reviews-lockup');
            }
            $('.product-reviews-lockup-qv__item-number, .product-reviews-lockup__item-number').eq(0).clone().appendTo('.size-chart-modal .product-right-rail .product-reviews-lockup');
            $('.product-right-rail .product-col-1 .product-name').eq(0).clone().appendTo('.size-chart-modal .product-right-rail .product-reviews-lockup');
        }
    }

    function fillSizeChartImage () {
        if ($('.size-chart-modal .modal-image img').length == 0) {
            if ($('.product-images-desktop__column .product-image').length) {
                $('.product-images-desktop__column .product-image').eq(0).clone().appendTo('.size-chart-modal .modal-image');
            } else {
                $('.product-image-wrapper.slick-current .product-image').eq(0).clone().appendTo('.size-chart-modal .modal-image');
            }
        }  
    }

    var observer = new MutationObserver(function (mutationsList, observer) {
        for (var mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.target === reviewComponent[0]) {
                fillSizeChartTitle();
                break;
            }
        }
    });
    
    fillSizeChartTitle();
    fillSizeChartImage();
    
    if (reviewComponent.length) {
        observer.observe(reviewComponent[0], config);
    }
}

var init = function ($scope) {
    $scope = $('body'); // currently will not use parameter value for $scope, as size chart opens in a new dialog, and should always have a scope of $('body')

    sizechart($scope); // remove once old sizechart JS is deleted
    unitcontrol($scope); // remove once old sizechart JS is deleted
    unitswitcher($scope);
    tableswitcher($scope);
    initstickysecondcolumn($scope);

    //Firing size chart js after dialog is open
    $(document).off('click.size-chart').on('click.size-chart', '.size-chart-link a', function (e) {
        e.preventDefault();
        redesignmodal();
        var sizechartHtml = $('.size-chart-modal').html();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                classes: {
                    'ui-dialog': 'size-chart-dialog'
                },
                width: 'auto'
            },
            callback: function () {
                $('#dialog-container').prepend(sizechartHtml);
                init($scope);
            }
        }, false);
    });
}


module.exports = init;
