/**
* Description of the module and the logic it provides
*
* @module cartridge/js/optinmodal
*/
'use strict';

var dialog = require('./dialog'),
    util = require('./util'),
    validator = require('./validator'),
    stickyFooter = require('./stickyfooter');

function initializeEvents() {
    if (!SitePreferences.SMS2_ENABLED) {
        return;
    }
    var dismissOptIn = Cookies.get('dismissOptIn');
    var sessionDismissOptin = sessionStorage.getItem('dismissOptIn');
    var optInIsRestricted = sessionStorage.getItem('optInIsRestricted');
    if (!optInIsRestricted && (typeof dismissOptIn == 'undefined' || dismissOptIn == 'false') && !sessionDismissOptin) {
        var optinType = Cookies.get('optinType');
        if ((optinType === 'sms' && !window.User.anonymous) || (optinType === 'email' && window.User.anonymous)) {
            loadOptIn(null, optinType);
        }
    } else {
        stickyFooter.init();
    }
}

/**
 * @private
 * @function
 * @description Handles the loading of the Optin form into the Modal.
 */
function loadOptIn(e, optinType) {
    var isGated = $('body').hasClass('gated');

    if (!SitePreferences.SMS2_ENABLED) {
        return;
    }

    if (!isGated && ($('.login-page').length || $('.pt_order-confirmation').length) && optinType !== 'checkout' && optinType !== 'hotjar') {
        return;
    }
    if (e) e.preventDefault();

    var url = window.Urls.sms2optin;

    $.ajax({
        url: url,
        type: 'get',
        data: {optinType: optinType},
        success: function(data) {
            var options = {
                html: data,
                options: {
                    classes: {
                        'ui-dialog': 'sms2optin-dialog ' + optinType,
                        'ui-dialog-content': 'sms2optin-dialog-content'
                    },
                    closeOnEscape: !isGated,
                    open: function() {
                        initializeForm()
                    },
                    close: function () {
                        $(this).dialog('close');
                        $('#wrapper').attr('aria-hidden', 'false');
                        if (optinType === 'sms' || optinType === 'email') {
                            Cookies.set('dismissOptIn', true, {expires: 60});
                        } else if (optinType === 'checkout') {
                            Cookies.set('dismissCheckoutOptIn', true, {expires: 60});
                        } else if (optinType === 'hotjar') {
                            Cookies.set('dismissHotJarCookie', true, {expires: 60});
                        }
                    }
                }
            }
            dialog.open(options);
        }
    });
}

/**
 * @private
 * @function
 * @description Initialize events once the form is loaded.
 */
function initializeForm() {
    $('.js-sms2optin-form').on('submit', formHandler);

    $('.js-sms2optin-form .input-text').on('blur', function () {
        validator.init();
    });

    $('.sms2-hotjar-btn').on('click', function (e) {
        e.preventDefault();
        var url = SitePreferences.SMS2_CONFIRM_HOTJAR_URL;
        if (url !== '' && url !== null) {
            window.open(url, '_blank');
        }
        dialog.close();
    });
}


/**
 * @private
 * @function
 * @description Handles triggering sticky footer
 */
function handleStickyFooter() {
    sessionStorage.setItem('showStickyFooterPromo', true);
    stickyFooter.init();
}

/**
 * @private
 * @function
 * @description Handles submission of the Optin Form, and parses response.
 */
function formHandler(e) {
    e.preventDefault();
    var $this = $(this);

    if (!$this.valid()) {
        return false;
    }

    // perform the SFMC API calls here
    $.ajax({
        type: 'POST',
        url: util.appendParamToURL($this.attr('action'), 'format', 'ajax'),
        data: $this.serialize()
    })
    .done(function (data) {
        if (data && data.success) {
            if (data.showSMSDoubleOptinMsg) {
                $('.js-sms-double-optin-msg').removeClass('d-none');
                $('.js-sms2optin-form').addClass('d-none');
            } else {
                $('.sms2optin-dialog-content').dialog('close');  
            }
            handleStickyFooter();
        }
    });
}

/**
 * EXPOSED METHODS
 */
module.exports = {
    /**
     * @public
     * @function
     * @description Init event(s) for the Registration Modal.
     */
    init: function () {
        initializeEvents();
    },
    loadOptIn: loadOptIn
}
