'use strict';

module.exports.init = function() {
    let rating = null,
        totalRatings = null;
    var observer = new MutationObserver(function (mutations, me) {
        const fitScaleImage = document.querySelector('.BVRRQuickTakeSummary .BVRRSecondaryRatingsContainer .BVRRRatingContainerSlider .BVImgOrSprite');
        const ratingsEl = $('.BVRRCount .BVRRNumber').first();
        if (fitScaleImage && ratingsEl) {
            rating = parseFloat(fitScaleImage.alt.split(' out of ')[0]);
            totalRatings = parseFloat(ratingsEl.text())
            if (totalRatings >= 5) {
                renderSizeRec(rating,totalRatings);
            }
            me.disconnect();
            return;
        }
    });
      
    observer.observe(document, {
        childList: true,
        subtree: true
    });

    function updateResourceString(string, replacementArray) {
        return string.replace(/{(\d+)}/g, function(match, g1) {
            return replacementArray[g1];
        });
    }
      
    function determineSizeText(rating) {
        var sizeText = 'small';
        if (rating > 2 && rating < 2.7) {
            sizeText = 'slightly small';
        } else if (rating >= 2.7 && rating < 3.4) {
            sizeText = 'true to size';
        } else if (rating >= 3.4 && rating < 4) {
            sizeText = 'slightly big';
        } else if (rating >= 4) {
            sizeText = 'big';
        }
        return sizeText;
    }

    function renderSizeRec(rating, totalRatings) {
        const targetEl = document.querySelector('.bv-size-text');
        if (!rating || !totalRatings) return;
        const text = `${ updateResourceString(Resources.SIZE_RECOMMENDATION,[determineSizeText(rating)]) } <a href="#BVReviewsContainer">${ totalRatings } ${ Resources.SIZE_RECOMMENDATION_REVIEWS }</a>.`;
        targetEl.insertAdjacentHTML('beforeend',text);
    }

    $(document).on('load-variant:after', function() {
        renderSizeRec(rating, totalRatings);
    });
}
