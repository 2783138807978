var removeProductToast = {
    init: function($container, product) {
        if($('.mini-cart-content').find('.toast').length == 0) {
            removeProductToast.show($container, product)
        }
    },
    show: function($container, product) {
        if($container.parents('.item-list').length > 0) {
            var toast = $('<tr></tr>').addClass('toast');
            var toastFlex = $('<td colspan="6"></td>').addClass('toast-flex');
        } else {
            var toast = $('<div></div>').addClass('toast');
            var toastFlex = $('<div></div>').addClass('toast-flex');
        }
        var productName =  $('<a></a>').addClass('toast-flex-text-pname');
        productName.text(product.name);
        var toastText = $('<p> was removed from your cart</p>').addClass('toast-flex-text');
        var undo = $('<button>undo</button>').addClass('toast-flex-undo add-to-cart');
        var isElectronicCard = product.name == "Electronic Gift Card";
        var isClassicCard = product.name == "Classic Gift Card";

        if(isElectronicCard || isClassicCard) {
            productName.attr('href', '/s/madewellUS/giftcard');
            undo
                .attr('data-dwfrm_giftcert_purchase_recipient', product.dwfrm_giftcert_purchase_recipient)
                .attr('data-dwfrm_giftcert_purchase_from', product.dwfrm_giftcert_purchase_from)
                .attr('data-dwfrm_giftcert_purchase_messageEGift', product.dwfrm_giftcert_purchase_messageEGift)
                .attr('data-dwfrm_giftcert_purchase_messageClassic1', product.dwfrm_giftcert_purchase_messageClassic1)
                .attr('data-dwfrm_giftcert_purchase_messageClassic2', product.dwfrm_giftcert_purchase_messageClassic2)
                .attr('data-dwfrm_giftcert_purchase_sendDate', product.dwfrm_giftcert_purchase_sendDate)
                .attr('data-dwfrm_giftcert_purchase_recipientEmail', product.dwfrm_giftcert_purchase_recipientEmail)
                .attr('data-dwfrm_giftcert_purchase_confirmRecipientEmail', product.dwfrm_giftcert_purchase_confirmRecipientEmail)
                .attr('data-dwfrm_giftcert_purchase_fromEmail', product.dwfrm_giftcert_purchase_fromEmail)
                .attr('data-productid', product.pid)
                .attr('data-quantity', product.quantity)
                .attr('data-amount', product.amount)
                .attr('data-cardDesign', JSON.stringify(product.cardDesign))
                .attr('data-cartAction', 'add')
                .attr('data-addtocartlocation', 'minicart')
                .attr('data-variationData', product.variation)
                .attr('data-masterproductid', product.masterpid)
        } else {
            productName.attr('href', product.pdpurl);
            undo
                .attr('data-quantity', product.quantity)
                .attr('data-cartAction', 'add')
                .attr('data-addtocartlocation', 'minicart')
                .attr('data-productid', product.pid)
                .attr('data-masterproductid', product.masterpid)
                .attr('data-upc', product.upc)
                .attr('data-isMasterProduct', 'false')
                .attr('data-variationData', product.variation)
                .attr('data-price', product.price)
                .attr('data-pdpurl', product.pdpurl)
        }
        undo
            .attr('data-productName', product.name)
            .attr('data-personalized', product.isPersonalized)
            .attr('data-personalizedvalue', product.isPersonalizedValue)
            .attr('data-recipeid', product.recipeId)
            .attr('data-gtmdata', JSON.stringify({ 
                                    "productID":product.masterpid,
                                    "productName":product.name,
                                    "item_name":product.name,
                                    "item_id":product.masterpid,
                                    "quantity":product.quantity,
                                    "price":product.price.toFixed(2),
                                    "item_variant":product.pid,
                                    "color_code":product.colorcode,
                                    "color_name":product.colorname,
                                    "size":product.size,
                                    "original_price":product.originalPrice ? product.originalPrice.toFixed(2) : null,
                                    "local_currency":product.localCurrency,
                                }));
        if($container.hasClass('mini-cart-products')) {
            var $containerChildren = $container.children('.mini-cart-product');
        } else {
            var $containerChildren = $container.children('.cart-row');
        }
        var $containerChildrenIndex = $containerChildren.eq(product.containerIndex);
        if (product.containerIndex <= $containerChildren.length) { 
            toast.insertBefore($containerChildrenIndex);
            if($containerChildrenIndex.length == 0) {
                toast.appendTo($container);    
            }
        } else { 
            toast.appendTo($container);
        };

        toastFlex.appendTo(toast)
        toastText.prepend(productName);
        toastText.appendTo(toastFlex);
        undo.appendTo(toastFlex);

        $('html,body').animate({scrollTop: product.scrollPosition}, 500)

        setTimeout(() => {
            $('.toast').fadeOut(400, function() {
                $(this).remove();
            });
        }, 10000);
    }
}
module.exports = removeProductToast;