/**
* Description of the module and the logic it provides
*
* @module cartridge/js/talkablemodal
*/
'use strict';

var dialog = require('./dialog');
var util = require('./util');

/**
 * @private
 * @function
 * @description Handles the loading of the Optin form into the Modal.
 */
function initializeTalkabelModalEvents() {
    if (!SitePreferences.TALKABLE_ENABLED) {
        return;
    }

    $('.js-talkable-promocode').on('click', function () {
        var promoCode = $(this).text();

        if (promoCode) {
            util.copyTextToClipboard(promoCode);
        }
    });

    $('.js-talkable-success').on('dialogbeforeclose', function() {
        window.location.href = window.Urls.accountShow;
    });
}

/**
 * @private
 * @function
 * @description Handles the loading of the Talkable signup registration modal with coupon code
 */
function loadTalkableModalSuccess(uuid) {
    if (!SitePreferences.TALKABLE_ENABLED) {
        return;
    }

    var url = window.Urls.talkableSignUpSuccess;
    var talkableUUID = uuid || '';

    $.ajax({
        url: url,
        data: {
            talkableuuid: talkableUUID
        },
        type: 'get',
        success: function(data) {
            var options = {
                html: data,
                options: {
                    width: 'auto',
                    classes: {
                        'ui-dialog': 'talkable-success registration',
                        'ui-dialog-content': 'talkable-dialog-content js-talkable-success'
                    },
                    open: function() {
                        initializeTalkabelModalEvents();
                    }
                }
            }
            dialog.open(options);
        }
    });
}

/**
 * @private
 * @function
 * @description Handles the loading of the Optin form into the Modal.
 */
function loadTalkablePostCheckout(orderNo) {
    if (!SitePreferences.TALKABLE_ENABLED || !orderNo) {
        return;
    }

    var url = util.appendParamToURL(window.Urls.talkablePostCheckout, 'orderNo', orderNo);

    $.ajax({
        url: url,
        type: 'get',
        success: function(data) {
            $('body').append(data);
        }
    });
}

/**
 * EXPOSED METHODS
 */
module.exports = {
    loadTalkableModalSuccess: loadTalkableModalSuccess,
    initializeTalkabelModalEvents: initializeTalkabelModalEvents,
    loadTalkablePostCheckout: loadTalkablePostCheckout
}
