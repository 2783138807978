'use strict';
var dialog = require('./dialog');

exports.init = function() {
    $('.js-share-favorites').on('click',(e) => {
        var button = $(e.target),
            shareLink = button.data('share-link');

        dialog.open({
            html: `
                <h3 class="share-favorites-modal__hed">Share Your Favorites</h3>
                <label class="share-favorites-modal__label" for="share-favorites-link">Use This Link</label>
                <div class="share-favorites-modal__input-group">
                    <input class="share-favorites-modal__input js-copy-text-input" type="text" value="${ shareLink }" id="share-favorites-link">
                    <button class="share-favorites-modal__button js-copy-text-button">Copy Link</button>
                </div>`,
            options: {
                modal: true,
                width: 'auto',
                maxWidth: '900',
                height: '670',
                dialogClass: 'share-favorites-modal'
            }
        });
    })

    $(document).on('click','.js-copy-text-button',(e) => {
        var copyText = $('.js-copy-text-input');
        copyText[0].select();
        copyText[0].setSelectionRange(0, 99999);
        document.execCommand('copy');
        $(e.target).text('Copied').addClass('copied');
    })
}
