/**
 * Description of the module and the logic it provides
 *
 * @module cartridge/js/registrationmodal
 */
'use strict';

var dialog = require('./dialog'),
    page = require('./page'),
    progress = require('./progress'),
    util = require('./util'),
    validator = require('./validator'),
    talkablemodal = require('./talkablemodal');

/**
 * @private
 * @function
 * @param $target The target to attach the Sign In Modal Load click event to.
 * @description Binds the events for any click that initiates the Registration modal.
 */
function initializeEvents() {
    // Any registration link - header, footer, or otherwise - will initiate this modal.
    $(document).on('click', '.create-account, #create-account', loadRegistration);

    window.openSignUpModal = function () {
        loadRegistration();
    };

    const phoneSelector = '.signinout-body-info .form-row.smsoptin-phonenum .input-text';
    const termsSelector = '.registration-container .sms-optin-account-terms';
    const modalContainerSelector = '.signinout-tab[data-action="registration"] .signinout-body-container';
    const modalContainerHeightSelector = '.signinout-tab[data-action="registration"] .signinout-body-info';

    let hasShownTerms = false;

    function clickCallback() {
        const $phoneInput = $(phoneSelector);
        const $termsContainer = $(termsSelector);
        const $modalContainerSelector = $(modalContainerSelector);
        const $modalContainerHeightSelector = $(modalContainerHeightSelector);

        if (!hasShownTerms) {
            $termsContainer.stop(true, true).hide();

            setTimeout(function () {
                $termsContainer.slideDown('fast');
            }, 0);

            setTimeout(function () {
                $phoneInput.blur();
                $modalContainerSelector.height($modalContainerHeightSelector.height());
            }, 1);

            hasShownTerms = true;
        }
    }

    function hideTermsIfClickedOutside(event) {
        const $phoneInput = $(phoneSelector);
        const $termsContainer = $(termsSelector);
        const $modalContainerSelector = $(modalContainerSelector);
        const $modalContainerHeightSelector = $(modalContainerHeightSelector);
    
        if (
            !$phoneInput.is(event.target) && !$termsContainer.is(event.target) &&
            $termsContainer.has(event.target).length === 0
        ) {
            $termsContainer.stop(true, true).slideUp('fast');
            hasShownTerms = false;
            setTimeout(function () {
                $phoneInput.blur();
                $modalContainerSelector.height($modalContainerHeightSelector.height());
            }, 1);
        }
    }

    $(document).off('click.phoneSelector', phoneSelector, clickCallback).on('click.phoneSelector', phoneSelector, clickCallback);

    $(document).off('click', hideTermsIfClickedOutside).on('click', hideTermsIfClickedOutside);

    function preventScrollOnFocus() {
        const originalScrollPosition = window.scrollY;
    
        document.body.style.position = 'fixed';
        document.body.style.top = `-${originalScrollPosition}px`;
        document.body.style.width = '100%';
    }
    
    function restoreScrollOnBlur() {
        const scrollPosition = parseInt(document.body.style.top || '0', 10) * -1;
    
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
    
        window.scrollTo(0, scrollPosition);
    }
    
    document.querySelectorAll('.signinout-tab input').forEach((input) => {
        input.addEventListener('focus', preventScrollOnFocus);
        input.addEventListener('blur', restoreScrollOnBlur);
    });

    $(document).on('click', '.signinout-tab .registration-check-button', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $form = $this.closest('form');
        var $email = $form.find('.email').val();
        $form.find('.email').trigger('blur');
        if (!$form.find('.email').hasClass('error')) {
            var url = util.appendParamToURL($this.data('url'), 'format', 'ajax');
            $this.prop('disabled', true);

            $.ajax({
                    type: 'POST',
                    url: url,
                    data: $form.serialize()
                })
                .done(function (data) {
                    $this.prop('disabled', false);
                    if (!data.existingCustomer) {
                        $form.addClass('active');
                        $('.signinout-tab[data-action="registration"] .signinout-body-container').height($('.signinout-tab[data-action="registration"] .signinout-body-info').height());
                        setTimeout(function () {
                            $('body').off('dialog:reposition');
                        }, 1000);
                    } else {
                        $('.signinout-tab .modal-login-form .email').val($email);
                        $('.signinout-head:visible [data-action="login"]').trigger('click');
                    }
                });
        }
    });
}

/**
 * @private
 * @function
 * @description Handles the loading of the Registration form into the Modal.
 */
function loadRegistration(e, params) {
    var isTabView = $('.ui-dialog .signinout-tab');
    var isGated = $('body').hasClass('gated');
    var isBackroom = $('body').hasClass('backroom');

    if (!isGated && ($('.login-page').length || $('.pt_order-confirmation').length)) {
        return;
    }
    if (e) e.preventDefault();
    var redirectOrigin;
    if (e && e.currentTarget && e.currentTarget.href && e.currentTarget.href.indexOf('?original=/') > -1) {
        redirectOrigin = e.currentTarget.href.substring(e.currentTarget.href.indexOf('?original=/') + '?original=/'.length);
    }
    var url = window.Urls.registration;
    if ($(this).parents('.save-for-later-container').length != 0) {
        url = util.appendParamToURL(url, 'source', 'wishlist');
    }
    if ($(this).parents('.cart-rewards').length != 0) {
        url = util.appendParamToURL(url, 'source', 'cartLoyalty');
    }
    if ($('.create-account').hasClass('on-session')) {
        url = util.appendParamToURL(url, 'source', 'desktopLoyaltyPopup');
    }

    if (params && params.isTalkableSignUp) {
        url = util.appendParamToURL(url, 'source', 'talkable');

        if (params.talkableUuid) {
            url = util.appendParamToURL(url, 'talkableuuid', params.talkableUuid);
        }
    }
    if (redirectOrigin) {
        url = util.appendParamToURL(url, 'redirectOrigin', redirectOrigin);
    }
    var checkAccessUrl = window.Urls.checkAccess;
    // determine if modal must be an access gate
    if (isGated) {
        url = window.Urls.registerGateModal;
        checkAccessUrl = window.Urls.checkGateAccess;
    }
    if (isGated && isBackroom) {
        url = window.Urls.registerGateModalBackroom;
        checkAccessUrl = window.Urls.checkBackroomAccess;
    }

    var backroomTimeInterval = SitePreferences.BACKROOM_TIMEOUT_TRIGGER ? SitePreferences.BACKROOM_TIMEOUT_TRIGGER : 60000;
    $.ajax({
        url: checkAccessUrl,
        type: 'post',
        success: function (data) {
            if (data.isAccessible && isGated) {
                $('body').removeClass('gate-page');
                setTimeout(loadRegistration, backroomTimeInterval);
            } else {
                if (!data.isAccessible && isGated) {
                    $('body').addClass('gate-page');
                }
                // redirect to the correct Sheer ID form if the customer has landed here after attempting to verify their status
                if ($('#sheerid-login').length && $('#sheerid-login').val()) {
                    if ($('#sheerid-login').val().toLowerCase() === 'student') {
                        url = util.appendParamToURL(url, 'sheerTarget', 'student');
                    } else {
                        url = util.appendParamToURL(url, 'sheerTarget', 'teacher');
                    }
                }
                url = util.appendParamToURL(url, 'modal', 'true');
                if (isTabView.length) {
                    url = util.appendParamToURL(url, 'format', 'ajax');
                    url = util.appendParamToURL(url, 'loginincluded', 'true');
                    $.get(url, function (response) {
                        $('.sign-in-out .signinout-title').height($('.sign-in-out .signinout-title').height()).append($(response).filter('.signinout-title').html());
                        $('.sign-in-out .signinout-body-container').height($('.sign-in-out .signinout-body-info').height());
                        $('.sign-in-out .signinout-body').append($(response).filter('.signinout-body').html());
                        $('.sign-in-out .signinout-tab-head[data-action="registration"]').removeClass('disabled');
                        initializeForm();
                    });
                    if ($('.create-account').hasClass('on-session')) {
                        $('.registration').removeClass('open');
                    }
                } else {
                    var options = {
                        url: url,
                        options: {
                            width: 'auto',
                            height: 'auto',
                            title: '',
                            classes: {
                                'ui-dialog': 'sign-in-out registration open' + (isGated ? ' no-close gate' : '') + (params && params.isTalkableSignUp ? ' talkable' : '')
                            },
                            closeOnEscape: !isGated,
                            open: function () {
                                $('.registration #dialog-container').css('height', 'auto');
                                if ($('.create-account').hasClass('on-session')) {
                                    $('.registration').removeClass('open');
                                }

                                var $signinoutClose = $('.sign-in-out .ui-dialog-titlebar-close').clone();
                                $('.sign-in-out .ui-dialog-titlebar-close').remove();
                                $('.sign-in-out .ui-dialog-titlebar').append($signinoutClose);
                            }
                        },
                        callback: function () {
                            initializeForm();
                            $('.login-modal.login-account-tab-view').trigger('click');
                        }
                    }
                    // if this is the on-session popup, do not render in mobile
                    if ((!$(this).hasClass('on-session') || $(window).width() >= 768) && !data.isAccessible) {
                        dialog.open(options);
                    }
                }
            }
        }
    });
}

/**
 * @private
 * @function
 * @description Initialize events once the form is loaded.
 */
function initializeForm() {
    var $form = $('.RegistrationForm-modal');
    var uiDialog = $('.ui-dialog');
    var isGated = $('body, .ui-dialog').hasClass('gated');
    var isLoyalty = $('.registration-container').length && $('.registration-container').hasClass('loyalty');
    var isTalkable = $('.registration-container').length && $('.registration-container').hasClass('talkable-signup');
    var isRegistering = uiDialog.find('.account-birthday-message-modal').length > 0 || uiDialog.find('h1').text().includes('Thanks');
    var loadTalkableInterval = 3000;

    if (!uiDialog.hasClass('sign-in-out')) {
        if (uiDialog.hasClass('login-in')) {
            uiDialog.removeClass('login-in').addClass('registration');
        }
    }

    if (isLoyalty) {
        uiDialog.addClass('loyalty');
    }

    if (isRegistering) {
        uiDialog.addClass('almost-done');
    }

    // Since the Validator was initiated globally, before the form was loaded, we need to target the form directly.
    validator.initForm($form[0]);
    // Allow the user to hide/show their password.

    var countryCode = $('select[name$="_country"]').find(':selected').val();
    if (countryCode && countryCode.toLowerCase() === 'us') {
        sessionStorage.setItem('optInIsRestricted', false);
        sessionStorage.removeItem('optInIsRestricted');
    }
    // For mobile, the initial form is not shown. This is a CTA to continue the registration process.
    $('.mobile-show-link a').on('click', function (e) {
        e.preventDefault();
        $(this).closest('.registration-container').find('.registration-form-container').show();
        $('.ui-dialog.registration').addClass('open').find('.create-loyalty-account').removeClass('premodal');
    });

    if (!$('.create-account').hasClass('on-session')) {
        $('.mobile-show-link a').closest('.registration-container').find('.registration-form-container').show();
        $('.ui-dialog.registration').addClass('open').find('.create-loyalty-account').removeClass('premodal');
    }
    //for mobile is separated popup, triggered on session and clicked by user
    if ($(window).width() < 768 && !isGated) {
        $('.create-account').removeClass('on-session');
        if (!uiDialog.hasClass('talkable')) {
            $('.ui-dialog.registration.loyalty').removeClass('open');
        }
        //smooth scrolling to the top of popup
        $('html,body').animate({
            scrollTop: uiDialog.offset().top - 80
        }, 'fast');
    }

    // Second stage of Loyalty capture - update the form input when the select is changed.
    $('select[name$="customer_bMonth"]').on('change', updateDays);

    //add error/valid class to parent element for selects
    $form.find('select').each(function () {
        if ($(this).val() === '') {
            $(this).parent().removeClass('valid');
        } else {
            $(this).parent().removeClass('error').addClass('valid');
        }
    });
    $form.find('select').on('change', function () {
        if ($(this).val() === '') {
            $(this).parent().addClass('error').removeClass('valid');
        } else {
            $(this).parent().removeClass('error').addClass('valid');
        }
    });

    // Submission of the form. Handle response in the Modal.
    $form.on('submit', formHandler);

    if (isTalkable) {
        talkablemodal.initializeTalkabelModalEvents();
    }

    //inserted into callback, needed to refresh page after registering
    $('#dialog-container').off('dialogbeforeclose').on('dialogbeforeclose', function () {
        var secondStepRegistration = uiDialog.find('.secondary-info').length;
        var isTalkableSignup = uiDialog.find('.js-talkablesignup-input').length &&
            uiDialog.find('.js-talkablesignup-input').val() === 'true';

        if (!$('body, .ui-dialog').hasClass('gated')) {
            //cookie for not showing register popup on event beforeclose dialog
            Cookies.set('dismissRegister', true, {
                expires: 30
            });
        }
        // complete the sucessful registration process
        if (secondStepRegistration && isTalkableSignup) {
            var talkableUUID = $('.js-talkableuuid-input').length ? $('.js-talkableuuid-input').val() : '';
            setTimeout(talkablemodal.loadTalkableModalSuccess(talkableUUID), loadTalkableInterval);
        } else if (uiDialog.find('.secondary-info').length > 0 || uiDialog.find('.registration-success').length > 0) {
            if ($('body').hasClass('gated') || $('body').hasClass('gate-page')) {
                // this is a gated page. refresh the current page now that the use is signed in.
                window.location.reload(true);
            } else {
                // this is not a gated page. redirect the user to their Account page.
                if ($('#sheerid-login').length && $('#sheerid-login').val()) {
                    window.location.href = window.Urls.accountShow + '?registration=true';
                    if ($('#sheerid-login').val() == 'student') {
                        window.location.href = window.Urls.accountShow + '?registration=true&sheerTarget=student';
                    } else if ($('#sheerid-login').val() == 'teacher') {
                        window.location.href = window.Urls.accountShow + '?registration=true&sheerTarget=teacher';
                    }
                } else {
                    window.location.href = window.Urls.accountShow;
                }
            }
        }
        if (uiDialog.hasClass('registration')) {
            uiDialog.removeClass('open');
        }
    });

    // if this is a first visit and the customer is seeing the premodal, set the dismissRegister cookie
    if (!uiDialog.hasClass('open') && !$('body, .ui-dialog').hasClass('gated')) {
        Cookies.set('dismissRegister', true, {
            expires: 30
        });
    }

    // Hide/Show the Password requirements when password field is focused out/focused.
    $form.find('input[name$="_password"]').parents('.form-row').find('.form-caption').hide();
    $form.find('input[name$="_password"]')
        .on('focus', function () {
            $(this).parent().siblings('.form-caption').show();
            $('.signinout-tab[data-action="registration"] .signinout-body-container').height($('.signinout-tab[data-action="registration"] .signinout-body-info').height());
        })
        .on('focusout', function () {
            $(this).parent().siblings('.form-caption').hide();
            $('.signinout-tab[data-action="registration"] .signinout-body-container').height($('.signinout-tab[data-action="registration"] .signinout-body-info').height());
        });

    AppleID.auth.init({
        clientId: SitePreferences.APPLE_LOGIN_CLIENT_ID,
        scope: SitePreferences.APPLE_LOGIN_SCOPE,
        redirectURI: SitePreferences.APPLE_LOGIN_REDIRECT_URL,
        state: SitePreferences.APPLE_LOGIN_STATE
    });
}

/**
 * @private
 * @function
 * @description Handle faux select boxes.
 */
function updateDays() {
    var bDay = $('select[name$="_customer_bDay"]');
    bDay.find('option').remove();
    var month = $(this).val(),
        daysCount = new Date((new Date()).getFullYear(), month, 0).getDate();

    var leadingZero = function (i) {
        return i.length == 1 ? '0' + i : i;
    }
    for (var i = 1; i <= daysCount; i++) {
        var val = leadingZero(i);
        bDay.append($('<option></option>').attr('value', val).text(val));
    }
}

/**
 * @private
 * @function
 * @description Handles submission of the Registration Form, and parses response.
 */
function formHandler(e) {
    e.preventDefault();
    var $this = $(this);
    var isGated = $('body').hasClass('gated');
    var signinout = $('.ui-dialog .signinout-tab[data-action="registration"]');

    if (!$this.valid()) {
        return false
    }

    $('<input/>').attr({
        type: 'hidden',
        name: $this.find('button[type="submit"]').attr('name'),
        value: 'registrationmodal'
    }).appendTo($this);
    var url = util.appendParamToURL($this.attr('action'), 'format', 'ajax');
    if (url && signinout.length) {
        url = util.appendParamToURL(url, 'modal', 'true');
    }
    $this.find('.form-action-button:visible').prop('disabled', true);

    $.ajax({
            type: 'POST',
            url: url,
            data: $this.serialize()
        })
        .done(function (data) {
            $this.find('.form-action-button:visible').prop('disabled', false);
            // Since the Registration Flow has an Success Page, we should never need to recieve an JSON Success/Redirect object.
            // TODO: MDC-5450: to add check for the inputted phone number and set cookie?
            if (typeof data === 'object' && typeof data.success) {
                // TODO - If there is need for a redirect, pass in the success object.
                page.refresh();
            } else if (data.indexOf('redirectOriginIsCheckout') > -1) {
                // refresh page after registration
                page.refresh();
            } else {
                var $data = $('<div></div>').html(data);
                var registrationSuccess = $data.find('#registration-success, .registration-success').length > 0;
                // Reload the dialog with the returned html.
                progress.show($('.ui-dialog'));
                $('body').trigger('dialog:reposition');
                var options = {
                    html: data,
                    options: {
                        classes: {
                            'ui-dialog': (isGated ? ' gate' : '') + (isGated && !registrationSuccess ? ' no-close' : '')
                        },
                        closeOnEscape: registrationSuccess
                    }
                }
                if (signinout.length && !$data.find('.secondary-info').length) {
                    signinout.find('.registration-form-container').html($data.find('.registration-form-container').html());
                    signinout.find('.registration-email-check').addClass('active');
                    $('body').trigger('dialog:reposition');
                    // Re-initalize the form.
                    initializeForm();
                } else {

                    dialog.openWithContent(options);
                    if (registrationSuccess) {
                        $('.ui-dialog').removeClass('no-close');
                    } else {
                        $('.ui-dialog').addClass('no-close');
                    }
                    // Re-initalize the form.
                    initializeForm();
                }
                progress.hide();
            }
        });
}

/**
 * EXPOSED METHODS
 */
module.exports = {
    /**
     * @public
     * @function
     * @description Init event(s) for the Registration Modal.
     */
    init: function () {
        initializeEvents();
    },
    loadRegistration: loadRegistration
}
