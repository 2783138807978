'use strict';

/**
 * @description Loop through video sources and assign src attribute
 */
function selectSource(target) {
    for (var source in target.children) {
        const videoSource = target.children[source]
        if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
            videoSource.src = window.matchMedia && window.matchMedia('(min-width:480px)').matches ? videoSource.dataset.srcMd || videoSource.dataset.src : videoSource.dataset.srcSm || videoSource.dataset.src;
        }
    }
}
/**
 * @description Lazyload PDP video
 */
function initVideos() {
    const videos = document.querySelectorAll('.js-pdp-video')
    if (!videos) return
    const lazyVideos = [].slice.call(videos)

    if ('IntersectionObserver' in window) {
        const lazyVideoObserver = new IntersectionObserver((entries) => { // eslint-disable-line
            entries.forEach((videoContainer) => {
                const {target} = videoContainer,
                    media = target.querySelector('.js-pdp-video__video')
                if ($(target).closest('#QuickViewDialog').length !== 0) return
                if (videoContainer.isIntersecting) {
                    if (!media.currentSrc) {
                        selectSource(media);
                        media.load()
                    }
                    lazyVideoObserver.unobserve(target);
                }
            })
        },{
            rootMargin: '80px',
            threshold: 0.4
        })

        lazyVideos.forEach((lazyVideo) => {
            lazyVideoObserver.observe(lazyVideo)
            initVideoButton(lazyVideo)
        })
    } else {
        lazyVideos.forEach((lazyVideo) => {
            const media = lazyVideo.querySelector('.js-pdp-video__video')
            selectSource(media)
            media.load()
            initVideoButton(lazyVideo)
        })
    }
}

/**
 * @description Initialize PDP video with play/pause button
 */
function initVideoButton(player) {
    const media = player.querySelector('.js-pdp-video__video'),
        button = player.querySelector('.js-pdp-video__play-button'),
        buttonText = player.querySelector('.js-pdp-video__play-button-text')
    
    // videos are set to autoplay but we can't assume they will automatically (iOS safari won't autoplay in low power mode)
    // updates the playing class with the play event
    media.addEventListener('play',() => {
        buttonText.innerHTML = 'Pause'
        player.classList.add('pdp-video--playing')
    })
    
    button.addEventListener('click', () => {
        if (media.paused === true) {
            media.play()
            buttonText.innerHTML = 'Pause'
            player.classList.add('pdp-video--playing')
        } else {
            media.pause()
            buttonText.innerHTML = 'Play'
            player.classList.remove('pdp-video--playing')
        }
    })
}

module.exports.init =  function() {
    initVideos()
}
