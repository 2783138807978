'use strict';

/**
 * einsteinObserver
 * Initialize an observer for adding a callback to Einstein DOM element injection.
 * @param {string} selector - the selector to observe for changes
 * @param {function} fn - the callback for when changes are found
 */
var einsteinObserver = function (selector, fn) {
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
    var einsteinSelector = '[id^="cq_recomm_slot"]';
    var elements = window.document.querySelectorAll(selector);
    var check = function (mutationList, observer) {
        if (mutationList.length) {
            // if new child detected, disconnect observer and call callback
            observer.disconnect();
            fn.call();
        }
    };

    var initializeElement = function (element) {
        var einsteinElement = element.querySelector(einsteinSelector);
        if (einsteinElement === null) {
            // not an einstein recommender slot
            if (element.childElementCount > 0) {
                // if child, call callback immediately
                fn.call();
            }
        } else if (einsteinElement.childElementCount > 0) {
            // if child, call callback immediately
            fn.call();
        } else {
            // if no child, attach observer and wait for child to be added
            var observer = new MutationObserver(check);
            observer.observe(einsteinElement, {childList: true});
        }
    };

    // check each element for child
    for (var i = 0; i < elements.length; i++) {
        initializeElement(elements[i]);
    }
};

module.exports = {einsteinObserver: einsteinObserver};
