'use strict';

var billing = require('./pages/checkout/billing')
var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    ccAmex: /^([0-9]){4}$/,
    ccVisa: /^([0-9]){3}$/,
    password: new RegExp(SitePreferences.CUSTOMER_PASSWORD_REGEX), // previous value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
    noSpecialChars: /^[a-zA-Z ,.'-]+$/,
    noSpecialCharsAddNumbers: /^[a-zA-Z0-9 ,.'-]+$/,
    email: /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/
};
// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    errorPlacement: function ($error, $element) {
        $error.attr('role', 'status');
        var $selectStyle = $element.parent('.select-style');
        if ($selectStyle.length) {
            $selectStyle.addClass('select-style-error').after($error);
        } else if ($element.attr('type') === 'checkbox' || $element.attr('type') === 'radio') {
            var $label = $element.next('label');

            if ($label.length) {
                $label.after($error);
            } else {
                $element.after($error);
            }
        } else {
            $element.after($error);
        }
    },
    ignore: '.suppress, :hidden',
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
    }
};
/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var country = $(el).closest('form').find('.country:input');
    if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.phone[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

/**
 * @function
 * @description get CC type (visa/mastercard/etc) by using jQuery plugin http://jquerycreditcardvalidator.com/ (version 1.10.0)
 */
function detectCardType(value, element) {
    //in case customer already submit payment and move back to edit CC - CC number will contain masked value e.g. ************1111
    if (value.match(/^\*{11,12}/)) {
        return true;
    }
    var ccField = $(element);
    var result = ccField.validateCreditCard();

    if (result.valid) {
        billing.updateCcType(result.card_type.name);
        return true;
    } else {
        return false;
    }
}

/**
 * @function
 * @description Validates a given cvv
 * @param {String} value The cvv which will be validated
 * @param {String} el The input field
 */
var validateCVV = function (value, el) {
  //in case customer already submit payment and move back to edit CC - CVV number will contain masked value e.g. ***
    if (value.match(/^\*{3,4}/)) {
        return true;
    }
    var ccTypeField = $(el).closest('form').find('select[name$="_creditCard_type"]');
    var ccType = ccTypeField.val();

    var rgx = ccType == 'Amex' ? regex.ccAmex : regex.ccVisa;
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

$.validator.addMethod('ccnumber', detectCardType, Resources.VALIDATE_CREDICARD);

$.validator.addMethod('cvv', validateCVV, Resources.VALIDATE_CREDICARDCVV);

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates a name to ensure no special characters
 * @param {String} value The name string which will be validated
 * @param {String} el The input field
 */
var noSpecialChars = function (value, el) {
    var isValid = regex.noSpecialChars.test($.trim(value));
    var isOptional = this.optional(el);
    return isOptional || isValid;
};

var noSpecialCharsAddNumbers = function (value, el) {
    var isValid = regex.noSpecialCharsAddNumbers.test($.trim(value));
    var isOptional = this.optional(el);
    return isOptional || isValid;
};

$.validator.addMethod('firstname', noSpecialChars, Resources.VALIDATE_NOSPECIALCHARACTERS);
$.validator.addMethod('firstName', noSpecialChars, Resources.VALIDATE_NOSPECIALCHARACTERS);
$.validator.addMethod('lastname', noSpecialChars, Resources.VALIDATE_NOSPECIALCHARACTERS);
$.validator.addMethod('lastName', noSpecialChars, Resources.VALIDATE_NOSPECIALCHARACTERS);
$.validator.addMethod('pickupFirstName', noSpecialChars, Resources.VALIDATE_NOSPECIALCHARACTERS);
$.validator.addMethod('pickupLastName', noSpecialChars, Resources.VALIDATE_NOSPECIALCHARACTERS);
$.validator.addMethod('no-special-chars', noSpecialChars, Resources.VALIDATE_NOSPECIALCHARACTERS);
$.validator.addMethod('no-special-chars-add-numbers', noSpecialCharsAddNumbers, Resources.VALIDATE_NOSPECIALCHARACTERS);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * password check on loyalty modal with different message
 */
$.validator.addMethod('password-use', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (value.length >= parseFloat($(el).attr('minlength')));
    if (isValid) {
        isValid = regex.password.test(value);
    }
    return isOptional || isValid;
}, function(value, el) {
    var $this = $(el),
        $val = $this.val();
    if ($val.length >= parseFloat($this.attr('minlength')) && !regex.password.test($val)) {
        return Resources.VALIDATE_PASSWORD_VALUE;
    }
    return $.validator.format(Resources.VALIDATE_MINLENGTH2, $(el).attr('minlength') , $(el).val().length);
});

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

/**
 * validate postal code.
 */
$.validator.addMethod('postalcode', function (value) {
    var postalcodeRegex = /(^\d{5}(-\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/;
    var isValid = postalcodeRegex.test(value);
    if (isValid && value && !isNaN(value)) {
        isValid = Number(value.trim()) > 500;
    }
    return isValid;
}, Resources.INVALID_POSTAL_CODE);

/**
 * validate email.
 */
$.validator.addMethod('email', function (value) {
    var isValid = regex.email.test(value);
    return isValid;
}, Resources.VALIDATE_EMAIL);

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
        $('select[name$="_state"], .select-update-style').on('change', function () {
            if ($(this).val()==='') {
                $(this).parent().addClass('select-style-error');
            } else {
                $(this).parent().removeClass('select-style-error');
            }
        })

    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;
