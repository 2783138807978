'use strict';

var dialog = require('./dialog'),
    validator = require('./validator');

function positionWidget() {
    $('.emailus-widget').position({
        my: 'center',
        at: 'center',
        of: window
     });
}

exports.init = function () {
    $('body').on('click', '.email-us-dialog', function () {
        var $this = $(this),
            url = $this.data('url');
        if (url) {
            dialog.open({
                url: url,
                options: {
                    width: 636,
                    dialogClass: 'emailus-widget',
                    title: window.Resources.EMAILUS_WIDGET_TILE,
                    open: function () {
                        $('.emailus-widget').attr('data-step', 0);
                        $('.emailus-widget :input.required').eq(0).trigger('blur');
                        validator.init();
                    }
                }
            });
        }
    });

    $('body').on('change', '.emailus-subject select', function () {
        $('.emailus-subject-content [data-item]').addClass('hide');
        if ($(this).val() === '') {
            $('.emailus-subject .form-row').addClass('default');
        } else {
            $('.emailus-subject .form-row').removeClass('default');
        }
        if (!$('.emailus-form').is(':visible')) {
            $('.emailus-subject-content [data-item="'+ $(this).val() +'"]').removeClass('hide');
            $('.emailus-actions .emailus-done').removeClass('hide');
        } else {
            $('.emailus-actions .emailus-done').addClass('hide');
        }
        positionWidget();
    }).on('click', '.emailus-continue', function (e) {
        if ($('.emailus-widget select[name$="emailus_subject"]').val() !== "") {
            e.preventDefault();
            $('.emailus-widget').attr('data-step', 1);
            $('.emailus-subject-content, .emailus-continue, .emailus-done').addClass('hide');
            $('.emailus-form, .emailus-submit').removeClass('hide');
            window.recaptchaOnload();
            positionWidget();
        }
    }).on('click', '.emailus-actions .emailus-done', function (e) {
        e.preventDefault();
        dialog.close();
    });

    $('body').on('submit', '.emailus-widget form', function (e) {
        e.preventDefault();
        var $form = $(this),
            $action = $('.emailus-widget .emailus-submit');
        $('.emailus-actions input[type="hidden"]').attr({
            name: $action.attr('name'),
            value: $action.attr('value')
        });
        if ($('.g-recaptcha-response').length && $('.g-recaptcha-response').val() === '') {
            return false;
        }
        if ($form.attr('action')) {
            var data = $form.serialize();
            $.ajax({
                url: $form.attr('action'),
                data: data,
                type: $form.attr('method'),
                dataType: 'json',
                contentType: 'application/json',
                success: function (data) {
                    if (data.success) {
                        $('.emailus-widget .dialog-content').html('<div class="emailus-success">' + data.msg + '</div>');
                        $('.emailus-widget').addClass('success');
                        positionWidget();
                    } else if (data.recaptchaError) {
                        $('.recaptcha-error').html(data.msg);
                    }

                    if ($('.g-recaptcha-response').length) {
                        window.grecaptcha.reset();
                        $('.g-recaptcha-response').val('');
                    }
                }                
            });
        }
    });
};
