'use strict';

/**
 * @description updates product SEO data after BV ratings load
 */
function init() {
    if ($('#seoProductData').length > 0) {
        $('#seoProductData').html(updateBvReviewsData(JSON.parse($('#seoProductData').html())));
    }
}

function updateBvReviewsData(seoContent) {
    if ($('.BVRRContentReview').length > 0) {
        var reviews = [],
            reviewObj,
            reviewRatingObj;
            
        $('.BVRRContentReview').each(function() {
            reviewObj = {
                '@type': Resources.SEO_TYPE_REVIEW
            };
            reviewRatingObj = {
                '@type': Resources.SEO_TYPE_RATING
            };
            reviewObj.author = $(this).find('span[itemprop="author"]').html();
            reviewObj.datePublished = $(this).find('[itemprop="datePublished"]').attr('content');
            reviewObj.description = $(this).find('div[itemprop="description"]').children().text();
            reviewObj.name = $(this).find('span[itemprop="name"]').html();
            reviewRatingObj.bestRating = $(this).find('span[itemprop="bestRating"]').html();
            reviewRatingObj.ratingValue = $(this).find('span[itemprop="ratingValue"]').html();
            reviewRatingObj.worstRating = '1';
            
            reviewObj.reviewRating = reviewRatingObj;
            reviews.push(reviewObj);
        });
        seoContent.review = reviews;
        return JSON.stringify(seoContent);
    }
}

module.exports.init =  function() {
    // wait for BV ratings to load
    var count = 0;
    var interval = setInterval(function() {
        if ('BVRRisLoaded' in window && window.BVRRisLoaded) {
            init();
            clearInterval(interval);
        } else {
            count++;
            if (count > 10) {
                clearInterval(interval);
            }
        }
    }, 500);
}
