/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    backtotop = require('./backtotop'),
    blog = require('blog/pages/blog/index'),
    minicart = require('./minicart'),
    modules = require('./modules'),
    page = require('./page'),
    rating = require('./rating'),
    wlStatus = require('./wishlist_status'),
    loginmodal = require('./loginmodal'),
    registrationmodal = require('./registrationmodal'),
    searchplaceholder = require('./searchplaceholder'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    giftcert = require('./giftcert'),
    loyaltymodal = require('./loyaltymodal'),
    omniture = require('omniture/omniture/init'),
    catlanding = require('./cat-landing'),
    sizechart = require('./sizechart'),
    storeinventory = require('./storeinventory'),
    iparcel = require('./iparcel'),
    multisell = require('./multisell'),
    productListing = require('./product-listing'),
    showpass = require('./show-pass'),
    registration = require('./pages/registration'),
    backroomCountdown = require('./backroomCountdown'),
    dialog = require('./dialog'),
    einsteinObserver = require('./einstein').einsteinObserver,
    qtySelector = require('./quantitySelect'),
    lightbox = require('./lightbox'),
    shopTheLook = require('./shopTheLook'),
    emailusWidget = require('./emailusWidget'),
    quickview = require('./quickview'),
    dualGenderNav = require('./dualGenderNav');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();
require('picturefill');
require('./carousel')();
require('./customSelect')();

/**
 * Header menu init
*/
function headerMenuInit() {
    if (!util.isMobileSize()) {
        $('.menu-category-container').accessibleMegaMenu({
            uuidPrefix: 'accessible-megamenu',
            menuClass: 'nav-menu',
            topNavItemClass: 'nav-item',
            panelClass: 'level-2',
            panelGroupClass: 'sub-nav-group',
            hoverClass: 'hover',
            focusClass: 'focus',
            openClass: 'open',
            openDelay: 150,
            closeDelay: 450
        });
        $('.nav-menu .has-third-menu > a').removeClass('has-sub-menu');
    } else {
        $('.nav-menu .has-third-menu > a').addClass('has-sub-menu');
    }
}
/**
 * My Account nav menu init
*/
function mobileAccountNav() {
    util.smartResize(function() {
        util.move(util.isDesktopSize(), '.page-title', '.account-mobile-nav-container', '.desktop-page-title-container');
        if (!util.isDesktopSize()) {
            $('.account-mobile-nav-container').empty();
        }
    })
}


/**
 * add href so shipto and flag links will be ready with keyboard navigate
*/
function addLinkShipTo() {
    $('.country-selector .iparWelcome').attr('href', 'javascript:void(0)');
    $('.country-selector .iparWelcome').attr('title', Resources.Ship_TO_TEXT);
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();

    // Make sure that the SVGs work properly in older browsers
    svg4everybody();

    // Back to top button for Homepage, PDP, Grid
    if(!window.SitePreferences.DENIM_CHAT){
        backtotop();
    }else{
        if(!window.location.href.includes("jeans","denim","curvy","womens")){
            backtotop();
        }
    }
    // Show/hide input password
    showpass();
    //Size Chart init
    sizechart();

    addLinkShipTo();

}
function homepageAddToWishlist() {
    if ($('.pt_storefront').length > 0 || $('.pt_wish-list').length > 0) {
        wlStatus.init();
    }
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    productfinder: require('./pages/productfinder'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    orderconfirmation: require('./pages/orderconfirmation'),
    storelocator: require('./pages/storelocator')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        headerMenuInit();

        // init specific global components
        modules.init();
        countries.init();
        tooltip.init({
            position: {
                my: 'right-25px center',
                at: 'left-25px center'
            }
        });
        minicart.init();
        validator.init();
        rating.init();
        mobileAccountNav();
        searchplaceholder.init();
        catlanding.init();
        productListing.init();
        loginmodal.init();
        registrationmodal.init();
        backroomCountdown.init();
        giftcert.init();
        qtySelector.init();
        lightbox.init();
        shopTheLook.init();
        emailusWidget.init();

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        if (ns === 'product') {
            $('body').on('init:producttile', function() {
                pages.search.productTile.init();
            });
            $('body').on('init:lazyload', function() {
                pages.search.intiLazyloadPlpProducts();
            });
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }
        loyaltymodal.init();
        omniture.init();
        blog.init();
        storeinventory.init();
        homepageAddToWishlist();
        iparcel.init();
        multisell.init();
        registration.init();

        if (typeof AppleID !== 'undefined') {
            AppleID.auth.init({
                clientId : SitePreferences.APPLE_LOGIN_CLIENT_ID,
                scope : SitePreferences.APPLE_LOGIN_SCOPE,
                redirectURI: SitePreferences.APPLE_LOGIN_REDIRECT_URL,
                state : SitePreferences.APPLE_LOGIN_STATE
            });
        }

        $('body').on('init:appleAuth', function () {
            AppleID.auth.init({
                clientId : SitePreferences.APPLE_LOGIN_CLIENT_ID,
                scope : SitePreferences.APPLE_LOGIN_SCOPE,
                redirectURI: SitePreferences.APPLE_LOGIN_REDIRECT_URL,
                state : SitePreferences.APPLE_LOGIN_STATE
            });
        });

        $('.product-usd').removeClass('hide-USD');

        $(function() {
            const queryString = window.location.search;
            const urlParams = new window.URLSearchParams(queryString);
            const quizStart = urlParams.get('quiz-start');
            const quizId = urlParams.get('quiz-id');
            if (quizStart === 'yes') {
                if (quizId !== null) {
                    pages.productfinder.launch({quizID: quizId});
                } else {
                    pages.productfinder.launch();
                }
                isInitializedFitfinderQuiz = true;
            }
        });
        /* eslint-disable */
        var isInitializedFitfinderQuiz = false;
        $(document).on('click', '.productfinder-launch', function(e) {
            e.preventDefault();
            pages.productfinder.launch();
            isInitializedFitfinderQuiz = true;
        });
        /* eslint-enable */
        $(document).on('click', '.resale-show-modal', function(e) {
            e.preventDefault();
            $.ajax({
                url: $(this).attr('href'),
                success: function(data) {
                    // if no element found, create one
                    var $target = $('#dialog-container-resale');
                    if ($target.length === 0) {
                        if ($target.selector && $target.selector.charAt(0) === '#') {
                            var id = $target.selector.substr(1);
                            $target = $('<div>').attr('id', id).addClass('dialog-content').appendTo('body');
                        }
                    }
                    $target.html(data);
                    $target.dialog({
                        modal: true,
                        width: '375',
                        dialogClass: 'resale-dialog',
                        position: {
                            my: 'center',
                            at: 'center',
                            of: window,
                            collision: 'flipfit'
                        }
                    }).dialog('open');
                }
            });
        });
        $(document).on('click', '.afterpay-link-tag', function(e) {
            e.preventDefault();
            $.ajax({
                url: $(this).attr('href'),
                success: function(data) {
                    dialog.open({
                        html: data,
                        options: {
                            dialogClass: 'afterpay-dialog'
                        },
                        callback: function () {
                            if ($('.pt_cart').length > 0) {
                                $('.afterpay-dialog').find('.learn-more-row').hide();
                            }
                            if (util.isMobileSize()) {
                                $(document).scrollTop(0);
                            }
                        }
                    });
                }
            });
        });

        // Scroll to the top of the page when the ESW country selector is clicked
        $(document).on('click keyup', '.footerDropdown', function (e) {
            if (e.keyCode !== 13) return;
            $('body, html').animate({
                scrollTop: 0
            });
        /* set up a mutation observer to store Einstein rec path once they are populated in the DOM */
        });
        einsteinObserver('.cart-rec-container', function () { // adjust this selector to include ALL einstein slots
            // alert('An Einstein slot has been rendered');

        });

        // check the dual gender builder nav updates
        dualGenderNav();

        // send email us form
        if (window.jQuery) {
            jQuery(document).off('submit.email-signup').on('submit.email-signup', '[id=email-alert-signup]', function(e) {
                e.preventDefault();
                var $form = jQuery(this);
                var $email = $form.find('[type=email]');
                var email = $email.val();
                var gender = $form.find('[type=radio]:checked').val();
            
                jQuery.ajax({
                    type: 'POST',
                    url: '/on/demandware.store/Sites-madewellUS-Site/default/Subscribe-Footer?format=ajax&ng=true',
                    data: {
                        gender: gender,
                        email: email
                    },
                    success: function(){
                        var $error = $form.find('[id=email-alert-address-error]');
                        if (!$error.length) {
                            $error = jQuery('<span id="email-alert-address-error" class="error" role="status"></span>');
                        }
                
                        $error.html('Success').css({
                            color: 'green'
                        });
                        $email.after($error);
                        setTimeout(function() {
                            $error.remove();
                        }, 5000)
                    }
                });
            });
        }

        $(document).on('click', '[id^="cq_recomm_slot"] .grid-tile .product-tile', function() {

            util.createCookie('recommendation_source', 'einstein');


            var recomenderNumber = 0;

            if (window.pageContext.ns == 'cart') {
                var element = $(this).closest('.product-listing');
                util.createCookie('recommendation_path', element.data('recommender'));
            } else {
                var recommender = null;
                var cqViewReco = window.sessionStorage.getItem('cq.viewReco');
                if (cqViewReco !== null) {
                    var cqViewRecoJSON = JSON.parse(cqViewReco);
                    if (typeof cqViewRecoJSON === 'object' && typeof cqViewRecoJSON[recomenderNumber] === 'object' && cqViewRecoJSON[recomenderNumber].recommenderName !== null) {
                        recommender = cqViewRecoJSON[recomenderNumber].recommenderName;
                        util.createCookie('recommendation_path', recommender);
                    }
                }
            }
        });

        // detect Android device
        if (util.isMobile('android')) {
            $('html').addClass('android-device');
        }

        window.launchFitFinder = require('./pages/productfinder').launch;

        //Interaction Studio Updates
        var isMapping = $('#isStudioMapping').length > 0 ? $('#isStudioMapping').text() : '{}';
        var isEvents = require('./interaction-studio-events');
        isEvents.updatePageType(isMapping);
        isEvents.postLoadEvents();
        //IS Fit does not fire as an AJAX call.  Accounting for that here.
        $('.extended-sizing-tile').on('click', function() {
            var pid = $('#pid').val();
            var masterPid = $('#masterPid').val();
            isEvents.triggerPostVariationSelection(pid, masterPid, 'fit');
        });

        giftcert.initEvents();

        // fix 100vh problem with iOS
        const appHeight = () => {;
            document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
        };
        $(window).on('resize scroll', _.throttle(function () {
            appHeight();
        }, 200));

        $('body').on('trigger:quickview', function(e, url) {
            if (url) {
                quickview.show({
                    url: url,
                    source: 'quickview',
                    format: 'ajax'
                });
            }
        });

        var handleHeightsOfSignin = function(stage) {
            $('.sign-in-out .signinout-body-container').height($('.sign-in-out .signinout-tab[data-action="'+ stage +'"] .signinout-body-info').height());
            $('.sign-in-out .signinout-title,.sign-in-out .signinout-head, .sign-in-out .signinout-body').attr("data-action", stage);
            $('.sign-in-out .signinout-title').height($('.sign-in-out .signinout-tab-top[data-action="'+ stage +'"]').height());
        }

        $('body').on('click', '.sign-in-out .signinout-tab-head', function(e) {
            e.preventDefault();
            if ($(this).hasClass('disabled')) {
                return false;
            }
            $('.sign-in-out .signinout-tab-head').removeClass('active');
            $(this).addClass('active');

            handleHeightsOfSignin($(this).data('action'));

            $('.sign-in-out #appleid-signin').remove();
            $('.sign-in-out .apple-' + ($(this).data('action') === 'registration' ? 'register' : 'signin')).prepend('<div id="appleid-signin" data-method="apple"></div>');

            var uiDialog = $('.ui-dialog');
            if ($(this).data('action') === 'registration') {
                uiDialog.addClass('registration').removeClass('login-in');
            } else {
                uiDialog.removeClass('registration').addClass('login-in');
            }
            $('body').trigger('init:appleAuth');
        });

        $('body').on('blur', '.sign-in-out .signinout-body-info input', function(e) {
            var $stage = $(this).closest('.signinout-tab').data('action');
            setTimeout(function() {
                if ($('.sign-in-out .signinout-tab[data-action="'+ $stage +'"] .signinout-body-container').length) {
                    $('.sign-in-out .signinout-tab[data-action="'+ $stage +'"] .signinout-body-container').height($('.sign-in-out .signinout-tab[data-action="'+ $stage +'"] .signinout-body-info').height());
                }
            }, 200);
        });

        $('body').on('click', '.sign-in-out .ui-dialog-titlebar-close', function(e) {
            $('.sign-in-out, .ui-widget-overlay').fadeOut(500, function() {
                dialog.close();
            });
        });

        appHeight();
        $('body').on('dialog:reposition', function() { 
            $('.ui-dialog').position({
                my: "center",
                at: "center",
                of: window
            });
        });
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
});
