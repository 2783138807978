'use strict';

var _ = require('lodash'),
    smallBreakpoint = 320,
    mediumBreakpoint = 480,
    largeBreakpoint = 768,
    desktopBreakpoint = 1025,
    menuBreakpoint = 1200,
    maxBreakpoint = 1280;

var util = {
    /**
    * @desc Media breakpoints that are used throughout the Javascript
    */
    breakpoints: {
        xs: smallBreakpoint,
        sm: mediumBreakpoint,
        md: largeBreakpoint,
        lg: desktopBreakpoint,
        xl: maxBreakpoint,
        'mobile-menu': menuBreakpoint,
        small: smallBreakpoint,
        medium: mediumBreakpoint,
        large: largeBreakpoint,
        menu: menuBreakpoint,
        desktop: desktopBreakpoint
    },
    /**
     * @function
     * @description Returns either an object with all of the available viewports or a specific viewport based on the given size
     * @param {String} size The viewport to return
     */
    getViewports: function (size, breakpoints) {
        const bps = typeof breakpoints !== 'undefined' ? breakpoints : this.breakpoints;

        if (typeof size !== 'undefined') {
            var viewport = bps[size];

            if (viewport) {
                return viewport;
            } else {
                window.console.error('Unexpected viewport size given in util.getViewports');
                throw 'Unexpected viewport size given in util.getViewports';
            }
        } else {
            return breakpoints;
        }
    },

    clearAddressFields: function($form) {
        $form.find('[name$="firstName"]').val('').trigger('change');
        $form.find('[name$="lastName"]').val('').trigger('change');
        $form.find('[name$="fullName"]').val('').trigger('change');
        $form.find('[name$="address1"]').val('').trigger('change');
        $form.find('[name$="address2"]').val('').trigger('change');
        $form.find('[name$="companyName"]').val('').trigger('change');
        $form.find('[name$="city"]').val('').trigger('change');
        $form.find('[name$="state"]').val('').trigger('change');
        $form.find('[name$="postal"]').val('').trigger('change');
        $form.find('[name$="phone"]').val('').trigger('change');
    },

    /**
     * @function
     * @description Returns the current viewport name (ex: 'medium') or 'max' if the current window is larger than any defined viewport width
     */
    getCurrentViewport: function () {
        var w = window.innerWidth;
        var viewports = util.getViewports();
        //traverse the object from small up to desktop, and return the first match
        _.each(viewports, function (value, name) {
            if (w <= value) {
                return name;
            }
        });
        return 'max';
    },

    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i] != '' && params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + (newParams.length > 0 ? '?' + newParams.join('&') : '') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        _.each(params, function (value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (!_.isString(url)) { return; }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key' || field === 'type') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field].toUpperCase());
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
        //multi line input character messages
        $('form').find('.classic-gift input:last').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG_MULTI,
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
      //shipping page multi line input character messages
        $('form').find('.gift-message-text input:last').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG_MULTI_SHIP,
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation) {
        $('html, body').animate({scrollTop: xLocation}, 500);
    },

    /**
     * @function
     * @desc Determines if the device that is being used is mobile
     * @param {String} id the specific agent string to test for. if null, function will return true for any mobile agent.
     * @returns {Boolean}
     */

    isMobile: function (id) {
        var mobileAgentHash = id ? [id] : ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },
    isMobileSize: function () {
        if (window.innerWidth <= menuBreakpoint - 1) {
            return true;
        } else {
            return false;
        }
    },
    isDesktopSize: function () {
        if (window.innerWidth <= desktopBreakpoint - 1) {
            return true;
        } else {
            return false;
        }
    },
    isIE11: function () {
        return !!window.MSInputMethodContext && !!document.documentMode;
    },

    /**
     * Relocate the given element to the given destination using the given method.
     *
     * Also accepts a rollback destination for back functionality
     * and dynamic width check for multiple viewport support.
     *
     * width === null -- disables the back functionality.
     *
     * @param int 		width (optional)
     * @param obj 		element
     * @param string 	destination
     * @param string 	rollBackDestination (optional)
     * @param string	moveType (optional)
     * @return 			VOID
     */
    move: function (width, element, destination, rollBackDestination, moveType) {
        var check = this.checkWidth(width);

        if (moveType === undefined) {
            moveType = 'appendTo';
        }

        if (check) {
            if (jQuery(element) !== undefined && jQuery(destination) !== undefined) {
                jQuery(element)[moveType](destination);
            }
        } else {
            if (jQuery(element) !== undefined && jQuery(destination) !== undefined && jQuery(rollBackDestination) !== undefined) {
                jQuery(element)[moveType](rollBackDestination);
            }
        }

        return;
    },
    /**
     * Checks if the document width is less than or equal to given width.
     * If it is, true
     * If it is not, false
     *
     * @param int 	width
     * @var bool 	check
     * @return bool
     */
    checkWidth: function (width) {
        var check = false;

        if (width === null || width == true) {
            check = true;
        }

        return check;
    },

    /**
     * Executes a callback function when the user has stopped resizing the screen.
     *
     * @param   {function}  callback
     * @return  {function}
     * @example util.smartResize(function() { do stuff }
     */
    smartResize: function (callback) {
        let windowWidth = $(window).innerWidth();

        $(window).on('resize', _.debounce(() => {
            if (windowWidth !== $(window).innerWidth()) {
                callback();
                windowWidth = $(window).innerWidth();
            }
        }, 100));
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints (optional)
     */
    mediaBreakpointUp: function (size, breakpoints) {
        const breakpoint = this.getViewports(size, breakpoints);
        const mediaQuery = window.matchMedia('(min-width: '+ breakpoint +'px)');
        return mediaQuery.matches;
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints object (optional)
     */
    mediaBreakpointDown: function (size, breakpoints) {
        const bps = typeof breakpoints !== 'undefined' ? breakpoints : this.breakpoints;
        const nextSize = this.getNextObjectKey(bps, size);

        if (typeof nextSize === 'string') {
            const breakpoint = this.getViewports(nextSize, breakpoints) - 1;
            const mediaQuery = window.matchMedia('(max-width: '+ breakpoint +'px)');
            return mediaQuery.matches;
        } else {
            return true;
        }
    },

    /**
     * @function
     * @desc Generates a min-width and max-width matchMedia media queries based on the given params
     * @param {string} sizeMin - Min breakpoint to use for the media query
     * @param {string} sizeMax - Max breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints object (optional)
     */
    mediaBreakpointBetween: function (sizeMin, sizeMax, breakpoints) {
        const min = this.mediaBreakpointUp(sizeMin, breakpoints);
        const max = this.mediaBreakpointDown(sizeMax, breakpoints);

        return min && max;
    },

    /**
     * @function
     * @desc Generates a min-width and max-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints object (optional)
     */
    mediaBreakpointOnly: function (size, breakpoints) {
        return this.mediaBreakpointBetween(size, size, breakpoints);
    },

    /**
     * @function
     * @desc Retrieves the next key in the object or null if it doesn't exist
     * @returns {string}|{null}
     */
    getNextObjectKey: function (obj, key) {
        const keys = Object.keys(obj);
        const nextIndex = keys.indexOf(key) + 1;

        if (keys.length > nextIndex) {
            return keys[nextIndex];
        } else {
            return null;
        }
    },

    /**
     * @function
     * @desc Retrieves the util breakpoints object
     * @returns {object}
     */
    getBreakpoints: function () {
        return this.breakpoints;
    },

    /**
     * Combines two objects without duplicating attributes
     *
     * - Attributes from src take priority over attributes in obj
     *
     * @param   obj     obj
     * @param   obj     src
     * @return  obj
     */
    extendObject: function (obj, src) {
        for (var key in src) {
            if (src.hasOwnProperty(key)) obj[key] = src[key];
        }
        return obj;
    },

    createCookie: function (name, value, days) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toGMTString();
        }
        document.cookie = name + '=' + value + expires + '; path=/';
    },

    readCookie: function (name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                try {
                    return window.atob(c.substring(nameEQ.length, c.length));
                } catch (e) {
                    window.console.log('util.js error: ' + e);
                }
            }
        }

        return null;
    },

    getCookie: function (cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },

    eraseCookie: function (name) {
        this.createCookie(name, '', -1);
    },
    getUrlParameter : function (url, name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(url);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },

    /**
     * @function
     * @desc Retrieves the height of the current sticky header, if stuck. Otherwise returns null.
     * @returns {number} the height of the sticky header element in pixels, or null
     */
    getStickyHeaderHeight: function () {
        const stickyHeaderElemSelector = '.top-banner';
        const $stickyHeader = $(stickyHeaderElemSelector);
        return $stickyHeader.outerHeight();
    },

    /**
     * @function
     * @desc Fallback functions for old browsers support. Copies a string to the clipboard
     * @param {string} text - text to be copied
     */
    fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement('textarea');
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
    },

    /**
     * @function
     * @desc Copies a string to the clipboard
     * @param {string} text - text to be copied
     */
    copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }

        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    },

    /**
     * @function
     * @desc Change address1 autocomplete "off" to "address-line1" 
     * @param {element} $address1Selector - address1 input from checkout pages
     */
    address1Observer($address1Selector) {
        //mutation Observer configuration
        var config = {attributes: true};

        var observer = new MutationObserver(function(mutationsList, observer) {
            for (var mutation of mutationsList) {            
                if (mutation.type == 'attributes' && mutation.attributeName == 'autocomplete') {
                    $address1Selector.attr('autocomplete', 'address-line1');
                    observer.disconnect();
                }
            }
        });

        //start observing changes on address1Selector using the configuration
        observer.observe($address1Selector[0], config);
    },
    
    /**
     * Add virtual_page_location parameter to page_view and push virual_page_view event
     */
    setVirtualPage: function () {
        var pageViewDataLayer = window.dataLayer.filter(pageView => pageView.event === 'page_view');
        pageViewDataLayer[0].virtual_page_location = window.location.href;
        window.dataLayer.push(pageViewDataLayer[0]);
        window.dataLayer.push({
            event: 'virtual_page_view',
            referrer: null
        });
    },

    setNotification: function (html, boolean = false) {
        var cross = boolean ? '<a href="javascript:void(0)" class="notification-close">X</a>' : '';
        var message = '<div class="notification-container"><div class="notification-message">'+html+'</div>'+cross+'</div>';
        $('body').append(message);
        setTimeout(() => {
            $(".notification-container").fadeOut("slow", "swing");
            $(".notification-container").remove();
        }, 5000);

        $(document).off('click', '.notification-close').on('click', '.notification-close', function () {
            $(".notification-container").remove();
        });
        $(document).off("click", ".notification-undo").on("click", ".notification-undo", function (){
            $(".add-to-cart-favorites-wrapper .wishlist-icon-wrap .add-to-favorites").trigger('click');
        });
    },
};

module.exports = util;
