'use strict';

module.exports = {
    init: function () {
        var backroomCountdownUrl = $('.backroom-countdown').attr('data-url');
        if (backroomCountdownUrl) {
            $.get(backroomCountdownUrl, function(data) {
                if (data.timestamp) {
                    $('.backroom-countdown__timer').countdown(new Date(data.timestamp), function(event){
                        $(this).text(
                            event.strftime('%-D DAYS : %-H HOURS : %-M MINUTES')
                        );
                    });
                    $('.backroom-countdown').show();
                }
            })
        }
    }
};
