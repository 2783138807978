'use strict';

const dialog = require('../dialog');
var quickview = require('../quickview'),
    util = require('../util'),
    isInitializedFitfinderQuiz = false;
var event = require('omniture/omniture/event');

/**
 * @private
 * @function
 * @description Initializes event listeners for the page
 */
function initializeEvents () {
    $(document).on('click', '.fit-finder-results .quickview', function (e) {
        e.preventDefault();
        var $btn = $(this);
        var url = $(this).attr('href');
        quickview.show({
            url: url,
            source: 'quickview',
            progress: $btn,
            position: 0
        });

        // do not use smartResize as we always want this to trigger
        $(window).one('resize', function() {
            $('#QuickViewDialog').parent('.ui-dialog').position({
                my: 'center',
                at: 'center',
                collision: 'fit',
                of: window
            });
            if ($(this).dialog.isOpen === true) {
                $(this).dialog('close');
            }
        });
    });

    // handle start quiz click
    $(document).on('click', '.fit-finder-start-quiz-button', function (e) {
        e.preventDefault();
        if ($(this).closest('.ui-dialog').length) {
            nextPage();
        } else {
            // not in a dialog yet, so launch dialog now
            launchFitFinderDialog({quizID:'fitfinder-v1', step: 1});
            $(this).closest('.fit-finder-page').remove();
        }
    });

    // handlle back button click
    $(document).on('click', '.fit-finder-back-button', function () {
        prevPage();
    });

    // handlle close dialog button click
    $(document).on('click', '.fit-finder-close-button', function () {
        dialog.close();
    });

    // handlle back button click
    $(document).on('click', '.skip-it-now', function (e) {
        e.preventDefault();
        $(this).closest('.fit-finder-questions').next('.fit-finder-submit').trigger('click');
    });

    // handlle skip button click
    $(document).on('click', '.skip-current-choice', function (e) {
        e.preventDefault();
        var $thisSegment = $(this).closest('.fit-finder-step-segment');
        activateNextSegment($thisSegment);
    });

    // handle select box dropdown interaction
    $(document).on('change', 'select.fit-finder-selections', function () {
        $(this).find('option[value="default"]').remove();
        var $selected = $(this).find('option:selected');
        $selected.attr('selected', 'selected');
        $selected.siblings().removeAttr('selected');
        selectOption($selected);
    });

    // handle a fit-finder-selection when it is clicked
    $(document).on('click', '.fit-finder-selection' , function (e) {
        e.preventDefault();
        selectOption($(this));
    });

    // handle answer submissions
    $(document).on('submit', '.productfinder-quiz-form', function (e) {
        var $form = $(this);
        var $resultsUrl = $form.data('resultsurl');
        var $quizPage = $form.closest('.fit-finder-page');
        var $submitButton = $form.find('button[type="submit"]');

        if ($form.parent('.fit-finder-page').hasClass('active')) {

            if (!$form.valid()) {
                return false
            }

            // if stepnumber is not equal to totalsteps, this should be an AJAX submit
            e.preventDefault();

            $.ajax({
                type: 'POST',
                url: util.appendParamToURL($form.attr('action'), 'format', 'ajax'),
                data: $form.serialize()
            })
            .done(function (data) {
                if (data) {
                    //last step number should still deliver payload to FtFinder-Answer to log answer.
                    // Response for final answer is to be redirected to results controller.
                    if ($submitButton.hasClass('final-question') || $quizPage.data('stepnumber') === $quizPage.data('totalsteps')) {
                        window.location.href = $resultsUrl;
                    } else {
                        $quizPage.after(data);
                        nextPage();
                    }
                }
            });
        }
    });

    // RESULTS PAGE
    var segmentDelay = 1500;
    var $resultsLoader = $('.fit-finder-results-loader');
    var $loaderSegments = $resultsLoader.find('.fit-finder-segment');

    // if we are on the results page
    if ($resultsLoader.length) {
        // lock the background from scrolling until the loader is closed
        $(document).trigger('freezeBackground');

        // in the Results page, make each segment in the loader content asset fade into view sequentially
        $loaderSegments.each(function (i, el) {
            var isLast = (i === $loaderSegments.length - 1);
            setTimeout(function () {
                activateSegment($(el), true);
                if (isLast) {
                    $('.fit-finder-results-loader-finalizing').removeClass('active');
                }
            }, i * segmentDelay);
        });

        // in the Results page, handle closing the initial loader modal
        $(document).on('click', '.fit-finder-results-loader .fit-finder-submit', function (e) {
            e.preventDefault();
            $(document).trigger('unfreezeBackground');
            var $loader = $(this).closest('.fit-finder-results-loader');
            $loader.addClass('-transition-out');
            setTimeout(function () {
                $loader.removeClass('active -transition-out');
                $('.fit-finder-results.fit-finder-page').addClass('active');
            }, 500);
        });
    }

    // process a fitfinder answer selection
    function selectOption(element) {
        var $this = element;
        $this.toggleClass('selected');

        // update hidden input value based on all selected answers
        var selectionValue = '';
        if ($this.parents('.fit-finder-step-segment').data('ux_answermultiselect') === true) {
            // change selectionValue to a pipe-delimited array of all selected values
            var $allSelectedOptions = $this.parents('.fit-finder-step-segment').find('.fit-finder-selection.selected');
            $allSelectedOptions.each(function (i) {
                if (i > 0) selectionValue += '|';
                selectionValue += $(this).data('value');
            });
        } else {
            $this.siblings().removeClass('selected');
            selectionValue = $this.is('.selected') ? $this.data('value') : '';
        }
        $('input[name$="' + $this.data('questionid') + '"]').val(selectionValue);

        // update segment's validity, and submit "Next" button enabled/disabled
        valitateOptionSelected($this);

        if ($('.fit-finder-page.active').data('ux_showcta') !== true){
            // No call to action.  Submit form on selection.
            $this.closest('.productfinder-quiz-form').trigger('submit');
        }
    }

    // determine whether the selected value(s) make this answer valid / whether to enable or disable the form submit button
    function valitateOptionSelected(element) {
        var $this = element;
        var $thisSegment = $this.closest('.fit-finder-step-segment');
        var $thisPage = $this.closest('.fit-finder-page');
        var $submitButton = $thisPage.find('.fit-finder-submit');
        var answerRequired = $thisSegment.is('[data-ux_answerrequired="true"]');
        // var multiSelect = $thisSegment.is('[data-ux_answermultiselect="true"]');
        if (answerRequired && $thisSegment.find('.fit-finder-selection.selected, .fit-finder-selection[selected="selected"]').length === 0) {
            // disable submit CTA
            $thisSegment.addClass('invalid');
            $submitButton.removeClass('enable').attr('disabled', 'disabled');
        } else {
            // enable submit CTA
            $thisSegment.removeClass('invalid');
            activateNextSegment($thisSegment);
            if ($thisPage.find('.fit-finder-step-segment.invalid').length === 0) {
                $submitButton.addClass('enable').removeAttr('disabled');
            }
        }

    }

    isInitializedFitfinderQuiz = true;
}
/**
 * @private
 * @function launchFitFinderDialog
 * @description launches the Fit Finder quiz into a new dialog. NOTE: this function is exposed to the window object!
 * @param {Object} options optional parameters
 * @param {String} options.url optional param to set the URL to be loaded into the dialog.  Default is FitFinder-Show.
 * @param {String} options.quizID optional param to set the Quiz ID to load.
 * @param {String} options.step optional param to set the starting step for the quiz.
 */
function launchFitFinderDialog (options) {
    var url = Urls.fitFinderLaunch;
    var quizStep = 1;
    if (options && options.url && typeof options.url === 'string') {
        url = options.url;
    }
    if (options && options.quizID && typeof options.quizID === 'string') {
        url = util.appendParamToURL(url, 'quizID', options.quizID);
    } else {
        url = util.appendParamToURL(url, 'quizID', 'fitfinder-v1');
    }
    if (options && options.step && typeof options.step === 'number') {
        quizStep = options.step;
    }
    url = util.appendParamToURL(url, 'step', quizStep);
    url = util.appendParamToURL(url, 'format', 'ajax');

    // Remove any existing dialogs (including the results page loader animation)
    $(document).find('.fit-finder-dialog, .fit-finder-dialog-content').remove();

    // Handle dialog open initial actions
    $(document).on('dialogopen', '.fit-finder-dialog', function () {
        // even if we're on step 1, we want the landing page to show, so pass 0
        if (quizStep === 1) quizStep = 0;

        $(this).attr('data-quizstep', quizStep);
        var totalSteps = $(this).find('[data-totalSteps]').attr('data-totalSteps');
        $(this).attr('data-totalquizsteps', totalSteps);
        if (!isInitializedFitfinderQuiz) initializeEvents();
        isInitializedFitfinderQuiz = true;
        setTimeout(function() {
            $('body').trigger('resize');
        },500);
        var elements = document.getElementsByClassName('fit-finder-landing__hed')
        if (elements.length > 0) {
            window.s.pageName = document.getElementsByClassName('fit-finder-landing__hed')[0].innerText;
        }
        event.pushEVar('', '');

        // Freeze ability to scroll background when dialog is open
        $(document).trigger('freezeBackground');
    });

    // Prepare background to unfreeze ability to scroll once dialog is closed
    $(document).on('dialogclose', '.fit-finder-dialog', function () {
        $(document).trigger('unfreezeBackground');
    });

    // Launch Fit Finder dialog
    dialog.open({
        url: url,
        options: {
            classes: {
                'ui-dialog': 'fit-finder-dialog',
                'ui-dialog-content': 'fit-finder-dialog-content'
            }
        }
    });
}

/**
 * @private
 * @function deactivateSegment
 * @description Hide segment in case it should not be displayed for specific selected value
 * @param {jQuery} $el the segment element to deactivate
 * @param {Boolean} unselectValue whether to unselect previously selected values for current segment
 * @returns Boolean - true if deactivation was successful, false if not
 */

function deactivateSegment ($el, unselectValues) {
    var $segment = $el;
    if ($segment.length > 0 && $segment.hasClass('active')) {
        $segment.addClass('-transition-out');

        setTimeout(function () {
            $segment.removeClass('active').removeClass('-transition-out');
        }, 500);

        if (unselectValues) {
            var questionId = $el.data('questionid');
            var $segmentSelectOptions = $segment.find('.fit-finder-selection[data-questionid="' + questionId +'"]');
            var $segmentInput = $segment.find('input[name="' + questionId +'"]');
            if ($segmentSelectOptions.length) {
                $segmentSelectOptions.removeClass('selected');
            }
            if ($segmentInput.length) {
                $segmentSelectOptions.val('');
            }
        }
        return true;
    }
    return false;
}

/**
 * @private
 * @function activateNextSegment
 * @param {jQuery} $el the current segment: the starting point from which to find the "next" segment in the DOM
 * @returns Boolean - true if activation was successful, false if not
 */

function activateNextSegment ($el) {
    var $nextSegment = $('.fit-finder-step-segment').eq($('.fit-finder-step-segment').index($el) + 1);
    var scrollToNext = !($el.data('ux_answermultiselect'));
    if ($nextSegment.data('showonlyfor')) {
        var showForSeletionValue = $nextSegment.data('showonlyfor');
        var $elementToShowOnlyFor = $('.fit-finder-selection[data-value=' + showForSeletionValue + ']');

        if ($elementToShowOnlyFor.length && !$elementToShowOnlyFor.hasClass('selected')) {
            // skip segment and show next one
            deactivateSegment($nextSegment, true);
            $nextSegment = $('.fit-finder-step-segment').eq($('.fit-finder-step-segment').index($el) + 2);
        }
    }

    return activateSegment($nextSegment, scrollToNext);
}

/**
 * @private
 * @function activateSegment
 * @description Fades the provided segment into view over a certain amount of time
 * @param {jQuery} $el the segment element to activate
 * @param {Boolean} scroll whether to attempt to scroll the viewport to show the next segment upon activation
 * @returns Boolean - true if activation was successful, false if not
 */

function activateSegment ($el, scroll) {
    var $segment = $el;
    if ($segment.length > 0 && !$segment.hasClass('active') && !$segment.hasClass('-transition-in')) {
        $segment.addClass('-transition-in');
        // test whether the full segment is visle in the viewport. if not, scroll to it.
        if (scroll && !elementInViewport($segment)) {
            $segment.closest('.fit-finder-dialog-content')[0].scroll({
                top: $segment.offset().top,
                behavior: 'smooth'
            });
        }
        setTimeout(function () {
            $segment.addClass('active').removeClass('-transition-in');
        }, 200);
        return true;
    }
    return false;
}

/**
 * @private
 * @function nextPage
 * @description switches the currently active "page" visible content to the next sibling page, or to to the given page, if provided
 * @param {jQuery} $next jQuery obect representing the page to active. Optional.
 */
function nextPage ($next) {
    var $quizPage = $('.fit-finder-page.active');
    var $nextPage = $next || $quizPage.next('.fit-finder-page');
    if ($nextPage.length) {
        var $dialog = $quizPage.closest('.fit-finder-dialog');
        var newStepNumber = $nextPage.data('stepnumber');
        $('.fit-finder-logo').addClass('hide-logo');
        $dialog.attr('data-quizstep', newStepNumber);
        $quizPage.removeClass('active').addClass('-transition-out');
        $nextPage.addClass('-transition-in');
        setTimeout(function () {
            $quizPage.removeClass('-transition-out');
            $nextPage.addClass('active').removeClass('-transition-in');
            document.getElementById('dialog-container').scrollTop = 0;
            $('body').trigger('resize');
        }, 500);
        window.s.pageName = $nextPage.data('pagename')
        event.pushEVar('', '');
    }
}

/**
 * @private
 * @function prevPage
 * @description switches the currently active "page" visible content to the previous sibling page, or to to the given page, if provided
 * @param {jQuery} $prev jQuery obect representing the page to active. Optional.
 */
function prevPage ($prev) {
    var $quizPage = $('.fit-finder-page.active');
    var $currStep = $quizPage.data('stepnumber');
    var $prevPage = $prev || $quizPage.prev('.fit-finder-page');
    var prevStep = $currStep - 1;
    if ($currStep > 1) {
        $prevPage = $('.fit-finder-page[data-stepnumber=' + prevStep + ']').last();
    } else {
        $('.fit-finder-logo').removeClass('hide-logo');
    }
    if ($prevPage.length) {
        var $dialog = $quizPage.closest('.fit-finder-dialog');
        var newStepNumber = prevStep;
        $dialog.attr('data-quizstep', newStepNumber);
        $quizPage.removeClass('active').addClass('-transition-out');
        $prevPage.addClass('-transition-in');
        setTimeout(function () {
            if ($currStep > 1) $quizPage.remove();
            // $quizPage.removeClass('-transition-out');
            $prevPage.addClass('active').removeClass('-transition-in');
            document.getElementById('dialog-container').scrollTop = 0;
            $('body').trigger('resize');
        }, 500);
    }
}

/**
 * @private
 * @function elementInViewport
 * @description Test whether a given jQuery element is in the visible viewport
 * @param {jQuery} $el the jQuery element to test
 * @returns Boolean - true if element is in viewport, false if not
 */

function elementInViewport ($el) {
    var topPos = $el.offset().top,
        height = $el.outerHeight(),
        windowY = window.pageYOffset,
        windowH = window.innerHeight;
    return (topPos + height) <= (windowY + windowH);
}

module.exports.launch = launchFitFinderDialog;
module.exports.init = function () {
    initializeEvents();
};
