'use strict';

var giftcert = require('../giftcert'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    login = require('../login'),
    validator = require('../validator'),
    accordion = require('./product/accordion'),
    fluidconfigure = require('fluidconfigure/fluidconfigure'),
    wlStatus = require('../wishlist_status'),
    googleplacesapi = require('../googleplacesapi'),
    billing = require('../pages/checkout/billing'),
    _ = require('lodash'),
    StoreHelper = require('../store'),
    formater = require('../formatCCNumber'),
    loqateAddressCheck= require('./checkout/loqateAddressCheck'),
    scrollToOrderReview = window.location.search.indexOf('scroll=true') > -1;

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $('#edit-address-form');

    $form.find('input[name="format"]').remove();
    tooltip.init();

    $form.on('click', '.delete-button', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            var url = util.appendParamsToUrl(Urls.deleteAddress, {
                AddressID: $form.find('#addressid').val()
            });

            page.redirect(url);
        }
    });
    fluidconfigure.initOrderDetailsLineItems();
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) { return; }

    addresses.on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            page.redirect($(this).attr('href'));
        }
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    initializePaymentForm();
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                classes: {
                    'ui-dialog': 'add-card-dialog'
                },
                open: initializePaymentForm
            }
        });
    });

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
        .done(function () {
            page.redirect(Urls.paymentsList);
        });
    });
}
function initializePaymentForm() {
    var form = $('#CreditCardForm')
    $(form).on('click', '.cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });
    $(form).find('input[name*="_number"]').on('input.cc', _.debounce((e) => {
        const cardType = billing.testCcType(e.target?.value);
        billing.updateCcType(cardType);
        if(cardType == 'amex') {
            $(form).find('#dwfrm_paymentinstruments_creditcards_newcreditcard_cvn').attr('maxlength', 4).attr('minlength', 4);
        } else {
            $(form).find('#dwfrm_paymentinstruments_creditcards_newcreditcard_cvn').attr('maxlength', 3).attr('minlength', 3);
        }
        var inputElement = $(e.target);
        formater.formater(inputElement);
    }, 50));
    $(form).find('[name*="_create"]').on('click', function() {
        $('input[name*="_number"]').each(function() {
            var val = $(this).val().replace(/ /g, '');
            $(this).val(val);
        })
    })
    setTimeout(function () {
        validator.initForm(form)
    })
    /**
     * @description avoid write letters within CVN input (since is type "tel" which allows writing text)
     */
    $(document).off('input.only-decimals').on('input.only-decimals', '#dwfrm_paymentinstruments_creditcards_newcreditcard_cvn', function() {
        this.value = this.value.replace(/[^\d]/g, ''); 
    });
}

function initOrderHistoryAccordion () {
    $('.a11yAccordionItemHeader').first().addClass('order-history-active');
}

function initOrderDetailsEvents () {
    if (!$('.order-history').length) {
        return;
    }

    $('body').on('click', '.trackingnumber a', function(e) {
        e.preventDefault();
        window.open($(this).attr('href'), '_blank');
    });

    $('body').on('change', '.js-cancel-checkbox', function (e) {
        e.preventDefault();
        var cb = $('.js-cancel-checkbox:checked');
        if (cb.length === 0) {
            $('.js-update-order').attr('disabled','disabled');
        } else {
            $('.js-update-order').removeAttr('disabled');
        }
    });
    $('body').on('change', '.js-confirm-cancel-checkbox', function () {
        var $checkbox = $(this);
        var $cancellBtn = $checkbox.closest('.orderdetails').find('.js-cancel-order');
        if ($checkbox.is(':checked')) {
            $cancellBtn.removeAttr('disabled');
        } else {
            $cancellBtn.attr('disabled','disabled');
        }
    });
    $('body').on('click', '.js-update-order', function (e) {
        var $that = $(this);
        e.preventDefault();
        var cb = $('.js-cancel-checkbox:checked');
        if (cb.length > 0) {
            var orderLines = [];
            cb.each(function () {
                orderLines.push($(this).data('orlid'));
            });
            var cancelRequest = {
                brand: cb[0].dataset.brand || 'jcrew',
                orderNo: $that[0].dataset.orderno,
                orderLines: orderLines.join(',')
            };
            var reloadItem = $that.closest('.order-history-items');
            cancelOrder(cancelRequest, reloadItem);
        } else {
            $that.attr('disabled','disabled');
        }
    });
    $('body').on('click', '.js-cancel-order', function (e) {
        var $that = $(this);
        e.preventDefault();
        var cancelRequest = {
            brand: 'madewell',
            orderNo: $that[0].dataset.orderno,
            orderLines: $that[0].dataset.orderlines
        };
        var reloadItem = $that.closest('.order-history-items');
        cancelOrder(cancelRequest, reloadItem);
    });
    setInterval(function () {
        $('.orderdetails').each(function () {
            var el = $(this);
            var minutes = el.find('.orderdetails-cancel-timer .minutes').html();
            if (minutes != undefined) {
                minutes--;
                el.find('.orderdetails-cancel-timer .minutes').html(minutes);
                if (minutes <= 0) {
                    el.find('.orderdetails-cancel-timer').remove();
                }
            }
        });
    }, 60000);
}

function updateBodyProfileBtnLabel(labelType) {
    var $denimSizeWomen = $('.denim-size-women');
    var $denimSizeMen = $('.denim-size-men');

    if (labelType && $denimSizeWomen.length && $denimSizeMen.length) {
        $denimSizeWomen.find('label').text($denimSizeWomen.find('.input-select').data(labelType));
        $denimSizeMen.find('label').text($denimSizeMen.find('.input-select').data(labelType));
    }
}

function initBodyProfileEvents() {
    var $denimProfileContent = $('.js-denim-profile-gender-container');
    if ($denimProfileContent.length === 0) {
        return;
    }

    $('body').on('click', '.js-denim-profile-gender-btn', function(e) {
        e.preventDefault();
        var $this = $(this);
        var genderValue = $this.data('gender-value');

        $('.js-denim-profile-gender-btn').removeClass('active');
        $this.addClass('active');

        if ($denimProfileContent.length && genderValue) {
            if (genderValue === 'both') {
                updateBodyProfileBtnLabel('secondarylabel');
                $denimProfileContent.removeClass('d-none');
            } else {
                $denimProfileContent.addClass('d-none');
                updateBodyProfileBtnLabel('label');
                $('.js-denim-profile-gender-container.' + genderValue).removeClass('d-none');
            }
        }
    });
}

function initLoyaltyEventsSlider() {
    var $loyaltyEventsSlider = $('.js-loyalty-events-slider');
    if (!$loyaltyEventsSlider.length) {
        return;
    }

    $loyaltyEventsSlider.slick({
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: false
    });

    $loyaltyEventsSlider.on('afterChange', function(e, slick, currentSlide){
        var $currentSlide = $(slick.$slides.get(currentSlide));
        var $currentPositionElement = $('.js-current-event-position');

        if ($currentSlide.length && $currentSlide.data('showing-items') && $currentPositionElement.length) {
            $currentPositionElement.text($currentSlide.data('showing-items'));
        }
    });
}

function initializeUpdateSMSPhoneModal() {
    var $phoneValueInput = $('.js-phone-value-input');
    var $updatePhoneModal = $('.update-phone-dialog');
    var $phoneValueInputModal = $updatePhoneModal.find('.js-phone-value-modal-input');

    $updatePhoneModal.on('click', '.js-update-phone-btn', function (e) {
        e.preventDefault();
        var $form = $updatePhoneModal.find('form');

        if (!$form.valid()) {
            return false;
        }

        $phoneValueInput.val($phoneValueInputModal.val());
        $('.js-sms-optin input').prop('checked', false);
        $('.js-sms-action-type').val('update');
        $('.js-save-phone-pref-btn').trigger('click');
    });

    $phoneValueInputModal.on('blur', function () {
        validator.init();
    });
}

function initializeDeleteSMSPhoneModal() {
    var $deletePhoneModal = $('.delete-phone-dialog');

    $deletePhoneModal.on('click', '.js-delete-phone-btn', function (e) {
        e.preventDefault();
        $('.js-phone-value-input').val('');
        $('.js-sms-action-type').val('delete');
        $('.js-save-phone-pref-btn').trigger('click');
    });

    $deletePhoneModal.on('click', '.js-cancell-delete-phone-btn', function (e) {
        e.preventDefault();
        $deletePhoneModal.find('.ui-dialog-content').dialog('close');
    });
}

function initCommunicationPrefEvents() {
    if (!$('.js-communication-preferences').length || (!SitePreferences.SMS2_ENABLED)) {
        return;
    }

    $('.js-phone-add, .js-phone-edit').on('click', function (e) {
        e.preventDefault();
        var $updatePhoneModal = $('.js-update-phone-modal');
        if ($updatePhoneModal.length) {
            dialog.open({
                html: $updatePhoneModal.clone(),
                options: {
                    classes: {
                        'ui-dialog': 'update-phone-dialog'
                    },
                    open: initializeUpdateSMSPhoneModal
                }
            });
        }
    });

    $('.js-phone-delete').on('click', function (e) {
        e.preventDefault();
        var $deletePhoneModal = $('.js-delete-phone-modal');
        if ($deletePhoneModal.length) {
            dialog.open({
                html: $deletePhoneModal.clone(),
                options: {
                    classes: {
                        'ui-dialog': 'delete-phone-dialog'
                    },
                    open: initializeDeleteSMSPhoneModal
                }
            });
        }
    });

    $('.js-sms-optin input').on('change', function (e) {
        e.preventDefault();
        var actionType = $(this).prop('checked') ? 'optin' : 'optout';
        $('.js-sms-action-type').val(actionType);
    });
}

/**
 * @desc Initializes a progress bar and progress dial on the loyalty page. The bar shows the user
 * how many points they have accrued and renders a message about how many points are required to reach
 * the next reward. The dial shows the user how much money they have spent this year  how close they
 * are to the next loyalty Tier.
 */
function initLoyaltyProgressBars() {
    var progressLine = $('#progressLine');
    var progressDial = $('#progressDial');
    var pointsAccrued; // points the customer has accrued (bar)
    var pointsAccruedValue; // points the customer has accrued to show above the bar
    var nextRewardLimit; // point cost for next reward (bar)
    var dollarsSpent; // dollars spent this year by customer (dial)
    var minlimit; // dollar limit for next reward tier (dial)
    var barlength;

    // Init loyalty progress bar
    if (progressLine.length) {
        pointsAccruedValue = parseInt(progressLine.data('pointsaccrued'), 10);
        pointsAccrued = pointsAccruedValue > 0 ? pointsAccruedValue : 0;
        nextRewardLimit = parseInt(progressLine.data('nextrewardlimit'), 10);

        var barLine = new window.ProgressBar.Line('#progressLine', {
            strokeWidth: 10,
            color: progressLine.data('color') || '#f8bf15',
            trailColor: '#f8f8f8',
            trailWidth: 15,
            text: {
                style: {
                    // Text color.
                    // Default: same as stroke color (options.color)
                    color: '#000',
                    position: 'absolute',
                    left: '0',
                    top: '0px'
                }
            },
            step: (state, barLine) => {
                var value = Math.round(pointsAccruedValue),
                    toNextReward = (Number(nextRewardLimit) - value);
                if (toNextReward === 1) {
                    barLine.setText('<span>' + toNextReward + ' point </span> until your next $10 Reward');
                } else {
                    barLine.setText(`<span>${toNextReward} points </span> until your next $10 Reward`);
                }

                // If customer balance is more than SitePreferences.NEXT_REWARD_LIMIT -- show customer balance
                if (pointsAccrued > nextRewardLimit) {
                    barLine.setText(`<span>${pointsAccrued} points </span>`);
                    barLine.text.removeAttribute('style');
                    $('#progressLine').addClass('progress-line--hidden');
                    $('.points-count').addClass('d-none');
                }
            }
        });
        var lineProgressPercentage = (pointsAccrued / nextRewardLimit);
        barLine.set(lineProgressPercentage);  // Number from 0.0 to 1.0
        if (lineProgressPercentage >= 0.95) {
            progressLine.siblings('.points-count').find('.next').addClass('active');
        }
        if (lineProgressPercentage >= 0.02) {
            progressLine.siblings('.points-count').find('.curr').addClass('active');
        }
    }

    // init loyalty progress dial
    if (progressDial.length) {
        dollarsSpent = progressDial.data('dollarsspent');
        minlimit = progressDial.data('minlimit');
        barlength = progressDial.data('barlength');

        var barDial = new window.ProgressBar.SemiCircle('#progressDial', {
            strokeWidth: 10,
            color: progressDial.data('color') || '#000',
            trailColor: '#fff',
            trailWidth: 10,
            text : {
                alignToBottom: true
            },
            from: {color: progressDial.data('color') || '#000'},
            to: {color: progressDial.data('color') || '#000'},
            // Set default step function for all animate calls
            step: (state, barDial) => {
                barDial.setText('You\u2019ve spent\n $' + dollarsSpent + ' this year');
            }
        });

        barDial.text.style.fontFamily = 'p22-underground,proxima-nova,sans-serif';
        barDial.text.style.fontSize = '12px';
        var dialProgressPercentage = ((dollarsSpent - minlimit) / barlength);
        barDial.set(dialProgressPercentage > 0 ? dialProgressPercentage : 0);  // Number from 0.0 to 1.0
        if (dialProgressPercentage >= 0.99) {
            progressDial.siblings('.curr-next-items').find('.next').addClass('active');
        }
        if (dialProgressPercentage >= 0.02) {
            progressDial.siblings('.curr-next-items').find('.curr').addClass('active');
        }
    }
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initializeAddressForm();
    initAddressEvents();
    initPaymentEvents();
    initOrderHistoryAccordion();
    initOrderDetailsEvents();
    initBodyProfileEvents();
    initCommunicationPrefEvents();
    StoreHelper.initStoreMap();
    orderReviewsData();
    login.init();
    wlStatus.init();
    accordion.init('.orders-accordion', orderDetails, hideOrder);
    // Loyalty Account Dashboard specific inits.
    if ($('#loyalty-dashboard').length) {
        initLoyaltyProgressBars();
        initLoyaltyEventsSlider();
    }
    selectActiveMenuLink();
    loqateAddressCheck.init();

    $(document).on('click', '.my-account-page #RegistrationForm button[type=submit]', function (e) {
        e.preventDefault();
        var form = $('#RegistrationForm');
        form.find('.form-action-submited').attr('name', $(this).attr('name'));
        form.trigger('submit');
    });

    // handle scroll of content anchor - issue with sticky
    $('.js-anchor-link').on('click', function(e) {
        e.preventDefault();
        var targetID = $(this).data('target');
        var $targetElement = targetID ? $(targetID) : null;

        if ($targetElement && $targetElement.length) {
            var stickyHeaderHeight = util.getStickyHeaderHeight() || 0;
            $('html,body').animate({scrollTop: ($targetElement.offset().top) - stickyHeaderHeight}, 500);
        }
    });

    $('.address-company-toggle').on('click', function () {
        $(this).toggleClass('active');
    });
}

function cancelOrder (item, reloadItem) {
    if (item) {
        $.post(Urls.orderCancel, item, function (data) {
            if (data && reloadItem) {
                orderDetails (reloadItem)
            }
        })
    }
}

function orderDetails (item) {
    if (item) {
        var info = item.data('info');
        $.post(Urls.orderDetails, {orderNo: info.orderid}, function (data) {
            if (data) {
                var $ccpayment = $(data).find('.order-payment-instruments-group[data-type*="credit"]').clone();
                item.find('.order-full-info').first().replaceWith(data);
                if ($ccpayment.length) {
                    item.find('.order-full-info').first().find('.order-payment-instruments-group[data-type*="credit"]').remove();
                    item.find('.order-full-info').first().find('.order-payment-instruments-row').prepend($ccpayment);
                }
                if (scrollToOrderReview) {
                    $(window).scrollTop($('.bv-order-ratings').eq(0).offset().top - $('.top-banner').height());
                }
                scrollToOrderReview = false;
            }
        })
    }

    // Remove class from any previous active accordion element
    $('.a11yAccordionItemHeader').removeClass('order-history-active');
    // Add active class to accordion header element
    $(item).siblings('.a11yAccordionItemHeader').addClass('order-history-active');
    var rowPosition = $(item).siblings('.a11yAccordionItemHeader').offset().top;
    if (window.pageYOffset > rowPosition) {
        setTimeout(function () {
            $('html,body').animate({scrollTop: ($(item).siblings('.a11yAccordionItemHeader').offset().top)}, 300);
        }, 100);
    }
}

function hideOrder (item) {
    // Remove active class for current accordion element
    if ($(item).siblings('.a11yAccordionItemHeader').hasClass('order-history-active')) {
        $(item).siblings('.a11yAccordionItemHeader').removeClass('order-history-active');
    }
}

function selectActiveMenuLink () {
    $('.account-mobile-nav').find('a').each(function() {
        if (this.href === window.location.href.split('?')[0]) {
            $(this).addClass('active');
        }
    });
}

function orderReviewsData () {
    var checkForReviews = function (ele, callback){
        var reviewsLoaded = setInterval(function(){
            if($(ele).length){
                clearInterval(reviewsLoaded);
                callback();
            }
        }, 1000);
    }

    $(document).on('click', '[data-order-ratings]', function(e) {
        e.preventDefault();
        var $this = $(this);
        var bvconfig = $this.data('bvconfig');
        var $BV = window.$BV || {configure: function (){}, ui: function (){}};
        //Active Profiles display container will use a different submission container than the one used for other BV products (to allow for customized styling)
        if (bvconfig) {
            bvconfig.submissionContainerUrl = util.appendParamsToUrl(bvconfig.submissionContainerUrl, {
                pid: bvconfig.productId
            });
            $('.bv-order-ratings-container').remove();
            $this.closest('.bv-order-ratings').append('<div class="bv-order-ratings-container" id="'+ bvconfig.summaryContainerDiv +'"></div>')

            var bvGlobalData = {};
            bvGlobalData.submissionContainerUrl = bvconfig.submissionContainerUrl;
            $BV.configure("global", bvGlobalData);
            $BV.ui('rr', 'show_reviews', bvconfig);
            checkForReviews(".BVRRRatingSummary", function () {
                var $link = document.querySelector('#BVRRRatingSummaryLinkWriteID a');

                if (!$link) {
                    $link = document.querySelector('#BVRRRatingSummaryLinkWriteFirstID a');
                }
                $link.click();
            });
        }
    });
}

var account = {
    init: function () {
        initializeEvents();
        giftcert.init();
        // initialize Google autocomplete if Maps is loaded
        googleplacesapi.init();
    },
    initCartLogin: function () {
        login.init();
    },
    selectActiveMenuLink: selectActiveMenuLink
};

module.exports = account;
