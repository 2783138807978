/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var event = require('../event');

var confirmation = {
    init: function (){
        
        // Purchase
        // Orders
        // Ocurrs when a user places an order.
        event.pushEvent('Purchase', null, false);
        event.pushEvent('event128', '1', false);
        event.pushEvent('event43', null, false);
    }
}

exports.init = confirmation.init;
