'use strict';

var productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    variant = require('./variant'),
    wlStatus = require('../../wishlist_status'),
    sizechart = require('../../sizechart'),
    accordion = require('./accordion'),
    fluidconfigure = require('fluidconfigure/fluidconfigure'),
    fitOptions = require('./fit-options'),
    productVideos = require('./product-videos'),
    bvSizeRec = require('./size-recommendation'),
    matchingPiece = require('./matchingPiece'),
    deliveryOptions = require('./deliveryOptions'),
    seo = require('./seo'),
    moreInWidget = require('./moreInWidget'),
    hybrid = require('./hybrid'),
    carousel = require('../../carousel');
    // stylitics = require('./stylitics');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 * @param $qv {jQuery} optional jQuery object representing the $scope or parent wrapper of the quickview product object to be initiatlized. if null, this is assumed to be a PDP main product initialization.
 */
function initializeDom($qv) {
    let $scope = $('.pt_product-details');
    let isQuickview = false;
    if ($qv && $qv.length) {
        $scope = $qv;
        isQuickview = true;
    }

    if (!isQuickview) {
        // this is a PDP, not a quickview
        
        if (window.moreInRecsConfig) {
            moreInWidget.moreInWidget();
        }

        productNav();
        tooltip.init({
            position: {
                my: 'right-25px center',
                at: 'left-25px center',
            }
        });

        hybrid.init();
    }

    // init the following whether this is a PDP or quickview
    tooltip.create($scope.find('.mirakl-info-tooltip'));
    wlStatus.init();
    fluidconfigure.init();
    seo.init();
    deliveryOptions.init($scope);
}

function styliticsChildrenObserver() {
    var observer = new MutationObserver(function(mutationRecords) {
        if (mutationRecords.length > 0) {
            var mutationRecord = mutationRecords[0];
            $(mutationRecord.target).find('.stylitics-swap-modal').empty().attr('data-title', window.Resources.SWAP_THE_ITEM);
            $(mutationRecord.target).find('.stylitics-item-image-wrapper').off('click').on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                $('.stylitics-swap-modal__close-wrapper button').trigger('click');
                $(this).closest('.stylitics-item-container').find('.stylitics-cta').trigger('click');
            });

            if (mutationRecord.type === 'childList' && $(mutationRecord.target).hasClass('stylitics-expandable-mix-and-match-row-wrapper')) {
                if ($(mutationRecord.target).find('.stylitics-swap-modal__close-wrapper').length) {
                    $('.stylitics-item-container').removeClass('mminactive');
                    $('.stylitics-item-container').not($(mutationRecord.target).closest('.stylitics-item-container')).addClass('mminactive');
                    $('.stylitics-card').on('click', function() {
                        $('.stylitics-card').removeClass('stylitics-card-active');
                        $(this).addClass('stylitics-card-active');
                    });

                    $('.stylitics-classic-base').on('click', function(e) {
                        if ($('.stylitics-swap-modal__close-wrapper button').is(':visible') && !$(e.target).closest('li:not(.mminactive)').length) {
                            $('.stylitics-swap-modal__close-wrapper button').trigger('click');
                        }
                    });

                    var $mobile_img = $('.stylitics-modal .replacements-row-wrapper img');
                    if ($mobile_img.length) {
                        $('.stylitics-modal .replacements-row-wrapper img[src*="'+ $('.stylitics-modal .stylitics-item-container:not(.mminactive)').attr('data-stylitics-item-id') +'"]').addClass('stylitics-img-active');
                        $mobile_img.on('click', function() {
                            $mobile_img.removeClass('stylitics-img-active');
                            $(this).addClass('stylitics-img-active');
                        });
                    }
                } else {
                    $('.stylitics-item-container').removeClass('mminactive');
                }
            }
            if (!$('.stylitics-desktop-modal').is(':visible') && !$('.stylitics-modal').is(':visible')) {
                $('html').removeClass('stylitics-active');
            }
        }
    });
    // observe everything except attributes
    observer.observe(document.querySelector('#stylitics'), {
        childList: true, // observe direct children
        subtree: true, // lower descendants too
        characterDataOldValue: true, // pass old data to callback
    });
}

function getBVConversations($scope) {
    var $reviewContainer = $scope.find('.conversationTopReviews');
    if ($reviewContainer.length && $reviewContainer.data('url') && $reviewContainer.data('id')) {
        $.get($reviewContainer.data('url'), function(response) {
            if (response && response.TotalResults > 0 && response.Includes && response.Includes.Products && response.Includes.Products[$reviewContainer.data('id')]) {
                var review = response.Includes.Products[$reviewContainer.data('id')];
                var averageRating = review.ReviewStatistics ? review.ReviewStatistics.AverageOverallRating : 0;
                var overallRatingRange = review.ReviewStatistics ? review.ReviewStatistics.OverallRatingRange : 0;
                var ratingWidth = averageRating/overallRatingRange * 100;
                $reviewContainer.addClass('conversationTopReviewsActive');
                $reviewContainer.find('.conversationTopReviewsStarsFilled').width(ratingWidth + '%');
                $reviewContainer.find('.conversationTopReviewsCount').html(response.TotalResults > 0 ? `(${response.TotalResults})` : '');
            }
        });
    }
}

/**
 * @description Initialize event handlers on product detail page
 * @param $qv {jQuery} optional jQuery object representing the parent $scope of what needs to be initiatlized
 */
function initializeEvents($qv) {
    let $scope = $('body');
    let isQuickview = false;
    if ($qv && $qv.length) {
        $scope = $qv;
        isQuickview = true;
    }
    var $pdpMain = $scope.find('#pdpMain');

    addToCart($scope);
    availability($scope);
    variant($scope);
    image($scope);
    sizechart($scope);
    productSet($scope);
    getBVConversations($scope);

    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($scope.find('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable:not(.extended-sizing-tile) a', function (e) {
        e.preventDefault();
    });
    // click on extended sizing - this should redirect to product with different fit
    $pdpMain.on('click', '.extended-sizing-tile:not(.unselectable, .selected) a', function (e) { // eslint-disable-line no-unused-vars
        e.preventDefault();
        var $this = $(this);
        var isQuickView = $('#QuickViewDialog').length;
        var url;
        if (!isQuickView && !$('.cart-edit-details:visible').length) {
            window.location.href = this.href;
        } else {
            if ($('.cart-edit-details:visible').length) {
                var $uuid = $('.cart-edit-details:visible .extended-sizing-tiles').data('uuid');
                var $source = $('.cart-edit-details:visible .extended-sizing-tiles').data('source');
                var $pli;
                url = util.appendParamToURL($this.attr('href'), 'format', 'ajax');
                if ($source === 'saveforlater') {
                    $pli = $('.save-for-later-container .sfl-item[data-plid="'+ $uuid +'"]');
                    url += '&' + util.getQueryString($pli.find('.sfl-item-details .sfl-item-buttons .edit-link a').attr('href'));
                } else {
                    $pli = $('#cart-table .cart-row[data-uuid="'+ $uuid +'"]');
                    url += '&' + util.getQueryString($pli.find('.item-edit-details a').attr('href'));
                }
            } else {
                url = util.appendParamToURL($this.attr('href'), 'source', 'quickview');
            }

            var $quickviewDialog = $('#QuickViewDialog');
            var isShopTheLook = $quickviewDialog.find('.shop-the-look-carousel').length;
            var shopLookActiveUrl = $quickviewDialog.find('.product-active').closest('a').attr('href');
            $quickviewDialog.load(url, function() {
                initializeEvents();
                carousel.loadQuickView($quickviewDialog);
                if (isShopTheLook && $quickviewDialog.data('products') ) {
                    $(document).trigger('shoplook:onload', {
                        url: shopLookActiveUrl || url,
                        prodArray: JSON.parse($quickviewDialog.data('products'))     
                    });
                }
            });
        }
    });

    // Initalize product accordion
    // for PDP rowIdexToOpen = 0
    // for quickview modal = 10 to prevent open first item on window open
    var rowIdexToOpen = 0
    if ($scope.find('#addtocartlocation').val() && $scope.find('#addtocartlocation').val().length > 0 && $scope.find('#addtocartlocation').val() == 'quickview') {
        rowIdexToOpen = 10;
    }
    accordion.init($pdpMain.find('.product-accordion'), null, null, rowIdexToOpen);

    // Disable the swatch hover event that changes the primary (image) on the page
    $pdpMain.off('mouseenter mouseleave', '.swatchanchor');

    if (!window.isFitOptionsInitialized) {
        fitOptions.init($scope);
    }

    productVideos.init();
    $(document).on('load-variant:after', function() {
        productVideos.init();
    });

    bvSizeRec.init();

    if (SitePreferences.STYLITICS_ENABLE && !isQuickview) {
        $scope.on('click', '#stylitics .slick-slide', function () {
            util.createCookie('recommendation_source', 'stylitics');
        });
        var itemNumber;

        try {
            // itemNumber = $scope.find('.swatches.color .selected a img').attr('src').split('/')[4].split('_');
            // itemNumber.splice(2, 1);
            // itemNumber = itemNumber.join('_');
            var colorCode = $('.swatches.color li.selected').length ? $('.swatches.color li.selected').data('value') : $scope.find('.swatches.color .selected a img').attr('src').split('/')[4].split('_');
            itemNumber = $('.pdp-main').data('pid')+'_'+colorCode;
        } catch (e) {
            // error handling
        }
        if (SitePreferences.STYLITICS_FORCE_V3) {
            $pdpMain.on('click', '.stylitics-jump-button', function (e) {
                e.preventDefault();
                var target = document.querySelector('#stylitics');
                target.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                if (typeof StyliticsClassicWidget !== 'undefined') {
                    window.styliticsWidget.trackClickJumpLink();
                }
            });

            var opts;
            try {
                opts = JSON.parse(SitePreferences.STYLITICS_JSON_CONFIG);
            } catch (e) {
                opts = {
                    api: {
                        min: 1,
                        max: 7,
                        item_number: itemNumber  // eslint-disable-line camelcase
                    },
                    display: {
                        disableMnM: true,
                    },
                };
            }
            if (!opts.display.disableMnM) {
                $('.styliticsContainer').addClass('styliticsMixMatch');
            }

            if ($('.styliticsMixMatch').length) {
                styliticsChildrenObserver();
                $('body').on('click', '.stylitics-view-item-details-cta', function() {
                    $('html').addClass('stylitics-active');
                }).on('click', '.stylitics-desktop-modal-close', function() {
                    $('html').removeClass('stylitics-active');
                });
            }

            if (typeof StyliticsClassicWidget !== 'undefined' && typeof opts === 'object' && opts.api) {
                opts.api.item_number = itemNumber;  // eslint-disable-line camelcase
                var clientID = SitePreferences.STYLITICS_CLIENT_ID;
                if (!opts.display.disableMnM) {
                    clientID = SitePreferences.STYLITICS_MM_CLIENT_ID;
                }
                window.styliticsWidget = new StyliticsClassicWidget(clientID, 'stylitics', opts); // eslint-disable-line no-undef

                window.styliticsWidget.on('mount', 'bundles', function (data) {
                    if (data.bundles && data.bundles.length > 0) {
                        let titleElement = document.getElementById('widget-title');
                        if (titleElement) {
                            titleElement.classList.remove('d-none');
                        }
                    }
                });
                window.styliticsWidget.on('mount', 'bundle', function (data) {
                    let bundle = data.bundle;
                    let element = data.element;
                    if (bundle['on-model-image']) {
                        let badge = document.createElement('img');
                        badge.setAttribute('src', Urls.styliticsBadgeImage);
                        element.querySelector('.stylitics-bundle-badge').appendChild(badge);
                        $('.product-stylitics-badge').addClass('active');
                    }
                });
                window.styliticsWidget.start();
                window.styliticsWidget.override("click", "item", function(e, a) {
                    if (opts.display.disableMnM && !$(".menu-toggle").is(":visible") && $(e.element).find(".stylitics-cta-link").attr("href")) {
                        $('body').trigger('trigger:quickview', $(e.element).find(".stylitics-cta-link").attr("href"));
                    } else {
                        window.location.href = $(e.element).find(".stylitics-cta-link").attr("href");
                    }
                });
            }
        } else {
            if (itemNumber) {
                var v2WidgetOpts = {
                    api: {
                        total: 12,
                        item_number: itemNumber  // eslint-disable-line camelcase
                    },
                    display: {
                        cols: 3,
                        alwaysRender: true,
                        ctaPosition: 'bottom',
                        ctaText: Resources.STYLITICS_CTA_TEXT,
                        ctaBackToLookText: Resources.STYLITICS_BACK_TO_LOOK_TEXT,
                        itemCtaText: Resources.STYLITICS_ITEM_CTA_TEXT
                    }
                }

                if (typeof StyliticsWidget !== 'undefined') {
                    window.styliticsWidget = new StyliticsWidget(SitePreferences.STYLITICS_CLIENT_ID, 'stylitics', v2WidgetOpts); // eslint-disable-line no-undef
                    window.styliticsWidget.load(onLoad);
                }
            }
        }
    }
    matchingPiece.init();

    // allow quickview to launch within PDP
    $pdpMain.on('click', '.quickview, .js-quickview', function (e) {
        e.preventDefault();
        var $btn = $(this);
        var url = $(this).attr('href');
        if (url) {
            require('../../quickview').show({
                url: url,
                source: 'quickview',
                progress: $btn,
                position: 0
            });
            util.smartResize(function() {
                $('#QuickViewDialog').parent('.ui-dialog').position({
                    my: 'center',
                    at: 'center',
                    collision: 'fit',
                    of: window
                });
                if ($(this).dialog.isOpen === true) {
                    $(this).dialog('close');
                }
            });
        }
    });

    $pdpMain.on('click', '.product-stylitics-badge', function (e) {
        e.preventDefault();
        var $stylitics = $('#stylitics');
        if ($stylitics.length) {
            if ($('.pdp-hybrid-title [data-component="hybrid-more"]').is(':visible')) {
                $('.pdp-hybrid-title [data-component="hybrid-more"]').trigger('click');
            }
            setTimeout(function(){
                $stylitics.find('.stylitics-sequence').scrollLeft(0);
                $(window).scrollTop($('#stylitics').offset().top - $('.top-banner').height());
            }, 200);
        }
    });

    $pdpMain.on('change', '.delivery-option', function() {
        var isMaster = $('#isMasterProduct').val() == 'true';
        if ($(this).is(':checked') && $(this).closest('.instore-delivery').length > 0 &&
            isMaster && $('.in-store-pickup-error').length < 1) {
            var $inStoreError = $('<div class="error in-store-pickup-error">' +Resources.ISPU_SELECT_PRODUCT_ERROR+ '</div>')
            $('.ispu-error-messages').append($inStoreError);
        }
    });

    if (!isQuickview) {
        // handle fav icon on pdp
        window.addEventListener('load', function () {
            if ($('.add-to-favorites').length) {
                $.ajax({
                    type: 'GET',
                    url: window.Urls.getWishListItems,
                    success: function (result) {
                        sessionStorage.setItem('favItems', result.wishList);
                        if (result && result.isLoggedIn) {
                            $('.add-to-favorites').removeClass('login-modal');
                            $('.add-to-favorites').addClass('logged')
                        }
                        if (result && result.wishList && result.wishList.length > 0) {
                            for (var k = 0; k <result.wishList.length; k++) {
                                if ($('.add-to-favorites[data-pid='+result.wishList[k]+']').length) {
                                    $('.add-to-favorites[data-pid='+result.wishList[k]+']').addClass('added');
                                }
                            }
                        }
                    }
                });
            }
        });
    }
}

function onLoad(outfits){
    if (outfits.length > 0 && $('#stylitics_head').length == 0) {
        $('#stylitics').prepend('<h3 id="stylitics_head" style="text-align:center">' + Resources.STYLITICS_HEAD + '</h3>');
    }
    if (outfits.length < 1) {
        $('#stylitics_head').remove();
    }
}

var product = {
    initializeEvents: initializeEvents,
    init: function ($qv) {
        initializeDom($qv);
        initializeEvents($qv);
    }
};

module.exports = product;
