'use strict';

var addProductToCart = require('./product/addToCart'),
    account = require('./account'),
    page = require('../page'),
    login = require('../login'),
    util = require('../util'),
    fluidconfigure = require('fluidconfigure/fluidconfigure'),
    wlStatus = require('../wishlist_status'),
    shareFavorites = require('../share-favorites'),
    productTilesWishlist = require('../product-tile-wishlist');


function wishlistRecommendations() {
    var $wishlistSlick = $('.favorites-recommendations [data-slick]');
    $wishlistSlick.not('.slick-initialized').slick({
        infinite: false,
        arrows: true,
        dots: false,
        slidesToShow: 4,
        slide: '.grid-tile',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            }
        ]
    });
    $wishlistSlick.slick('refresh');
}

exports.init = function () {
    addProductToCart();
    wishlistRecommendations();
    productTilesWishlist.init();
    $('body').on('init:wishlistRecommendations', function () {
        wishlistRecommendations();
    });
    $('#editAddress').on('change', function () {
        page.redirect(util.appendParamToURL(Urls.wishlistAddress, 'AddressID', $(this).val()));
    });

    //add js logic to remove the , from the qty feild to pass regex expression on client side
    $('.option-quantity-desired input').on('focusout', function () {
        $(this).val($(this).val().replace(',', ''));
    });

    $('.delete-item').on('click', function() {
        var isEvents = require('../interaction-studio-events');
        var $parentTr = $(this).parents('tr');
        var pid = $parentTr.find('.sku .value').text();
        var price = '',
            priceLocal = ''; // currently for price display in wishlist
            // always used EShopWorld-GetConvertedPrice call
            // so in our case price and local price would be equal and with currency sign
        if ($parentTr.find('.price .value .price-sales').length > 0) {
            price = $parentTr.find('.price .value .price-sales').clone()
                .children()
                .remove()
                .end()
                .text().trim();
            priceLocal = price;
        } else if ($parentTr.find('.price .value .price-standard').length > 0) {
            price = $parentTr.find('.price .value .price-standard').clone()
                .children()
                .remove()
                .end()
                .text().trim();
            priceLocal = price;
        }
        
        isEvents.removeFromWishlist(pid, price, priceLocal);
    });

    login.init();
    wlStatus.getStatus();
    fluidconfigure.init();
    account.selectActiveMenuLink();
    shareFavorites.init();
};
