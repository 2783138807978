/**
 *    Google Places API
 *    Uses address1 formfield input to suggest addresses and auto-complete address forms
 */
'use strict';

/* eslint-disable */
const territories = ['AS', 'GU', 'PR', 'VI'];
const countryRestrictionVals = ['us', 'as', 'gu', 'pr', 'vi'];
const address1 = $('input[id$="_address1"]')[0];
var autocomplete;
const componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'short_name',
        postal_code: 'short_name'
};

function initAutocomplete() {
    if (!address1) {
        return;
    }
    autocomplete = new google.maps.places.Autocomplete(address1, {types: ['geocode']});
    autocomplete.setFields(['address_component']);
    autocomplete.addListener('place_changed', fillInAddress);
    autocomplete.setComponentRestrictions({'country': countryRestrictionVals});

    // prevent the form from attempting to submit if the enter key is pressed in the address1 field
    $(document).keydown(function(e) {
        if (e.which == 13 &&  e.target == address1) {
            e.preventDefault();
        }
    });
}

function fillInAddress() {
    // Get the place details from the autocomplete object.
    var place = autocomplete.getPlace();
    var address = {};
    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            address[addressType] = val;
        }
    }

    var streetNumber = address.hasOwnProperty('street_number') ? address.street_number + ' ' : '',
        streetName = address.hasOwnProperty('route') ? address.route : '',
        address1 = streetNumber + streetName,
        // check if city is a locality or sublocality
        city = address.hasOwnProperty('locality') ? address.locality : address.sublocality_level_1,
        // check if this is a U.S. territory
        state = territories.indexOf(address.country) > -1 ? address.country : address.administrative_area_level_1,
        postal = address.postal_code,
        country = address.country;

    $('input[id$="_address1"]').val(address1);
    $('input[id$="_city"]').val(city);
    $('select[id$="_state"]').val(state);
    $('input[id$="_postal"]').val(postal);
    /* The site currently only ships to U.S.
     * $('input[id$="_country"]').val(country);
     */
}

exports.init = function () {
    // wait for Google Maps to initiate before initiating autocomplete
    var count = 0;
    var interval = setInterval(function() {
        if (typeof google === 'object' && typeof google.maps === 'object') {
            initAutocomplete();
            clearInterval(interval);
        } else {
            count++;
            if (count > 10) {
                clearInterval(interval);
            }
        }
	}, 250);
}
/* eslint-enable */

