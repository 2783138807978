'use strict';

var quickview = require('./quickview'),
    carousel = require('./carousel'),
    util = require('./util'),
    dialog = require('./dialog'),
    shopLookTitle = window.Resources.SHOP_THE_LOOK;

var shopTheLook = {
    init: function () {
        var self = this;
        $(document).off('click.shop-the-look', '.js-shop-the-look').on('click.shop-the-look', '.js-shop-the-look', function(e) {
            e.preventDefault();
            var isOpen = $('.shop-the-look-dialog').length > 0;
            var $button = $(this);
            var quickviewLink = $button.attr('href');
            var products = $button.data('products');
            var productsArray = products.split(',');
            shopLookTitle = $(this).data('modal-title') || window.Resources.SHOP_THE_LOOK;
            productsArray = productsArray.map(function(element) {
                return element.trim();
            });
            if(!isOpen) {
                shopTheLook.show($button, quickviewLink, productsArray);
                $('#QuickViewDialog').removeClass('shop-the-look-dialog');
            } else {
                shopTheLook.update(quickviewLink, productsArray, $button.closest('#QuickViewDialog'));
            }
        }).off('shoplook:onload').on('shoplook:onload', function(e, data) {
            self.onload(data.url, data.prodArray);
        });
    },
    update: function(url, prodArray, container) {
        var self = this;
        url = util.appendParamToURL(url, 'format', 'ajax');
        url = util.appendParamToURL(url, 'source', 'quickview');
        dialog.replace({
            url: url,
            container: container,
            callback: function() {
                quickview.setup(url);
                self.onload(url, prodArray);
            }
        });
    },
    show: function ($button, url, prodArray) {  
        var position = 0;
        var self = this;
        url = util.appendParamToURL(url, 'format', 'ajax');
        url = util.appendParamToURL(url, 'source', 'quickview');
        quickview.show({
            url: url,
            progress: $button,
            source: 'quickview',
            position: position,
            callback: function() {
                self.onload(url, prodArray);
            }
        });
    },
    onload: function(url, prodArray) {
        var $quickviewDialog = $('#QuickViewDialog');
        $quickviewDialog.data('products', JSON.stringify(prodArray));
        $quickviewDialog.addClass('shop-the-look-container');
        var $shopTheLookContainer = !util.isDesktopSize() ? $quickviewDialog.find('.product-main-top-content') : $quickviewDialog.find('.quickview-nav');
        $('[aria-describedby="QuickViewDialog"]').addClass('shop-the-look-container');

        $quickviewDialog.addClass('shop-the-look-dialog');
        
        shopTheLook.generateMenu($shopTheLookContainer, prodArray);
        var quickviewLinkComparission = url.split('?')[0];
        var slideIndex = 0;
        for (var i = 0; i < prodArray.length; i++) {
            if (quickviewLinkComparission === prodArray[i].split('?')[0]) {
                slideIndex = i;
                $('.shop-the-look-dialog').find('.js-shop-the-look').eq(slideIndex).find('img').addClass('product-active');
                break;
            }
        }  
        
        if($shopTheLookContainer.find('.js-shop-the-look').length > 3) {
            if (slideIndex === ($shopTheLookContainer.find('.js-shop-the-look').length - 1)) {
                slideIndex = slideIndex - 1;
            }
            carousel.loadShopTheLook($('#QuickViewDialog'), slideIndex);
            $('.stl-prev').show();
            $('.stl-next').show();
            $('.shop-the-look-carousel').addClass('border');
        }

        var addToBagBtn = $quickviewDialog.find('.pdpForm');
        var dialogAccordion = $quickviewDialog.find('.dialog-details');
        var mainCarousel = $quickviewDialog.find('.product-image-carousel-container');
        var mainCarouselPrev = $quickviewDialog.find('.carousel-arrow-prev');
        var mainCarouselNext = $quickviewDialog.find('.carousel-arrow-next');
        if(util.isDesktopSize()) {
            addToBagBtn.insertBefore(dialogAccordion);
            mainCarouselPrev.appendTo(mainCarousel);
            mainCarouselNext.appendTo(mainCarousel);
        }
 
        var personalizeBtn = $quickviewDialog.find('#personalize') ?? null;
        var personalizeHelp = $quickviewDialog.find('#personalize-help') ?? null;
        if(personalizeBtn.length > 0) {
            personalizeBtn.insertBefore(dialogAccordion);
            personalizeHelp.insertAfter(personalizeBtn);
        }
        if($("#accordion__content_details").length > 0){
            if($("#accordion__content_details").css("display") === "block"){
                $("#accordion__title_details").trigger("click")
            }
        }
    },
    generateMenu: function($container, products) {
        var $div = $('<div></div>');
        var $header = $('<div></div>');
        var $h5 = $('<h5></h5>');
        var $slickContainer = $('<div></div>');
        var $slickArrowPrev = $('<div></div>');
        var $slickArrowNext = $('<div></div>');
        $div.addClass('shop-the-look-carousel');
        $slickContainer.addClass('stl-slick-container');
        $slickArrowPrev.addClass('stl-prev');
        $slickArrowNext.addClass('stl-next');
        $header.addClass('header-container');
        $h5.text(shopLookTitle).addClass('header-container-content');
        $h5.text(shopLookTitle).css("text-transform", "uppercase");
        $header.append($h5);
        $('.ui-dialog .ui-dialog-title').remove();
        $div.prepend($slickArrowPrev);
        $('.ui-dialog-titlebar.ui-corner-all').prepend($header);
        $div.append($slickContainer);
        $div.append($slickArrowNext);

        var prevBtn = $('<button>', {
                            'type': 'button',
                            'class': 'stl-carousel-arrow-prev slick-prev'
                        });
        $slickArrowPrev.append(prevBtn);
        var nextBtn = $('<button>', {
                            'type': 'button',
                            'class': 'stl-carousel-arrow-next slick-next'
                        });
        $slickArrowNext.append(nextBtn);

        for (var i=0; i < products.length; i++) {
            var productId = products[i].split('?')[0].split('-').pop().split('.')[0].toUpperCase().trim();
            var colorCode = products[i].split('&')[0].split('=')[1].toUpperCase().trim();
            $slickContainer.append('<a class="js-shop-the-look inner" data-products="'+products.join(',')+'" href="'+products[i]+'"><img src="https://www.madewell.com/images/'+productId+'_'+colorCode+'_ld?wid=280&hei=355&fmt=jpeg&fit=crop&qlt=75&resMode=bisharp&op_usm=0.5"/></a>');
        }
        $container.prepend($div);
    }
};

module.exports = shopTheLook;