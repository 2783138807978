'use strict';
var event = require('omniture/omniture/event');

/**
 * @description Initializes events for change fit model menu on pdp
 */
function initEvents($scope) {
    var $scope = $scope || $('.pt_product-details');

    $scope.off('click', '.js-fit-options');
    $scope.off('keydown', '.js-fit-options');
    $scope.off('click');

    $(document).on('click', '.js-fit-options', function (e) {
        toggleMenuVisibility(this);
        menuItemEvents(this,e);
    });

    $(document).on('keydown', '.js-fit-options', function(e) {
        switch (e.keyCode) {
            // space bar: open menu
            case 32:
                e.preventDefault();
                openMenu(this);
                return;
            // enter: change fit model images if selecting a menu item
            case 13:
                menuItemEvents(this,e);
                if (getMenuItem($(e.target))) {
                    closeMenu(this);
                }
                return;
            // escape: close the menu
            case 27:
                closeMenu(this);
                return;
            default:
                return;
        }
    });

    $scope.on('click', function (e) {
        var container = $scope.find('.js-fit-options');
        var isOpen = container.hasClass('fit-options--open');
        if (!$(e.target).closest(container).length && !$(e.target).is(container) && isOpen) {
            closeMenu(container);
        }
    });

    window.isFitOptionsInitialized = true;
}

/**
 * @description updates button text and images when fit model menu item is clicked
 */
function menuItemEvents(context,e) {
    var $clicked = $(e.target);
    var menuItem = getMenuItem($clicked)
    if (menuItem) {
        // update button with selected fit text
        var buttonTextEl = $('.js-fit-options-button-text',context),
            clickedHtml = menuItem.html();
        buttonTextEl.html(clickedHtml);

        swapImages(menuItem.data('shot-type'), context);
        triggerCounterEvents();
        handleQuickView();
    }
}


/**
 * @description toggles fit model menu visibility and aria expanded state
 */
function toggleMenuVisibility(container) {
    $(container).toggleClass('fit-options--open');
    var menu = $('.js-fit-options-menu', container),
        expanded = menu.attr('aria-expanded');
    menu.attr('aria-expanded', expanded === 'true' ? 'false' : 'true');
}

/**
 * @description opens fit model menu visibility and aria expanded state
 */
function openMenu(container) {
    $(container).addClass('fit-options--open');
    $('.js-fit-options-menu', container).attr('aria-expanded','true');
}

/**
 * @description closes fit model menu visibility and aria expanded state
 */
function closeMenu(container) {
    $(container).removeClass('fit-options--open');
    $('.js-fit-options-menu', container).attr('aria-expanded','false');
}

/**
 * @description checks if clicked element is a menu item, returns the menu item or null
 */
function getMenuItem(el) {
    if (el.is('.js-fit-options-menu-item')) {
        return el;
    }
    if (el.parents('.js-fit-options-menu-item').length > 0) {
        return el.parents('.js-fit-options-menu-item').first();
    }
    return null;
}

/**
 * @description swaps out fit model images
 */
function swapImages(shotType, context) {
    var $scope = $(context).closest('.pdp-main');
    $scope.find('.product-main-content').addClass('finder-active');
    $scope.find('.product-images .product-image,.product-image-container .product-image,.zoom-images-carousel .product-image').each(function () {
        var img = $(this).is('img') ? $(this) : $(this).find('img'),
            src = img.data('src'),
            srcSet = img.data('srcset');

        var regex = /(\?wid=)/g;
        var $src = src.replace(regex,'_' + shotType + '?wid=');
        img.attr('data-src', $src).attr('xoriginal', $src).attr('xpreview', $src);
        img.attr('data-srcset', srcSet.replace(regex,'_' + shotType + '?wid='));
    });

    setTimeout(function() {
        $scope.find('img.xzoom').each(function() {
            if ($(this).data('xzoom')) {
                $(this).data('xzoom').adaptive();
            }
        });
        $scope.find('.product-main-content').removeClass('finder-active');
    }, 500);
}

/**
 * @description triggers counter events for tracking change fit model interactions
 */
function triggerCounterEvents() {
    //Counter event to trigger whenever a fit model selector is available on the page and the visitor selects a fit model.
    event.pushEvent('event167', null, true);

    // event166
    // Fit Model Indicator
    // Counter event to trigger whenever a fit model selector is available on the page.
    event.pushEvent('event166', null, true);
    event.push();
}

/**
 * @description updates main image in quickview when changing fit model
 */
function handleQuickView() {
    var quickViewDialog = $('#QuickViewDialog');
    if (quickViewDialog.length === 1) {
        var activeSlide = $('.product-image-quickview img', quickViewDialog).data('slide') - 1;
        $('.product-image-zoom', quickViewDialog).eq(activeSlide).trigger('click');
    }
}

/**
 * @description aligns top of pdp images with right rail when fit options are present
 */
function positionRightRail() {
    var quickViewDialog = $('#QuickViewDialog');
    if ($('.js-fit-options').length > 0 && quickViewDialog.length !== 1) {
        $('.js-product-col-1').addClass('has-fit-options');
    }
}

function initStickyMenu() {
    const wrapper = document.querySelector('.js-desktop-images'),
        menu = document.querySelector('.js-fit-options-wrapper');
    if (!menu) {
        return;
    }

    function toggleStickyClasses() {
        if (!window.matchMedia('(min-width:1025px)').matches) {
            return;
        }
        // get distance of product images div from bottom of viewport
        // subtract 24px to account for padding
        const distance = wrapper ? wrapper.getBoundingClientRect().bottom - window.innerHeight - 24 : 0;
        if (distance<=0) {
            menu.classList.remove('fit-options-wrapper--sticky');
        } else {
            menu.classList.add('fit-options-wrapper--sticky')
        }
    }

    toggleStickyClasses();

    let timeout;

    window.addEventListener('scroll', function () {
        if (timeout) {
            window.cancelAnimationFrame(timeout);
        }
        timeout = window.requestAnimationFrame(function () {
            toggleStickyClasses();
        });
    }, false);
}

exports.init = function($scope) {
    initEvents($scope);
    positionRightRail();
    initStickyMenu();
}
