'use strict';

var Templates = {
    newLine: '\n',

    prefferedStoreTemplate: function (storeData) {
        var storeHours = '';
        var storeEvents = '';
        var storePhone = '';
        if (storeData.hours && storeData.hours.length) {
            storeHours = `<div class="mystore-timetable">
                            <h3 class="mystore-timetable-title">${Resources.ACCOUNT_MYSTORE_STOREHOURS}</h3>
                            <ul class="mystore-timetable-list">
                                ${storeData.hours.map((storeHoursItem) => `
                                    <li class="mystore-timetable-list-daytime">
                                        <span class="mystore-timetable-list-daytime-day">${storeHoursItem.day}</span>
                                        <span class="mystore-timetable-list-daytime-hour">${storeHoursItem.time}</span>
                                    </li>
                                `).join('')}
                            </ul>
                        </div>`
        }

        if (storeData.events) {
            storeEvents = `<div class="mystore-events">
                                <h3 class="mystore-events-title">${Resources.ACCOUNT_MYSTORE_STOREEVENTS}</h3>
                                <div class="mystore-events-text">${storeData.events}</div>
                            </div>`
        }
        if (storeData.phone) {
            storePhone = `<div class="mystore-phone">${storeData.phone}</div>`
        }
        var template =  `<div class="mystore">
                            <div class="mystore-address">
                                <h2 class="mystore-address-name">${storeData.name}</h2>
                                <div class="mystore-address-address">
                                    <div class="mystore-address-address1">${storeData.address1}</div>
                                    <div class="mystore-address-citystatezip">
                                        <span class="mystore-address-city">${storeData.city}</span>,
                                        <span class="mystore-address-state">${storeData.stateCode}</span>
                                        <span class="mystore-address-postal">${storeData.postalCode}</span>
                                    </div>
                                    ${storePhone}
                                </div>
                            </div>
                            <div class="mystore-actions">
                                <a href="javascript:void(0)" class="mystore-actions-change-store js-mystore-select" data-store-id="${storeData.storeId}">${Resources.ACCOUNT_MYSTORE_CHANGESTORE}</a><a href="https://google.com/maps/?q=${storeData.lat},${storeData.long}" class="mystore-actions-direction  js-mystore-directions" target="_blank">${Resources.ACCOUNT_MYSTORE_GETDIRECTIONS}</a>
                            </div>
                            ${storeHours}
                            ${storeEvents}
                        </div>
                        <div class="mystore-map-wrapper">
                            <div class="mystore-map js-mystore-map" id="js-mystore-map" data-store-lng="${storeData.long}" data-store-lat="${storeData.lat}"></div>
                        </div>`

        return template;
    },

    filterPLPByStoreTemplate: function (storeData) {
        var template = `${Resources.PLP_MYSTORE_NO_LABEL}`;
        if (storeData) {
            template = `${Resources.PLP_MYSTORE_LABEL} ${storeData.address1}`;
        }

        return template;
    },

    accountDrawerStoreTemplate: function (storeData) {
        var template = `<div class="mystore-address-address">
                    <div class="mystore-address-address1">${storeData.address1}</div>
                    <div class="mystore-address-citystatezip">
                        <span class="mystore-address-city">${storeData.city}</span>,
                        <span class="mystore-address-state">${storeData.stateCode}</span>
                        <span class="mystore-address-postal">${storeData.postalCode}</span>
                    </div>
                </div>`;

        return template;
    },

    findStoreTemplate: function (title, className = "") {
        title = title || Resources.STORE_NEAR_YOU;
        var template = `<div id="preferred-store-panel" class="${className}">
                            <h3 id="dialog-title">${title}</h3>
                            <div class="zip-retry-form-row">
                                <div class="zip-retry-inpu-wrapper">
                                    <label for="zipCode" title="Zip code" class="visually-hidden">Zip code</label>
                                    <input type="text" id="user-zip" placeholder="${Resources.ENTER_ZIP}" name="zipCode" aria-label="Zip code" value="${User.zip || ''}"/>
                                </div>
                                <button class="search-by-user-zip">${Resources.SEARCH_ANOTHER_ZIP}</button>
                            </div>
                        </div>
                        <div class="store-list-container loading-template">
                            <div class="ispu-loader">
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                            </div>
                        </div>
                        <div class="store-list-pagination"></div>`;
        return template;
    },

    storeListItemTemplate: function (store, selectedStoreId, selectedStoreText, chooseStoreText) {
        var hours = '';
        if (store && store.hours && store.hours.length) {
            hours = `<p class="store-tile-hours">
                        ${store.hours.map((hour) => `${hour.day}: ${hour.time}`).join('</br>')}
                     </p>`;
        }
        // let's use this flag to indicate that myAccount Set Store was invoked
        const myAccountSetStore = chooseStoreText !== undefined && chooseStoreText !== null && chooseStoreText !== '' ? true : false;
        // --//-- flag to indicat myAccount Set Store + this store is selected now
        const myAccountSetStoreSelected = myAccountSetStore && store.storeId && store.storeId == selectedStoreId ? true : false;
        var storeText = ($('.pt_cart').length > 0 || $('.pt_product-details').length > 0) ? Resources.SELECT_STORE : Resources.SELECT_VIEW_STORE;
        var selectStoreButton = `<button class="select-store-button"
                data-store-id="${store.storeId}"
                data-city="${store.city}"
                data-address="${store.address1}"
                data-country-code="${store.countryCode}">
                ${myAccountSetStore ? chooseStoreText : storeText}
            </button>`;
        var myStoreBadgeClass = '';
        var storeDistanceClassName = '';
        if (myAccountSetStoreSelected) {
            selectStoreButton = '';
            myStoreBadgeClass = `<span class="my-store-badge">${Resources.MY_STORE_BADGE}</span>`;
        } else if (store.storeId && store.storeId == selectedStoreId) {
            // myStoreBadgeClass = `<span class="my-store-text">${Resources.MY_STORE_TEXT}</span>`;
            storeDistanceClassName = 'plp-store-distance';
        }
        return `<li class="store-tile ${store.storeId && store.storeId == selectedStoreId ? 'selected' : ''}">
                    ${myStoreBadgeClass}
                    <h4 class="store-name">${store.name}</h4>
                    <p class="store-distance ${storeDistanceClassName}">(${store.distance} ${Resources.MILES_ABBREV})</p>
                    <p class="store-address">${store.address1}
                        <span class="down-arrow"></span>
                    </p>
                    <div class="store-address-info">
                        <p>${store.city}, ${store.stateCode} ${store.postalCode}</p>
                        <p><a target="_blank" href="https://google.com/maps/?q=${store.lat},${store.long}" title=${Resources.GET_DIRECTIONS}>${Resources.GET_DIRECTIONS}</a></p>
                        ${hours}
                    </div>
                    ${selectStoreButton}
                </li>`;
    },

    storeListTemplate: function (stores, selectedStoreId, selectedStoreText, chooseStoreText, title, disclaimer) {
        title = title || Resources.STORE_NEAR_YOU;
        var disclaimerContent = disclaimer ?  `<div class="store-list-disclaimer">${disclaimer}</div>` : '';
        if (stores && stores.length) {
            stores = stores.length > 4 ? stores.slice(0,4) : stores;

            return `<div id="preferred-store-panel">
                        <h2 id="dialog-title">${title}</h2>
                        <div class="zip-retry-form-row">
                            <div class="zip-retry-input-wrapper">
                                <label for="zipCode" title="Zip code" class="visually-hidden">Zip code</label>
                                <input type="text" id="user-zip" placeholder="${Resources.ENTER_ZIP}" name="zipCode" aria-label="Zip code" value="${User.zip || ''}"/>
                            </div>
                            <button class="search-by-user-zip">${Resources.SEARCH_ANOTHER_ZIP}</button>
                        </div>
                    </div>
                    <div class="store-list-container">
                        <ul class="store-list">
                            ${stores.map((store) => Templates.storeListItemTemplate(store, selectedStoreId, selectedStoreText, chooseStoreText)).join('')}
                         </ul>
                        ${disclaimerContent}
                    </div>`;
        } else if (User.previousStores && User.previousStores.length) {
            return Templates.retryWithStoresTemplate(User.previousStores, selectedStoreId, selectedStoreText, chooseStoreText, title);
        } else {
            return Templates.retryTemplate();
        }
    },

    retryWithStoresTemplate: function (stores, selectedStoreId, selectedStoreText, chooseStoreText, title) {
        var prevStores = stores.length > 6 ? stores.slice(0,6) : stores;
        title = title || Resources.STORE_NEAR_YOU;
        return `<div id="preferred-store-panel" class="zip-retry">
                    <h2 id="dialog-title">${title}</h2>
                    <div class="zip-retry-form-row">
                        <div class="zip-retry-inpu-wrapper">
                            <label for="zipCode" title="Zip code" class="visually-hidden">Zip code</label>
                            <input type="text" id="user-zip" placeholder="${Resources.ENTER_ZIP}" name="zipCode" aria-label="Zip code" value="${User.zip || ''}"/>
                        </div>
                        <button class="search-by-user-zip">${Resources.SEARCH_ANOTHER_ZIP}</button>
                    </div>
                </div>
                <div class="no-results">${Resources.TRY_ANOTHER_ZIP}</div>
                <div class="store-list-container">
                    <ul class="store-list">
                        ${prevStores.map((prevStore) => Templates.storeListItemTemplate(prevStore, selectedStoreId, selectedStoreText, chooseStoreText))}
                    </ul>
                </div>`
    },

    retryTemplate: function(message, title) {
        var msg = message || Resources.TRY_ANOTHER_ZIP;
        title = title || Resources.STORE_NEAR_YOU;
        return `<div id="preferred-store-panel" class="zip-retry">
                    <h2 id="dialog-title">${title}</h2>
                    <div class="zip-retry-form-row">
                        <div class="zip-retry-inpu-wrapper">
                            <label for="zipCode" title="Zip code" class="visually-hidden">Zip code</label>
                            <input type="text" id="user-zip" placeholder="${Resources.ENTER_ZIP}" name="zipCode" aria-label="Zip code" value="${User.zip || ''}"/>
                        </div>
                        <button class="search-by-user-zip">${Resources.SEARCH_ANOTHER_ZIP}</button>
                    </div>
                </div>
                <div class="no-results">${msg}</div>`
    }
};

module.exports = Templates;
