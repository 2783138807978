'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    progress = require('../../progress'),
    util = require('../../util'),
    googleplacesapi = require('../../googleplacesapi'),
    _ = require('lodash'),
    formater = require('../../formatCCNumber');
const { changeLocationCallBack } = require('../../storeinventory/product');

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    // $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    copyFirstLastNameToCC();
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    var selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();


    if (selectedPaymentMethodID == 'SA_SILENTPOST') {
        $creditCard.find('[name$="_month"]').val(data.expirationMonth);
        $creditCard.find('[name$="_year"]').val(data.expirationYear);
    } else {
        $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger(
                'change');
        $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger(
                'change');
    }

    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
    $creditCard.find('[name$="creditCard_selectedCardID"]').val(data.selectedCardID).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('');
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID, selectedPaymentMethod) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            switch (selectedPaymentMethod) {
                case 'SA_REDIRECT':
                    $('.payment-method-expanded .saCCToken .field-wrapper').val(
                            data.selectedCardID);
                    $('#dwfrm_billing_paymentMethods_creditCard_selectedCardID')
                            .val(data.selectedCardID);
                    break;
                case 'SA_IFRAME':
                    $('.payment-method-expanded .saIframeCCToken .field-wrapper')
                            .val(data.selectedCardID);
                    $('#dwfrm_billing_paymentMethods_creditCard_selectedCardID')
                            .val(data.selectedCardID);
                    break;
                case 'CREDIT_CARD':
                    setCCFields(data);
                    break;
                default:
                    setCCFields(data);
            }
        }
    });
}

function shippingAddressForBilling () {
    var $sameAsShipping = $('#same-as-shipping'),
        $enterNewAddress = $('#enter-new-address');

    if ($sameAsShipping.val() === 'false') {
        newAddressEvent();
    } else {
        sameAddressEvent();
    }

    $sameAsShipping.on('click', sameAddressEvent);
    $enterNewAddress.on('click', newAddressEvent);
}

function sameAddressEvent () {
    var $sameAsShippingInput = $('#same-as-shipping'),
        $enterNewAddress = $('#enter-new-address'),
        $checkoutForm = $('.checkout-billing'),
        $billingAddressSet = $('.billing-address-set'),
        $clearFormLink = $('.clear-address-form');

    var selectedAddress = $sameAsShippingInput.data('address');
    if (!selectedAddress) { return; }
    util.fillAddressFields(selectedAddress, $checkoutForm);
    $billingAddressSet.addClass('hide');
    $clearFormLink.addClass('hide');
    $sameAsShippingInput.val(true);
    $enterNewAddress.attr('checked', false);
    $enterNewAddress.attr('disabled', false);

    formPrepare.validateForm();
}

function newAddressEvent () {
    var $sameAsShipping = $('#same-as-shipping'),
        $sameAsShippingInput = $('#enter-new-address'),
        $checkoutForm = $('.checkout-billing'),
        $billingAddressSet = $('.billing-address-set'),
        $clearFormLink = $('.clear-address-form');

    util.clearAddressFields($checkoutForm);
    $billingAddressSet.removeClass('hide');
    $clearFormLink.removeClass('hide');
    $sameAsShippingInput.val(false);
    $sameAsShipping.attr('checked', false);
    $sameAsShipping.attr('disabled', false);


    //prevent error on init but works after a real error occurs
    if ($('.states .select-style').hasClass('select-style-error')) {
        $('.states .select-style').removeClass('select-style-error');
    }
    formPrepare.validateForm();
}


/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method');
    $paymentMethods.removeClass('payment-method-expanded');
    var summary = $('#secondary');
    var billingForm = $('#dwfrm_billing');

    function hidecustomPayments () {
        summary.find('.paypal').addClass('visually-hidden');
        summary.find('.applepay').addClass('visually-hidden');
        summary.find('.afterpay').addClass('visually-hidden');
        billingForm.find('.address-header').show();
        billingForm.find('.same-as-shipping').removeClass('hide-for-afterpay');
        billingForm.find('.clear-address-form.billing').removeClass('hide-for-afterpay');
        billingForm.find('.billing-address-set').removeClass('hide-for-afterpay');
        billingForm.find('.form-row-button').removeClass('hide-for-afterpay');
        summary.removeClass('remove-border');
    }
    hidecustomPayments();

    var dataMethod = paymentMethodID;
    if (paymentMethodID == 'SA_SILENTPOST') {
        dataMethod = 'CREDIT_CARD';
    }
    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="'
            + dataMethod + '"]');

    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    if (paymentMethodID == 'VISA_CHECKOUT') {
        $('.continue-place-order').hide();
        $('.visacheckoutbutton').show();
    } else if (paymentMethodID == 'PAYPAL'
            || paymentMethodID == 'PAYPAL_CREDIT') {
        $('#billingAgreementCheckbox').attr('checked', false);
        $('.continue-place-order').hide();
        $('#fakeContinueBtn').hide();
        summary.find('.paypal').removeClass('visually-hidden');
    } else {
        $('.continue-place-order').show();
        $('.visacheckoutbutton').hide();
    }
    if (paymentMethodID == 'CREDIT_CARD' || paymentMethodID == 'SA_SILENTPOST') {
        $('.spsavecard').show();
        hidecustomPayments();
        $('#fakeContinueBtn').show();
    } else if ((paymentMethodID == 'SA_REDIRECT' || paymentMethodID == 'SA_IFRAME')
            && SitePreferences.TOKENIZATION_ENABLED) {
        $('.spsavecard').show();
    } else {
        $('.spsavecard').hide();
    }

    if (paymentMethodID == 'DW_APPLE_PAY') {
        hidecustomPayments();
        $('#fakeContinueBtn').hide();
        summary.find('.applepay').removeClass('visually-hidden');
    }

    if (paymentMethodID == 'AFTERPAY_PBI') {
        hidecustomPayments();
        $('#fakeContinueBtn').hide();
        summary.find('.afterpay').removeClass('visually-hidden');
        billingForm.find('.address-header').hide();
        billingForm.find('.same-as-shipping').addClass('hide-for-afterpay');
        billingForm.find('.billing-address-set').addClass('hide-for-afterpay');
        billingForm.find('.clear-address-form.billing').addClass('hide-for-afterpay');
        billingForm.find('.form-row-button').addClass('hide-for-afterpay');
        summary.addClass('remove-border');
    }

    var isBicRequired = $selectedPaymentMethod.data('bicrequired');
    if (isBicRequired) {
        $('.bic-section').show();
    } else {
        $('.bic-section').hide();
    }

    $selectedPaymentMethod.addClass('payment-method-expanded');

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value="' + paymentMethodID + '"]').prop('checked', 'checked');

    formPrepare.validateForm();
}

/**
 * @function
 * @description Gift card & promo section events
 */
function collapsibleSections() {
    var $collapsible = $('.billing-collapsible legend'),
        $addedGC = $('.redemption.giftcert .success');

    // If session storage object gCExpanded is true or gc applied
    if (sessionStorage.gCExpanded == 'true' || $addedGC.length) {
        $('.gcpayment').parent().addClass('opened');
    }

    // Toggle gc & promo fields, add/remove session storage object gCExpanded
    $collapsible.on('click', function (){
        var $content = $(this).next();
        $content.slideToggle('fast', function() {
            if ($content.is(':visible')) {
                $(this).parent().addClass('opened');
                sessionStorage.gCExpanded = true;
            } else {
                $(this).parent().removeClass('opened');
                sessionStorage.gCExpanded = false;
            }
        });
    });

    // ADA - Run above code if enter key pressed
    $collapsible.keypress(function(e){
        if (e.which == 13){
            $(this).closest($collapsible).click();
        }
    });
}

/**
 * @function
 * @description Copy the Billing Address first and last names into the Credit Card Owner field
 */
function copyFirstLastNameToCC() {
    var $checkoutForm = $('.checkout-billing');
    var $ccContainer = $($checkoutForm).find('.payment-method').filter(function() {
        return $(this).data('method') == 'CREDIT_CARD';
    });
    var $billingAddy = $($checkoutForm).find('.billing-address-set');
    var $ccOwner = $ccContainer.find('input[name$="creditCard_owner"]');
    if ($ccOwner.length && $billingAddy.length) {
        var $firstname = $billingAddy.find('input[name$="_billingAddress_addressFields_firstName"]');
        var $lastname = $billingAddy.find('input[name$="_billingAddress_addressFields_lastName"]');
        $ccOwner.val($firstname.val() + ' ' + $lastname.val());
    }
}

/**
 * @function
 * @description Update the creditcard type dropdown in the page with the value determined by the passed-in cc shorthand
 * @param {String} ccStr the credit shorthand string, ex: "amex"
 */
function updateCcType (ccStr) {
    // target billing page and account payment page creditcard type elements
    var $ccType = $('select[name$="_creditCard_type"], select[name$="_newcreditcard_type"]');
    var $ccNumRow = $('.ccnumber-row');
    // used to map DW card type to jquery plugin cards
    var ccTypes = {
        'visa': 'Visa',
        'visa_electron': 'Visa',
        'amex': 'Amex',
        'mastercard': 'MasterCard',
        'discover': 'Discover'
    };

    const typeName = ccTypes[ccStr] || '';
    if (typeName) {
        $ccType.val(typeName);
    }
    $ccNumRow.attr('data-cctype', typeName);
}

/**
 * @function
 * @description Test which creditcard type a CC number value corresponds to
 * @param {String} ccNum the partial or full credit number to test
 */
function testCcType (ccNum) {
    const amexREGX = /^3[47]/; // starts with 34 or 37
    const visaREGX = /^4[0-9]/; // starts with 40 thru 49
    const mastREGX = /^2[2-7]|^5[2-5]/; // starts with 22 thru 27, or 52 thru 55
    const discREGX = /^6[045]/; // starts with 60, 64 or 65
    return amexREGX.test(ccNum) ? 'amex' : visaREGX.test(ccNum) ? 'visa' : mastREGX.test(ccNum) ? 'mastercard' : discREGX.test(ccNum) ? 'discover' : '';
}

/**
 * @description avoid write letters within CVN input (since is type "tel" which allows writing text)
 */
$(document).off('input.only-decimals').on('input.only-decimals', '#dwfrm_billing_paymentMethods_creditCard_cvn', function() {
    this.value = this.value.replace(/[^\d]/g, ''); 
});

module.exports = {
    updateCcType: updateCcType,
    testCcType: testCcType,
    /**
     * @function
     * @description loads billing address, Gift Certificates, Coupon and Payment methods
     */
    init: function () {
        var $checkoutForm = $('.checkout-billing');
        var $addGiftCert = $('#add-giftcert');
        var $giftCertCode = $('input[name$="_giftCertCode"]');
        var $giftCertPin = $('input[name$="_giftCertPin"]');
        var $addCoupon = $('#cart-items-form #add-coupon');
        var $couponCode = $('input[name$="_couponCode"]');
        var $selectPaymentMethod = $('.payment-method-options');
        var selectedPaymentMethod = $selectPaymentMethod.find(':checked').length ? $selectPaymentMethod.find(':checked').val() : '';

        formPrepare.init({
            isBilling: true,
            formSelector: 'form[id$="billing"]',
            continueSelector: '[name$="billing_save"]',
            fakeContinue:'#fakeContinueBtn'
        });
        // initialize Google autocomplete if Maps is loaded
        googleplacesapi.init();
        collapsibleSections();

        $('#secondary').on('click','#fakeContinueBtn', function(e) {
            e.preventDefault();
            $('input[name*="_number"]').each(function() {
                var val = $(this).val().replace(/ /g, '');
                $(this).val(val);
            })
            $('button[name$="_billing_save"]').trigger('click');
        });

        var $ccContainer = $($checkoutForm).find('.payment-method').filter(function() {
            return $(this).data('method') == 'CREDIT_CARD';
        });
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');

        $ccContainer.find('input[name*="_number"]').on('change', function() {
            $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
        });
        $ccContainer.find('input[name*="_number"]').on('input.cc', _.debounce((e) => {
            const cardType = testCcType(e.target?.value);
            updateCcType(cardType);
            var inputElement = $(e.target);
            formater.formater(inputElement);
            var isCVVBlur = $ccContainer.find('input.cvv').val().includes('*');
            if(isCVVBlur) {
                $('input.cvv').val('');
            }
        }, 50)).on('focus', function () {
            this.select();
        });

        // $ccContainer.find('input[name$="_owner"]').on('change', function() {
        //     $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
        // });

        var $billingAddy = $($checkoutForm).find('.billing-address-set');
        var $firstname = $billingAddy.find('input[name$="_billingAddress_addressFields_firstName"]');
        var $lastname = $billingAddy.find('input[name$="_billingAddress_addressFields_lastName"]');

        // // updated the CC name field initially, and then on change of first or last at any point
        copyFirstLastNameToCC();
        $firstname.on('change', function() {
            copyFirstLastNameToCC();
        });
        $lastname.on('change', function() {
            copyFirstLastNameToCC();
        });

        $ccContainer.find('select[name*="expiration"]').on('change', function() {
            $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
            var selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();
            var cardNumber = $($checkoutForm).find('input[name*="_number"]').val();
            if (cardNumber.indexOf('****') != -1 && selectedPaymentMethodID == 'SA_SILENTPOST') {
                $($checkoutForm).find('input[name*="_number"]').val('');
            }
        });

        // checking if some payment method already user
        updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : '');

        $selectPaymentMethod.on('click', 'input[type="radio"]', function() {
            updatePaymentMethod($(this).val());
        });

        // work on logic based on comps
        $(document).on('click', '.form-modifier__addCard', function (e) {
            e.preventDefault();
            $('.credit-card-block').removeClass('d-none');
            if ($('.hideSaveCard').length > 0) {
                $('.hideSaveCard').addClass('d-none');
            }
            $('.form-modifier').addClass('d-none');
            $checkoutForm.find('[name$="creditCard_number"]').val('').trigger('change');
            $checkoutForm.find('[name$="creditCard_type"]').val('').trigger('change');
            $checkoutForm.find('[name$="creditCard_expiration_month"]').val('').trigger('change');
            $checkoutForm.find('[name$="creditCard_expiration_year"]').val('').trigger('change');
            $checkoutForm.find('[name$="creditCard_cvn"]').val('').trigger('change');
            $checkoutForm.find('[name$="creditCardList"]').val('').trigger('change');
            $checkoutForm.find('[id$=creditCardList] option:selected').removeAttr('selected');
            $checkoutForm.find('[id$=creditCard_expiration_month] option:selected').removeAttr('selected');
            $checkoutForm.find('[id$=creditCard_expiration_year] option:selected').removeAttr('selected');
            $checkoutForm.find('[id$=creditCard_type] option:selected').removeAttr('selected');
            $checkoutForm.find('input[name$="_selectedCardID"]').val('');
        });

        // select saved credit card from list
        $('#creditCardList').on('change', function () {
            var cardUUID = $(this).val();
            if (!cardUUID) {
                $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
                return;
            }
            populateCreditCardForm(cardUUID, selectedPaymentMethod);
            
            var selectedOption = $(this).find('option:selected');
            if(selectedOption.text().includes('Amex')) {
                $('#dwfrm_billing_paymentMethods_creditCard_cvn').attr('maxlength', 4)
            } else {
                $('#dwfrm_billing_paymentMethods_creditCard_cvn').attr('maxlength', 3)
            }

            // remove server side error
            $('.required.error').removeClass('error');
            $('.error-message').remove();
            $('.credit-card-block').addClass('d-none');
            $('.form-modifier').removeClass('d-none');
        });

        $('input[name*="_creditCard_number"]').on('change.update-cvn-max', function() {
            var ccType = $(this).parents('.ccnumber-row').attr('data-cctype');
            if (!ccType && $(this).attr('cctype')) {
                ccType = $(this).attr('cctype');
            }

            if($('#dwfrm_billing').find('.credit-card-block').hasClass('d-none')) {
                return
            }
            if(ccType == 'Amex') {
                $('#dwfrm_billing_paymentMethods_creditCard_cvn').attr('maxlength', 4)
            } else {
                $('#dwfrm_billing_paymentMethods_creditCard_cvn').attr('maxlength', 3)
            }
        })

        $('*[name*="creditCard_expiration"]').on('change', function () {
            $('.error-message').remove();
        });

        $('#check-giftcert').on('click', function (e) {
            e.preventDefault();
            progress.show($('.payment-method.gcpayment'));
            var $balance = $('.balance'),
                couponContainer = $('.gcpayment .billing-coupon'),
                error = couponContainer.find('.giftcert-error');

            error.empty();

            if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
                if (error.length === 0) {
                    error = $('<span>').addClass('error').appendTo($balance);
                }
                error.html(Resources.GIFT_CERT_MISSING);
                progress.hide();
                return;
            }

            if ($giftCertPin.length === 0 || $giftCertPin.val().length === 0) {
                if (error.length === 0) {
                    error = $('<span>').addClass('error').appendTo($balance);
                }
                error.html(Resources.GIFT_CERT_PIN_MISSING);
                progress.hide();
                return;
            }

            giftcard.checkBalance($giftCertCode.val(), $giftCertPin.val(), function (data) {
                if (!data || !data.giftCertificate || !data.message) {
                    // $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                    error.html(Resources.GIFT_CERT_INVALID);
                    progress.hide();
                    return;
                }
                $balance.html(data.message).removeClass('error').addClass('success');
                progress.hide();
            });
        });

        $addGiftCert.on('click', function (e) {
            e.preventDefault();
            progress.show($('.payment-method.gcpayment'));
            var code = $giftCertCode.val(),
                pin = $giftCertPin.val(),
                $error = $checkoutForm.find('.giftcert-error');

            $error.empty();
            if (code.length === 0) {
                $error.html(Resources.GIFT_CERT_MISSING);
                progress.hide();
                return;
            }

            if (pin.length === 0) {
                $error.html(Resources.GIFT_CERT_PIN_MISSING);
                progress.hide();
                return;
            }

            $error.empty();
            var url = util.appendParamsToUrl(Urls.addGiftCert, {giftCertCode: code, giftCertPin: pin, format: 'ajax'});
            $.getJSON(url, function (data) {
                var fail = false;
                var msg = '';
                if (!data || $.isEmptyObject(data)) {
                    msg = Resources.BAD_RESPONSE;
                    fail = true;
                } else if (!data.success && data.message) {
                    msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                    fail = true;
                }
                if (fail) {
                    $error.html(msg);
                    progress.hide();
                    return;
                } else {
                    window.location.assign(Urls.billing);
                    progress.hide();
                }
            });
        });

        $('#primary').on('click', '.billing-coupon #add-coupon', function (e) {
            e.preventDefault();
            var $error = $('#primary .billing-coupon').find('.coupon-error'),
                code = $couponCode.val(),
                $success = $('.redemption.coupon');

            if (code.length === 0) {
                $error.html(Resources.COUPON_CODE_MISSING);
                return;
            }

            var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
            $.getJSON(url, function (data) {
                var fail = false;
                var msg = '';
                if (!data) {
                    msg = Resources.BAD_RESPONSE;
                    fail = true;
                } else if (!data.success) {
                    if (data.status == 'COUPON_CODE_ALREADY_IN_BASKET') {
                        msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                        msg = $('<div class="success">' + msg + '</div>');
                    } else {
                        msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                        fail = true;
                    }
                }
                if (fail) {
                    $(document).trigger('addCouponError', [ {data: data} ]);
                    $error.html('<p>' + msg + '</p>').show();
                    return;
                }

                //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
                //this will force a page refresh to display the coupon message based on a parameter message
                if (data.success) {
                    $(document).trigger('addCouponSucess', [ {data: data} ]);
                    window.location.assign(Urls.billing);
                }  else if (data.success == false && data.status == 'COUPON_CODE_ALREADY_IN_BASKET') {
                    $error.hide();
                    $success.prepend('<div class="success">' + data.message + '</div>');
                }
            });
        });
        $('#primary').on('click', '.redemption .delete-coupon', function (e) {
            e.preventDefault();
            var code = $(this).data('code');

            var url = util.appendParamsToUrl(Urls.deleteCoupon, {couponCode: code, format: 'ajax'});
            $.getJSON(url, function (data) {
                //this will force a page refresh to display the coupon message based on a parameter message
                if (data.success) {
                    window.location.assign(Urls.billing);
                }
            });
        });

        shippingAddressForBilling();
        // trigger events on enter
        $couponCode.on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                $addCoupon.click();
            }
        });
        $giftCertCode.on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                $addGiftCert.click();
            }
        });

        if (window.ApplePaySession) {
            $('.payment-method-options').find('.apple-pay').removeClass('visually-hidden');
        }

        var ccl = $('#creditCardList');
        if (ccl.val() && ccl.val().length > 0) {
            ccl.trigger('change');
        }

        $(function() {
            var inputElement = $('input[name*="_number"]');
            if (inputElement.length > 0 && inputElement.val().length > 0) {
                inputElement.trigger('change');
            }
        });
        
        //change address1 autocomplete "off" to "address-line1"
        $(function () {
            var $address1Selector = $('input[name$="_addressFields_address1"]');
            if ($address1Selector.length > 0) {
                util.address1Observer($address1Selector);
            }
        })

        //delete cvn duplicated error message
        $(function () {
            if ( $(".cvn-group").find("span.error").length && $(".cvn-group").find(".error-message").length ) {
                $(".cvn-group").find(".error-message").remove();
            }
        });
    }
};
