'use strict';
var initEventHandler = function () {
    $('.back-to-top-btn').on('click', function () {
        $('body, html').animate({scrollTop: 0});
    });
};

var backToTop = function () {
    var isRendered = false;
    var backToTopOffset = getBackToTopOffset();

    $(window).scroll(function () {
        var topOffset = window.pageYOffset + window.innerHeight - 50;
        if (topOffset > (backToTopOffset - 1) && !isRendered) {
            $('.back-to-top-btn').fadeIn();
            isRendered = true;
        } else if (topOffset < backToTopOffset && isRendered) {
            isRendered = false;
            $('.back-to-top-btn').fadeOut();
        }
    }).resize(function () {
        backToTopOffset = getBackToTopOffset();
    });
};

// get the scroll position where the back-to-top button should appear, based on viewport
var getBackToTopOffset = function () {
    var topOffset = 1550, //default
        pageWidth = window.innerWidth;

    if (pageWidth < 767) {
        topOffset = 1750; // mobile
    } else if (pageWidth < 1024) {
        topOffset = 1300; //tablet
    }

    return topOffset;
};

module.exports = function () {
    initEventHandler();
    backToTop();
};
