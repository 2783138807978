/**
 * @function
 * @description
 * @param {Element} input received to apply mask while typing.
 */

var formater = function (input) {
    var $self = input;
    var lastValue = '';

    $($self).on('input', function(e) {
        var cardNumber = $(this).val().replace(/\s/g, '');
        var ccType = $self.parents('.ccnumber-row').attr('data-cctype');
        var formattedValue = '';
        var cursorPosition = this.selectionStart;
      
        var groupSizes = ccType === 'Amex' ? [4, 6, 5] : [4, 4, 4, 4];
        var maxLength = ccType === 'Amex' ? 17 : 19;
        $self.attr('maxlength', maxLength);
      
        var i = 0;
        for (var groupSize of groupSizes) {
            var chunk = cardNumber.substr(i, groupSize);
            if (chunk.length > 0) {
                formattedValue += chunk + ' ';
                i += groupSize;
            } else {
                break;
            }
        }
      
        if (formattedValue.length > 0) {
            formattedValue = formattedValue.trimEnd();
        }
      
        var spaceCount = (formattedValue.slice(0, cursorPosition).match(/ /g) || []).length;
        var adjustedCursorPosition = cursorPosition + spaceCount;
      
        if (formattedValue === lastValue + ' ' && formattedValue !== $(this).val()) {
            $(this).val(lastValue);
        } else {
            $(this).val(formattedValue);
            lastValue = formattedValue;

            if (adjustedCursorPosition > formattedValue.length) {
                adjustedCursorPosition = formattedValue.length;
            }
            this.setSelectionRange(adjustedCursorPosition, adjustedCursorPosition);
        }
    });

    $($self).on('keyup', function(e) {
        var currentValue = $(this).val();
        var caretPosition = this.selectionStart;
        var ccType = $self.parents('.ccnumber-row').attr('data-cctype');
        var allowedPositions = ccType === 'Amex' ? [4, 11] : [4, 9, 14];
        if (e.which === 32) {
            var currentCharacter = currentValue.charAt(caretPosition - 1);
            if (currentCharacter === ' ') {
                return;
            }
            if (allowedPositions.includes(caretPosition)) {
                var newValue = [currentValue.slice(0, caretPosition), ' ', currentValue.slice(caretPosition)].join('');
                $(this).val(newValue);
                this.setSelectionRange(caretPosition + 1, caretPosition + 1);
            } else {
                e.preventDefault();
            }
        }
    });
};

exports.formater = formater;