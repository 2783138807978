'use strict';
var util = require('./util');

/**
 * @function initializeRecommendationsTabs
 * @description Initializes the tabbed recommendation section
 */
function initializeRecommendationsTabs() {
    $('#primary').on('recommendationsFetched', function() {
        handleRecommendationsFetched();
    });
    $('.recommendation-tab').on('click', 'button', function() {
        updateSelectedTab($(this).parent().data('tab'));
    })
}

/**
 * @function handleRecommendationsFetched
 * @description Called after recommendations slot content is loaded. Hides any tabs that do not have at least 2 products
 * @param {Number} iteration
 */
function handleRecommendationsFetched() {
    var $recommendations = $('.cart-recommendations');
    $recommendations.find('.cart-rec-container').each(function () {
        var $recContainer = $(this),
            headerCallout = $recContainer.find('.product-listing').data('callout'),
            $recTab = $('.recommendation-tab[data-tab="' + $recContainer.attr('ID') + '"]');
        // if we have 2 or fewer products in a tab, hide the tab and the tabbed content
        if ($recContainer.find('.product-tile').length <= 2) {
            $recContainer.removeClass('selected');
            $recContainer.addClass('visually-hidden');
            $recTab.removeClass('selected');
            $recTab.addClass('visually-hidden');
        } else {
            $recTab.removeClass('visually-hidden');
            initRecommendationTiles($recContainer);
        }
        // use callout message as tab header, if available
        if (headerCallout) {
            $recTab.find('span').text(headerCallout);
        }
        // hide prices if country of ship is not US
        if ($('.ship-to-country-name').html() !== 'United States') {
            $('.grid-tile .product-tile .product-pricing').hide()
        }
    });

    // check if we have multiple tabs of recommendations visible
    updateTabDisplay();
    
    
    // show recommendations tabs once recommendations are loaded
    $recommendations.removeClass('visually-hidden');
    
    // initialize carousels
    initializeCarousel($('.cart-rec-container').not('.visually-hidden'));

    // if we have hidden the first tab, open the next available tab
    if ($('.recommendation-tab').not('.visually-hidden').length > 0) {
        $('.recommendation-tab').not('.visually-hidden').first().find('button').trigger('click');
    }
}

/**
 * @function updateSelectedTab
 * @description updates the selected tab and tabbed content
 */
function updateSelectedTab(tabId) {
    // return if the clicked tab is already selected
    if ($('.recommendation-tab.selected').data('tab') === tabId) {
        return;
    }
    $('.recommendation-tab').each(function() {
        if ($(this).data('tab') === tabId) {
            $(this).addClass('selected');
        } else {
            $(this).removeClass('selected');
        }
    });
    $('.cart-rec-container').each(function () {
        if ($(this).attr('id') === tabId) {
            $(this).removeClass('visually-hidden');
            initializeCarousel($(this));
        } else if (!$(this).hasClass('visually-hidden')) {
            $(this).addClass('visually-hidden');
        }
    });
}

/**
 * @function updateTabDisplay
 * @description checks if we have more than 1 visible tab of recommendations. 
 * If so, uses callout header to set the text for the tab
 */
function updateTabDisplay() {
    var $visibleTabs = $('.recommendation-tab').not('.visually-hidden'),
        $tabContainer,
        $calloutHeader;
    
    if ($visibleTabs.length > 0) {
        // if we have 1 or more tabs, hide the h3 headers and set the tab text
        $visibleTabs.each(function() {
            $tabContainer = $('#' + $(this).data('tab'));
            $calloutHeader = $tabContainer.find('h3.recomm-header');
            if ($calloutHeader.text().length) {
                // if callout is set in the slot config, use that value
                $(this).find('span').text($calloutHeader.text());
            } else {
                // if no callout is specified, set tab text to the title for that slot type
                $(this).find('span').text($(this).data('title'));
            }
            $calloutHeader.hide();
        });
        $('.cart-recommendations').addClass('tabbed');
        
        // need to determine which is first and last visible element
        $visibleTabs.removeAttr('data-position');
        $visibleTabs.first().attr('data-position', 'first');
        $visibleTabs.last().attr('data-position', 'last');
    }
}

/**
 * @function initRecommendationTiles
 * @description initializes the alt image rollover for recommendation product tiles
 */
function initRecommendationTiles($container) {
    var isMobileSize = util.isMobileSize();
    $container.on('mouseenter touchstart', '.product-image', function () {
        // Hide the primary image and show the alternate image if one exists
        if (!isMobileSize) {
            var $altImage = $(this).find('.alternate-image[src]');
            if ($altImage.length) {
                $(this).find('.primary-image').removeClass('visible');
                $altImage.addClass('visible');
            }
        }
    }).on('mouseleave touchend', '.product-image', function () {
        // Hide the alternate image and show the primary image if one exists
        if (!isMobileSize) {
            var $altImage = $(this).find('.alternate-image[src]');
            if ($altImage.length) {
                $altImage.removeClass('visible');
                $(this).find('.primary-image').addClass('visible')
            }
        }
    });
}

/**
 * @function
 * @description Initialize events for carousels
 */
function initializeCarousel($container) {
    var $tilesContainer = $container.find('.tiles-container');
    $tilesContainer.not('.slick-initialized').slick({
        infinite: false,
        arrows: true,
        dots: false,
        slidesToShow: 4,
        slide: '.grid-tile',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            }
        ]
    });
    $tilesContainer.removeClass('pre-carousel');
    $tilesContainer.slick('refresh');
}

exports.init = initializeRecommendationsTabs;
