/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var event = require('../event');

var search = {
    init: function (){
        if (window.location.href.indexOf('q=') != -1) {
            
            // event12
            // Internal Search
            // Counter event to fire when a user conducts a search through the search functionality OR visits an internal search page. Does not fire on internal search redirects.
            event.pushEvent('event12', null, true);
            
            if ($('.nohits-body').size() > 0) {
                
                // event15
                // Null Searches
                // Fires when the search keyword is not able to display an array. When this event is triggered, event 12 will also trigger to indicate that an internal search was performed.
                event.pushEvent('event15', null, true);
            }
            event.push();
        }
        
        $(document).on('change', '#grid-sort-header', function () {
            
            // event140
            // Page Sort Selection
            // Counter event to trigger on the page load for any page that have a sort option AND a user manually changed the sort option. This will always trigger with the "Page Sort Type" eVar and prop.
            event.pushEvent('event140');
            
            var $el = $(this);
            var $selectedOption = $el.find(':selected');
            var sertingRule = $selectedOption.html();
            
            // eVar18
            // Sort Type (v18)
            // Captures the sorting selection value when a user chooses to sort a product array.
            event.pushEVar('eVar18', sertingRule);
            
            // prop26
            // Sort Type
            // Captures the sorting selection value when a user chooses to sort a product array.
            event.pushProp('prop26', sertingRule);
        });
        
        $(document).on('click', '.pagination li > a', function () {
            
            // event148
            // Pagination Selection
            // Counter event to trigger whenever a use selects a pagination value.
            event.pushEvent('event148');
        });
    }
};

exports.init = search.init;
