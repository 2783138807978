/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var event = require('../event'),
    util = require('org/util');

var bazaarvoice = {
    init: function (){
        bazaarvoice.write();

        $(document).on('click', '#BVButtonPreviewID', function () {
            bazaarvoice.review();
        });

        $(document).on('click', '#BVButtonEditID', function () {
            bazaarvoice.write();
        });

        $(document).on('click', '#BVButtonSubmitID', function () {
            bazaarvoice.submit();
        });
    },

    write: function () {
        var q = util.getQueryStringParams(util.getQueryString(window.location.href)),
            pid = '';

        if ('pid' in q) {
            pid = q.pid;
        }
        var action = 'WRITE YOUR REVIEW ('+pid+')';
        bazaarvoice.push(action);
    },

    submit: function () {
        var action = 'THANKS FOR SHARING YOUR FEEDBACK (!)';
        bazaarvoice.push(action);

        // event165
        // Submit Product Review
        // Counter event to trigger whenever a visitor successully submits a product review.
        event.pushEvent('event165');
    },

    review: function () {
        var action = 'LET\'S DOUBLE-CHECK YOUR REVIEW';
        bazaarvoice.push(action);
    },

    push: function (action) {
        var pipeline = 'Bazaarvoice-SubmissionContainer';
        event.pushProp('prop11','Madewell:Ratings & Reviews Submission:' + action + ':' + pipeline);
    }
}

exports.init = bazaarvoice.init;
