'use strict';

var _ = require('lodash'),
    util = require('../util'),
    dialog = require('../dialog'),
    inventory = require('./'),
    StoreHelper = require('../store');

var instoreDeliveryComponents = $('.instore-delivery label[for="delivery_option_store"], .instore-delivery, .selected-store-address');
var newLine = '\n';
var pdpStoreTemplate = function (store) {
    return [
        '<li class="store-list-item ' + (store.storeId === User.storeId ? ' selected' : '') + '">',
        '    <div class="store-address">' + store.address1 + ', ' + store.city + ' ' + store.stateCode +
        ' ' + store.postalCode + '</div>',
        '    <div class="store-status" data-status="' + store.statusclass + '">' + store.status + '</div>',
        '</li>'
    ].join(newLine);
};
var pdpStoresListingTemplate = function (stores) {
    if (stores && stores.length) {
        return [
            '<div class="store-list-pdp-container">',
            (stores.length > 1 ? '    <a class="stores-toggle collapsed" href="#">' + Resources.SEE_MORE + '</a>' : ''),
            '    <ul class="store-list-pdp">',
            _.map(stores, pdpStoreTemplate).join(newLine),
            '    </ul>',
            '</div>'
        ].join(newLine);
    }
};

var storesListing = function (stores) {
    // list all stores on PDP page
    if ($('.store-list-pdp-container').length) {
        $('.store-list-pdp-container').remove();
    }
    $('.availability-results').append(pdpStoresListingTemplate(stores));
};

var productInventory = {
    productSelectStore: function () {
        var self = this;
        var params = null;
        if (window.mapsLoaded) {
            var geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({'address': User.zip}, function (results, status) {
                if (status === window.google.maps.GeocoderStatus.OK && results[0].geometry.location) {
                    var location = results[0].geometry.location;
                    var isMonogrammed = false;
                    var personalizedOption = $('[data-optionid="isPersonalized"]').find(':selected').attr('value');
                    if (personalizedOption != null && typeof personalizedOption != 'undefined') {
                        if ($('[data-optionid="isPersonalized"]').find(':selected').attr('value').toLowerCase() == 'yes') {
                            isMonogrammed = true;
                        }
                    }
                    params = {
                        pid: self.pid,
                        lat: location.lat(),
                        long: location.lng(),
                        isMonogrammed: isMonogrammed
                    };
                    self.getStoresAjax(params);
                }
            });
        } else {
            params = {
                pid: self.pid,
                zipCode: User.zip
            };
            self.getStoresAjax(params);
        }
    },
    getStoresAjax: function (params) {
        $.ajax({
            url: util.appendParamsToUrl(Urls.storesInventory, params),
            dataType: 'json',
            success: function (stores) {
                inventory.selectStoreDialog({
                    stores: stores,
                    pid: params.pid,
                    selectedStoreId: User.storeId,
                    selectedStoreText: Resources.PREFERRED_STORE,
                    lat: params.lat,
                    long: params.long,
                    continueCallback: function () {
                        storesListing(stores)
                    },
                    selectStoreCallback: inventory.setPreferredStore
                });
            }
        });
    },
    changeLocationCallBack: function () {
        inventory.setUserZip(null);
        // trigger the event to start the process all over again
        $('.set-preferred-store').trigger('click');
    },
    updateAvailabilityMessaging: function(isMonogrammed) {
        var $statusMsg = $('.store-info-wrap').find('.isavail'),
            $nonMonogramStatus = $('input#nonMonogramAvailability'),
            $availabilityAttr = $('input#availableInStore');
        if (isMonogrammed) {
            $nonMonogramStatus.val($statusMsg.data('status'));
            $statusMsg.data('status', 'STS_monogramming');
            $statusMsg.text(Resources.ISPU_STS_monogramming);
            $availabilityAttr.val('false');
        } else {
            if ($nonMonogramStatus.val()) {
                var status = $nonMonogramStatus.val();
                $statusMsg.html('<p class="store-status">' + Resources['ISPU_' + status] + '</p>');
                if (status.indexOf('STS') > -1) {
                    $availabilityAttr.val('false');
                } else {
                    $availabilityAttr.val('true');
                }
            }
        }
    },
    hideShowStoreDetails: function (isHomeDelivery) {
        var $storeDetails = $('.store-info'),
            $storeDetailsWrap = $('.store-info-wrap'),
            $preferredStoreLink = $('.set-preferred-store'),
            $storePickUpError = $('.in-store-pickup-error'),
            isMaster = $('#isMasterProduct').val(),
            isQuickView = $('#QuickViewDialog').length,
            itemNotAvailForISPU = $('.notAvailForIspuMessage').length > 0,
            storeNotSelected = $('.selected-store-address').text() == '',
            storeStatus = $('.isavail').data('status');

        // if product is a master not on quickview and not pick up unavailable, display the instore link as grayed out
        if (isMaster == 'true') {
            if (!isQuickView) {
                $('.instore-delivery').addClass('unavailable');
                $('.selected-store-address').addClass('unavailable');
            }
            $storePickUpError.removeClass('visually-hidden');
        } else {
            $storePickUpError.not('.visually-hidden').addClass('visually-hidden');
        }
        //do not grey out the ispu radio if user has not selected a store
        if (storeNotSelected) {
            $('.instore-delivery').removeClass('unavailable');
        }
        if (itemNotAvailForISPU && !isQuickView) {
            $('.instore-delivery').addClass('unavailable');
        }

        if (isHomeDelivery) {
            if (storeStatus != 'NA') {
                // hide store details, ISPU error message, and change link when home delivery option is selected
                $storeDetails.addClass('hidden');
                $storeDetailsWrap.addClass('hidden');
                $preferredStoreLink.addClass('hidden');
                $storePickUpError.addClass('hidden');
            }
            $('.swatchanchor').each(function (){
                var href = $(this).attr('href').replace('&ispu=true','');
                $(this).attr('href', href);
            });
        } else { // in-store pickup
            $storeDetails.removeClass('hidden');
            $storeDetailsWrap.removeClass('hidden');
            $preferredStoreLink.removeClass('hidden');
            $storePickUpError.removeClass('hidden');
            $('.swatchanchor').each(function (){
                // update swatch URLs with ISPU param to retain in-store option selection when color/size is changed on the PDP
                var href = $(this).attr('href') + '&ispu=true';
                $(this).attr('href', href);
            });
            if (isMaster == 'false') { // hide error and enable "change store" link if a variant is selected
                $preferredStoreLink.find('a').removeClass('disabled');
                $preferredStoreLink.removeClass('disabled');
            } else { // disable links and display error if no variant is selected
                $preferredStoreLink.find('a').addClass('disabled');
                $preferredStoreLink.addClass('disabled');
            }
        }
        if (storeStatus == 'NA') {
            $('input.delivery-option[value="store"]').attr('disabled', true);
            $('label[for="delivery_option_store"]').addClass('NA');
            if (!$('input.delivery-option[value="home"]').is(':checked')) {
                $('input.delivery-option[value="home"]').trigger('click');
            }
        }
    },
    initGetItByTooltip: inventory.initGetItByTooltip,
    init: function () {
        var $availabilityContainer = $('.availability-results'),
            self = this,
            isQuickView = $('#QuickViewDialog').length;
        this.pid = $('input[name="pid"]').val();

        var loadingTemplate = function (stores, selectedStoreId, selectedStoreText, pid, title) {
            title = title || Resources.STORE_NEAR_YOU;
            return [
                '<div id="preferred-store-panel" class="store-inventory-search get-it-by">',
                '     <h3 id="dialog-title">' + title + '</h3>',
                '     <div class="zip-retry-form-row">',
                '     <div class="zip-retry-inpu-wrapper">',
                '           <label for="zipCode" title="Zip code" class="visually-hidden">Zip code</label>',
                '           <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode" aria-label="Zip code"/>',
                '           <input type="hidden" id="ispu-pid" name="ispu-pid" value="' +pid+ '"/>',
                '     </div>',
                '     <button class="search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
                '     </div>',
                '</div>',
                '<div class="store-list-container loading-template">',
                '    <div class="ispu-loader">',
                '       <span>&nbsp;</span>',
                '       <span>&nbsp;</span>',
                '       <span>&nbsp;</span>',
                '    </div>',
                '</div>',
                '<div class="store-list-pagination">',
                '</div>'
            ].join(newLine);
        };

        $('#product-content .set-preferred-store').on('click', function () {
            if ($(this).hasClass('disabled')) {
                return;
            }

            var loadingOptions = {
                dialogClass: 'ispu-select-store'
            };

            $(instoreDeliveryComponents).removeClass('unavailable');
            var isMaster = $('#isMasterProduct');
            var variationSelected = $('.size .selectable.selected').length > 0 && $('.color .selectable.selected').length > 0;
            if (isMaster.length > 0 && isMaster.val() == 'true' && !variationSelected) {
                $(this).find('span').addClass('disabled');
                $(this).addClass('disabled');
                return;
            }
            //initial dialog to let the user know stores are loading
            dialog.open({
                html: loadingTemplate,
                options: loadingOptions
            });

            const bindSearchByZip = function () {
                $('.store-inventory-search .search-by-user-zip').off('click.inventory-search').on('click.inventory-search', function (e) {
                    // there's another binding in store.js that would trigger unless we prevent it
                    e.stopPropagation();

                    var zipCode = $('#user-zip').val();
                    var loadingOptions = {
                        modal: true,
                        width: 'auto',
                        height: 'auto',
                        dialogClass: 'ispu-select-store'
                    };

                    if (StoreHelper.validateZipCode(zipCode)) {
                        User.previousStores = [];

                        dialog.open({
                            html: loadingTemplate,
                            options: loadingOptions
                        });
                        StoreHelper.setUserZip(zipCode);
                        self.productSelectStore();

                        // once the "searching dialog" we need to rebind
                        // the search-by-zip click, otherwise
                        // the user would get stuck if the zipcode backend service fails
                        // or something like that
                        bindSearchByZip()
                    }
                });
            }

            bindSearchByZip();
            if (User.storeId && User.zip) {
                self.productSelectStore();
            } else {
                $(document).trigger('customFisModalOpened', [{pid: this.dataset.pid}]);
                var url = Urls.getGeolocation,
                    geolocationObj = null;
                $.getJSON(url, function (data) {
                    if (data) {
                        geolocationObj = data;
                    } else {
                        navigator.geolocation.getCurrentPosition(function (pos) {
                            const coords = pos.coords;
                            if (coords.latitude && coords.longitude) {
                                geolocationObj.available = true;
                                geolocationObj.location = {
                                    lat: coords.latitude,
                                    long: coords.longitude
                                }
                            }
                        });
                    }
                }).done(function() {
                    if (geolocationObj && geolocationObj.available) {
                        const location = geolocationObj.location;
                        const pid = $('input#pid').val();
                        inventory.showStoresByGeolocation(location.lat, location.long, pid);
                    } else {
                        self.productSelectStore();
                    }
                    //scroll to top if mobile to prevent scrolling issue
                    if (util.isMobile()) {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                    }
                });
            }
        });

        if ($availabilityContainer.length) {
            if (User.storeId) {
                inventory.getStoresInventory(this.pid).then(storesListing);
            }

            // See more or less stores in the listing
            $availabilityContainer.on('click', '.stores-toggle', function (e) {
                e.preventDefault();
                $('.store-list-pdp .store-list-item').toggleClass('visible');
                if ($(this).hasClass('collapsed')) {
                    $(this).text(Resources.SEE_LESS);
                } else {
                    $(this).text(Resources.SEE_MORE);
                }
                $(this).toggleClass('collapsed');
            });
        }
        $('#BVRRRatingSummaryLinkReadID a').on('click', function(e) {
            e.preventDefault();
            $('html,body').animate({scrollTop: ($('#BVReviewsContainer').offset().top)-77}, 500);
        });

        $('#checkStoreInventory').on('click', function(e) {
            e.preventDefault();
            var pid = $('input[name="pid"]').val();
            var storeId = User.storeId;
            inventory.getStoreInventory(pid,storeId).done(function() {
                if ($('.isavail').length < 1) {
                    $('.ispu-service-error').text(Resources.TRY_LATER);
                }
            });
        });

        $('.delivery-option').on('change', function () {
            var isHomeDelivery = $('.delivery-option:checked').val().toLowerCase() == 'home',
                selectedStore = User.storeId,
                $deliveryInput = $('input#homeDelivery'),
                $fromStoreInput = $('input#fromStoreId'),
                $preferredStoreLink = $('.set-preferred-store'),
                $capacityErrorMsg = $('.ispuCapacityMessage:not(".hidden")'),
                isStoreAtCapacity = $('.isavail').hasClass('NA');

            // set hidden input values
            $deliveryInput.val(isHomeDelivery);
            if (selectedStore != null) {
                $fromStoreInput.val(selectedStore);
                $capacityErrorMsg.addClass('hidden');
            }
            if (!isStoreAtCapacity) {
                productInventory.hideShowStoreDetails(isHomeDelivery);
            }
            if (!selectedStore && !isHomeDelivery) {
                $preferredStoreLink.trigger('click');
            }

            if ($(this).is(':checked') && $(this).closest('.instore-delivery').length > 0) {
                var pid = $('input[name="pid"]').val();
                var isMasterProduct = $('input[name="isMasterProduct"]').val();
                var storeId = User.storeId;
                if ($('.isavail').length < 1 && isMasterProduct === 'false') {
                    inventory.updateFooterInfo(storeId, pid);
                }
            }
        });

        var storeInfoWrapUnavail = $('.product-add-to-cart-wrap .store-info-wrap.unavailable');
        //remove pink box availability message
        if ($('.unselectable.selected').length > 0) {
            $('.availability-message').addClass('hidden');
        }
        //make sure if oos item has other available sizes the color swatch with available sizes is not unselectable
        if ($('.swatches.color .unselectable.selected').length > 0 && $('.swatches.size .selectable').length > 0) {
            $('.swatches.color .unselectable.selected').removeClass('unselectable');
        }

        if ($('.swatches.size .unselectable').length > 0) {
            $('.swatches.size .unselectable').attr("aria-label","Unavailable size");
        }

        if ($('.swatches.size .selectable').length > 0) {
            $('.swatches.size .selectable').attr("aria-label","Available size");
        }

        if (storeInfoWrapUnavail.length > 0 && !isQuickView) {
            //add unavailable class to ispu radio on pdp if selected store becomes unavailable
            //show the store info as well as the change store button to show user they can change it
            $(instoreDeliveryComponents).addClass('unavailable');
            $('.set-preferred-store, .store-info, .store-info-wrap').removeClass('hidden');
        }

        if ($('.notAvailForIspuMessage').length > 0 && !isQuickView) {
            $('.instore-delivery').addClass('unavailable');
        }

        if ($('#product-content .delivery-option').length) {
            // set initial hidden input value for homeDelivery (boolean)
            if ($('#product-content .delivery-option:checked').val() == 'home') {
                $('input#homeDelivery').val(true);
            } else {
                $('input#homeDelivery').val(false);
            }
        }

        if (Number($('.inventoryAmt').val()) == 0 && $('#homeDelivery').val() == 'false' && $('#isMasterProduct').val() == 'false') {
            $('#delivery_option_home').trigger('click');
            setTimeout(function() {
                $('.store-info, .store-info-wrap, .set-preferred-store').removeClass('hidden');
                /*
                 * update swatch URLs to preserve the ISPU selection
                 * so inventory error is still displayed if another ISPU OOS variant is selected
                 * or In-Store method is selected if an ISPU available variant is selected
                 */
                $('.swatchanchor').each(function (){
                    var href = $(this).attr('href') + '&ispu=true';
                    $(this).attr('href', href);
                });
            }, 100);
        }
        productInventory.hideShowStoreDetails($('input#homeDelivery').val() == 'true');
    },
    updateLocalGetItByCutOffTime: inventory.updateLocalGetItByCutOffTime
};

module.exports = productInventory;
