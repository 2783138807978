'use strict';

var ajax = require('./ajax'),
    util = require('./util'),
    _ = require('lodash'),
    imagesLoaded = require('imagesloaded'),
    bluecore = require('./bluecore'),
    bodyScrollLock = require('body-scroll-lock');

var dialog = {
    scrollY: null,
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    create: function (params) {
        var $target, id;

        if (_.isString(params.target)) {
            if (params.target.charAt(0) === '#') {
                $target = $(params.target);
            } else {
                $target = $('#' + params.target);
            }
        } else if (params.target instanceof jQuery) {
            $target = params.target;
        } else {
            $target = $('#dialog-container');
        }

        // if no element found, create one
        if ($target.length === 0) {
            if ($target.selector && $target.selector.charAt(0) === '#') {
                id = $target.selector.substr(1);
                $target = $('<div>').attr('id', id).addClass('dialog-content').appendTo('body');
            }
        }

        // create the dialog
        this.$container = $target;
        this.$container.dialog(_.merge({}, this.settings, params.options || {}));
    },
    /**
     * @function
     * @description Opens a dialog using the given url (params.url) or html (params.html)
     * @param {Object} params
     * @param {Object} params.url should contain the url
     * @param {String} params.html contains the html of the dialog content
     */
    open: function (params, closeOtherModal = true) {
        // close any open dialog
        if ($('.ui-dialog').length && closeOtherModal) {
            this.close();
        }
        this.create(params);
        this.replace(params);
        $('.ui-dialog').attr('aria-modal', 'true');
        bluecore.pushOnDialogOpen();
    },
    /**
     * @description populate the dialog with html content, then open it
     **/
    openWithContent: function (params) {
        var content, position, callback;

        if (!this.$container) { return; }
        content = params.content || params.html;
        if (!content) { return; }
        if (params.container) {
            this.$container = params.container;
        }
        this.$container.empty().html(content);
        if (!this.$container.dialog('isOpen')) {
            var onDialogOpen = (typeof params.onDialogOpen === 'function') ? params.onDialogOpen : function () {};
            this.$container.on('dialogopen', onDialogOpen);
            this.$container.dialog('open');
        }

        if (params.options) {
            position = params.options.position;
        }
        if (!position) {
            position = this.settings.position;
        }
        imagesLoaded(this.$container).on('done', function () {
            this.$container.dialog('option', 'position', position);
        }.bind(this));

        $('.ui-dialog').focus();
        callback = (typeof params.callback === 'function') ? params.callback : function () {};
        callback();
    },
    /**
     * @description Replace the content of current dialog
     * @param {object} params
     * @param {string} params.url - If the url property is provided, an ajax call is performed to get the content to replace
     * @param {string} params.html - If no url property is provided, use html provided to replace
     */
    replace: function (params) {
        if (!this.$container) {
            return;
        }
        if (params.url) {
            params.url = util.appendParamToURL(params.url, 'format', 'ajax');
            ajax.load({
                url: params.url,
                data: params.data,
                callback: function (response) {
                    params.content = response;
                    this.openWithContent(params);
                    if (!this.scrollY) {
                        this.scrollY = window.scrollY;
                    }
                    if (this.$container.find('#pdpMain').length === 0) {
                        var stickyHeader = $('html').hasClass('sticky');
                        $('body').addClass('modal-open');
                        if (!util.isMobile()) {
                            $('html, body').css({height: '100%', overflow: 'hidden'});
                            $('#wrapper').css({'position': 'absolute', 'scrollTop': dialog.scrollY + 'px'});
                            if (window.innerWidth >= $('#main').width()) {
                                $('body.modal-open').css({width: '100vw'});
                            }
                            if (stickyHeader) {
                                // replace the sticky header after the body css is updated
                                setTimeout(function() {
                                    $('html').addClass('sticky');
                                }, 50);
                            }
                        }
                    }
                    this.$container.css({'max-height': '100vh', 'max-height': 'var(--app-height)', 'overflow-y': 'auto', '-webkit-overflow-scrolling': 'touch'});
                    if (bodyScrollLock) {
                        const target = this.$container.parent().hasClass('registration') ? this.$container.parent()[0] : this.$container[0];
                        bodyScrollLock.disableBodyScroll(target);
                    }
                    // do not use smartResize as we always want this to trigger
                    $(window).on('resize', function() {
                        dialog.settings.position = {
                            my: 'center',
                            at: 'center',
                            of: window,
                            collision: 'flipfit'
                        }
                        // only call the positioning if the dialog is visible
                        if (dialog && $(dialog.$container).is(':visible')) {
                            dialog.$container.dialog('option', 'position', dialog.settings.position);
                        }
                    });
                }.bind(this)
            });
        } else if (params.html) {
            this.openWithContent(params);
        }
        util.smartResize(function() {
            dialog.settings.position = {
                my: 'center',
                at: 'center',
                of: window,
                collision: 'flipfit'
            }
            dialog.$container.dialog('option', 'position', dialog.settings.position);
        });
    },
    /**
     * @function
     * @description Closes the dialog
     */
    close: function () {
        if (!this.isActive()) {
            return;
        }
        this.$container.dialog('close');
        if (this.$container.find('#pdpMain').length === 0 || this.$container.find('#bonus-product-dialog').length === 0) {
            $('html, body').removeClass('modal-open').css({height: 'auto', overflow: 'scroll'});
            $('#wrapper').css({'position': 'relative'});
            if (dialog.scrollY !== null) window.scrollTo({top: parseInt(dialog.scrollY, 10), left: 0});
            dialog.scrollY = null;
        }
        if (bodyScrollLock) {
            bodyScrollLock.clearAllBodyScrollLocks();
        }
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    },
    isActive: function () {
        var exists = this.exists();
        var hasChildren = exists && this.$container.children.length > 0;
        var isInPage =  exists && hasChildren && $('body').find(this.$container).length > 0;
        return isInPage;
    },
    settings: {
        autoOpen: false,
        height: 'auto',
        modal: true,
        overlay: {
            opacity: 0.5,
            background: 'black'
        },
        resizable: false,
        title: '',
        width: '800',
        close: function () {
            $(this).dialog('close');
            $('#wrapper').attr('aria-hidden', 'false');
            $('html, body').removeClass('modal-open').css({height: 'auto', overflow: 'scroll'});
            $('#wrapper').css({'position': 'relative'});
            if (dialog.scrollY !== null) window.scrollTo({top: parseInt(dialog.scrollY, 10), left: 0});
            dialog.scrollY = null;
            $(this).dialog('destroy').remove();
            if (bodyScrollLock) {
                bodyScrollLock.clearAllBodyScrollLocks();
            }
        },
        position: {
            my: 'center',
            at: 'center',
            of: window,
            collision: 'flipfit'
        }
    }
};

module.exports = dialog;
