'use strict';

const minicart = require('./minicart'),
    searchsuggest = require('./searchsuggest'),
    util = require('./util'),
    tagmanager = require('googletags/tagmanager'),
    progress = require('./progress'),
    cart = require('./pages/cart'),
    fluidconfigure = require('fluidconfigure/fluidconfigure'),
    loyaltymodal = require('./loyaltymodal'),
    upstreamworks = require('upstreamworks/upstreamworks'),
    dialog = require('./dialog'),
    StoreHelper = require('./store'),
    page = require('./page');
const modules = {
    init: function () {
        if (window.SitePreferences.ENABLE_STICKY_HEADER) {
            initStickyHeader();
        }

        initCharacterLimit();
        initSearchSuggestions();
        initSecondaryNavigation();
        initToggle();
        initEmailSignup();
        initHeaderSearch();
        initMiniCart();
        initHeader();
        initNavigation();
        // initQuantity();
        initMobileAccount();
        //initMobileNavigation();
        loyaltymodal.initEmailModalListener();
        setMicroSite();
        var getSetGeoLocateStore = User.registered && User.authenticated && !User.storeId || !User.registered && !User.authenticated;
        var eswIsDomesticUser = util.getCookie('esw.location') == 'US' && util.getCookie('esw.InternationalUser') != true;
        StoreHelper.initMyStore(eswIsDomesticUser && getSetGeoLocateStore);
        initPLPFiltration();
        initFreezeBackground();
        upstreamworks.initClickToChat();
        upstreamworks.onResizeWindow();
        initShippingCutoffModal();
        initMobileFooter();
        initDesktopFooter();
    },
    initStickyHeader: function () {
        if (window.SitePreferences.ENABLE_STICKY_HEADER) {
            initStickyHeader();
        }
    }
};

/**
 * @function
 * @desc Initializes the textarea character limit functionality
 */
function initCharacterLimit () {
    const controlKeys = ['8', '9', '13', '46', '45', '36', '35', '38', '37', '40', '39'];
    var limitReached = false;
    if ('pageContext' in window && 'ns' in window.pageContext) {
        tagmanager.init(window.pageContext.ns);
    }

    $('body').on('keydown', 'textarea[data-character-limit]', function (e) {
        const text = $.trim($(this).val()),
            charsLimit = $(this).data('character-limit'),
            charsUsed = text.length;

        if (((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) || e.which.toString() == '13' || limitReached && e.which.toString() != '8') {
            e.preventDefault();
        }
    }).on('change keyup', 'textarea[data-character-limit]', function () {
        var $textarea = $(this);
        var cursorPosition = $textarea.prop('selectionStart');
        var text = $textarea.val();
        var hasSpaceLast = false;
        if (text[text.length - 1] == ' ') {
            hasSpaceLast = true;
        }
        text = $.trim($textarea.val());
        text = text.replace(/\n/g, ' ');
        if (text.length > 500) {
            text = text.slice(0, 499);
        }
        var allWords = $.trim(text).split(' ');
        var currentLine = '';
        var tempLine = '';
        var lines = [];
        var newWords = [];
        var finalWords = [];
        for (var i = 0; i < allWords.length; i++){
            var currentWord = allWords[i];
            if (currentWord.length > 39){
                while (currentWord.length > 39) {
                    newWords.push(currentWord.slice(0, 39));
                    currentWord = currentWord.slice(40)
                }
                newWords.push(currentWord);
                if (finalWords.length) {
                    finalWords = finalWords.concat(newWords)
                } else {
                    finalWords = newWords;
                }
            } else {
                finalWords.push(currentWord);
            }
        }

        allWords = finalWords;
        var isLastWord = false;
        for (var j = 0; j < allWords.length; j++){
            if (j == allWords.length -1) {
                isLastWord = true;
            }
            var currentWord2 = allWords[j];
            if (currentWord2.length) {
                if (currentLine.length < 40) {
                    tempLine = (j == 0) ? currentWord2 : currentLine + ' ' + currentWord2;
                    if (tempLine.length > 39) {
                        lines.push($.trim(currentLine));
                        currentLine = (isLastWord && !hasSpaceLast) ? currentWord2 : currentWord2 + ' ';
                    } else {
                        currentLine += (isLastWord && !hasSpaceLast) ? currentWord2 : currentWord2 + ' ';
                    }
                } else {
                    lines.push($.trim(currentLine.slice(0, 39)));
                    currentLine = (isLastWord && !hasSpaceLast) ? currentWord2 : currentWord2 + ' ';
                }
            }
        }
        if (currentLine.trim().length > 0 && currentLine.trim().length < 40) {
            lines.push(currentLine);
        }
        var newTextareaValue = lines.slice(0,15).join('\r\n');
        $textarea.val(newTextareaValue);
        if (!(cursorPosition == 0 && ($(this).prop('selectionEnd') == $textarea.val().length))) {
            $(this).prop('selectionStart', cursorPosition);
            $(this).prop('selectionEnd', cursorPosition);
        }
    });

    // load js specific styles
    util.limitCharacters();
}

/**
 * @function
 * @desc Initializes the search suggestion functionality in the header
 */
function initSearchSuggestions () {
    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */

    searchsuggest.init($('.search-overlay .overlay-container'), Resources.SIMPLE_SEARCH);
}

/**
 * @function
 * @desc Initializes the secondary navigation functionality
 */
function initSecondaryNavigation () {
    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).next('ul').slideToggle('fast');
        $(this).children('span').toggleClass('rot180-svg');
    });
}

/**
 * @function
 * @desc Initializes the generic toggle functionality
 */
function initToggle () {
    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();

    $('body').on('click keypress', '.toggle', function (e) {
        if (e.type === 'keypress' && e.keyCode !== 13) return;
        var disabled = $(this).parents('.disable-toggle').length;
        var desktopOnly = $(this).hasClass('desktop-only-toggle');
        var mobileOnly = $(this).hasClass('mobile-only-toggle');
        disabled = disabled || (desktopOnly && !util.mediaBreakpointUp('lg'));
        disabled = disabled || (mobileOnly && util.mediaBreakpointUp('lg'));
        if (!disabled) {
            $(this).toggleClass('expanded').next('.toggle-content').toggle();
            if ($(this).is('.expanded')) {
                $(this).trigger('toggle:expanded');
                $(this).attr('aria-expanded', 'true');
            } else {
                $(this).trigger('toggle:collapsed');
                $(this).attr('aria-expanded', 'false');
            }
        }
    });
}

/**
 * @function
 * @desc Initializes the email signup functionality
 */
function initEmailSignup () {
    // subscribe email box
    const $subscribeEmail = $('.subscribe-email');

    if ($subscribeEmail.length > 0) {
        $subscribeEmail.focus(function () {
            const val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            const val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }
}
/**
 * @function
 * @desc Initializes the mini cart functionality
 */
function initMiniCart () {
    // Minicart focus
    minicart.setFocus();

    // Minicart  close button
    $('#close-minicart-btn').on('click', function() {
        minicart.close();
    });
}

/**
 * @function
 * @desc Initializes user welcome message in the header user info section
 */
function setUserWelcomeMsg() {
    const userWelcomeMsgSelector = '.js-welcome-msg';
    const $userWelcomeMsg = $(userWelcomeMsgSelector);

    if (!$userWelcomeMsg.length) {
        return;
    }

    const now = new Date();
    const currentHour = now.getHours();
    let currentTime = 'morning';

    if (currentHour > 12) {
        currentTime = currentHour < 18 ? 'afternoon' : 'evening';
    }

    $userWelcomeMsg.addClass('d-none');
    $(`${userWelcomeMsgSelector}.${currentTime}`).removeClass('d-none');
}

/**
 * @function
 * @desc Initializes header search overlay functionality
 */
function initHeaderSearch () {

    // Launch search overlay
    $('.exposed-search-wrapper').on('click', function() {
        var topBar = ($(".global-promo-placeholder").css("display") === 'none') ? 0 : $('.js-global-promo-placeholder').height();
        var height = $(window).height() - ($('.menu-active').length ? 0 : topBar);
        if ($(this).siblings('.search-overlay').length) {
            $(this).siblings('.search-overlay').css({
                'top': $('.menu-active').length ? 0 : topBar,
                'height': height,
                'padding-right': window.innerWidth - document.documentElement.clientWidth
            }).toggleClass('active');
        } else {
            $('.search-overlay').css({
                'top': $('.menu-active').length ? 0 : topBar,
                'height': height,
                'padding-right': window.innerWidth - document.documentElement.clientWidth
            }).toggleClass('active');
        }
        $('.mobile-close').addClass('search-open');
        $('#wrapper').addClass('no-scroll');
        $('nav, .header-main-navigation').addClass('search-open-width');
        $('.search-overlay input[name="q"]').focus();
    });

    // Search  close button
    $('.search-close').on('click', function() {
        $('.search-overlay').removeClass('active');
        $('.mobile-close').removeClass('search-open');
        $('#wrapper').removeClass('no-scroll');
        $('nav, .header-main-navigation').removeClass('search-open-width');
    });

    // Clear search field
    $('.clear-search-field').on('click', function() {
        $('div.search-overlay input.q').attr('value', '');
    });

    setSearchToggle();

    // Customizing the order of navigation by Tab
    $('.search-overlay', 'body')
        .on('keydown', '.submit-search', function(e) {
            var keyCode = e.keyCode || e.which;

            // The Tab key code is 9
            // Without Shift
            if (keyCode === 9 && !e.shiftKey) {
                e.preventDefault();
                $(this).closest('.search-overlay').find('.search-close').focus();
            }
        })
        .on('keydown', '.search-close', function(e) {
            var keyCode = e.keyCode || e.which;

            // The Tab key code is 9
            // With Shift
            if (keyCode === 9 && e.shiftKey) {
                e.preventDefault();
                $(this).closest('.search-overlay').find('.submit-search').focus();
            }
        });
}

/**
 * @function
 * @desc Initializes the misc. header functionality
 */
function initHeader () {
    var userInfoTimeout,
        $userInfo = $('.user-info'),
        $userAutenticathed = $('.user-info.authenticated');

    // main menu toggle
    $('.menu-toggle').on('click', function () {
        $('html').toggleClass('menu-active');
        // Check the builder dual nav menu is open
        if (document.querySelector('.mobile-category-sub-nav-title > div')) {
            document.querySelector('.mobile-category-sub-nav-title > div').click();
        }
        if ($('html').hasClass('menu-active')) {
            var element = document.getElementById('navigation');
            var focusableEls = element.querySelectorAll('a[href]:not([disabled]),.mobile-close,.launch-search');
            var firstFocusableEl = focusableEls[0];
            firstFocusableEl.focus();
            var lastFocusableEl = focusableEls[focusableEls.length - 1];
            var KEYCODE_TAB = 9;

            element.addEventListener('keydown', function (e) {
                var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableEl) {
                        lastFocusableEl.focus();
                        e.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastFocusableEl) {
                        firstFocusableEl.focus();
                        e.preventDefault();
                    }
                }
            });
            $(document).trigger('freezeBackground');
        } else {
            $('.primary-logo a').focus();
            $(document).trigger('unfreezeBackground');
        }
    });

    setUserWelcomeMsg();

    // display drawer on mouseenter for international and all logged in users
    $userAutenticathed.on('mouseenter', function () {
        userInfoTimeout = setTimeout(function() {
            $userInfo.addClass('active');
            $('body').addClass('noClick');
        }, 550);
    }).on('mouseleave', function () {
        if (!$userInfo.hasClass('active')) {
            hideUserInfo();
        }
    })

    $(document).on('click touchstart', '.authenticated .user-account', function (e) {
        e.preventDefault();
        $userInfo.addClass('active');
    });

    $userInfo.on('mouseleave', function () {
        if ($('.right-drawer').length === 0) {
            hideUserInfo();
        }
    });

    $('.right-drawer').not('.checkout').on('mouseleave', function () {
        hideUserInfo();
    });

    $userInfo.on('keyup', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.keyCode == 27) {
            hideUserInfo();
        }
    });

    $('.user-panel .button-close').on('click', function() {
        hideUserInfo();
    });

    function hideUserInfo() {
        $userInfo.removeClass('active');
        $('body').removeClass('noClick');
        if ($('.pt_cart').length > 0) {
            $userInfo.addClass('cart');
            $('.user-panel').removeClass('checkout');
        }
        clearTimeout(userInfoTimeout);
    }

    $('body').on('click', '.js-rewards-header', function() {
        $(this).toggleClass('hide-rewards');
        $('.js-rewards-content').toggleClass('hide-rewards');
        if ($('.cart-actions').length) {
            cart.handleViewportAdjustments();
        }
    });

    /**
     * Application of & Rewards, in the checkout Draw.
     */
    // handle trigger of user panel from loyalty rewards apply button
    $('body').on('click', '.reward-box .button', function(e) {
        // handle trigger of user panel from loyalty rewards apply button
        e.preventDefault();
        e.stopPropagation();
        $('.user-info').addClass('active').removeClass('cart');
        $('.user-panel').addClass('checkout');
        $('body').addClass('noClick');
    });

    // Offers addition/removal.
    $('body').on('click', '.offer-button', function(e) {
        e.preventDefault();
        var btn = $(this);

        // Prevent subsiquent clicks of the button, until request is processed and completed.
        btn.attr('disabled', true);
        // Display Loading hold:
        progress.show('#wrapper');
        // Send change request.
        $.ajax({
            url: btn.data('processpath'),
            data: {
                offerID: btn.data('offerid'),
                discountID: btn.data('discountid'),
                processRequest: btn.data('processrequest'),
                offerType: btn.data('offertype'),
                oneTimeOffer : btn.data('onetimeoffer')
            },
            method: 'POST',
            success: function(response) {
                var errorElement;
                var $data = {
                    CouponCode : btn.data('discountid')
                };
                // We have a returned response. If it's a success, toggle button states.
                if (response.success) {
                    var wrapper = $('#wrapper');
                    btn.toggleClass('offer-applied');
                    btn.data('processrequest', response.processRequest);
                    // check for offers that have been automatically un-applied due to exclusivity rules
                    if (response.hasOwnProperty('appliedOffers')) {
                        $('button.offer-applied').each(function() {
                            if (response.appliedOffers.indexOf($(this).data('offerid')) === -1) {
                                $(this).removeClass('offer-applied');
                            }
                        });
                    }
                    if (wrapper.hasClass('pt_cart')) {
                        $.ajax({
                            url: Urls.cartShow,
                            success: function(data) {
                                $('.ajax-refresh').html($(data).find('.ajax-refresh').html());
                                $('.cart-actions .mobile-subtotal').html($(data).find('.cart-actions .mobile-subtotal').html());
                                $('.afterpay.learn-more').html($(data).find('.afterpay.learn-more').html());
                                cart.reloadGA360Data(data);
                                cart.cartEvents();
                                // Release Loading hold.
                                // Put a pause on it, so that there isn't a milisecond flash (page loads faster than expected).
                                setTimeout(function() {
                                    progress.hide();
                                }, 250);
                            }
                        });
                    } else if ($('.step-2.active').length) {
                        // Billing page, need to reload.
                        page.redirect(Urls.billing);
                    } else {
                        // Reload the Checkout totals.
                        var summaryUrl = util.appendParamToURL(Urls.summaryRefreshURL, 'checkoutstep', $('.pt_checkout').data('checkoutstep'));
                        $('#secondary.summary').load(summaryUrl, function () {
                            $(this).hide().fadeIn('fast');
                            fluidconfigure.initMiniLineItems();
                            // Release hold on the button.
                            btn.attr('disabled', false);
                            // Release Loading hold.
                            // Put a pause on it, so that there isn't a milisecond flash (page loads faster than expected).
                            setTimeout(function() {
                                progress.hide();
                            }, 250);
                        });
                    }
                    $(document).trigger('addCouponSucess', [ {data: $data} ]);
                } else if (response.hasOwnProperty('result')){
                    btn.closest('.offer').find('.error').remove();
                    errorElement = '<div class="error">'+response.result.CouponStatus+'</div>';
                    btn.closest('.offer').prepend(errorElement);
                    $data.status = response.result.CouponStatus;
                    $(document).trigger('addCouponError', [ {data: $data} ]);

                    // Release Loading hold.
                    // Put a pause on it, so that there isn't a milisecond flash (page loads faster than expected).
                    setTimeout(function() {
                        progress.hide();
                    }, 250);
                } else {
                    btn.closest('.offer').find('.error').remove();
                    errorElement = '<div class="error">' + Resources.OFFER_COUPON_CODE_MISSING + '</div>';
                    btn.closest('.offer').prepend(errorElement);
                    $data.status = Resources.OFFER_COUPON_CODE_MISSING;
                    $(document).trigger('addCouponError', [ {data: $data} ]);

                    // Release Loading hold.
                    // Put a pause on it, so that there isn't a milisecond flash (page loads faster than expected).
                    setTimeout(function() {
                        progress.hide();
                    }, 250);
                }
            }
        }).done(function() {
            // Release hold on the button.
            btn.attr('disabled', false);
        });
    });

    // Rewards addition/removal.
    $('body').on('change', '.js-apply-all-rewards', function() {
        var $input = $(this);
        var $rewards = (this.checked) ? $('.reward-button:not(.offer-applied)') : $('.reward-button.offer-applied');
        $input.attr('disabled', true);
        progress.show('#wrapper');

        var rewardsData = {};
        $rewards.each(function() {
            var reward = $(this);
            rewardsData[reward.data('rewardid')] = reward.data('processrequest');
        });
        $.ajax({
            url: $input.data('processpath'),
            method: 'POST',
            data: {
                rewardsData: JSON.stringify(rewardsData)
            },
            success: function(response) {
                response.forEach(function(applyResult) {
                    var btn = $(`[data-rewardid=${applyResult.rewardID}]`);
                    if (applyResult.success) {
                        btn.toggleClass('offer-applied');
                        btn.data('processrequest', applyResult.processRequest);
                    } else {
                        var btnParent = btn.parent();
                        if (applyResult.hasOwnProperty('errorMsg')) {
                            if (btnParent.find('span.error').length) {
                                btnParent.find('span.error').html(applyResult.errorMsg);
                            } else {
                                btnParent.append('<span class="error">' + applyResult.errorMsg + '</span>');
                            }
                        }
                    }
                });

                if ($('.pt_cart').length) {
                    // Reload the Order Totals.
                    $('.cart-order-totals').load(Urls.updateCartTotals, function () {
                        $(this).hide().fadeIn('fast');
                        var $cartActions = $('.cart-actions');
                        if ($('.order-discount').length > 1) {
                            $cartActions.addClass('has-cart-rewards');
                        } else {
                            if ($cartActions.hasClass('has-cart-rewards')) {
                                $cartActions.removeClass('has-cart-rewards');
                            }
                        }
                        if ($('.cart-actions .mobile-subtotal').length > 0) {
                            $('.cart-actions .mobile-subtotal').html($('.cart-order-totals .order-total td').html() + ' : ' + $('.cart-order-totals .order-total td.order-value').html().trim());
                        }
                        $input.attr('disabled', false);
                        cart.handleViewportAdjustments();
                        // Release Loading hold.
                        // Put a pause on it, so that there isn't a milisecond flash (page loads faster than expected).
                        setTimeout(function() {
                            progress.hide();
                        }, 250);
                    });
                } else if ($('.step-2.active').length) {
                    // Billing page, need to reload.
                    page.redirect(Urls.billing);
                } else {
                    // Reload the Checkout totals.
                    var summaryUrl = util.appendParamToURL(Urls.summaryRefreshURL, 'checkoutstep', $('.pt_checkout').data('checkoutstep'));
                    $('#secondary.summary').load(summaryUrl, function () {
                        $(this).hide().fadeIn('fast');
                        fluidconfigure.initMiniLineItems();
                        $input.attr('disabled', false);
                        // Release Loading hold.
                        // Put a pause on it, so that there isn't a milisecond flash (page loads faster than expected).
                        setTimeout(function() {
                            progress.hide();
                        }, 250);
                    });
                }
            }
        });
    });

    $('body').on('click', '.reward-button', function(e) {
        e.preventDefault();
        var btn = $(this);

        // Prevent subsiquent clicks of the button, until request is processed and completed.
        btn.attr('disabled', true);
        // Display Loading hold:
        progress.show('#wrapper');
        // Send change request.
        $.ajax({
            url: btn.data('processpath'),
            data: {
                rewardID: btn.data('rewardid'),
                processRequest: btn.data('processrequest')
            },
            method: 'POST',
            success: function(response) {
                // We have a returend response. If it's a success, toggle button states.
                if (response.success) {
                    btn.toggleClass('offer-applied');
                    btn.data('processrequest', response.processRequest);
                    if ($('.pt_cart').length) {
                        // Reload the Order Totals.
                        $('.cart-order-totals').load(Urls.updateCartTotals, function () {
                            $(this).hide().fadeIn('fast');
                            var $cartActions = $('.cart-actions');
                            if ($('.order-discount').length > 1) {
                                $cartActions.addClass('has-cart-rewards');
                            } else {
                                if ($cartActions.hasClass('has-cart-rewards')) {
                                    $cartActions.removeClass('has-cart-rewards');
                                }
                            }
                            if ($('.cart-actions .mobile-subtotal').length > 0) {
                                $('.cart-actions .mobile-subtotal').html($('.cart-order-totals .order-total td').html() + ' : ' + $('.cart-order-totals .order-total td.order-value').html().trim());
                            }
                            // Release hold on the button.
                            btn.attr('disabled', false);
                            // Release Loading hold.
                            // Put a pause on it, so that there isn't a milisecond flash (page loads faster than expected).
                            cart.handleViewportAdjustments();
                            setTimeout(function() {
                                progress.hide();
                            }, 250);
                        });
                    } else if ($('.step-2.active').length) {
                        // Billing page, need to reload.
                        page.redirect(Urls.billing);
                    } else {
                        // Reload the Checkout totals.
                        var summaryUrl = util.appendParamToURL(Urls.summaryRefreshURL, 'checkoutstep', $('.pt_checkout').data('checkoutstep'));
                        $('#secondary.summary').load(summaryUrl, function () {
                            $(this).hide().fadeIn('fast');
                            fluidconfigure.initMiniLineItems();
                            // Release hold on the button.
                            btn.attr('disabled', false);
                            // Release Loading hold.
                            // Put a pause on it, so that there isn't a milisecond flash (page loads faster than expected).
                            setTimeout(function() {
                                progress.hide();
                            }, 250);
                        });
                    }
                } else {
                    // Release hold on the button.
                    btn.attr('disabled', false);
                    // Release Loading hold.
                    // Put a pause on it, so that there isn't a milisecond flash (page loads faster than expected).
                    setTimeout(function() {
                        progress.hide();
                    }, 250);
                    // if there's an error message, append it
                    var btnParent = btn.parent();
                    if (response.hasOwnProperty('errorMsg')) {
                        if (btnParent.find('span.error').length > 0) {
                            btnParent.find('span.error').html(response.errorMsg);
                        } else {
                            btnParent.append('<span class="error">' + response.errorMsg + '</span>');
                        }
                    }
                }
            }
        });
    });

    //sign out click in right drawer (refreshes page instead of redirect to sign in page)
    $('body').on('click', '.right-drawer .user-logout', function(e) {
        e.preventDefault();
        $.ajax({
            url: util.appendParamToURL($(this).attr('href'), 'calledFromDrawer', true),
            success: function() {
                page.refresh()
            }
        });
    });

    $('html').on('click', function(e) {
        if ($(e.target).closest('.user-panel').length == 0) {
            var $userInfo = $('.user-info');
            $userInfo.removeClass('active');
            if ($('.pt_cart').length > 0) {
                $userInfo.addClass('cart');
            }
            $('body').removeClass('noClick');
        }
    });


    $(window).on('resize.header', function () {
        const mobileMenuBreakpoint = util.mediaBreakpointUp('mobile-menu');

        if (mobileMenuBreakpoint && $('html').hasClass('menu-active')) {
            $('html').removeClass('menu-active');
            $(document).trigger('unfreezeBackground');
        }
    });
    if ($('#sidebarProgressLine').length >0) {
        var pointsAccrued = $('#sidebarProgressLine').data('pointsaccrued'); // points the customer has accrued (bar)
        var nextRewardLimit = $('#sidebarProgressLine').data('nextrewardlimit'); // point cost for next reward (bar)
        var pointsAccruedValue = parseInt(pointsAccrued, 10);
        nextRewardLimit = parseInt(nextRewardLimit, 10);
        pointsAccrued = pointsAccruedValue > 0 ? pointsAccruedValue : 0;

        var barLine = new window.ProgressBar.Line('#sidebarProgressLine', {
            strokeWidth: 9,
            color: $('#sidebarProgressLine').data('color') || '#f8bf15',
            trailColor: '#f8f8f8',
            trailWidth: 15,
            text: {
                style: {
                    // Text color.
                    // Default: same as stroke color (options.color)
                    color: '#000',
                    position: 'absolute',
                    top: '-22px'
                }
            },
            step: (state, barLine) => {
                var value = Math.round(pointsAccruedValue),
                    toNextReward = (Number(nextRewardLimit) - value);
                if (toNextReward === 1) {
                    barLine.setText('<span>' + toNextReward + ' point </span> until your next $10 Reward');
                } else {
                    barLine.setText(`<span>${toNextReward} points </span> until your next $10 Reward`);
                }

                // If customer balance is more than SitePreferences.NEXT_REWARD_LIMIT -- show customer balance
                if (pointsAccrued > nextRewardLimit) {
                    barLine.setText(`<span>${pointsAccrued} points </span>`);
                    barLine.text.removeAttribute('style');
                    $('#sidebarProgressLine').addClass('progress-line--hidden');
                    $('.points-count').addClass('d-none');
                }
            }
        });
        var lineProgressPercentage = (pointsAccrued / nextRewardLimit);
        barLine.set(lineProgressPercentage);  // Number from 0.0 to 1.0
        if (lineProgressPercentage > 0.95) {
            $('#sidebarProgressLine').siblings('.points-count').find('.next').addClass('active');
        }
        if (lineProgressPercentage > 0.02) {
            $('#sidebarProgressLine').siblings('.points-count').find('.curr').addClass('active');
        }
    }
}

function initPLPFiltration() {
    $(document).on('change', '#filterstoreid', function (e) {
        e.preventDefault();
        var url = $('#filterstoreid').data('action');
        const pMinVal = 'pmin=0.01';
        var isFilterStoreActive = $('#filterstoreid').is(':checked');
        //check from pmin added for storefilter
        if (!isFilterStoreActive && url.indexOf(pMinVal) > -1) {
            if (url.indexOf('pmax') < 0) {
                // let's cleanup after adding artificial filtering
                url = util.removeParamFromURL(url, 'pmin');
            } else if (url.indexOf('pmax') > -1) {
                // let's keep the pmin - just update the filtering to zero
                url = url.replace(pMinVal, pMinVal.substring(0, pMinVal.length - 1));
            }
        }
        var newAction = util.appendParamToURL(url, 'filterstoreid', (isFilterStoreActive ? 'true' : 'false'));
        window.location.href = newAction;
    });
}

/**
 * @function
 * @desc Initializes the navigation functionality
 */
function initNavigation () {
    if ($(window).innerWidth() < 1200) {
        $('.menu-category .top-level-link').filter(function() {
            return $(this).siblings('.level-2').length;
        }).addClass('disabled-top-cat');
    }

    $('.menu-category .disabled-top-cat,.menu-category .menu-item-toggle:not(.menu-category .disabled-top-cat .menu-item-toggle)').on('click', function (e) {
        e.preventDefault();
        const $parentLi = $(e.target).closest('li');
        $parentLi.siblings('li').removeClass('active');
        $parentLi.toggleClass('active');
    });

    $('.mobile-nav-background').on('click', function() {
        $('html').removeClass('menu-active');
        $(document).trigger('unfreezeBackground');
    });
}

/**
 * @function
 * @desc Initializes the sticky header functionality
 * @returns
 */
function initStickyHeader () {
    // Setup the sticky header functionality
    var $window = $(window),
        lastWindowWidth = window.innerWidth,
        scrollTriggerDesktop = 222,
        scrollTriggerTablet = 109,
        scrollTriggerMobile = 134,
        toggleStickyHeader = function (e) {
            var windowWidth = window.innerWidth;
            // Only true if the event came from a trigger call, if the event is of type
            // scroll, or only the width changed when the resize event occurs
            if (e.isTrigger === 3 || e.type === 'scroll' || lastWindowWidth !== windowWidth) {
                var scrollTop = $window.scrollTop(),
                    tabletQuery = util.mediaBreakpointUp('md'),
                    desktopQuery = util.mediaBreakpointUp('mobile-menu'),
                    scrollTrigger;

                if (desktopQuery) {
                    scrollTrigger = scrollTriggerDesktop;
                } else if (tabletQuery) {
                    scrollTrigger = scrollTriggerTablet;
                } else {
                    scrollTrigger = scrollTriggerMobile;
                }

                // Fix reviews submission 
                if ((desktopQuery || tabletQuery) && $('#bvSubmitContentMain .BVContentPreview').length) {
                    scrollTrigger += scrollTrigger;
                }

                if (scrollTop > scrollTrigger && !$('html').hasClass('sticky')) {
                    $('html').addClass('sticky');
                    $(document).trigger('stickyheaderchange');
                } else if (scrollTop <= scrollTrigger) {
                    $('html').removeClass('sticky');
                    $(document).trigger('stickyheaderchange');
                }

                lastWindowWidth = windowWidth;
            }
        };

    /**
     * @listener
     * @description Listens for the scroll and resize events on the window to toggle the sticky header when appropriate
     */
    $window.on('scroll.sticky resize.sticky', toggleStickyHeader).trigger('resize.sticky');
}
/**
 * @function
 * @desc Initializes the mobile footer functionality
 */
function initMobileFooter() {
    if ($(window).innerWidth() < util.getViewports('desktop')) {
        $('.footer-item h3 button').attr('aria-disabled', 'false');
        $('.footer-header').off('click.topsearch-dropdown');
        $(document).off('click.topsearch-dropdown');
        $('.footer-item h3').off('click.mobile-toggle').on('click.mobile-toggle', function () {
            $(this).children('button').children('span').toggleClass('rot180-svg');
            const panelExpand = $(this).children('button').attr('aria-expanded') == 'true' ? 'false' : 'true';
            $(this).children('button').attr('aria-expanded', panelExpand);
            $(this).next('.menu-footer').slideToggle('fast');
            $(this).toggleClass('no-border');
        });
        var countrySelector = $('#footer-selectors');
        if($('.footer-container').eq(1).find('#footer-selectors').length == 0){
            $('.footer-container').eq(1).append(countrySelector);
        }
        $('.jcrew-logos').hide();
    }
    util.move(util.isMobileSize(), '#footer-item-last', '#footer-item-first');
    if ($(window).innerWidth() > util.getViewports('desktop')) {
        $('.menu-footer').not('.top-searches-container .menu-footer').show();
    }
}

/**
 * @function
 * @desc Initializes the desktop footer functionality
 */
function initDesktopFooter() {
    var jcrewLogos = $('.jcrew-logos');
    var countrySelector = $('#footer-selectors');
    if ($(window).innerWidth() > util.getViewports('desktop')) {
        $('#footer-item-first').append(jcrewLogos);
        if($('.footer-selectors-copy').find('#footer-selectors').length == 0) {
            $('.footer-selectors-copy').append(countrySelector);
        }
        jcrewLogos.show();
        $('#california-do-not-sell').show();
        $('#topsearches').attr('aria-disabled', 'false');
        $('.footer-item h3').off('click.mobile-toggle');
        $('.menu-footer').removeAttr('style');
        $('.footer-header').off('click.topsearch-dropdown').on('click.topsearch-dropdown', function () {
            var $this = $(this);    
            $this.children('button').children('span').toggleClass('rot180-svg');
            var panelExpand = $this.children('button').attr('aria-expanded') == 'true' ? 'false' : 'true';
            $this.children('button').attr('aria-expanded', panelExpand);
            $this.next('.menu-footer').toggleClass('dropdown-opened');
        });
        $(document).off('click.topsearch-dropdown').on('click.topsearch-dropdown', function(e) {
            if($(e.target).closest('.footer-header').length == 0) {
                $('.menu-footer').removeClass('dropdown-opened');
                $('.footer-header').children('button').children('span').removeClass('rot180-svg');
            }
        });
    } else if ($(window).innerWidth() < util.getViewports('medium')) {
        $('#california-do-not-sell').hide();
        $('#california-do-not-sell').siblings().eq(4).text('ca transparency');
        $('#footer-item-last').insertBefore(jcrewLogos);
        jcrewLogos.show();
        countrySelector.appendTo(jcrewLogos);
        $('.copyright').find('span').each(function() {
            $(this).text('|')
        })
    }
}

/**
 * @function
 * @desc Initializes the account mobile functionality
 */
function initMobileAccount() {
    if ($(window).innerWidth() < util.getViewports('desktop')) {
        $('.mobile-toggle').unbind('click').on('click', function () {
            $(this).children('span').toggleClass('rot180-svg');
            $(this).next('.account-mobile-nav').slideToggle('fast');
            $(this).next('.account-mobile-nav').toggleClass('add-border');
            $(this).toggleClass('no-border');
        });
    }

    if ($(window).innerWidth() > util.getViewports('desktop')) {
        $('.account-mobile-nav').show();
    }
}

util.smartResize(function() {
    initMobileFooter();
    initMobileAccount();
    initDesktopFooter();
});

/**
 * @function
 * @desc initializes the mobile navigation to refresh the nav links using an ajax call
 * on click of the mens or womens microsite tabs.
 */
function initMobileNavigation() {
    var $mobileNavigationContainer = $('#navigation .menu-category-container');
    $('body').on('click', '#navigation .header-microsite-tabs a', function(e) {
        var dataMenu = $(this).attr('data-menu');

        var tabs = $('.header-microsite-tabs a');
        var activeTab = $('.header-microsite-tabs a[data-menu="' + dataMenu + '"]')

        if (window.matchMedia('(min-width: 1200px)').matches) {

            tabs.removeClass('active');
            activeTab.addClass('active');
            return;

        } else {

            if ($(this).hasClass('active')) {
                return true;
            } else {
                e.preventDefault();
            }

            tabs.removeClass('active');
            activeTab.addClass('active');

            var $search = $('.search-overlay label span');

            $search.text(dataMenu);
            var url = util.appendParamToURL(Urls.mobilemenu, 'cgid', dataMenu);
            $.ajax({
                url: url,
                success: function(result){
                    $mobileNavigationContainer.html(result);
                    initNavigation();
                }
            });
        }
    });
}

/**
 * @function
 * @desc initializes the mobile navigation to refresh the nav links using an ajax call
 * on click of the mens or womens microsite tabs.
 */
function setMicroSite() {
    $('.header-microsite-tabs a').on('click', function() {
        var microSite = $(this).data('menu');
        var url = util.appendParamToURL(Urls.setMicroSite, 'microSiteId', microSite);
        $.ajax({
            url: url
        });
    });
}

function setSearchToggle() {
    $('.header-search .search-overlay .checkbox-search-category label').on('click', function() {
        $(this).parent().parent().parent().find(".context-search-hidden").val($(this).children().val().trim())
    });
}

/**
 * @function initFreezeBackground
 * @description Set up listeners to freeze the page background in place, or unfreeze it. init freeze on modal show, unfreeze on modal disappear.
 *    This aims to fix a known iOS quirk which allows scrolling of the background when it should not be accessible by the user.
 * @example $(document).trigger('freezeBackground');
 */
function initFreezeBackground() {
    let previousScrollY = 0;

    $(document).on('freezeBackground', () => {
        previousScrollY = window.scrollY;
        $('html').addClass('freeze-scroll');
    }).on('unfreezeBackground', () => {
        $('html').removeClass('freeze-scroll');
        window.scrollTo(0, previousScrollY);
    });
}

/**
 * @function initShippingCutoffModal
 * @description Set up a click to trigger a modal displaying shipping deadlines per user type.
 */
function initShippingCutoffModal() {
    $(document).on('click', '.shipping-cutoff-cta', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        dialog.open({
            url: url,
            options: {
                width: 600,
                dialogClass: 'shipping-cutoff'
            }
        });
    });
}

module.exports = modules;
